import React from "react";
import BlurImage from "./Img/blur.png";

export default function BioTemplate4sample({value}) {
  const personalInfo=value.personal_info;
   const Navamsam=value.amsam_array;
   const amsamSlash=value.amsam_slash;
   const pavagaMaruthal=value.pavaga_maruthal;
   const RasiChart=value.rasi_array;
   const RasiSlash=value.rasivalue;
  const style = {
    sheet: {
      width: "20.50cm",
      minHeight: "29.1cm",
      margin: "10px auto",
      border: "5px solid rgb(65, 105, 225)",
      backgroundColor: "#e6e6ff",
      color: "#000",
      borderStyle: "groove",
      // margin: "5mm",
    },

    "@page": {
      size: "A4",
      margin: 0,
    },
    "@media print": {
      sheet: {
        margin: "30px",
        border: "initial",
        borderRadius: "initial",
        width: "initial",
        minHeight: "initial",
        boxShadow: "initial",
        pageBreakAfter: "always",
      },
    },
    // sheetPrint: {
    //   height: "1090px",
    //   border: "5px solid #793508",
    //   backgroundColor: "#FDE3D6",
    //   color: "#793508",
    //   borderStyle: "double",
    //   margin: "5mm",
    // },
    imgFluid: {
      height: "100%",
    },
    bgSuccess: {
      backgroundColor: "#e6ffe6 !important",
    },
    bgPink: {
      backgroundColor: "#ffebe6 !important",
    },
    bgOrange: {
      backgroundColor: "#fff5e6 !important",
    },
    bgPrimary: {
      background: "rgb(65, 105, 225) !important",
    },
    bgDark: {
      backgroundColor: "#f2f2f2 !important",
    },
    textPink: {
      color: "#009933 !important",
    },
    borderBottom: {
      borderBottom: "1px solid rgb(65, 105, 225) !important",
    },
    borderTop: {
        borderTop: "1px solid rgb(65, 105, 225) !important",
      },
    qus: {
      fontSize: "13px",
      fontWeight: "bold",
    },
    ans: {
      fontSize: "12px",
    },
    birthTable: {
      width: "100%",
      backgroundColor: "#e6e6ff", 
      fontSize: "12px",
      marginBottom: "1px",
      border: "1px solid rgb(65, 105, 225)",
      position: "relative",
    },
    laknamAfter: {
      content: "''",
      width: "23px",
      border: "1px solid rgb(65, 105, 225)",
      position: "absolute",
      top: "7px",
      left: "-4px",
      transform: "rotate(136deg)",
    },
    laknamBefore: {
      content: "''",
      width: "35px",
      border: "1px solid rgb(65, 105, 225)",
      position: "absolute",
      top: "11px",
      left: "-6px",
      transform: "rotate(136deg)",
    },
    rasiChart: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "600",
      color: "rgb(65, 105, 225)",
    },
    rasiBirth: {
      border: "1px solid rgb(65, 105, 225)",
      width: "70px",
      height: "75px",
      paddingLeft: "12px",
      position: "relative",
    },
  };

  return (
    <>
      <div id="bio-modal" className="mt-2">
        <div className=" m-auto" id="" style={style.sheet}>
          <div className="container-fluid" id="page1">
            <div className="head text-center">
              <p className="m-0 p-0">உ</p>
              <p className="m-0 p-0"></p>
            </div>
            <div className="row">
              <div className="col-12 text-center m-0 p-0">
                {/* <span className="fw-bold border-bottom">
                  <span>{personalInfo.name} </span> விவரம்
                </span> */}
              </div>
              <div className="col-6 pb-2 ">
                <div className="row">
                <div className="col-5 qus" style={style.qus}>
                  பெயர் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  {personalInfo.name}
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    பாலினம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  {personalInfo.gender}
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    பிறந்த தேதி <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.birth_date }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    பிறந்த நேரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.birth_time }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    பிறந்த ஊர் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.birth_place }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    படிப்பு <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.education }
 
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    பணி விவரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.work_details }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                  மாத வருமானம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.income }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    ஜாதி <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.caste }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    குலம்/கோத்ரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.kulam }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    உயரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.height }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    எடை <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.weight }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    நிறம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.color }
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 p-0 m-0">
                    <div className="text-center my-1 bg-primary text-white rounded-end">
                      <span className="fw-bold ">குடும்ப விவரம்</span>
                    </div>
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    தந்தை பெயர் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.father_name }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    தந்தை தொழில்  <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.father_occupation }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    தாய் பெயர் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.mother_name }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    தாய் தொழில்  <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  { personalInfo.mother_occupation }
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    உடன் பிறந்தவர்கள் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  {personalInfo.sibling.map((sibling) => sibling.value).join(', ')}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 p-0 m-0">
                    <div className="text-center my-1 bg-primary text-white rounded-end">
                      <span className="fw-bold ">ஜாதக விவரம்</span>
                    </div>
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    லக்னம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img src={BlurImage}alt="Logo"style={{ height: "15px", width: "50%" }}/>
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    ராசி/நட்சத்திரம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    தமிழ் தேதி<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    பஷம்/திதி <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    யோகம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    கரணம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    திதி சூன்யம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                  உதயாதி நாழிகை <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    சூர்ய உதயம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    சூர்ய அஸ்தம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    யோகா நட்சத்திரம்<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    யோகி<span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    அவயோகி <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    அவயோக நட்சத்திரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 p-0 m-0">
                    <div className="text-center my-1 bg-primary text-white rounded-end">
                      <span className="fw-bold ">தொடர்பு விவரம்</span>
                    </div>
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    தொலைப்பேசி <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    வாட்ஸ்ஆப் எண் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    முகவரி <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-5 qus" style={style.qus}>
                    சொத்து விபரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-7 ans text-break p-0" style={style.ans}>
                  {personalInfo.assets_details}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12 p-1">
                    <div className="text-center" style={{ height: "250px" }}>
                    <img src={`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`} className="img-fluid" alt="sss" />
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 p-0 m-0">
                    <div className="text-center my-1 bg-primary text-white rounded-start">
                      <span className="fw-bold">ஜாதக கட்டம்</span>
                    </div>
                  </div>
                  <div className="col-12 pb-2">
                  <table
                      style={style.birthTable} cellSpacing="2"cellPadding="2" >
                      <tbody>
                        <tr>
                          <td className={` ${RasiSlash.r12}`} style={ style.rasiBirth }>
                          <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                          </td>
                          <td className={` ${RasiSlash.r1}`} style={ style.rasiBirth }> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td className={` ${RasiSlash.r2}`} style={ style.rasiBirth }><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td className={` ${RasiSlash.r3}`} style={ style.rasiBirth }><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                            
                          </td>
                        </tr>
                        <tr>
                          <td className={` ${RasiSlash.r11}`} style={ style.rasiBirth }><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td
                            className=" chart-birth" style={ style.rasiBirth }
                            width="220"
                            height="220"
                            colSpan="2"
                            rowSpan="2"
                          >
                            <p className="" style={style.rasiChart}> ராசி </p>
                          </td>
                          <td className={` ${RasiSlash.r4}`} style={ style.rasiBirth }><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                        
                          </td>
                        </tr>
                        <tr>
                          <td className={` ${RasiSlash.r10}`} style={ style.rasiBirth } valign="top"><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td className={` ${RasiSlash.r5}`} style={ style.rasiBirth } valign="top"><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                        </tr>
                        <tr>
                          <td className={` ${RasiSlash.r9}`} style={ style.rasiBirth } valign="top"><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td className={` ${RasiSlash.r8}`} style={ style.rasiBirth } valign="top"><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td className={` ${RasiSlash.r7}`} style={ style.rasiBirth } valign="top"><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                          <td className={` ${RasiSlash.r6}`}  style={ style.rasiBirth } valign="top"><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12 pb-2">
                   
                    <table className="" cellSpacing="2" cellPadding="2" style={style.birthTable}>
                  <tbody>
                    <tr>
                      <td className={` ${amsamSlash.a12}`} style={style.rasiBirth}>
                      <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />  </td>
                      <td className={` ${amsamSlash.a1}`}  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                      <td className={` ${amsamSlash.a2}`}  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                      <td className={` ${amsamSlash.a3}`} style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td className={` ${amsamSlash.a11}`}  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                      <td
                        className=" chart-birth"
                        width="220"
                        height="220"
                        colSpan="2"
                        rowSpan="2"  style={style.rasiBirth}
                      >
                        <p className="rasi-chart" style={style.rasiChart}> நவாம்சம் </p>
                      </td>
                      <td className={` ${amsamSlash.a4}`} style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td className={` ${amsamSlash.a10}`} valign="top"  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                      <td className={` ${amsamSlash.a5}`} valign="top"  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td className={` ${amsamSlash.a9}`} valign="top"  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                      </td>
                      <td className={` ${amsamSlash.a8}`} valign="top"  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                      <td className={` ${amsamSlash.a7}`} valign="top"  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                      <td className={` ${amsamSlash.a6}`} valign="top"  style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 p-0 m-0">
                <div className="text-center my-1 bg-primary text-white rounded-start">
                  <span className="fw-bold">பொருந்தும் நட்சத்திரம்</span>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-4 qus" style={style.qus}>
                    பொருந்தும் நட்சத்திரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-8 ans p-0 text-break" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />   <img
                            src={BlurImage}
                            alt="Logo"
                            style={{ height: "15px", width: "50%" }}
                          />
                  </div>
                </div>
              </div>
            </div>
            <div
  className="container-fluid text-center ans pb-1"
  style={{ ...style.qus, borderTop: "2px solid rgb(65, 105, 225)" }}
>
  <span className="">
    இந்த ஜாதகம் www.onepagehoroscope.com இணைய தளத்தில்
    உருவாக்கப்பட்டது...
  </span>
</div>

          </div>
        </div>
      </div>
    </>
  );
}
