import "./mgPaidTemplate.css";

function MgPaidTemplate({ value }) {
  const personalInfo = value.personal_info;
  const femaleStarmatchValues = value.female_jathagam_info;
  const maleStarMatchValues = value.male_jathagam_info;
  const Result = value.matching_values;
  const MaleRasihart = value.male_rasi_table;
  const FemaleChart = value.female_rasi_table;
  const FemaleNavamsam = value.female_amsam_table;
  // console.log(FemaleNavamsam);
  const MaleNavamsam = value.male_amsam_table;
  const FemaleRaiSlash = value.female_rasi_slash;
  const MaleRasiSlash = value.male_rasi_slash;
  const FemaleNavamsamsamSlash = value.female_amsam_slash;
  const maleNavamsamSlash = value.male_amsam_slash;
// console.log(personalInfo);
  return (
    <>
      <div className="subpagemg mt-0 " id="page1">
        <div className="head">
          <table width="100%" id="heading" border="0">
            <tr>
              <td width="33.33%">
                <center>
                  <img src={require("./img/bride.png")} alt="" width="100" />
                </center>
              </td>
              <td width="33.33%">
                <img
                  src={require("./img/one-page-horoscope-mrriage-vintage.png")}
                  alt=""
                  width="180"
                />
              </td>
              <td width="33.33%">
                <center>
                  <img
                    src={require("./img/groom.png")}
                    alt=""
                    width="100"
                    height=""
                  />
                </center>
              </td>
            </tr>
          </table>
        </div>
        <table width="100%" id="detail">
          <tr>
            <td width="50%" className="girl-detail">
              <table width="100%">
                <span className="heading">
                  <h3 >ØÃõ Ûªª sk«D</h3>
                </span>
                <tr>
                  <th width="40%">பெயர்</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "15px" }}
                  >
                    : {personalInfo.female_name}
                  </td>
                </tr>
                <tr>
                  <th width="40%">பிறந்த தேதி</th>
                  <td width="60%" style={{ fontSize: "15px" }}>
                    : {personalInfo.female_dob}
                  </td>
                </tr>
                <tr>
                  <th width="40%">பிறந்த நேரம் </th>
                  <td width="60%" style={{ fontSize: "15px" }}>
                    : {personalInfo.female_birth_time}
                  </td>
                </tr>
                <tr>
                  <th width="40%">பிறந்த இடம்</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "12px" }}
                  >
                    : {personalInfo.female_birthplace}
                  </td>
                </tr>
                <tr>
                  <th width="40%">ராசி</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "12px" }}
                  >
                    : {personalInfo.female_rasi}
                  </td>
                </tr>
                <tr>
                  <th width="40%">நட்சத்திர / பாதம்</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "12px" }}
                  >
                    : {personalInfo.female_natchathiram}-
                    {personalInfo.female_patham}
                  </td>
                </tr>
                <tr>
                  <th width="40%">லக்னம்</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "12px" }}
                  >
                    :{personalInfo.female_laknam}
                  </td>
                </tr>
                <tr>
                  <th width="40%">நடப்பு திசை</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "12px" }}
                  >
                    : {personalInfo.female_nadapu_thisa}
                  </td>
                </tr>
              </table>
            </td>
            <td width="50%" className="boy-detail">
              <table width="100%">
                <span className="heading">
                  <h3>gõ Ûªª sk«D</h3>
                </span>
                <tr>
                  <th width="40%">பெயர்</th>
                  <td
                    width="60%"
                    style={{ fontFamily: "arial", fontSize: "15px" }}
                  >
                    : {personalInfo.male_name}
                  </td>
                </tr>
                <tr>
                  <th width="40%">பிறந்த தேதி</th>
                  <td width="60%" style={{ fontSize: "15px" }}>
                    : {personalInfo.maleDateofBirth}
                  </td>
                </tr>
                <tr>
                  <th width="40%">பிறந்த நேரம் </th>
                  <td width="60%" style={{ fontSize: "15px" }}>
                    : {personalInfo.male_birth_time}
                  </td>
                </tr>
                <tr>
                  <th width="40%">பிறந்த இடம்</th>
                  <td width="60%" style={{ fontSize: "12px" }}>
                    : {personalInfo.male_birthplace}
                  </td>
                </tr>
                <tr>
                  <th width="40%">ராசி</th>
                  <td width="60%">: {personalInfo.male_rasi}</td>
                </tr>
                <tr>
                  <th width="40%">நட்சத்திர / பாதம்</th>
                  <td width="60%">
                    : {personalInfo.male_natchathiram}-{" "}
                    {personalInfo.male_patham}
                  </td>
                </tr>
                <tr>
                  <th width="40%">லக்னம்</th>
                  <td width="60%">:{personalInfo.male_laknam}</td>
                </tr>
                <tr>
                  <th width="40%">நடப்பு திசை</th>
                  <td width="60%">:{personalInfo.male_nadapu_thisa}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <div className="heading">
          <img
            src={require("./img/srat-split-one-page-horoscope.png")}
            alt=""
            width="50"
          />
          <h3>åâÄÝ]« ØÃVòÝ&gt;D</h3>
        </div>
        {/* div */}
        <table width="100%" border="0" className="star">
          <tr>
            <td width="50%">
              <table className="w-100 my-3 star-left" border="0">
                <tr>
                  <th
                    width="70%"
                    className="p-2 w-70 font-small font-weight-bold text-primary"
                  >
                    தினம் (ஆயுள் ஆரோக்கியம்){" "}
                  </th>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.thinam_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.thinam_icon}`}></i>
                    {value.mathipu.thinam}
                  </td>
                </tr>
                <tr>
                  <th className="p-2 w-70 font-small font-weight-bold text-primary">
                    கணம் (மங்களம் சுப சோபனங்கள்)
                  </th>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.ganam_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.ganam_icon}`}></i>
                    {value.mathipu.ganam_po}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    மகேந்திரம் (புத்திர விருத்தி)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.magenthira_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.magenthira_icon}`}></i>
                    {value.mathipu.magenthira}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    ஸ்திரி தீர்க்கம் (ஆத்மார்த்த நட்பு)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.isthiri_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.isthiri_icon}`}></i>
                    {value.mathipu.isthiri}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    யோனி (தாம்பத்யம்){" "}
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.yoni_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.yoni_icon}`}></i>
                    {value.mathipu.yoni_po}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    ராசி (வம்ச விருத்தி)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.rasi_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.rasi_icon}`}></i>
                    {value.mathipu.rasi}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    ராசியதிபதி (சந்தான விருத்தி)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.rasi_athipathi_color}`}
                  >
                    <i
                      class={`fa-solid ${value.mathipu.rasi_athipathi_icon}`}
                    ></i>
                    {value.mathipu.rasi_athipathi}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    வசியம் (அன்யோன்ய நட்பு)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.vasiya_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.vasiya_icon}`}></i>
                    {value.mathipu.vasiya}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    ரஜ்ஜு (மாங்கல்ய பாக்கியம்)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.rajju_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.rajju_icon}`}></i>
                    {value.mathipu.rajju}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    வேதை (நட்பும் ஒற்றுமையும்)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.vedhai_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.vedhai_icon}`}></i>
                    {value.mathipu.vedhai}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    நாடி (உடல் நலம்)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.nadi_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.nadi_icon}`}></i>
                    {value.mathipu.nadi}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-70 font-small font-weight-bold text-primary">
                    மர (புத்திர பாக்கியம்)
                  </td>
                  <td
                    className={`p-2 w-30 text-center font-small ${value.mathipu.mara_color}`}
                  >
                    <i class={`fa-solid ${value.mathipu.mara_icon}`}></i>
                    {value.mathipu.mara}
                  </td>
                </tr>
              </table>
            </td>
            <td width="50%">
              <table className="w-100 my-3 text-center star-right">
                <tr className="text-center border border-dark">
                  <td
                    colSpan="2"
                    className="py-2 w-50 border border-dark heading"
                  >
                    <h3 className=""></h3>
                  </td>
                  <td
                    colSpan="2"
                    className="py-2 w-50 border border-dark heading"
                  >
                    <h3></h3>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-1">மிருகம்</td>
                  <td>
                    <b>
                      {femaleStarmatchValues.female_mirugam}
                      {femaleStarmatchValues.female_mirugam_group}
                    </b>
                  </td>
                  <td className="p-1 border-left border-dark">மிருகம் </td>
                  <td>
                    <b>
                      {maleStarMatchValues.male_mirugam}
                      {maleStarMatchValues.male_mirugam_group}
                    </b>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-1">கணம்</td>
                  <td>
                    <b>{femaleStarmatchValues.femlale_ganam_tamil}</b>
                  </td>
                  <td className="p-1 border-left border-dark">கணம்</td>
                  <td>
                    <b>{maleStarMatchValues.mlale_ganam_tamil}</b>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-1">ரஜ்ஜு</td>
                  <td>
                    <b>{femaleStarmatchValues.female_rajju_type_tamil}</b>
                  </td>
                  <td className="p-1 border-left border-dark">ரஜ்ஜு</td>
                  <td>
                    <b>{maleStarMatchValues.male_rajju_type_tamil}</b>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-1">மரம்</td>
                  <td>
                    <b>{femaleStarmatchValues.female_virucham_tamil}</b>
                  </td>
                  <td className="p-1 border-left border-dark">மரம்</td>
                  <td>
                    <b>{maleStarMatchValues.male_virucham_tamil}</b>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className="p-1">பட்சி</td>
                  <td>
                    <b>{femaleStarmatchValues.female_patchi_tamil}</b>
                  </td>
                  <td className="p-1 border-left border-dark">பட்சி</td>
                  <td>
                    <b>{maleStarMatchValues.male_patchi_tamil}</b>
                  </td>
                </tr>
              </table>
              <div className="text-center sevvai-right-bottom">
                <p className="mb-1">
                  ஜாதக ராசிப்படி <span style={{ fontSize: "15px" }}>12</span>{" "}
                  க்கு{" "}
                  <span style={{ fontSize: "15px" }}>
                    {value.total_porutham_count}
                  </span>{" "}
                  பொருத்தங்கள் உண்டு
                </p>
                <p className="border border-primary px-5 py-2 mb-1">
                  இருவருக்கும் திசை சந்திப்பு{" "}
                  {value.matching_values.thasa_santhipu}
                </p>
                <p className="border border-primary px-5 py-2 mb-1">
                  இருவருக்கும் செவ்வாய் தோஷப் பொருத்தம்
                  {value.matching_values.sevvai_thosam}
                </p>
              </div>
            </td>
          </tr>
        </table>
        <div className="heading">
          <h3>ØÄËkVF gF¡ </h3>
        </div>
        <div className="w-100 sevvai">
          <div className="row ">
            <div className="col-6">
              <h3 className="h-7 my-1 text-danger">
                செவ்வாய் தோஷப் பொருத்தம்:
              </h3>
              <p className="fs-7 text-justify">
                ஒவ்வொரு ஜாதகத்திலும் செவ்வாய் தோஷத்திற்கு மிகவும் முக்கியத்துவம்
                கொடுக்கப்பட வேண்டும். திருமண பொருத்தம் பார்க்கும் பொழுது
                செவ்வாய் கிரகத்தினுடைய நிலை முக்கியத்துவம் பெறுகிறது. செவ்வாய்
                ஏழாம் ஸ்தானத்திலோ அல்லது எட்டாம் ஸ்தானத்திலோ உள்ளதினால் செவ்வாய்
                தோஷம் உண்டு என்று பலரும் சொல்லுவார்கள். ஏழாம் அல்லது எட்டாம்
                ஸ்தானத்திலுள்ள செவ்வாய் தோஷத்திற்கு பல விலக்குகள் உள்ளதாக ஆதார
                பூர்வமான நூல்கள் கூறுகின்றன.இந்த முறையில் உள்ள செவ்வாய் தோஷ
                நிர்ணயம் வலது பக்கத்தில் கொடுக்கப்பட்டுள்ளது.
              </p>
            </div>
            <div className="col-6">
              <h3 className="h-7 my-1 font-weight-bold text-danger">
                லக்னம் ராசியிலிருந்து செவ்வாய் ஆய்வு:
              </h3>
              <p className="fs-7">
                பெண்ணிற்கு செவ்வாய்{" "}
                <span style={{ fontSize: "15px" }}>
                  {Result.female_sevvai_posi}
                </span>{" "}
                -ஆம் வீட்டில் உள்ளது : <b> </b>.
              </p>
              <p className="fs-7">
                ஆணிற்கு செவ்வாய்{" "}
                <span style={{ fontSize: "15px" }}>
                  {Result.male_sevvai_posi}
                </span>
                -ஆம் வீட்டில் உள்ளது : <b> </b>.
              </p>
              <table width="100%" border="1" className="my-3 text-center star-right">
                <tr className="text-center border border-dark">
                  <td
                    width="33.33%"
                    className="py-2 border border-dark heading"
                  >
                    <h3 className="h8">தோஷம்</h3>
                  </td>
                  <td className="py-2 border border-dark heading">
                    <h3 className=""></h3>
                  </td>
                  <td className="py-2  border border-dark heading">
                    <h3></h3>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 text-dark border border-dark font-weight-bold">
                    செவ்வாய்
                  </td>
                  <td className="py-2 border border-dark font-weight-bold">
                    {Result.female_sevvai}
                  </td>
                  <td className="py-2 border border-dark font-weight-bold">
                    {Result.male_sevvai}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className=" star-right-bottom my-1 text-center">
          <span className="fs-7 text-success font-weight-bold" style={{ fontSize: "13px" }}>
  {Result.sevvai_thosa_porutham}
</span>

          </div>
        </div>
       
        
<br />
          <div className="heading mt-2">
            <h3>  </h3>

          </div>
          <div className="thasai w-100">
            <div className="row">
              <div className="col-5">
                <h3 className="h-7 my-1 text-danger">
                  தசா சந்திப்பு பொருத்தம்:
                </h3>
                <p className="fs-7 text-justify ">
                  ஆண் அல்லது பெண் இருவருக்கும்{" "}
                  <span style={{ fontSize: "15px" }}>18</span> மாத காலத்தில் தசா
                  மாறுதல் ஏற்ப்பட்டால் அதனை தசா சந்தி என்று ஐதீகம்.
                </p>
                <p className="fs-7 text-justify ">
                  இருவருக்கும் ஒரே தசா இருந்தால் கூட்டு தசா ஏற்படும்
                </p>
                <p className="fs-7 text-justify ">
                  இருவருக்கும் வெவ்வேறு தசா இருந்தால் உத்தமம்
                </p>
              </div>
              <div className="col-7">
                <p className="fs-7 text-justify mb-0">
                  பெண்பிறந்த தேதி :-
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.female_dob}
                  </span>
                </p>
                <p className="fs-7 text-justify mb-0">
                  ஜனனகாலத்தில் தசை இருப்பு {personalInfo.female_thasa_value}
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.female_year_int}
                  </span>{" "}
                  வருடம்
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.female_month_int}
                  </span>{" "}
                  மாதம்
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.female_day_int}
                  </span>{" "}
                  நாள்
                </p>
                <p className="fs-7 text-justify mb-0">
                  {personalInfo.female_nadapu_thisa} தசை முடிவுறுகிறது
                  <span style={{ fontSize: "13px" }}> </span>
                </p>
                <p className="fs-7 text-justify mb-0">
                  ஆண்பிறந்த தேதி :-
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.maleDateofBirth}
                  </span>
                </p>
                <p className="fs-7 text-justify mb-0">
                  ஜனனகாலத்தில் தசை இருப்பு {personalInfo.male_thasa_value}
                  <span style={{ fontSize: "14px" }}>
                    {personalInfo.male_year_int}
                  </span>{" "}
                  வருடம்
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.male_month_int}
                  </span>{" "}
                  மாதம்
                  <span style={{ fontSize: "13px" }}>
                    {personalInfo.male_day_int}
                  </span>{" "}
                  நாள்
                </p>
                <p className="fs-7 text-justify mb-0">
                  {personalInfo.male_nadapu_thisa} தசை முடிவுறுகிறது
                  <span style={{ fontSize: "15px" }}> </span>
                </p>
              </div>
            </div>
            <div className=" star-right-bottom my-1 text-center">
              <span className="fs-7 text-success font-weight-bold p-1">
                {value.personal_info.thasa_porutham}
              </span>
            </div>
          </div>
          <div className="heading">
            <h3>ÃVÃÄVDBD gF¡</h3>
          </div>
          <div className="pavam w-100">
            <div className="row">
              <div className="col-6">
                <h3 className="h-7  text-danger">பெண்ணின் பாவசாம்யம் :</h3>
                <table width="100%" className="pavam-left">
                  <tr>
                    <th width="25%">பெண்</th>
                    <th width="25%">லக்னம் </th>
                    <th width="25%">சுக்ரன் </th>
                    <th width="25%">சந்திரன் </th>
                  </tr>
                  <tr>
                    <td>பாவம்</td>
                    <td style={{ fontSize: "14px" }}>
                      {value.female_pavasamayam.female_total_lak_pavam}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {value.female_pavasamayam.female_total_suk_pavam}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {value.female_pavasamayam.female_total_chan_pavam}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-6">
                <h3 className="h-7  text-danger">ஆணின் பாவசாம்யம் :</h3>
                <table width="100%" className="pavam-right">
                  <tr>
                    <th width="25%">ஆண்</th>
                    <th width="25%">லக்னம் </th>
                    <th width="25%">சுக்ரன் </th>
                    <th width="25%">சந்திரன் </th>
                  </tr>
                  <tr>
                    <td>பாவம்</td>
                    <td style={{ fontSize: "14px" }}>
                      {value.male_pavasamayam.male_total_lak_pavam}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {value.male_pavasamayam.male_total_suk_pavam}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {value.male_pavasamayam.male_total_chan_pavam}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          {/* <br /> */}
         
        
         <div className="row">
            <div className="col-6">
              <div className="heading  my-1">
                <h3 style={{ fontSize: "12px" }}>பெண் ராசி</h3>
              </div>
              <table className="birth-table-1" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td className={`rasi-birth-1 ${FemaleRaiSlash.r12}`}>
                      {FemaleChart.female_meenam}
                    </td>
                    <td className={`rasi-birth-1 ${FemaleRaiSlash.r1}`}>
                      {FemaleChart.female_mesham}
                    </td>
                    <td className={`rasi-birth-1 ${FemaleRaiSlash.r2}`}>
                      {FemaleChart.female_rishbam}
                    </td>
                    <td className={`rasi-birth-1 ${FemaleRaiSlash.r3}`}>
                      {FemaleChart.female_mithunam}
                    </td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth-1 ${FemaleRaiSlash.r11}`}>
                      {FemaleChart.female_kumbam}
                    </td>
                    <td
                      className="rasi-birth-1 chart-birth-1"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart-1"> ராசி </p>
                    </td>
                    <td className={`rasi-birth-1 ${FemaleRaiSlash.r4}`}>
                      {FemaleChart.female_kadagam}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-1 ${FemaleRaiSlash.r10}`}
                      valign="top"
                    >
                      {FemaleChart.female_magaram}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleRaiSlash.r5}`}
                      valign="top"
                    >
                      {FemaleChart.female_simmam}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-1 ${FemaleRaiSlash.r9}`}
                      valign="top"
                    >
                      {FemaleChart.female_dhanush}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleRaiSlash.r8}`}
                      valign="top"
                    >
                      {FemaleChart.female_viruchagam}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleRaiSlash.r7}`}
                      valign="top"
                    >
                      {FemaleChart.female_thulam}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleRaiSlash.r6}`}
                      valign="top"
                    >
                      {FemaleChart.female_kanni}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6">
              <div className="heading  my-1">
                <h3 style={{ fontSize: "12px" }}>ஆண் ராசி</h3>
              </div>
              <table className="birth-table-2" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td className={`rasi-birth-2 ${MaleRasiSlash.r12}`}>
                      {MaleRasihart.male_meenam}
                    </td>
                    <td className={`rasi-birth-2 ${MaleRasiSlash.r1}`}>
                      {MaleRasihart.male_mesham}
                    </td>
                    <td className={`rasi-birth-2 ${MaleRasiSlash.r2}`}>
                      {MaleRasihart.male_rishbam}
                    </td>
                    <td className={`rasi-birth-2 ${MaleRasiSlash.r3}`}>
                      {MaleRasihart.male_mithunam}
                    </td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth-2 ${MaleRasiSlash.r11}`}>
                      {MaleRasihart.male_kumbam}
                    </td>
                    <td
                      className="rasi-birth-2 chart-birth-2"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart-2"> ராசி </p>
                    </td>
                    <td className={`rasi-birth-2 ${MaleRasiSlash.r4}`}>
                      {MaleRasihart.male_kadagam}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-2 ${MaleRasiSlash.r10}`}
                      valign="top"
                    >
                      {MaleRasihart.male_magaram}
                    </td>
                    <td
                      className={`rasi-birth-2 ${MaleRasiSlash.r5}`}
                      valign="top"
                    >
                      {MaleRasihart.male_simmam}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-2 ${MaleRasiSlash.r9}`}
                      valign="top"
                    >
                      {MaleRasihart.male_dhanush}
                    </td>
                    <td
                      className={`rasi-birth-2 ${MaleRasiSlash.r8}`}
                      valign="top"
                    >
                      {MaleRasihart.male_viruchagam}
                    </td>
                    <td
                      className={`rasi-birth-2 ${MaleRasiSlash.r7}`}
                      valign="top"
                    >
                      {MaleRasihart.male_thulam}
                    </td>
                    <td
                      className={`rasi-birth-2 ${MaleRasiSlash.r6}`}
                      valign="top"
                    >
                      {MaleRasihart.male_kanni}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6">
              <div className="heading  my-1">
                <h3 style={{ fontSize: "12px" }}>பெண் நவாம்சம்</h3>
              </div>
              <table className="birth-table-1" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam12}`}
                    >
                      {FemaleNavamsam.f_a12}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam1}`}
                    >
                      {FemaleNavamsam.f_a1}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam2}`}
                    >
                      {FemaleNavamsam.f_a2}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam3}`}
                    >
                      {FemaleNavamsam.f_a3}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam11}`}
                    >
                      {FemaleNavamsam.f_a11}
                    </td>
                    <td
                      className="rasi-birth-1 chart-birth-1"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart-1"> நவாம்சம் </p>
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam4}`}
                    >
                      {FemaleNavamsam.f_a4}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam10}`}
                      valign="top"
                    >
                      {FemaleNavamsam.f_a10}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam5}`}
                      valign="top"
                    >
                      {FemaleNavamsam.f_a5}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam9}`}
                      valign="top"
                    >
                      {FemaleNavamsam.f_a9}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam8}`}
                      valign="top"
                    >
                      {FemaleNavamsam.f_a8}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam7}`}
                      valign="top"
                    >
                      {FemaleNavamsam.f_a7}
                    </td>
                    <td
                      className={`rasi-birth-1 ${FemaleNavamsamsamSlash.female_slash_amsam6}`}
                      valign="top"
                    >
                      {FemaleNavamsam.f_a6}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6">
              <div className="heading my-1">
                <h3 style={{ fontSize: "12px" }}>ஆண் நவாம்சம்</h3>
              </div>
              <table className="birth-table-2" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam12}`}
                    >
                      {MaleNavamsam.m_a12}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam1}`}
                    >
                      {MaleNavamsam.m_a1}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam2}`}
                    >
                      {MaleNavamsam.m_a2}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam3}`}
                    >
                      {MaleNavamsam.m_a3}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam11}`}
                    >
                      {MaleNavamsam.m_a11}
                    </td>
                    <td
                      className="rasi-birth-2 chart-birth-2"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart-2"> நவாம்சம் </p>
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam4}`}
                    >
                      {MaleNavamsam.m_a4}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam10}`}
                      valign="top"
                    >
                      {MaleNavamsam.m_a10}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam5}`}
                      valign="top"
                    >
                      {MaleNavamsam.m_a5}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam9}`}
                      valign="top"
                    >
                      {MaleNavamsam.m_a9}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam8}`}
                      valign="top"
                    >
                      {MaleNavamsam.m_a8}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam7}`}
                      valign="top"
                    >
                      {MaleNavamsam.m_a7}
                    </td>
                    <td
                      className={`rasi-birth-2 ${maleNavamsamSlash.male_slash_amsam6}`}
                      valign="top"
                    >
                      {MaleNavamsam.m_a6}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        
          
          <div className="heading my-1">
            <h3 style={{ fontSize: "15px" }}>
              பொருத்ததின் விளக்கமும் சிபாரிசும்{" "}
            </h3>
          </div>
          <div className="result w-100">
            <div className="row justify-content-center">
              <div className="col-10">
                <table width="100%" id="result" border="1">
                  <tr>
                    <th width="50%" className="py-1">
                      சோதனை
                    </th>
                    <th width="50%" className="py-1">
                      பலன்கள்
                    </th>
                  </tr>
                  <tr>
                    <td>நட்சத்திர பொருத்தம்</td>
                    <td className="font-weight-bold py-1">
                      {value.star_porutham.final_natchathira_porutham}
                    </td>
                  </tr>
                  <tr>
                    <td>செவ்வாய் தோஷப் பொருத்தம் </td>
                    <td className="font-weight-bold py-1">
                      {value.star_porutham.final_sevvai_porutham}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="star-right-bottom my-1 text-center">
                {(value.star_porutham.final_natchathira_porutham).trim() ===
                  "திருப்திகரமாகும்" &&
                value.star_porutham.final_sevvai_porutham ===
                  "திருப்திகரமாகும்" ? (
                  <span className=" text-success font-weight-bold p-1">
                    பொருத்தம் சிபாரிசு செய்யப்படுகிறது
                  </span>
                ) : (
                  <span className=" text-danger font-weight-bold p-1">
                    பொருத்தம் சிபாரிசு செய்யப்படவில்லை
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="foot m-0 p-0">
            <span>
              இந்த பொருத்தம்{" "}
              <span style={{ fontFamily: "arial", fontSize: "11px" }}>
                www.onepagehoroscope.com
              </span>{" "}
              இணைய தளத்தில் உருவாக்கப்பட்டது...
            </span>
          </div>
        
      </div>
    </>
  );
}
export default MgPaidTemplate;
