import React from "react";

import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";

function BabySampleRedirect(){
    return (
        <>
       <Helmet>
        <script>
          {`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '289014880786777');
           fbq('track', 'PageView');
           fbq('track', 'CompleteRegistration');
          `}
        </script>
        <noscript>
          {`  <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=289014880786777&ev=PageView&noscript=1"
            />
            `}
        </noscript>
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-11314190467/FQu6CLTYyNcYEIOxg5Mq'});
          `}
        </script>
      </Helmet>
        <Menubar />
      <Content />
      <Footer />
        </>
    )
}

const Content=()=>{
    const redirectStyle = {
        orderIcon: {
          fontSize: "70px",
          color: "green",
          textAlign: "center",
        },
        orderCard: {
          borderRadius: "20px",
          backgroundColor: "#ffffff",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          border: "none",
        },
        orderId: {
          fontSize: "17px",
          textAlign: "center",
        },
        orderSuccess: {
          fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
          textAlign: "center",
          marginTop: "3%",
          fontSize: "2em",
          color: "green",
        },
        orderThank: {
          fontFamily:
            '"Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif',
          color: "black",
          textAlign: "center",
        },
      };
      const Redirect=()=>{
        window.location='/Baby-Name-view';
      }
    
      return (
        <>
          <div className="container " style={{marginTop:"150px"}}>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-9">
                <div className="card p-1" style={redirectStyle.orderCard}>
                  <div className="card-body text-center">
                    <div className="text-start"></div>
                    <i className="fa-sharp fa-solid fa-circle-check" style={redirectStyle.orderIcon}></i>
                    <h3 className="text-center mt-3 fs-2" style={redirectStyle.orderSuccess}>
                    success
                    </h3>
                    <p className="orderid" style={redirectStyle.orderId}>
                      {/* Transaction ID: <span className="fw-bold text-dark"></span> */}
                    </p>
                    <p className="text-center fw-bold order-thank" style={redirectStyle.orderThank}>
                    உங்களது Sample Baby Name நீங்கள் கொடுக்கப்பட்ட WhatsApp மற்றும் Email க்கு வெற்றிகரமாக அனுப்பப்பட்டது. 
                    </p>
                    <p className="text-center">உங்கள் செல்ல குழந்தைகளுக்கு பெயர் சூட்டுவதற்கு நியூமராலஜி படியும் , பிரமிட் முறையிலும் உதாரண  பெயர்கள் மற்றும் பெயர்கள் தருகிறோம்,

அறிமுக சலுகையாக 249 க்கு மட்டுமே தருகிறோம் ,இது உங்கள் குழந்தை க்கு பெயர் சூட்ட பயனுள்ளதாக இருக்கும்,வேறு எந்த ஒரு இணைய தளத்திலும் நமது பெயர் பட்டியல் போல கிடைக்காது    .</p>
                    {/* <p className="text-center">Click the Below Button to Download the pdf</p> */}
                    {/* <p><span className="fw-bold fs-6">Paid Amount: ${{ $payment_response['txnAmount'] }}</span></p> */}
                  </div>
                  <div className="card-footer bg-transparent border-0">
                    <div className="text-center">
                      <button onClick={Redirect} className="btn btn-success">Next</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default BabySampleRedirect;