import "./Birth-horoscopeForm.css";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function BirthHoroscopeForm() {
  const [dayOptions] = useState(
    [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0"))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0"))
  );
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => startYear + year
    );
  });
  const [hourOptions] = useState(
    [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0"))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0"))
  );
  const [birthPlace, setBirthPlace] = useState("");
  const [userName, setUserName] = useState("");
  const [userGender, setUserGender] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmpm, setSelectedAmpm] = useState("");
  const [nameError, setNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [birthplaceError, setBirthplaceError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthHourError, setBirthHourError] = useState("");
  const [birthMinuteError, setBirthMinuteError] = useState("");
  const [birthAmpmError, setBirthAmpmError] = useState("");
  const [formdata, setformdata] = useState({
    username: "",
    gender: "",
    userDateofBirth: "",
    userBirthtime: "",
    userBirthplace: "",
    latitude: "",
    longitude: "",
  });

  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

  const handleSuggestionSelect = (selectedPlace) => {
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const parts = selectedPlace.place_name.split(",");
    const displayName = parts.slice(0, 3).join(",");
    const location = displayName;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");

    setSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey =
        "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=10`;

      try {
        const response = await fetch(url);
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };

  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const validation = () => {
    let IsValid = true;
    if (!userName) {
      setNameError("Field is required");
      IsValid = false;
    } else {
      setNameError("");
    }

    if (!userGender) {
      setGenderError("Field is required");
      IsValid = false;
    } else {
      setGenderError("");
    }

    if (!selectedDay || !selectedMonth || !selectedYear) {
      setBirthdateError("Field is required");
      IsValid = false;
    } else {
      setBirthdateError("");
    }

    if (!selectedHour) {
      setBirthHourError("Field is required");
      IsValid = false;
    } else {
      setBirthHourError("");
    }

    if (!selectedMinute) {
      setBirthMinuteError("Field is required");
      IsValid = false;
    } else {
      setBirthMinuteError("");
    }

    if (!selectedAmpm) {
      setBirthAmpmError("Field is required");
      IsValid = false;
    } else {
      setBirthAmpmError("");
    }

    if (!birthPlace) {
      setBirthplaceError("Field is required");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }
    let LatitudeError = document.getElementById("atflt").value;

    if (!LatitudeError) {
      setBirthplaceError("Again Select the BirthPlace");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }
    return IsValid;
  };

  const from_submit_button = (e) => {
    e.preventDefault();
    if (validation() === true) {
      const formdata = {
        username: userName,
        gender: userGender,
        userDateofBirth: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        userBirthplace: birthPlace,
        latitude: document.getElementById("atflt").value,
        longitude: document.getElementById("atflg").value,
      };

      Loading.dots("Your Horoscope is processing...");

      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-RandomInsert",
          formdata
        )
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
                formdata
              )
              .then((response) => {
                if (response.status === 200) {
                  Loading.remove();
                  customRedirect("/Birth-Table-view", response.data);
                  const value = JSON.stringify(response.data.data);
                  localStorage.setItem("onepageValues", value);
                  const formdatavalue = JSON.stringify(formdata);
                  localStorage.setItem("onepageformdata", formdatavalue);
                  const userInfo = {
                    user_id: null,
                    customerDataId: null,
                  };
                  const UserInfoValues = JSON.stringify(userInfo);
                  localStorage.setItem("userInfo", UserInfoValues);
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setformdata({
      username: userName,
      gender: userGender,
      userDateofBirth: `${selectedYear}-${selectedMonth}-${selectedDay}`,
      userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
      userBirthplace: birthPlace,
      latitude: document.getElementById("atflt").value,
      longitude: document.getElementById("atflg").value,
    });
  }, [
    userName,
    userGender,
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedHour,
    selectedMinute,
    selectedAmpm,
    birthPlace,
  ]);

  useEffect(() => {
    const storedValue = localStorage.getItem("onepageformdata");
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      const dateofBirth = parsedValue.userDateofBirth;
      const [year, month, day] = dateofBirth.split("-");

      const Birthtime = parsedValue.userBirthtime;
      const timeComponents = Birthtime.split(" ");
      const time = timeComponents[0];
      const ampm = timeComponents[1];
      const [hour, minute] = time.split(":");

      setUserName(parsedValue.username);
      setUserGender(parsedValue.gender);
      setSelectedPlaces(parsedValue.userBirthplace);
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedAmpm(ampm);
      setBirthPlace(parsedValue.userBirthplace);
      document.getElementById("atflg").value = parsedValue.longitude;
      document.getElementById("atflt").value = parsedValue.latitude;
    }
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
      <div className="container">
        <h1 className="cate-title fs-3 fw-bold">இலவச ஜாதக தகவல்</h1>
        <div className="row justify-content-between">
          <div className="col-lg-6 col-md-6 col-12">
            <form
              action="#"
              method="POST"
              className="border-2 border-success shadow-lg mt-5 p-4 rounded"
              id="one_page"
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-3">
                    <input
                      className={`border-0 border-bottom rounded-0 dSuggest  ${
                        nameError && "is-invalid"
                      }`}
                      name="user_name"
                      id="user_name"
                      type="text"
                      placeholder="பெயர்"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      aria-label="default input example"
                    />
                    {nameError && (
                      <div className="invalid-feedback fw-bold">
                        {nameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-4">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest  ${
                        genderError && "is-invalid"
                      }`}
                      name="gender"
                      id="gender"
                      aria-label="Default select example"
                      value={userGender}
                      onChange={(e) => setUserGender(e.target.value)}
                    >
                      <option value="">பாலினம்</option>
                      <option value="Male">Male - ஆண்</option>
                      <option value="Female">Female - பெண்</option>
                    </select>
                    {genderError && (
                      <div className="invalid-feedback">{genderError}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  onChange={handleLocationChange}
                  value={birthPlace}
                  className={`autocomplete-container border-0 border-bottom rounded-0 dSuggest ${
                    birthplaceError && "is-invalid"
                  }`}
                  id="autocomplete-container"
                  placeholder="பிறந்த இடத்தை தேர்வு செய்யவும்"
                />
                {birthplaceError && (
                  <div className="invalid-feedback  ">{birthplaceError}</div>
                )}

                <input
                  id="atflt"
                  className="pfipbx"
                  name="the_lat"
                  type="hidden"
                  placeholder="Latitude"
                />
                <input
                  id="atflg"
                  className="pfipbx"
                  name="the_lng"
                  type="hidden"
                  placeholder="Longitude"
                />
                <ul>
                  {suggestedPlaces.map((place, index) => {
                    if (place.place_name) {
                      const listItemStyle = {
                        padding: "5px 10px",
                        cursor: "pointer",
                        fontSize: "16px",
                        borderBottom: "1px dashed #ddd",
                      };

                      // Split the place_name and get the first three parts
                      const parts = place.place_name.split(",");
                      const displayName = parts.slice(0, 3).join(",");

                      return (
                        <p
                          style={listItemStyle}
                          key={index}
                          onClick={() => handleSuggestionSelect(place)}
                        >
                          {displayName}
                        </p>
                      );
                    }
                    return null; // Handle the case where place.place_name is falsy
                  })}
                </ul>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <div className="mb-3">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest ${
                        birthdateError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      name="fin_day"
                      id="fin_day"
                      value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}
                    >
                      <option value="">தேதி</option>
                      {dayOptions.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                    {birthdateError && (
                      <div className="invalid-feedback ">{birthdateError}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <div className="mb-3">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest ${
                        birthdateError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      id="fin_month"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                      <option value="">மாதம்</option>
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <div className="mb-3">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest ${
                        birthdateError && "is-invalid"
                      }`}
                      name="fin_year"
                      id="fin_year"
                      aria-label="Default select example"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      <option value="">வருடம்</option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <div className="mb-3">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest ${
                        birthHourError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      name="hour"
                      id="hour"
                      value={selectedHour}
                      onChange={(e) => setSelectedHour(e.target.value)}
                    >
                      <option value="">நேரம்</option>
                      {hourOptions.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>
                    {birthHourError && (
                      <div className="invalid-feedback  fw-bold">
                        {birthHourError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <div className="mb-3">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest ${
                        birthMinuteError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      id="minute"
                      value={selectedMinute}
                      onChange={(e) => setSelectedMinute(e.target.value)}
                    >
                      <option value="">நிமிடம்</option>
                      {minuteOptions.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </select>
                    {birthMinuteError && (
                      <div className="invalid-feedback  fw-bold">
                        {birthMinuteError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <div className="mb-3">
                    <select
                      className={`form-select border-0 border-bottom rounded-0 dSuggest ${
                        birthAmpmError && "is-invalid"
                      }`}
                      id="Ampm"
                      value={selectedAmpm}
                      onChange={(e) => setSelectedAmpm(e.target.value)}
                    >
                      <option value="">காலம்</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                    {birthAmpmError && (
                      <div className="invalid-feedback  ">{birthAmpmError}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button
                  className="btngreen mt-3 bg-success"
                  type="button"
                  onClick={from_submit_button}
                >
                  ஜாதகம் எடுக்க
                  <span
                    style={{ fontSize: "12px" }}
                    className="ms-2 fas fa-arrow-right"
                  ></span>
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mt-5 form-board">
            <div className="position-relative">
              <div>
                <img
                  src={require("./img/board_form.webp")}
                  alt="tamil birth chart"
                />
              </div>
              <div className="position-absolute onepage_board">
                {userName !== "" && (
                  <div>
                    <label
                      htmlFor=""
                      id="showUserName"
                      className="text-white fw-bold"
                    >
                      Name: <span id="">{userName}</span>
                    </label>
                  </div>
                )}

                {userGender !== "" && (
                  <div>
                    <label
                      htmlFor=""
                      id="ShowUserGnder"
                      className="text-white fw-bold"
                    >
                      Gender: <span id="">{userGender}</span>
                    </label>
                  </div>
                )}

                {birthPlace !== "" && (
                  <div>
                    <label
                      htmlFor=""
                      id="showUserBirthPlace"
                      className="text-white fw-bold"
                    >
                      BirthPlace: <span id="">{birthPlace}</span>
                    </label>
                  </div>
                )}

                {(selectedDay !== "" ||
                  selectedMonth !== "" ||
                  selectedYear !== "") && (
                  <div>
                    <label
                      htmlFor=""
                      id="showUserDateofBirth"
                      className="text-white fw-bold"
                    >
                      Date:{" "}
                      <span id="">
                        {selectedDay + "-" + selectedMonth + "-" + selectedYear}
                      </span>
                    </label>
                  </div>
                )}

                {(selectedHour !== "" ||
                  selectedMinute !== "" ||
                  selectedAmpm !== "") && (
                  <div>
                    <label
                      htmlFor=""
                      id="showUserBirthTime"
                      className="text-white fw-bold"
                    >
                      Time{" "}
                      <span id="">
                        {selectedHour +
                          ":" +
                          selectedMinute +
                          "  " +
                          selectedAmpm}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
