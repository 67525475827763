import React from 'react';
import { Link } from 'react-router-dom';

export default function BabyNameContainer() {
  return (
    <div>
      <section className="about-section bg-tertiary overflow-hidden" id="porutham">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mt-5">
              <img
                src={require("./images/baby-name-one-page-horoscope.webp")}
                className="form-img border"
                alt="Kulanthai peyar pattiyal"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-5">
              <div className="section-title">
                <h2 className="text-primary text-uppercase fw-bold mb-3">குழந்தை பெயர்</h2>
                <p className="lead mb-0 mt-4 fw-bold">
                  உங்கள் குழந்தைக்கான அதிஷ்ட பெயர், உங்கள் குழந்தையின் பிறந்த நேரம், பிறந்த தேதி, பிறந்த இடம், கொண்டு நட்சத்திரத்திற்கு பொருத்தமான பெயர்கள், நியூமெராலஜி மற்றும் பிரமிட் நியூமெராலஜி உடன் சுலபமான முறையில் துல்லியமாக நீங்கள் பயன்படுத்தி கொள்ளலாம்..
                </p>
              <div className='mt-3'>  
                <Link to="/Baby-Name-List" className="btn-darkred" >
                  பெயர் பார்க்க
                  <span style={{ fontSize: '14px' }} className="ms-2 fas fa-arrow-right"></span>
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
