import React from "react";
import { useState, useEffect } from "react";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import ReactPaginate from "react-js-pagination";
import axios from "axios";
import BabyNameTemplate from "../../tamil/BabyNameTemplate/BabyNameTemplate";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import getepayPortal from "../../tamil/Getepay_pg_react";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";

export default function BabyNameReport() {
  const [ReportData, setReportData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [Name, setUserName] = useState("");
  const [Email, setUserEmail] = useState("");
  const [MobileNumber, setUserMobileNumber] = useState("");
  const [mobilenumberError, setMobieNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerNameError, setBuyerNameError] = useState("");
  const [user_id, setUserId] = useState("");
  const [customerDataId, setcustomerDataId] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ReportData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1);
  };

  useEffect(() => {
    Loading.dots();
    const Token = localStorage.getItem("authToken");

    axios
      .get("https://www.ctsvsolutions.com/onepage-Api/public/api/myProfile", {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setUserEmail(response.data.email);
        setUserName(response.data.user_name);
        setUserMobileNumber(response.data.mobile_number);
        Loading.remove();
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
        Loading.remove();
      });
  }, []);

  const PayNowData = (report) => {
    setSelectedReport(report);
    setUserId(report.user_id_fk);
    setcustomerDataId(report.id);
    const UserInfo = {
      user_id: report.user_id_fk,
      customerDataId: report.id,
    };
    const UserInfoValue = JSON.stringify(UserInfo);
    sessionStorage.setItem("User_Baby_Ids", UserInfoValue);
  };

  const ViewPaidPdf = async (data) => {
    Loading.hourglass("Pdf is generating.....");
    console.log(data);
    const dateComponents = data.birth_date.split("-");

    const selectedDay = dateComponents[0];
    const selectedMonth = dateComponents[1];
    const selectedYear = dateComponents[2];

    const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;

    const BabyNameList = {
      BirthMonth: selectedMonth,
      BirthYear: selectedYear,
      BirthDay: selectedDay,
      BirthDate: formattedDate,
      BirthTime: data.birth_time,
      BirthPlace: data.birth_place + "," + data.state,
      initial: data.initial,
      father_name: data.father_name,
      mother_name: data.mother_name,
      gender: data.gender,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    try {
      const response = await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List",
        BabyNameList
      );

      console.log("baby name list", response.data.data);

      const paidPdf = () => {
        return <BabyNameTemplate value={response.data.data} />;
      };

      const printElement = ReactDOMServer.renderToString(paidPdf());

      await html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .save();

      Loading.remove();
      Notify.success("Pdf Downloaded Successfully....!!!");
    } catch (error) {
      console.error("Error generating PDF:", error);
      Loading.remove();
      Notify.error("Error generating PDF. Please try again.");
    }
  };

  useEffect(() => {
    Loading.dots();
    const Token = localStorage.getItem("authToken");
    console.log("token is", Token);
    fetch("https://www.ctsvsolutions.com/onepage-Api/public/api/BabyNameData", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response is ", data);
        setReportData(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        Loading.remove();
      });
  }, []);

  const validateInput = () => {
    let hasError = false;
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!Email) {
      setEmailError("Field is required");
      hasError = true;
    } else if (!emailRegex.test(Email)) {
      setEmailError("Invalid email format");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!Name) {
      setBuyerNameError("Field is required");
      hasError = true;
    } else {
      setBuyerNameError("");
    }

    if (!MobileNumber || MobileNumber.length !== 10) {
      setMobieNumberError("Field is required and must be 10 digits");
      hasError = true;
    } else {
      setMobieNumberError("");
    }

    return !hasError;
  };

  const payNow = () => {
    if (validateInput()) {
      const Token = localStorage.getItem("authToken");
      const updateInfo = {
        email: Email,
        mobile_number: MobileNumber,
        customerDataId: selectedReport.id,
      };

      axios
        .post(
          "https://www.ctsvsolutions.com/onepage-Api/public/api/Update-payment-user-info",
          updateInfo,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        )
        .then((response) => {
          const check = response.data.status;
          if (check === true) {
            const data = selectedReport;
            const dateComponents = data.birth_date.split("-");

            const selectedDay = dateComponents[0];
            const selectedMonth = dateComponents[1];
            const selectedYear = dateComponents[2];

            const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;

            const BabyNameList = {
              BirthMonth: selectedMonth,
              BirthYear: selectedYear,
              BirthDay: selectedDay,
              BirthDate: formattedDate,
              BirthTime: data.birth_time,
              BirthPlace: data.birth_place + "," + data.state,
              initial: data.initial,
              father_name: data.father_name,
              mother_name: data.mother_name,
              gender: data.gender,
              latitude: data.latitude,
              longitude: data.longitude,
            };

            // Perform the second Axios request here
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List",
                BabyNameList
              )
              .then((response) => {
                  const ApiResponse = response.data.data;
                  const ApiResponseString = JSON.stringify(ApiResponse);
                  sessionStorage.setItem("userBabyNameData", ApiResponseString);
                  BabyNamePaymentGateWay();
              
              })
              .catch((error) => {
                console.error("Error in the second request:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error in the first request:", error);
        });
    }
  };

  const BabyNamePaymentGateWay = () => {
    const data = {
      mid: "993377",
      amount: "249.00",
      merchantTransactionId: "sd12121",
      transactionDate: "Mon Oct 03 13:54:33 IST 2022",
      terminalId: "getepay.merchant131068@icici",
      udf1: MobileNumber,
      udf2: Email,
      udf3: Name,
      udf4: user_id,
      udf5: customerDataId,
      udf6: "",
      udf7: "",
      udf8: "",
      udf9: "",
      udf10: "",
      ru: "https://www.ctsvsolutions.com/onepage-Api/public/Baby-Name-user-payment",
      callbackUrl: "",
      currency: "INR",
      paymentMode: "ALL",
      bankId: "",
      txnType: "single",
      productType: "IPG",
      txnNote: "Babyname(user-D)",
      vpa: "getepay.merchant131068@icici",
    };

    const Config = {
      mid: "993377",
      "Getepay Terminal Id": "getepay.merchant131068@icici",
      "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
      "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
      "Getepay Url":
        "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
    };
    // console.log(data);
    getepayPortal(data, Config);
  };
  //after the payment
  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const Authorization = queryParamsObject["Authorization"];
    const Paymemt_Status = queryParamsObject["payment_Status"];

    if (Authorization === "STruE" && Paymemt_Status === "CrEdiT") {
      Report.success(
        "Payment Success",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("kindly please wait,it takes 5 minutes....", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          const storedUserInfo = sessionStorage.getItem("User_Baby_Ids");
          const userInfo = JSON.parse(storedUserInfo);

          const printElement = ReactDOMServer.renderToString(paidPdf());
          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .save();

          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(
                base64Data,
                userInfo.user_id,
                userInfo.customerDataId
              );
            });
        }
      );
    }
  }

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "baby_name",
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    axios
      .post(" https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-pdf-Whastapp", DataInfo)
          .then((response) => {
            axios
              .post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-Email", DataInfo)
              .then((response) => {
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      user_id: user_id,
                      customer_data_id: customerDataId,
                      feedback: clientAnswer,
                    };
                    axios
                      .post(
                        "https://www.ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        window.location.replace("/user");
                      });
                  },
                  (clientAnswer) => {
                    window.location.replace("/user");
                  }
                );
                Notify.success("Sample pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  const paidPdf = () => {
    const storedUserInfo = sessionStorage.getItem("userBabyNameData");
    const dataInfo = JSON.parse(storedUserInfo);
    return <BabyNameTemplate value={dataInfo} />;
  };

  return (
    <div>
      <div>
        <h5 className="text-stat text-primary">Baby Name Report</h5>
      </div>
      <div className="card border-0">
        <div className="card-body ">
          <div className="row">
            <div className="col-md-12 m-auto  table-responsive">
              <div className="mb-3">
                <label htmlFor="itemsPerPage">Items per page:</label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={ReportData.length}>All</option>
                </select>
              </div>
              <table class="table text-nowrap table-bordered table-hover">
                <thead>
                  <tr className="">
                    <th scope="col" className="px-4 bg-info text-white">
                      S.no
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Gender
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Father Name
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Mother Name
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Date of Birth
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Birth Time
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Birth place
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Status
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((Report, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>{Report.gender}</td>
                      <td>{Report.father_name}</td>
                      <td>{Report.mother_name}</td>
                      <td>{Report.birth_date}</td>
                      <td>{Report.birth_time}</td>
                      <td>{Report.birth_place + "," + Report.state}</td>
                      <td>{Report.status}</td>
                      <td>
                        {Report.status === "PAID" ? (
                          // Pass Report data to the ViewPaidPdf function on button click
                          <button
                            type="button"
                            onClick={() => ViewPaidPdf(Report)}
                            className="btn btn-success"
                          >
                            View
                          </button>
                        ) : (
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop1"
                            className="btn btn-danger"
                            onClick={() => PayNowData(Report)}
                          >
                            Pay & Download
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <ReactPaginate
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={ReportData.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  prevPageText="Previous"
                  nextPageText="Next"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop1"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="staticBackdropLabel">
                Download Free Pdf
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container py-3 rounded shadow-lg" id="package">
                <form className="mt-5" id="free-pdf-form">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-dark fw-bold"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Mail"
                      name="email"
                      id="email1"
                      value={Email}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                      aria-describedby="emailHelp"
                      required
                    />
                    <span id="email_error1" style={{ color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name1"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      value={Name}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {buyerNameError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      pattern="[6789][0-9]{9}"
                      placeholder="Enter Your Number"
                      id="mobile_number1"
                      value={MobileNumber}
                      onChange={(e) => setUserMobileNumber(e.target.value)}
                    />
                    <span id="mobile_number_error1" style={{ color: "red" }}>
                      {mobilenumberError}
                    </span>
                  </div>
                  <button
                    type="button"
                    name="free"
                    id="free_pdf"
                    className="text-center but1 mt-3"
                    onClick={payNow}
                  >
                    Print
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
