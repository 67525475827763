import React, { useState,useEffect } from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import axios from "axios";
import { Notify } from 'notiflix/build/notiflix-notify-aio';

export default function Offcanvas({ MobileNumber,setMobileNumber,Email,setEmail,setChanged,user_id,customerDataId}) {
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobileNumber) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobileNumber);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(validateEmail(newEmail) ? "" : "Invalid email address");
  };

  const handleMobileChange = (e) => {
    const newMobileNumber = e.target.value;
    setMobileNumber(newMobileNumber);
    setMobileError(
      validateMobileNumber(newMobileNumber) ? "" : "Invalid mobile number"
    );
  };

  const handleButtonClick = () => {
    Loading.dots('Loading...', {
      backgroundColor: 'rgba(1,0,0,0.9)',
    });
    handleSubmit();
  };

  const handleSubmit = () => {
    if (validateEmail(Email) && validateMobileNumber(MobileNumber)) {
      const OfferEdit = {
        user_id: user_id,
        customerDataId: customerDataId,
        Email: Email,
        MobileNumber: MobileNumber
      };
      axios.post('https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Offer-Contact-Edit', OfferEdit)
        .then(response => {
          const showNotification = response.data.status;
          if (showNotification) {
            Notify.success(response.data.message);
            setChanged(true);
            const offcanvasElement = document.getElementById('offcanvasRight');
            offcanvasElement.classList.remove('show');
          }
          console.log("Form submitted successfully!");
        })
        .catch(error => {
          console.error("Error submitting the form:", error);
        })
        .finally(() => {
          Loading.remove();
        });
    } else {
      console.log("Form validation failed. Please check your inputs.");
      Loading.remove();
    }
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel" className="text-danger">Contact Info</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label text-primary fw-bold">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    onChange={handleEmailChange}
                    value={Email}
                  />
                  <div className="invalid-feedback">{emailError}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput2" className="form-label fw-bold text-primary">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    className={`form-control ${mobileError ? "is-invalid" : ""}`}
                    id="exampleFormControlInput2"
                    placeholder="9087654321"
                    onChange={handleMobileChange}
                    value={MobileNumber}
                  />
                  <div className="invalid-feedback">{mobileError}</div>
                </div>
              </div>
              <div className="d-grid gap-2 col-6 mx-auto">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={handleButtonClick}
                  disabled={emailError || mobileError}
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  Change
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
