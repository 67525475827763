import React, { useState,useEffect } from "react";
import Input from "../components/input";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import axios from "axios";
import { Loading } from 'notiflix/build/notiflix-loading-aio';



export default function ReportSearch({SetPackage,setUserData}){
    const [user_id,setUserId]=useState('');
const [Duration,setDuration]=useState('');
const [showCustomDate, setShowCustomDate] = useState(false);
    const [CusomerDataId,setCusomerDataId]=useState('');
    function HanleChangeDate(event){
      setDuration(event.target.value);
    }
    useEffect(() => {
      if (Duration === '2') {
        setShowCustomDate(true);
      } else {
        setShowCustomDate(false);
      }
    }, [Duration]);
    // console.log(Duration);

    const HandleSearch = () => {

        if (!CusomerDataId) {
            Notify.failure('Customer Data Id Required');
        } else {
          Loading.pulse('Loading....');
            axios.post(
                'https://ctsvsolutions.com/onepage-Api/public/api/IndidualSearch',
                {
                    userInput: CusomerDataId
                },
            )
            .then(response => {
              
                if (response.status === 200) {
                    console.log(response);
                    SetPackage(response.data.message[0]);
                    setUserData(response.data.data[0]);
                    Loading.remove();

                } else {
                    Notify.failure('Data Unavailable');
                    Loading.remove();
                }
            })
            .catch(error => {
                console.error(error);
                Notify.failure('No data found,....old Data');
                Loading.remove();
            });
        }
    };
    
    
    return(
        <>
         <form  action="">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label fs-6 fw-bold"
                    >
                      ID:
                    </label>
                    <Input
                      type="text"
                      className="form-control border-success p-2"
                      name="user_id"
                      id="user_id"
                      UserInput={(e)=>setUserId(e.target.value)}
                       value={user_id}
                      placeholder="Enter Your ID or Mobile Number"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label fs-6 fw-bold"
                  >
                    Select:
                  </label>
                  <select
                    name="duration"
                    className="form-select bordersuccess p-2 rounded-3"
                    id="custom"
                    aria-label="Default select example"
                    onChange={HanleChangeDate}
                    // onChange={(e)=>setDateFillter(e.target.value)}
                  >
                    <option value="">select</option>
                    <option value="1">Today</option>
                    <option value="-1">Yesterday</option>
                    <option value="7">7 Days</option>
                    <option value="30">30 Days</option>
                    <option value="2">Custom</option>
                  </select>
                </div>
              </div>
              {showCustomDate && (
                <div className="col-md-12" id="shopType">
                  <div className="row">
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fs-6 fw-bold"
                      >
                        From Date:
                      </label>
                      <Input
                        type="date"

                        className="form-control border-1 border-primary"
                        name="f_create_date"
                        value=""
                        id="f_create_date"
                        placeholder="name@example.com"
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fs-6 fw-bold"
                      >
                        To Date:
                      </label>
                      <Input
                        type="date"
                        value=""
                        className="form-control border-1 border-primary"
                        name="t_create_date"
                        id="t_create_date"
                        placeholder="name@example.com"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6 col-12">
                <Input
                      type="number"
                      className="form-control border-success mt-3"
                      name="user_id"    
                      id="customer_data_is"
                      value={CusomerDataId}
                      UserInput={(e)=>setCusomerDataId(e.target.value)}
                      placeholder="Enter CustomerData Id"
                    />
                </div>
                <div className="col-12 col-md-6">
                <button
                className="btn btn-danger fw-bold my-3 px-5 "
                name="search_profile"
                type="button"
                onClick={HandleSearch}
              >
                Search
              </button>
                </div>
              </div>
            
            </form>
        </>
    )
}