import React, { useEffect, useState } from "react";
import "./marriage-matching-form.css";
import { useNavigate } from 'react-router-dom';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import axios from 'axios';
export default function MarriageMatching_form() {
  const [dayOptions] = useState([...Array(31).keys()].map((day) => String(day + 1).padStart(2, '0')));
  const [monthOptions] = useState([...Array(12).keys()].map((month) => String(month + 1).padStart(2, '0')));
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => String(startYear + year)
    );
  });

  const [hourOptions] = useState([...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, '0')));
  const [minuteOptions] = useState([...Array(60).keys()].map((minute) => String(minute).padStart(2, '0')));

  const [maleName, setMalename] = useState("");
  const [maleHour, setMaleHour] = useState("");
  const [male_minute, setMaleMinute] = useState("");
  const [maleAmpm, setMaleAmpm] = useState("");
  const [maleBirthday, setMaleBithDay] = useState("");
  const [maleBirthMonth, setMaleBirthMonth] = useState("");
  const [maleBirthYear, setMaleBirthYear] = useState("");
  const [maleBirthplace, setMaleBirthPlace] = useState("");
  const [femaleName, setFemaleName] = useState("");
  const [femaleHour, setFemaleHour] = useState("");
  const [femaleMinute, setfemaleMinute] = useState("");
  const [femaleAmpm, setFemaleAmpm] = useState("");
  const [femaleBirthDay, setFemaleBirthDay] = useState("");
  const [femaleBirthMonth, setFemaleBirthMonth] = useState("");
  const [femaleBirthYear, setFemaleBirthYear] = useState("");
  const [femaleBirthplace, setFemaleBirthPlace] = useState("");
  const [malesuggestedPlaces, setMaleSuggestedPlaces] = useState([]);
  const [femalesuggestedPlaces, setfeMaleSuggestedPlaces] = useState([]);
  const [maleselectedPlaces, setmaleSelectedPlaces] = useState([]);
  const [femaleselectedPlaces, setfemaleSelectedPlaces] = useState([]);
  // error validation states
  const [maleNameError, setMaleNameError] = useState("");
  const [femaleNameError, setFemaleNameError] = useState("");
  const [maleDayError, setMaleDayError] = useState("");
  const [feMaleDayError, setFemaleDayError] = useState("");
  const [maleMonthError, setMaleMonthError] = useState("");
  const [femaleMonthError, setFemaleMonthError] = useState("");
  const [maleYearError, setMaleYearError] = useState("");
  const [femaleYearError, setFemaleYearError] = useState("");
  const [maleHourError, setMaleHourError] = useState("");
  const [femaleHourError, setFemaleHourError] = useState("");
  const [maleMinuteError, setMaleMinuteError] = useState("");
  const [feMaleMinuteError, setFemaleMinuteError] = useState("");
  const [maleAmPmError, setAmpmMinuteError] = useState("");
  const [femaleAmPmError, setfeAmpmMinuteError] = useState("");
  const [malebirthplaceError, setMaleBirthplaceError] = useState("");
  const [femalebirthplaceError, setfeMaleBirthplaceError] = useState("");
  const [marriageMatchingFromData, setmarriageMatchingFromData] = useState({
    maleName: "",
    MaleDateofBirth: "",
    MaleBirthtime: "",
    MaleBirthplace: "",
    Malelatitude: "",
    Malelongitude: "",
    FemaleName: "",
    femaleBirthplace: "",
    feMaleDateofBirth: "",
    FemaleBirthTine: "",
    femalelatitude: "",
    femalelongitude: "",
  });
  useEffect(() => {
    const storedValue = localStorage.getItem('mgformdata');
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      const MaleDOB = parsedValue.MaleDateofBirth;
      const [maleyear, malemonth, maleday] = MaleDOB.split('-');
      const male_name=parsedValue.maleName;
      const male_laitude=parsedValue.Malelatitude;
      const male_longitide=parsedValue.Malelongitude;
      const male_birthplace=parsedValue.MaleBirthplace;
      const Male_Birth_time = parsedValue.MaleBirthtime;
      const male_timeComponents = Male_Birth_time.split(' ');
      const maletime = male_timeComponents[0];
      const male_ampm = male_timeComponents[1];
      const [malehour, maleminute] = maletime.split(':');

      const feMaleDOB = parsedValue.feMaleDateofBirth;
      const [femaleyear, femalemonth, femaleday] = feMaleDOB.split('-');
      const female_name=parsedValue.FemaleName;
      const female_laitude=parsedValue.femalelatitude;
      const female_longitide=parsedValue.femalelongitude;
      const female_birthplace=parsedValue.femaleBirthplace;
      const feMale_Birth_time = parsedValue.FemaleBirthTime;
      const female_timeComponents = feMale_Birth_time.split(' ');
      const femaletime = female_timeComponents[0];
      const female_ampm = female_timeComponents[1];
      const [femalehour, femaleminute] = femaletime.split(':');
      setMalename(male_name);
      setMaleHour(malehour);
      setMaleMinute(maleminute);
      setMaleAmpm(male_ampm);
      setMaleBithDay(maleday);
      setMaleBirthMonth(malemonth);
      setMaleBirthYear(maleyear);
      setMaleBirthPlace(male_birthplace );
      setFemaleName(female_name);
      setFemaleHour(femalehour);
      setfemaleMinute(femaleminute);
      setFemaleAmpm(female_ampm);
      setFemaleBirthDay(femaleday);
      setFemaleBirthMonth(femalemonth);
      setFemaleBirthYear(femaleyear);
      setFemaleBirthPlace(female_birthplace);
      document.getElementById("atflg").value = parsedValue.Malelongitude;
      document.getElementById("atflt").value = parsedValue.Malelatitude;

      document.getElementById("female_longitude").value = parsedValue.femalelongitude;
      document.getElementById("female_latitude").value = parsedValue.femalelatitude;
    }
  }, []);

  const handleMaleLocationChange = (event) => {
    const currentValue = event.target.value;
    setMaleBirthPlace(currentValue);
    // console.log(currentValue);
    fetchMaleSuggestions(currentValue);
    // console.log(currentValue);
  };

  const handlefeMaleLocationChange = (event) => {
   
    const currentValue = event.target.value;
    console.log(currentValue);
    setFemaleBirthPlace(currentValue);
    fetchfeMaleSuggestions(currentValue);
  };

  const fetchfeMaleSuggestions = async (currentValue) => {
// console.log('im comed ');
    if (currentValue.length >= 3) {
      const apiKey = "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=5`;
  
      try {
        const response = await fetch(url);
        // console.log(response);
        if (response.ok) {
     
          const data = await response.json();
          setfeMaleSuggestedPlaces(data.features);
        
        } else {
          console.error("Error fetching suggestions from the API");
          setfeMaleSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setfeMaleSuggestedPlaces([]);
      }
    }
  };

  const handlefeMaleSuggestionSelect = (selectedPlace) => {

const femalelatitude =selectedPlace.center[1];
    const femalelongitude = selectedPlace.center[0];
    const femaleparts =selectedPlace.place_name.split(',');
    const FemaledisplayName = femaleparts.slice(0, 3).join(',');
    const Female_location = FemaledisplayName

    const female_location = Female_location;

    const updatedSelectedPlaces = [...malesuggestedPlaces, female_location];

    setfemaleSelectedPlaces(updatedSelectedPlaces);
    setFemaleBirthPlace(female_location);
    setfeMaleSuggestedPlaces([]);

    document.getElementById("female_longitude").value = femalelongitude;
    document.getElementById("female_latitude").value = femalelatitude;
  };

  const handleMaleSuggestionSelect = (selectedPlace) => {
    
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const maleParts =selectedPlace.place_name.split(',');
    const maleisplayName = maleParts.slice(0, 3).join(',');
    const male_location = maleisplayName

    // const fmale_location = male_location;
 
    const location = male_location;

    const updatedSelectedPlaces = [...maleselectedPlaces, location];

    setmaleSelectedPlaces(updatedSelectedPlaces);
    setMaleBirthPlace(location);
    setMaleBirthplaceError("");

    setMaleSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchMaleSuggestions = async (currentValue) => {
    
    if (currentValue.length >= 3) {
      const apiKey = "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=5`;
  
      try {
        const response = await fetch(url);
 
        if (response.ok) {
        // console.log('im from response okat  ');
          const data = await response.json();
          setMaleSuggestedPlaces(data.features);
          // console.log('its my response',malesuggestedPlaces);
        } else {
          console.error("Error fetching suggestions from the API");
          setMaleSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setMaleSuggestedPlaces([]);
      }
    }
  };
  const validateForm = () => {
    let isValid = true;
  
    // Male fields
    if (!maleName) {
      setMaleNameError("Field is required");
      isValid = false;
    } else {
      setMaleNameError("");
    }
  
    if (!maleBirthday) {
      setMaleDayError("Field is required");
      isValid = false;
    } else {
      setMaleDayError("");
    }
  
    if (!maleBirthMonth) {
      setMaleMonthError("Field is required");
      isValid = false;
    } else {
      setMaleMonthError("");
    }
  
    if (!maleBirthYear) {
      setMaleYearError("Field is required");
      isValid = false;
    } else {
      setMaleYearError("");
    }
  
    if (!maleHour) {
      setMaleHourError("Field is required");
      isValid = false;
    } else {
      setMaleHourError("");
    }
  
    if (!male_minute) {
      setMaleMinuteError("Field is required");
      isValid = false;
    } else {
      setMaleMinuteError("");
    }
  
    if (!maleAmpm) {
      setAmpmMinuteError("Field is required");
      isValid = false;
    } else {
      setAmpmMinuteError("");
    }
  
    if (!maleBirthplace) {
      setMaleBirthplaceError("Field is required");
      isValid = false;
    } else {
      setMaleBirthplaceError("");
    }
  
    // Female fields
    if (!femaleName) {
      setFemaleNameError("Field is required");
      isValid = false;
    } else {
      setFemaleNameError("");
    }
  
    if (!femaleBirthDay) {
      setFemaleDayError("Field is required");
      isValid = false;
    } else {
      setFemaleDayError("");
    }
  
    if (!femaleBirthMonth) {
      setFemaleMonthError("Field is required");
      isValid = false;
    } else {
      setFemaleMonthError("");
    }
  
    if (!femaleBirthYear) {
      setFemaleYearError("Field is required");
      isValid = false;
    } else {
      setFemaleYearError("");
    }
  
    if (!femaleHour) {
      setFemaleHourError("Field is required");
      isValid = false;
    } else {
      setFemaleHourError("");
    }
  
    if (!femaleMinute) {
      setFemaleMinuteError("Field is required");
      isValid = false;
    } else {
      setFemaleMinuteError("");
    }
  
    if (!femaleAmpm) {
      setfeAmpmMinuteError("Field is required");
      isValid = false;
    } else {
      setfeAmpmMinuteError("");
    }
  
    if (!femaleBirthplace) {
      setfeMaleBirthplaceError("Field is required");
      isValid = false;
    } else {
      setfeMaleBirthplaceError("");
    }
  
    // Additional checks for latitude
    let MaleLatitideError = document.getElementById('atflt').value;
    if (!MaleLatitideError) {
      setMaleBirthplaceError('Please select the Birthplace again');
      isValid = false;
    } else {
      setMaleBirthplaceError('');
    }
  
    let FemaleLatitudeError = document.getElementById('female_latitude').value;
    if (!FemaleLatitudeError) {
      setfeMaleBirthplaceError('Please select the Birthplace again');
      isValid = false;
    } else {
      setfeMaleBirthplaceError('');
    }
  
    return isValid;
  };
  
  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const marriageMatchingSubmit = () => {
    if (validateForm()) {
      // console.log("Form has been submitted");
      const marriageMatchingFormData = {
        maleName: maleName,
        MaleDateofBirth: `${maleBirthYear}-${maleBirthMonth}-${maleBirthday}`,
        MaleBirthtime: `${maleHour}:${male_minute} ${maleAmpm}`,
        MaleBirthplace: maleBirthplace,
        Malelatitude: document.getElementById("atflt").value,
        Malelongitude: document.getElementById("atflg").value,
        FemaleName: femaleName,
        femaleBirthplace: femaleBirthplace,
        feMaleDateofBirth: `${femaleBirthYear}-${femaleBirthMonth}-${femaleBirthDay}`,
        FemaleBirthTime: `${femaleHour}:${femaleMinute} ${femaleAmpm}`,
        femalelatitude: document.getElementById("female_latitude").value,
        femalelongitude: document.getElementById("female_longitude").value,
      };
      Loading.dots('Marriage matching loading......');

      // console.log(marriageMatchingFromData);
      axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching-Random", marriageMatchingFormData)
      .then((response1) => {
        if (response1.status === 200) {
          axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching", marriageMatchingFormData)
            .then((response2) => {
              if (response2.status === 200) {
                Loading.remove();
                // console.log("Response 2:", response2);
                const value = JSON.stringify(response2.data.data);
                localStorage.setItem('mgValues', value);
                const formdatavalue = JSON.stringify(marriageMatchingFormData);
                localStorage.setItem('mgformdata', formdatavalue);
                const MgUserInfo={
                  'user_id':"",
                  'customerDataId':""
                }
                const mg_user_info = JSON.stringify(MgUserInfo);
                localStorage.setItem('MgUserInfo', mg_user_info);
                customRedirect('/Marriage-Matching-view', response2.data);
              } else {
                console.error("Error in Response 2:", response2);
              }
            })
            .catch((error) => {
              console.error("Error in Axios POST for Marriage-Matching:", error);
            });
        } else {
          console.error("Error in Response 1:", response1);
        }
      })
      .catch((error) => {
        console.error("Error in Axios POST for Marriage-Matching-Random:", error);
      });
    
    }
  };
  useEffect(() => {
    setmarriageMatchingFromData({
      maleName: maleName,
      MaleDateofBirth: `${maleBirthYear}-${maleBirthMonth}-${maleBirthday}`,
      MaleBirthtime: `${maleHour}:${male_minute} ${maleAmpm}`,
      MaleBirthplace: maleBirthplace,
      Malelatitude: document.getElementById("atflt").value,
      Malelongitude: document.getElementById("atflg").value,
      FemaleName: femaleName,
      femaleBirthplace: femaleBirthplace,
      feMaleDateofBirth: `${femaleBirthYear}-${femaleBirthMonth}-${femaleBirthDay}`,
      FemaleBirthTine: `${femaleHour}:${femaleMinute} ${femaleAmpm}`,
      femalelatitude: document.getElementById("female_latitude").value,
      femalelongitude: document.getElementById("female_longitude").value,
    });
  }, [
    maleName,
    maleBirthYear,
    maleBirthMonth,
    maleBirthday,
    maleHour,
    male_minute,
    maleAmpm,
    maleBirthplace,
    femaleName,
    femaleBirthplace,
    femaleBirthYear,
    femaleBirthMonth,
    femaleBirthDay,
    femaleHour,
    femaleMinute,
    femaleAmpm,
  ]);

  return (
    <div style={{ marginTop: "120px" }} id="">
      <h1 className="cate-title fs-3">Marriage Matching - திருமண பொருத்தம்</h1>
      <p>
        <img
          className="hr1"
          src={require("./img/heart-one-page-horoscope.webp")}
          alt="tamil marriage matching"
          style={{ width: "300px", height: "auto" }}
        />
      </p>
      <div className="container">
        <form action="#" method="" className="" id="marriage_matching">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 p-3">
              <div
                className="groom-tab p-3 rounded"
                style={{ backgroundColor: "#e6e6ff" }}
              >
                <h3 className="groom-title">
                  <img
                    className="groom-img"
                    src={require("./img/bride.webp")}
                    alt="marriage matching in Tamil"
                    style={{ width: "120px", height: "120px" }}
                  />
                  <br />
                  மணமகன்
                </h3>
              </div>
              <div
                className="rounded-0 borderless mt-3 p-3 rounded"
                style={{ backgroundColor: "#e6e6ff" }}
              >
                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="male_name" className="form-label">
                      பெயர்:
                    </label>
                    <input
                      className={`form-control ${
                        maleNameError && "is-invalid"
                      }`}
                      type="text"
                      placeholder="பெயர்"
                      id="male_name"
                      name="male_name"
                      value={maleName}
                      onChange={(e) => {
                        setMalename(e.target.value);
                      }}
                      aria-label="default input example"
                    />
                    {maleNameError && (
                      <div className="invalid-feedback fw-bold">
                        {maleNameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    பிறந்த தேதி :
                  </label>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          maleDayError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="male_day"
                        id="male_day"
                        value={maleBirthday}
                        onChange={(e) => {
                          setMaleBithDay(e.target.value);
                        }}
                      >
                        <option value="">தேதி</option>
                        {dayOptions.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>

                      {maleDayError && (
                        <div className="invalid-feedback fw-bold">
                          {maleDayError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          maleMonthError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="male_month"
                        id="male_month"
                        value={maleBirthMonth}
                        onChange={(e) => {
                          setMaleBirthMonth(e.target.value);
                        }}
                      >
                        <option value="">மாதம்</option>
                        {monthOptions.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                      {maleMonthError && (
                        <div className="invalid-feedback fw-bold">
                          {maleMonthError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          maleYearError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        id="male_year"
                        name="male_year"
                        value={maleBirthYear}
                        onChange={(e) => setMaleBirthYear(e.target.value)}
                      >
                        <option value="">வருடம்</option>
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {maleYearError && (
                        <div className="invalid-feedback fw-bold">
                          {maleYearError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    பிறந்த நேரம்:
                  </label>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          maleHourError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="male_hour"
                        id="male_hour"
                        value={maleHour}
                        onChange={(e) => {
                          setMaleHour(e.target.value);
                        }}
                      >
                        <option value="">நேரம்</option>
                        {hourOptions.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                      {maleHourError && (
                        <div className="invalid-feedback fw-bold">
                          {maleHourError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          maleMinuteError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="male_minute"
                        id="male_minute"
                        value={male_minute}
                        onChange={(e) => {
                          setMaleMinute(e.target.value);
                        }}
                      >
                        <option value="">நிமிடம்</option>
                        {minuteOptions.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </select>
                      {maleMinuteError && (
                        <div className="invalid-feedback fw-bold">
                          {maleMinuteError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          maleAmPmError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="male_ampm"
                        id="male_ampm"
                        value={maleAmpm}
                        onChange={(e) => {
                          setMaleAmpm(e.target.value);
                        }}
                      >
                        <option value="">காலம்</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                      {maleAmPmError && (
                        <div className="invalid-feedback fw-bold">
                          {maleAmPmError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-label mb-3">
                    <input
                      type="text"
                      onChange={handleMaleLocationChange}
                      value={maleBirthplace}
                      className={`form-control border-0 border-bottom rounded-0 dSuggest fw-bold ${
                        malebirthplaceError && "is-invalid"
                      }`}
                      id="autocomplete-container"
                      placeholder="பிறந்த இடத்தை தேர்வு செய்யவும்"
                    />
                    {malebirthplaceError && (
                      <div className="invalid-feedback  fw-bold">
                        {malebirthplaceError}
                      </div>
                    )}

                    <input
                      id="atflt"
                      className="pfipbx"
                      name="the_lat"
                      type="hidden"
                      placeholder="Latitude"
                    />
                    <input
                      id="atflg"
                      className="pfipbx"
                      name="the_lng"
                      type="hidden"
                      placeholder="Longitude"
                    />
 <ul>
                      {malesuggestedPlaces.map((place, index) => {
                       if (place.place_name) {
                        const listItemStyle = {
                          padding: "5px 10px",
                          cursor: "pointer",
                          fontSize: "16px",
                          borderBottom: "1px dashed #ddd",
                        };
  
                        // Split the place_name and get the first three parts
                        const parts = place.place_name.split(",");
                        const displayName = parts.slice(0, 3).join(",");
  
                          return (
                            <p
                              style={listItemStyle}
                              key={index}
                              onClick={() => handleMaleSuggestionSelect(place)}
                            >
                              {displayName}
                            </p>
                          );
                        }
                      })}
                    </ul>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 p-3">
              <div
                className="groom-tab p-3"
                style={{ backgroundColor: "#e6ffee" }}
              >
                <h3 className="groom-title">
                  <img
                    className="groom-img"
                    src={require("./img/groom.webp")}
                    alt="jathaga porutham tamil"
                    style={{ width: "120px", height: "120px" }}
                  />
                  <br />
                  மணமகள்
                </h3>
              </div>
              <div
                className="rounded-0 borderless mt-3 p-3 rounded"
                style={{ backgroundColor: "#e6ffee" }}
              >
                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="female_name" className="form-label">
                      பெயர்:
                    </label>
                    <input
                      className={`form-control ${
                        femaleNameError && "is-invalid"
                      }`}
                      type="text"
                      placeholder="பெயர்"
                      id="female_name"
                      name="female_name"
                      aria-label="default input example"
                      value={femaleName}
                      onChange={(e) => {
                        setFemaleName(e.target.value);
                      }}
                    />
                    {femaleNameError && (
                      <div className="invalid-feedback fw-bold">
                        {femaleNameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    பிறந்த தேதி :
                  </label>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          feMaleDayError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="female_day"
                        id="female_day"
                        value={femaleBirthDay}
                        onChange={(e) => {
                          setFemaleBirthDay(e.target.value);
                        }}
                      >
                        <option value="">தேதி</option>
                        {dayOptions.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                      {feMaleDayError && (
                        <div className="invalid-feedback fw-bold">
                          {feMaleDayError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          femaleMonthError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="female_month"
                        id="female_month"
                        value={femaleBirthMonth}
                        onChange={(e) => {
                          setFemaleBirthMonth(e.target.value);
                        }}
                      >
                        <option value="">மாதம்</option>
                        {monthOptions.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                      {femaleMonthError && (
                        <div className="invalid-feedback fw-bold">
                          {femaleMonthError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          femaleYearError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        id="female_year"
                        name="female_year"
                        value={femaleBirthYear}
                        onChange={(e) => {
                          setFemaleBirthYear(e.target.value);
                        }}
                      >
                        <option value="">வருடம்</option>
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {femaleYearError && (
                        <div className="invalid-feedback fw-bold">
                          {femaleYearError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    பிறந்த நேரம்:
                  </label>
                  <div className="col-lg-4 col-sm-4 col-12 ">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          femaleHourError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="female_hour"
                        id="female_hour"
                         value={femaleHour}
                        onChange={(e) => {
                          setFemaleHour(e.target.value);
                        }}
                      >
                        <option value="">நேரம்</option>
                        {hourOptions.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                      {femaleHourError && (
                        <div className="invalid-feedback fw-bold">
                          {femaleHourError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          feMaleMinuteError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        name="female_minute"
                        id="female_minute"
                        value={femaleMinute}
                        onChange={(e) => {
                          setfemaleMinute(e.target.value);
                        }}
                      >
                        <option value="">நிமிடம்</option>
                        {minuteOptions.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </select>
                      {feMaleMinuteError && (
                        <div className="invalid-feedback fw-bold">
                          {feMaleMinuteError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <div className="mb-3">
                      <select
                        className={`form-select text-dark ${
                          femaleAmPmError && "is-invalid"
                        }`}
                        aria-label="Default select example"
                        id="female_ampm"
                        name="female_ampm"
                        value={femaleAmpm}
                        onChange={(e) => {
                          setFemaleAmpm(e.target.value);
                        }}
                      >
                        <option value="">காலம்</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                      {femaleAmPmError && (
                        <div className="invalid-feedback fw-bold">
                          {femaleAmPmError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      onChange={handlefeMaleLocationChange}
                      value={femaleBirthplace}
                      className={`form-control border-0 border-bottom rounded-0 dSuggest fw-bold ${
                        femalebirthplaceError && "is-invalid"
                      }`}
                      id="autocomplete-container"
                      placeholder="பிறந்த இடத்தை தேர்வு செய்யவும்"
                    />
                    {femalebirthplaceError && (
                      <div className="invalid-feedback  fw-bold">
                        {femalebirthplaceError}
                      </div>
                    )}
                  </div>

                  <input
                    id="female_latitude"
                    className="pfipbx"
                    name="female_the_lat"
                    type="hidden"
                    placeholder="Latitude"
                  />
                  <input
                    id="female_longitude"
                    className="pfipbx"
                    name="female_the_lng"
                    type="hidden"
                    placeholder="Longitude"
                  />
<ul>
                      {femalesuggestedPlaces.map((place, index) => {
                        if (place.place_name) {
                          const listItemStyle = {
                            padding: "5px 10px",
                            cursor: "pointer",
                            fontSize: "16px",
                            borderBottom: "1px dashed #ddd",
                          };
    
                          // Split the place_name and get the first three parts
                          const parts = place.place_name.split(",");
                          const displayName = parts.slice(0, 3).join(",");
                          return (
                            <p
                              style={listItemStyle}
                              key={index}
                              onClick={() =>
                                handlefeMaleSuggestionSelect(place)
                              }
                            >
                              {displayName}
                            </p>
                          );
                        }
                      })}
                    </ul>
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <button
                className="btn-darkred"
                type="button"
                onClick={() => marriageMatchingSubmit()}
              >
                பொருத்தம் பார்க்க
                <span
                  style={{ fontSize: "14px" }}
                  className="ms-2 fas fa-arrow-right"
                ></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
