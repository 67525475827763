import React from "react";
import { useState, } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Notify } from 'notiflix/build/notiflix-notify-aio';

export default function MarriageMatchingForm() {
  const styles = {
    form: {
      backgroundColor: "#f5f5f5", // Update background color
      borderRadius: "8px", // Update border radius
      padding: "30px", // Update padding
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Update box shadow
    },
    input: {
      fontFamily: "Arial, sans-serif", // Update font family
      outline: "none",
      backgroundColor: "#eee", // Update background color
      width: "100%",
      border: "1px solid #ccc", // Update border color
      margin: "0 0 15px",
      padding: "12px", // Update padding
      boxSizing: "border-box",
      fontSize: "16px", // Update font size
    },
    select: {
      backgroundColor: "#eee", // Update background color
      border: "1px solid #ccc", // Update border color
      padding: "10px", // Update padding
      color: "#333",
      fontSize: "16px", // Update font size
    },
    button: {
      backgroundColor: "#4caf50", // Update background color
      color: "#fff",
      border: "none",
      padding: "15px 25px", // Update padding
      fontSize: "16px", // Update font size
      borderRadius: "8px", // Update border radius
      cursor: "pointer",
    },
    label: {
      color: "#333", // Update label color
      fontSize: "16px",
      fontWeight:"bold" // Update font size
    },
  };
  const [dayOptions] = useState(
    [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0"))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0"))
  );
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map((year) =>
      String(startYear + year)
    );
  });

  const [hourOptions] = useState(
    [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0"))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0"))
  );

  const [maleName, setMalename] = useState("");
  const [maleHour, setMaleHour] = useState("");
  const [male_minute, setMaleMinute] = useState("");
  const [maleAmpm, setMaleAmpm] = useState("");
  const [maleBirthday, setMaleBithDay] = useState("");
  const [maleBirthMonth, setMaleBirthMonth] = useState("");
  const [maleBirthYear, setMaleBirthYear] = useState("");
  const [maleBirthplace, setMaleBirthPlace] = useState("");
  const [femaleName, setFemaleName] = useState("");
  const [femaleHour, setFemaleHour] = useState("");
  const [femaleMinute, setfemaleMinute] = useState("");
  const [femaleAmpm, setFemaleAmpm] = useState("");
  const [femaleBirthDay, setFemaleBirthDay] = useState("");
  const [femaleBirthMonth, setFemaleBirthMonth] = useState("");
  const [femaleBirthYear, setFemaleBirthYear] = useState("");
  const [femaleBirthplace, setFemaleBirthPlace] = useState("");
  const [malesuggestedPlaces, setMaleSuggestedPlaces] = useState([]);
  const [femalesuggestedPlaces, setfeMaleSuggestedPlaces] = useState([]);
  const [maleselectedPlaces, setmaleSelectedPlaces] = useState([]);
  // error validation states
  const [maleNameError, setMaleNameError] = useState("");
  const [femaleNameError, setFemaleNameError] = useState("");
  const [maleDayError, setMaleDayError] = useState("");
  const [feMaleDayError, setFemaleDayError] = useState("");
  const [maleMonthError, setMaleMonthError] = useState("");
  const [femaleMonthError, setFemaleMonthError] = useState("");
  const [maleYearError, setMaleYearError] = useState("");
  const [femaleYearError, setFemaleYearError] = useState("");
  const [maleHourError, setMaleHourError] = useState("");
  const [femaleHourError, setFemaleHourError] = useState("");
  const [maleMinuteError, setMaleMinuteError] = useState("");
  const [feMaleMinuteError, setFemaleMinuteError] = useState("");
  const [maleAmPmError, setAmpmMinuteError] = useState("");
  const [femaleAmPmError, setfeAmpmMinuteError] = useState("");
  const [malebirthplaceError, setMaleBirthplaceError] = useState("");
  const [femalebirthplaceError, setfeMaleBirthplaceError] = useState("");

  const handleMaleLocationChange = (event) => {
    const currentValue = event.target.value;
    setMaleBirthPlace(currentValue);
    // console.log(currentValue);
    fetchMaleSuggestions(currentValue);
    // console.log(currentValue);
  };

  const handlefeMaleLocationChange = (event) => {
   
    const currentValue = event.target.value;
    // console.log(currentValue);
    setFemaleBirthPlace(currentValue);
    fetchfeMaleSuggestions(currentValue);
  };

  const fetchfeMaleSuggestions = async (currentValue) => {
// console.log('im comed ');
    if (currentValue.length >= 3) {
      const apiKey = "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=5`;
  
      try {
        const response = await fetch(url);
        // console.log(response);
        if (response.ok) {
     
          const data = await response.json();
          setfeMaleSuggestedPlaces(data.features);
        
        } else {
          console.error("Error fetching suggestions from the API");
          setfeMaleSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setfeMaleSuggestedPlaces([]);
      }
    }
  };

  const handlefeMaleSuggestionSelect = (selectedPlace) => {

const femalelatitude =selectedPlace.center[1];
    const femalelongitude = selectedPlace.center[0];
    const femaleparts =selectedPlace.place_name.split(',');
    const FemaledisplayName = femaleparts.slice(0, 3).join(',');
    const Female_location = FemaledisplayName

    const female_location = Female_location;


    // setfemaleSelectedPlaces(updatedSelectedPlaces);
    setFemaleBirthPlace(female_location);
    setfeMaleSuggestedPlaces([]);

    document.getElementById("female_longitude").value = femalelongitude;
    document.getElementById("female_latitude").value = femalelatitude;
  };

  const handleMaleSuggestionSelect = (selectedPlace) => {
    
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const maleParts =selectedPlace.place_name.split(',');
    const maleisplayName = maleParts.slice(0, 3).join(',');
    const male_location = maleisplayName

    // const fmale_location = male_location;
 
    const location = male_location;

    const updatedSelectedPlaces = [...maleselectedPlaces, location];

    setmaleSelectedPlaces(updatedSelectedPlaces);
    setMaleBirthPlace(location);
    setMaleBirthplaceError("");

    setMaleSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchMaleSuggestions = async (currentValue) => {
    
    if (currentValue.length >= 3) {
      const apiKey = "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=5`;
  
      try {
        const response = await fetch(url);
 
        if (response.ok) {
       
          const data = await response.json();
          setMaleSuggestedPlaces(data.features);
        
        } else {
          console.error("Error fetching suggestions from the API");
          setMaleSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setMaleSuggestedPlaces([]);
      }
    }
  };
  const validateForm = () => {
    let isValid = true;

    // Male fields
    if (!maleName) {
      setMaleNameError("Field is required");
      isValid = false;
    } else {
      setMaleNameError("");
    }

    if (!maleBirthday) {
      setMaleDayError("Field is required");
      isValid = false;
    } else {
      setMaleDayError("");
    }

    if (!maleBirthMonth) {
      setMaleMonthError("Field is required");
      isValid = false;
    } else {
      setMaleMonthError("");
    }

    if (!maleBirthYear) {
      setMaleYearError("Field is required");
      isValid = false;
    } else {
      setMaleYearError("");
    }

    if (!maleHour) {
      setMaleHourError("Field is required");
      isValid = false;
    } else {
      setMaleHourError("");
    }

    if (!male_minute) {
      setMaleMinuteError("Field is required");
      isValid = false;
    } else {
      setMaleMinuteError("");
    }

    if (!maleAmpm) {
      setAmpmMinuteError("Field is required");
      isValid = false;
    } else {
      setAmpmMinuteError("");
    }

    if (!maleBirthplace) {
      setMaleBirthplaceError("Field is required");
      isValid = false;
    } else {
      setMaleBirthplaceError("");
    }

    // Female fields
    if (!femaleName) {
      setFemaleNameError("Field is required");
      isValid = false;
    } else {
      setFemaleNameError("");
    }

    if (!femaleBirthDay) {
      setFemaleDayError("Field is required");
      isValid = false;
    } else {
      setFemaleDayError("");
    }

    if (!femaleBirthMonth) {
      setFemaleMonthError("Field is required");
      isValid = false;
    } else {
      setFemaleMonthError("");
    }

    if (!femaleBirthYear) {
      setFemaleYearError("Field is required");
      isValid = false;
    } else {
      setFemaleYearError("");
    }

    if (!femaleHour) {
      setFemaleHourError("Field is required");
      isValid = false;
    } else {
      setFemaleHourError("");
    }

    if (!femaleMinute) {
      setFemaleMinuteError("Field is required");
      isValid = false;
    } else {
      setFemaleMinuteError("");
    }

    if (!femaleAmpm) {
      setfeAmpmMinuteError("Field is required");
      isValid = false;
    } else {
      setfeAmpmMinuteError("");
    }

    if (!femaleBirthplace) {
      setfeMaleBirthplaceError("Field is required");
      isValid = false;
    } else {
      setfeMaleBirthplaceError("");
    }

    // Additional checks for latitude
    let MaleLatitideError = document.getElementById("atflt").value;
    if (!MaleLatitideError) {
      setMaleBirthplaceError("Please select the Birthplace again");
      isValid = false;
    } else {
      setMaleBirthplaceError("");
    }

    let FemaleLatitudeError = document.getElementById("female_latitude").value;
    if (!FemaleLatitudeError) {
      setfeMaleBirthplaceError("Please select the Birthplace again");
      isValid = false;
    } else {
      setfeMaleBirthplaceError("");
    }

    return isValid;
  };

  const navigate = useNavigate();



  const marriageMatchingSubmit = () => {
    if (validateForm()) {
      // console.log("Form has been submitted");
      const marriageMatchingFormData = {
        maleName: maleName,
        MaleDateofBirth: `${maleBirthYear}-${maleBirthMonth}-${maleBirthday}`,
        MaleBirthtime: `${maleHour}:${male_minute} ${maleAmpm}`,
        MaleBirthplace: maleBirthplace,
        Malelatitude: document.getElementById("atflt").value,
        Malelongitude: document.getElementById("atflg").value,
        FemaleName: femaleName,
        femaleBirthplace: femaleBirthplace,
        feMaleDateofBirth: `${femaleBirthYear}-${femaleBirthMonth}-${femaleBirthDay}`,
        FemaleBirthTime: `${femaleHour}:${femaleMinute} ${femaleAmpm}`,
        femalelatitude: document.getElementById("female_latitude").value,
        femalelongitude: document.getElementById("female_longitude").value,
      };

      const token=localStorage.getItem('authToken');
  
      axios.post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/Add-Marriage-Matching",
         marriageMatchingFormData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      ).then(response=>{
           const mgResponse=response.data.status;
         
           if(mgResponse===true){
            Notify.success('Your Data Added to  Your report,you can pay and download it...');    
        navigate('/Marriage-Matching-Report', { replace: true });
           }
      });
    }
  };

  return (
    <div>
      <div className="card border-0">
        <div className="card-body">
       <div className="text-center">
       <div className="text-primary fw-bold p-4 fs-5 ">
          <h3> திருமண பொருத்தம் </h3>
          </div>
       </div>
          <div className="row">
            <div className="col-md-6 col-12">
             
              <form style={styles.form}>
                <div className="row">
                  
                  <div className="text-center">
                    <h4 className="text-danger fw-bold">மணமகன்</h4>
                  </div>
                  <div className="col-12 col-md-12">
                    <label style={styles.label}>பெயர்</label>
                    <input
                      className={`form-control ${
                        maleNameError && "is-invalid"
                      }`}
                      style={styles.input}
                      value={maleName}
                      onChange={(e) => {
                        setMalename(e.target.value);
                      }}
                      type="text"
                      placeholder="பெயர்"
                    />
                    {maleNameError && (
                      <div className="invalid-feedback fw-bold">
                        {maleNameError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <label style={styles.label}> பிறந்த தேதி :</label>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        maleDayError && "is-invalid"
                      }`}
                      value={maleBirthday}
                      onChange={(e) => {
                        setMaleBithDay(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">தேதி</option>
                      {dayOptions.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                    {maleDayError && (
                      <div className="invalid-feedback fw-bold">
                        {maleDayError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        maleMonthError && "is-invalid"
                      }`}
                      value={maleBirthMonth}
                      onChange={(e) => {
                        setMaleBirthMonth(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">மாதம்</option>
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    {maleMonthError && (
                      <div className="invalid-feedback fw-bold">
                        {maleMonthError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        maleYearError && "is-invalid"
                      }`}
                      value={maleBirthYear}
                      onChange={(e) => setMaleBirthYear(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="">வருடம்</option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    {maleYearError && (
                      <div className="invalid-feedback fw-bold">
                        {maleYearError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <label style={styles.label}> பிறந்த நேரம்:</label>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        maleHourError && "is-invalid"
                      }`}
                      value={maleHour}
                      onChange={(e) => {
                        setMaleHour(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">நேரம்</option>
                      {hourOptions.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>
                    {maleHourError && (
                      <div className="invalid-feedback fw-bold">
                        {maleHourError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        maleMinuteError && "is-invalid"
                      }`}
                      value={male_minute}
                      onChange={(e) => {
                        setMaleMinute(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">நிமிடம்</option>
                      {minuteOptions.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </select>
                    {maleMinuteError && (
                      <div className="invalid-feedback fw-bold">
                        {maleMinuteError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      value={maleAmpm}
                      onChange={(e) => {
                        setMaleAmpm(e.target.value);
                      }}
                      className={`form-select text-dark ${
                        maleAmPmError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                    >
                      <option value="">காலம்</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                    {maleAmPmError && (
                      <div className="invalid-feedback fw-bold">
                        {maleAmPmError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <label style={styles.label}>Birth Place</label>
                    <input
                      style={styles.input}
                      type="text"
                      onChange={handleMaleLocationChange}
                      value={maleBirthplace}
                      className={`form-control  dSuggest  ${
                        malebirthplaceError && "is-invalid"
                      }`}
                      id="autocomplete"
                      placeholder="பிறந்த இடத்தை தேர்வு செய்யவும்"
                    />
                    {malebirthplaceError && (
                      <div className="invalid-feedback  fw-bold">
                        {malebirthplaceError}
                      </div>
                    )}
                    <input
                      id="atflt"
                      className="pfipbx"
                      name="the_lat"
                      type="hidden"
                      placeholder="Latitude"
                    />
                    <input
                      id="atflg"
                      className="pfipbx"
                      name="the_lng"
                      type="hidden"
                      placeholder="Longitude"
                    />
                    <ul>
                      {malesuggestedPlaces.map((place, index) => {
                       if (place.place_name) {
                        const listItemStyle = {
                          padding: "5px 10px",
                          cursor: "pointer",
                          fontSize: "16px",
                          borderBottom: "1px dashed #ddd",
                        };
  
                        // Split the place_name and get the first three parts
                        const parts = place.place_name.split(",");
                        const displayName = parts.slice(0, 3).join(",");
  
                          return (
                            <p
                              style={listItemStyle}
                              key={index}
                              onClick={() => handleMaleSuggestionSelect(place)}
                            >
                              {displayName}
                            </p>
                          );
                        }
                      })}
                    </ul>

                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 col-12">
              <form style={styles.form}>
                <div className="row ">
                  <div className="text-center">
                    <h4 className="text-danger fw-bold">மணமகள்</h4>
                  </div>
                  <div className="col-12 col-md-12">
                    <label style={styles.label}>பெயர்</label>
                    <input
                      style={styles.input}
                      type="text"
                      className={`form-control ${
                        femaleNameError && "is-invalid"
                      }`}
                      value={femaleName}
                      onChange={(e) => {
                        setFemaleName(e.target.value);
                      }}
                      placeholder="பெயர்"
                    />
                    {femaleNameError && (
                      <div className="invalid-feedback fw-bold">
                        {femaleNameError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <label style={styles.label}>பிறந்த தேதி : </label>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      value={femaleBirthDay}
                      onChange={(e) => {
                        setFemaleBirthDay(e.target.value);
                      }}
                      aria-label="Default select example"
                      className={`form-select text-dark ${
                        feMaleDayError && "is-invalid"
                      }`}
                    >
                      <option value="">தேதி</option>
                      {dayOptions.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                    {feMaleDayError && (
                      <div className="invalid-feedback fw-bold">
                        {feMaleDayError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        femaleMonthError && "is-invalid"
                      }`}
                      value={femaleBirthMonth}
                      onChange={(e) => {
                        setFemaleBirthMonth(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">மாதம்</option>
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    {femaleMonthError && (
                      <div className="invalid-feedback fw-bold">
                        {femaleMonthError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        femaleYearError && "is-invalid"
                      }`}
                      value={femaleBirthYear}
                      onChange={(e) => {
                        setFemaleBirthYear(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">வருடம்</option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    {femaleYearError && (
                      <div className="invalid-feedback fw-bold">
                        {femaleYearError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <label style={styles.label}>பிறந்த நேரம்:</label>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        femaleHourError && "is-invalid"
                      }`}
                      value={femaleHour}
                      onChange={(e) => {
                        setFemaleHour(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">நேரம்</option>
                      {hourOptions.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>
                    {femaleHourError && (
                      <div className="invalid-feedback fw-bold">
                        {femaleHourError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        feMaleMinuteError && "is-invalid"
                      }`}
                      value={femaleMinute}
                      onChange={(e) => {
                        setfemaleMinute(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">நிமிடம்</option>
                      {minuteOptions.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </select>
                    {feMaleMinuteError && (
                      <div className="invalid-feedback fw-bold">
                        {feMaleMinuteError}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select text-dark ${
                        femaleAmPmError && "is-invalid"
                      }`}
                      value={femaleAmpm}
                      onChange={(e) => {
                        setFemaleAmpm(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">காலம்</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                    {femaleAmPmError && (
                      <div className="invalid-feedback fw-bold">
                        {femaleAmPmError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <label style={styles.label}>Birth Place</label>
                    <input
                      style={styles.input}
                      type="text"
                      onChange={handlefeMaleLocationChange}
                      value={femaleBirthplace}
                      placeholder="பிறந்த இடத்தை தேர்வு செய்யவும்"
                      className={`form-control dSuggest  ${
                        femalebirthplaceError && "is-invalid"
                      }`}
                    />
                    {femalebirthplaceError && (
                      <div className="invalid-feedback  fw-bold">
                        {femalebirthplaceError}
                      </div>
                    )}
                    <input
                      id="female_latitude"
                      className="pfipbx"
                      name="female_the_lat"
                      type="hidden"
                      placeholder="Latitude"
                    />
                    <input
                      id="female_longitude"
                      className="pfipbx"
                      name="female_the_lng"
                      type="hidden"
                      placeholder="Longitude"
                    />

<ul>
                      {femalesuggestedPlaces.map((place, index) => {
                        if (place.place_name) {
                          const listItemStyle = {
                            padding: "5px 10px",
                            cursor: "pointer",
                            fontSize: "16px",
                            borderBottom: "1px dashed #ddd",
                          };
    
                          // Split the place_name and get the first three parts
                          const parts = place.place_name.split(",");
                          const displayName = parts.slice(0, 3).join(",");
                          return (
                            <p
                              style={listItemStyle}
                              key={index}
                              onClick={() =>
                                handlefeMaleSuggestionSelect(place)
                              }
                            >
                              {displayName}
                            </p>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </form>
            </div>
            <div className="row mt-4">
              <div className="col-6 m-auto text-center d-grid gap-2 d-md-block">
                <button
                  className="fs-6 fw-bold"
                  onClick={() => marriageMatchingSubmit()}
                  style={styles.button}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
