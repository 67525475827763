import React,{useState} from "react";
import { Link } from "react-router-dom";

export default function Menubar() {
  return (
    <>
      <Navbar1 />
      <Navbar2 />
    </>
  );
}

function Navbar1() {
  return (
    <div className="fixed-top bg-tertiary">
      <div className="container">
        <div className="row p-2">
          <div className="col-8">
            <div className="suppor_mail">
              <p className="fw-bold" style={{ color: "#b2012f" }}>
                <i className="fa-regular fa-envelope"></i> support@onepagehoroscope.com<br />
                <i className="fa-solid fa-mobile " style={{ color: "#b2012f" }}></i> Customer support: 9787420717
              </p>
            </div>
          </div>
          <div className="col-4 suppor_mail">
            <Link className="float-end text-white button-7 p-2" to="/Login">
              <i className="fa-solid fa-user text-white px-2 fs-6"></i>Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function Navbar2() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <div className={`navigation bg-tertiary fixed-top ${isNavOpen ? "show" : ""}`} style={{ top: "58px" }}>
      <div className="navbar navbar-expand-xl navbar-light text-center header">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={require('./img/one_page_logo.webp')} alt="logo" className="img-fluid onepage-logo" width="200" height="200" />
          </Link>
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
              
              <li className="nav-item"><Link to="/Birth-horoscope" className="nav-link">Birth Horoscope</Link></li>
              <li className="nav-item"><Link to="/Marriage-matching" className="nav-link">Marriage Matching</Link></li>
              <li className="nav-item"><Link to="/Marriage-Bio-Data" className="nav-link">Marriage Bio-Data</Link></li>
              <li className="nav-item"><Link to="/Baby-Name-List" className="nav-link">Baby Name</Link></li>
              <li className="nav-item"><Link to="/Tamil-jathagam-book" className="nav-link">Horoscope Book</Link></li>
              <li className="nav-item"><Link to="/Ruthu-Jathagam" className="nav-link">Ruthu Jathagam</Link></li>
            

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
