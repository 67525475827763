import React from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
export default function BlogView(){
    return(
        <>
        <Menubar />
        <BlogViewContent />
        <Footer/>
        </>
    );
}
const BlogViewContent = () => {
    return (
      <>
        <section style={{ marginTop: "190px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-9 blog-head">
                <Link href="Blog/categories/Tamil/Blog-View">
                  <img
                  src={require('./images/eye-hand-space_508732-95.jpg')}
                    // src=""
                    style={{ borderRadius: "10px" }}
                    width="700px"
                    alt="Post Thumbnail"
                  />
                </Link>
                <div className="vc_empty_space" style={{ height: "30px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <h1 className="fs-3">Learn about the 12 house of Astrology in Tamil</h1>
                <div className="vc_empty_space" style={{ height: "50px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <h2 className="fs-4 fw-normal">முதல் வீடு - 1st house :</h2>
                <div className="vc_empty_space" style={{ height: "10px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <font className="fs-6 text-black" style={{ verticalAlign: "inherit" }}>
                  முதல் வீடு என்பது லக்கினத்தை குறிக்கும். இந்த வீடு ஜாதகரின் வாழ்க்கையை சொல்கிறது. ஒருவருடைய உடல் , வலிமை வாய்மை நிறம் ஆகியவற்றை லக்கினம் கூறுகிறது .
                </font>
                <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">இரண்டாம் வீடு - 2nd house :</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இரண்டாம் வீடு  என்பது ஜாதகத்தையும் ஜாதகரின் குடும்பம் உறுப்பினர்களையும் குறிக்கும். ஜாதகத்திற்கு இரண்டாம் வீடு முக்கியமானது . இரண்டாம் வீடு கல்வி, பொன், பொருள் உள்நாட்டு , வெளிநாட்டுப் பிரயாணங்கள் ஆகியவற்றை அறிய உதவுகிறது. </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">மூன்றாம் வீடு - 3rd house:</h2>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>மூன்றாம் வீடு  என்பது ஜாதகரின் சகோதர, சகோதரிகளை பற்றி அறிய உதவுகிறது. சகோதர, சகோதரிகள் நல்ல அந்தஸ்தோடு இருப்பார்கள். அவர்களால் ஜாதகருக்கு எல்லாவிதத்திலும் உதவிகள் கிடைக்கும். ஜாதகரின் மனவலிமை,செயல் துணிவு ஆயுள் ஆகியவற்றை அறிய உதவும்.  </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">நான்காம் வீடு - 4th house :</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>நான்காம் வீடு என்பது சொந்த நலன்களுக்கும் மகிழ்ச்சிக்கும் உரியது. தாய், தாய்மாமன் ,வாகனம், உறவினர், மூதாதையார்  சொத்து, பயிர், நிலம், கால்நடை ஆகியவற்றை அறிய பயன்படுகிறது .</font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">ஐந்தாம் வீடு - 5th house:</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே பூர்வ புண்ணிய ஸ்தானம் , புத்திர ஸ்தானமாகும். . இந்த வீடு ஜாதகரின் திறமை தகப்பனார் வலிச்சொத்து , அரசாங்க அனுகூலம் , மதிப்பு , உடல்நலம் ஆகிய அனைத்து நலன்களும் இந்த பாவத்தின் தன்மைகேற்பவே அமையும்.  </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">ஆறாம் வீடு - 6th house  :</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalalign:"inherit" }}>இதுவே சத்ரு ஸ்தானமாகும் இந்த வீடு வீணான தொல்லைகள் , உடல் நோய் , அச்சம் , கடன் தொல்லைகள் ஆகிய அனைத்தையும் அளிக்கக் கூடியது. இந்த வீட்டில் ஏதாவது ஒரு நல்ல கிரகம் தங்கினாலோ அல்லது நல்ல கிரகத்தின் பார்வை ஏற்பட்டாலோதான் தொல்லைகள் குறைய வழிபிறக்கும். </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">ஏழாம் வீடு - 7th house  :</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே களத்திர ஸ்தானமாகும். இந்த ஏழாவது வீடு கணவன் மனைவி தாம்பத்திய உறவு , எண்ணங்கள் ஆகியவற்றை குறிப்பதாகும். திருமணம் , கண்ணிரைத்த வாழ்க்கை துணை , சௌபாக்கிய வாழ்வு மனைவி மூலமாக சொத்து , லாட்டரி , பந்தயம் முதலியவற்றில் வெற்றி ஆகியவற்றை இந்த வீடு நிர்ணயிக்கும்.</font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">எட்டாம் வீடு - 8th house :</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே ஆயுள் ஸ்தானமாகும். இதுவே ஜாதகரின் ஆயுள் பாவத்தை நிர்ணயிப்பதாகும். இந்த வீடு நன்கு அமைய பெற்றிருந்தால் , ஜாதகர் ஆரோக்கியமான நீண்ட ஆயுளை பெறுவார்.ஜாதகரின் வாழ்நாள் நீண்டதாக அமைய ராகு மேஷம் ரிஷபம் கடகம் சிம்மம் மகரம் ஆகிய ராசிகளில் தங்கிருந்தாலே போதுமானது. </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">ஒன்பதாம் வீடு - 9th house :</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே பிதுர்ஸ்தானம் பாக்கிய ஸ்தானமாகும். இது அதிஷ்டம் , தர்மம் , கர்பம் , ஆகியவை பற்றியும் தந்தையின் நலன் பற்றியும் குறிக்கும் பாவமாகும். சொந்த சுகம் , தந்தை யாரின் உடல்நலம் , அவருடைய நன்மை தீமைகள் தந்தைக்கும் மகனுக்கும் உள்ள உறவு முறைகள் , பாசம் ஆகியவற்றையும் இந்த பாவமே நிர்ணயிக்கும். </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">பத்தாம் வீடு - 10th house:</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே கர்ம ஸ்தானம். தொழில் ஸ்தானமாகும். தொழில் , வாழ்க்கை நிலை , கர்மம் ஆகியவற்றிக்குப் பத்தாவது வீடே முக்கியமானது. பத்தாவது பாவமே அவற்றிக்கு உரியதாகும். பத்தாவது வீடு காலியாக இல்லாமல் ஏதாவது ஒரு கிரகம் அமர்ந்திருந்தால் மிகவும் நலமாக , இருக்கும். </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">பதினொன்றாம் வீடு - 11th house:</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே லாபஸ்தானம் , மூத்த சகோதர ஸ்தானமாகும். இதை லாப பாவம் என்று அழைப்பார்கள் செய்தொழிலில் நன்மை, நண்பர்கள், சுற்றத்தார் ஆகியோர் ஆதரவு வாழ்க்கையின் ஆசாபாசங்கள் நிறைவேறுதல் சொத்து சுகம் முதலியவற்றிற்கு இந்த வீட்டையே கொள்ளலாம். </font>
                  <div className="vc_empty_space" style={{ height: "20px" }}><span className="vc_empty_space_inner"></span></div>
                  <h2 className="fs-4 fw-normal">பன்னிரெண்டாம் வீடு - 12th house:</h2>
                  <div className="vc_empty_space" style={{ height: "10px" }}><span className="vc_empty_space_inner"></span></div>
                  <font className="fs-6 text-black" style={{ verticalAlign:"inherit" }}>இதுவே விரயஸ்தானம் , சயன ஸ்தானமாகும். இந்த வீடு விரயம் எனப்படும். பன்னிரெண்டாவது வீடு பொருள் இழப்பையும் தொல்லைகளையும் அளிக்கும். மற்றும் உறவினர் நண்பர்களை பகைவர்களாக செய்யும் உடல்நலம் பாதிக்கப்படும் வழக்குகளை வீண் கவலைகளை ஏற்படுத்தும்.</font>
              
              </div>
              <div className="col-lg-3">
                <div className="widget">
                  <h5 className="widget-title"><span>Latest Article</span></h5>
                  <ul className="list-unstyled widget-list">
                    <li className="d-flex widget-post align-items-center">
                      <Link className="text-black" to="/Blog/categories/Tamil/Blog-View">
                        <div className="widget-post-image flex-shrink-0 me-3">
                          <img
                            className="rounded"
                            loading="lazy"
                            src={require('./images/eye-hand-space_508732-95.jpg')}
                            // src="images/blog/eye-hand-space_508732-95.jpg"
                            alt="Post Thumbnail"
                          />
                        </div>
                      </Link>
                    
                    </li>
                    <div className="flex-grow-1 mt-1">
                        <h5 className="h6 mb-0"><Link to="/Blog/categories/Tamil/Blog-View" className="text-black" style={{ textDecoration:"none" }}>Learn about the 12 house of Astrology in Tamil.</Link></h5>
                        <small>March 15, 2020</small>
                      </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  
  