import "./Carousel.css"

export default function Banner_carousel(){
    return(
        <div className="bg-tertiary position-relative overflow-hidden" id="Home">
            <div className="banner">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                        <div>
                           
                            <img src={require('./img/one_page_horoscope-birth-shart-slider.webp')} className="d-block" width="100%" alt="jathagam in tamil" />
                        </div>
                        </div>
                        <div className="carousel-item">
                        <div>
                            
                            <img src={require('./img/one-page-horoscope-marriage-matching-slider.webp')} className="d-block" width="100%" alt="tamil marriage matching" />
                        </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"><span className="carousel-control-prev-icon" aria-hidden="true"></span><span className="visually-hidden">Previous</span></button><button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"><span className="carousel-control-next-icon" aria-hidden="true"></span><span className="visually-hidden">Next</span></button>
                </div>
            </div>
        </div>
    );
}