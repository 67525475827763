import { useParams } from "react-router-dom";
import Logo from "../tamil/header/img/one_page_logo.webp";
import { useState, useEffect } from "react";
import { Report } from "notiflix/build/notiflix-report-aio";
import { useNavigate } from "react-router-dom";
import { OnepagePayment } from "./payment/payment";
import Offcanvas from "./components/offcanvas";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { OnePageApiResponse } from "./data/response";
import OnepagePaidTemplate from "../tamil/onepagePaidTemplate/onepagePaidtemplate";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import ReactDOMServer from "react-dom/server";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import axios from "axios";

const styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    background: "#f8f9fa",
  },
  cardContainer: {
    width: "80%",
    maxWidth: "600px",
    background: "#fff",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "20px",
    margin: "20px",
  },
  heading: {
    textAlign: "center",
    color: "#000",
    fontSize: "28px",
    marginBottom: "20px",
  },
  tableContainer: {
    margin: "20px 0",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    borderBottom: "1px solid #dee2e6",
  },
  tableCell: {
    padding: "12px",
    textAlign: "left",
    fontSize: "17px",
    color: "#495057",
  },
  tableCellBold: {
    fontWeight: "bold",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: "30px",
  },
  successButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    fontSize: "16px",
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
  },
  dangerButton: {
    backgroundColor: "#dc3545",
    color: "#fff",
    fontSize: "16px",
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
  },
};

const Offer = () => {
  const Navigation = useNavigate();
  const { user_id, data_id } = useParams();
  const [UserData, setUserData] = useState("");
  const [Email, setEmail] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [Changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const OfferData = {
      user_id: user_id,
      customer_data: data_id,
    };
    setLoading(true);
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Offer-Search",
        OfferData
      )
      .then((response) => {
        const ApiResponse = response.data.data;
        const finalResponse = response.data.status;
        if (finalResponse === true) {
          setLoading(false);
          setUserData(ApiResponse[0]);
          setEmail(ApiResponse[0].email);
          setMobileNumber(ApiResponse[0].mobile_number);
          console.log('pdf', ApiResponse[0].pdf_file);
          const PdfFile= ApiResponse[0].pdf_file;
          if (PdfFile!=null) {
            console.log('yes im not null');
            Report.info(
              "Offer Expired",
              'sorry for the Inconvience !!!!, Try Again Someother Time"',
              "OKAY",
              () => {
                Navigation("/");
              }
            );
          }
        } else {
          Report.info(
            "No Data Found ",
            'sorry for the Inconvience !!!!, Try Again Someother Time"',
            "OKAY",
            () => {
              Navigation("/");
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });
  }, [user_id, data_id, Changed]);

  const checkPackage = (userPackage) => {
    if (userPackage === "one_page") {
      return "One Page Horoscope";
    } else if (userPackage === "marriage_matching") {
      return "marriage_matching";
    } else if (userPackage === "baby_name") {
      return "Baby Name List";
    } else {
      return "Bio Data";
    }
  };

  const HandlePay = async () => {
    localStorage.setItem("offer-pack", "one_page");
    await OnePageApiResponse(UserData);

    setTimeout(() => {
      GetUserInfoFromDB(UserData.package);
    }, 2000);
  };


  const GetUserInfoFromDB = async(userPackage) => {
    if (userPackage === "one_page") {
     

      const OnePagePaymentOption = {
        buyerMobilenumber: MobileNumber,
        email: Email,
        buyerName: UserData.name,
        user_id: user_id,
        customerDataId: data_id,
        RedirectUrl:
          "https://ctsvsolutions.com/onepage-Api/public/offer-onepage-payment",
        price: "50",
        tnxNote:'offer-onepage'
      };
      OnepagePayment(OnePagePaymentOption);
    } else if (userPackage === "marriage_matching") {
    } else if (userPackage === "baby_name") {
      // BabyNameResponse();
    } else {
      return "Bio Data";
    }
  };

  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const Authorization = queryParamsObject["isAuth"];
    const Paymemt_Status = queryParamsObject["payment_status"];

    if (Authorization === "TruE" && Paymemt_Status === "CrEdiT") {
      Report.success(
        "Payment Success",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Birth Horoscope is Processing...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          const userInfo = {
            user_id: user_id,
            customerDataId: data_id,
          };

          const printElement = ReactDOMServer.renderToString(paidPdf());
          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .save();

          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(
                base64Data,
                userInfo.user_id,
                userInfo.customerDataId
              );
            });
        }
      );
    }
  }

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "one_page",
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    axios
      .post(
        " https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
        pdfDataVal
      )
      .then((response) => {
        axios
          .post(
            " https://www.ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                " https://www.ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      user_id: user_id,
                      customer_data_id: customerDataId,
                      feedback: clientAnswer,
                    };
                    axios
                      .post(
                        "https://www.ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        window.location.replace("/user");
                      });
                  },
                  (clientAnswer) => {
                    window.location.replace("/user");
                  }
                );
                Notify.success("Sample pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  const paidPdf = () => {
    const storedUserInfo = localStorage.getItem("OfferOnepage");
    const dataInfo = JSON.parse(storedUserInfo);
    return <OnepagePaidTemplate value={dataInfo} />;
  };

  return (
    <div style={styles.mainContainer} className="">
      <div style={styles.cardContainer}>
        <div className="row">
          <div className="col-6">
            <div className="img">
              <img src={Logo} alt="" />
            </div>
          </div>
          <div className="col-6">
            <div>
              <h2 style={styles.heading}>Exclusive Offer</h2>
              <div className="row">
                <div className="col-12">
                  {/* <span className="text-primary fw-bold">Sale End In:</span> */}
                  {/* {formatRemainingTime()} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div style={styles.tableContainer} className="table-responsive">
              <table style={styles.table}>
                <tbody>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Name
                    </td>
                    <td style={styles.tableCell}>
                      {loading ? (
                        <Skeleton height={30} width={160} />
                      ) : (
                        UserData.name
                      )}
                    </td>
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Date Of Birth
                    </td>
                    <td style={styles.tableCell}>
                      {loading ? (
                        <Skeleton height={30} width={160} />
                      ) : (
                        UserData.birth_date
                      )}
                    </td>
                    {/* <td style={styles.tableCell}>{UserData.birth_date}</td> */}
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Birth Time
                    </td>
                    <td style={styles.tableCell}>
                      {loading ? (
                        <Skeleton height={30} width={160} />
                      ) : (
                        UserData.birth_time
                      )}
                    </td>
                    {/* <td style={styles.tableCell}>{UserData.birth_time}</td> */}
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Birth Place
                    </td>
                    <td style={styles.tableCell}>
                      {loading ? (
                        <Skeleton height={30} width={160} />
                      ) : (
                        UserData.birth_place
                      )}
                    </td>
                    {/* <td style={styles.tableCell}>{UserData.birth_place}</td> */}
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Package
                    </td>
                    <td style={styles.tableCell}>
                      {loading ? (
                        <Skeleton height={30} width={160} />
                      ) : (
                        checkPackage(UserData.package)
                      )}
                    </td>
                    {/* <td style={styles.tableCell}>{checkPackage(UserData.package)}</td> */}
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Offer
                    </td>
                    <td class="text-primary fw-bold" style={styles.tableCell}>
                      50%
                    </td>
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Mobile Number
                    </td>
                    <td class="" style={styles.tableCell}>
                      <td style={styles.tableCell}>
                        <i class="fa-brands fa-whatsapp text-success"></i>
                        {loading ? (
                          <Skeleton height={30} width={160} />
                        ) : (
                          UserData.mobile_number
                        )}
                      </td>
                      {/* {UserData.email} */}
                    </td>
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      Email
                    </td>
                    <td class="" style={styles.tableCell}>
                      <td style={styles.tableCell}>
                        <i class="fa-regular fa-envelope text-danger"></i>
                        {loading ? (
                          <Skeleton height={30} width={160} />
                        ) : (
                          UserData.email
                        )}
                      </td>
                      {/* {UserData.mobile_number} */}
                    </td>
                  </tr>
                  <tr style={styles.tableRow}>
                    <td
                      style={{ ...styles.tableCell, ...styles.tableCellBold }}
                    >
                      {/* <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Edit</button> */}
                      <button
                        class="btn btn-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        Edit
                      </button>
                    </td>
                    <td style={styles.tableCell}>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          HandlePay();
                        }}
                      >
                        BUY NOW
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        MobileNumber={MobileNumber}
        setMobileNumber={setMobileNumber}
        Email={Email}
        setEmail={setEmail}
        setChanged={setChanged}
        user_id={user_id}
        customerDataId={data_id}
      />
    </div>
  );
};

export default Offer;
