import React from "react";
import backgroundImage from "./Img/bg-template.jpg";

export default function BioTemplate3({ value }) {
  const personalInfo=value.personal_info;
  const Navamsam=value.amsam_array;
  const amsamSlash=value.amsam_slash;
  const pavagaMaruthal=value.pavaga_maruthal;
  const RasiChart=value.rasi_array;
  const RasiSlash=value.rasivalue;
  const style = {
    sheet: {
      width: "20.3cm",
      minHeight: "29.5cm",
      margin: "auto",
    },

    sheet1: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat, repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    },

    sheet2: {
      backgroundColor: "#ecdfb2",
      color: "#33034a",
      border: "2px solid #33034a",
    },

    imgFluid: {
      height: "100%",
    },
    qus: {
      fontSize: "13px",
      fontWeight: "bold",
    },

    ans: {
      fontSize: "12px",
      fontWeight: "bold",
    },

    textDarkBlue: {
      color: "#33034a",
    },

    birthTable: {
      width: "100%",
      background: "#ecdfb2",
      fontSize: "12px",
      marginBottom: "1px",
      border: "2px solid #33034a",
      position: "relative",
    },

    laknamAfter: {
      content: "",
      width: "23px",
      border: "1px solid #33034a",
      position: "absolute",
      top: "7px",
      left: "-4px",
      transform: "rotate(136deg)",
    },

    laknamBefore: {
      content: "",
      width: "35px",
      border: "1px solid #33034a",
      position: "absolute",
      top: "11px",
      left: "-6px",
      transform: "rotate(136deg)",
    },

    rasiChart: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "600",
      color: "#33034a",
    },

    rasiBirth: {
      border: "2px solid #33034a",
      width: "70px",
      height: "75px",
      paddingLeft: "12px",
      position: "relative",
    },

    borderTop: {
      borderTop: "1px solid #33034a",
    },
  };
  return (
    <>
      <div style={{ ...style.sheet, ...style.sheet1 }} className="mt-2">
        <div className="container-fluid position-relative">
          <div className="row">
            <div
              className="col-12 p-1"
              style={{ position: "absolute", top: "198px" }}
            >
              <div className="text-center" style={{ height: "420px" }}>
              <img src={`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`} className="img-fluid rounded-circle" alt="sss" />

              </div>
            </div>
            <div
              className="col-12 p-1 fw-bold"
              style={{
                ...style.textDarkBlue,
                position: "absolute",
                top: "680px",
                left: "80px",
              }}
            >
              <div style={{ width: "80%" }}>
                <div className="row">
                  <div className="col-12 ps-5 text-center my-5 fs-2">
                  <div>{personalInfo.name}</div>
                    <div>  { personalInfo.birth_date }( { personalInfo.birth_time })</div>
                    <div>{ personalInfo.birth_place }</div>
                    <div>{ personalInfo.education }</div>
                    <div>{ personalInfo.caste }</div>
                    <div> {personalInfo.mobile_number} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ ...style.sheet, ...style.sheet2 }} className="mt-1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold">சுய விபரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              பாலினம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>  {personalInfo.gender}</div>
            <div className="col-3 qus my-1" style={style.qus}>
              பணி விவரங்கள் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.work_details }/</div>
            <div className="col-3 qus my-1" style={style.qus}>
            மாத வருமானம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.income }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              குலம்/கோத்ரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>  { personalInfo.kulam }/</div>
            <div className="col-3 qus my-1" style={style.qus}>
              உயரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.height }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              எடை <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.weight }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              நிறம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.color }</div>
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold">குடும்ப விவரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தந்தை பெயர் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.father_name }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              தாய் பெயர் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.mother_name }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              தந்தை பணி விபரம்  <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.father_occupation }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              தாய் பணி விபரம்   <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.mother_occupation }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              உடன் பிறந்தவர்கள் <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}> {personalInfo.sibling.map((sibling) => sibling.value).join(', ')}</div>

            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold">ஜாதக விவரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              லக்னம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{personalInfo.laknam}</div>
            <div className="col-3 qus my-1" style={style.qus}>
              உதயாதி நாழிகை <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.udhayathi_naligai } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              ராசி - நட்சத்திரம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> {personalInfo.rasi}-{ personalInfo.natchathiram }-{ personalInfo.patham }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              சூர்ய உதயம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.suriya_uthayam } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தமிழ் தேதி<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.tamil_day }</div>
            <div className="col-3 qus my-1" style={style.qus}>
              சூர்ய அஸ்தம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.surya_asthamanam } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              பஷம்/திதி <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.patcham }- { personalInfo.thithi } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              யோகா நட்சத்திரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.yoga_natchathiram } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              யோகம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.yogam } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              யோகி<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.yogi } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              கரணம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.karanam } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              அவயோக நட்சத்திரம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.avayogi_natchathiram } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              திதி சூன்யம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{ personalInfo.thithi_suniyam } </div>
            <div className="col-3 qus my-1" style={style.qus}>
              அவயோகி <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> { personalInfo.avayogi} </div>
            <div className="col-3 qus my-1" style={style.qus}>
              ஜாதக நிலை <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}> { personalInfo.jathaga_nilai} </div>
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold">தொடர்பு விவரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தொலைப்பேசி எண் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>{personalInfo.mobile_number} </div>
            <div className="col-3 qus my-1" style={style.qus}>
              வாட்ஸ்ஆப் எண் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}> {personalInfo.whatsapp_number}</div>
            <div className="col-3 qus my-1" style={style.qus}>
              முகவரி <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}>{personalInfo.user_address}</div>
            <div className="col-3 qus my-1" style={style.qus}>
              சொத்து விபரம் <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}> {personalInfo.assets_details}</div>
            <div
              className="col-3 qus my-1"
              style={{ ...style.qus, fontSize: "11px" }}
            >
              பொருந்தும் நட்சத்திரம் <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1 text-break" style={style.ans}> { value.macthing_star} </div>
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold">ஜாதக கட்டம்</span>
              </div>
            </div>
            <div className="col-6 pb-2 my-1">
              <table
                className=""
                cellSpacing="2"
                cellPadding="2"
                style={style.birthTable}
              >
                <tbody>
                  <tr>
                    <td className="" style={style.rasiBirth}>
                    {RasiChart.r12}
                    </td>
                    <td className="" style={style.rasiBirth}> {RasiChart.r1}</td>
                    <td className="" style={style.rasiBirth}> {RasiChart.r2}</td>
                    <td className="" style={style.rasiBirth}> {RasiChart.r3}
                  
                    </td>
                  </tr>
                  <tr>
                    <td className="" style={style.rasiBirth}>{RasiChart.r11}</td>
                    <td
                      className=""
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                      style={style.rasiBirth}
                    >
                      <p className="rasi-chart" style={style.rasiChart}>
                        ராசி
                      </p>
                    </td>
                    <td className="" style={style.rasiBirth}> {RasiChart.r4}</td>
                  </tr>
                  <tr>
                    <td className="" valign="top" style={style.rasiBirth}> {RasiChart.r10}</td>
                    <td className="" valign="top" style={style.rasiBirth}> {RasiChart.r5} </td>
                  </tr>
                  <tr>
                    <td
                      className=""
                      valign="top"
                      style={style.rasiBirth}
                    >
                     {RasiChart.r9}
                    </td>
                    <td className="" valign="top" style={style.rasiBirth}>{RasiChart.r8}</td>
                    <td className="" valign="top" style={style.rasiBirth}>{RasiChart.r7}</td>
                    <td className="" valign="top" style={style.rasiBirth}>{RasiChart.r6}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 pb-2 my-1">
              <table
                className=""
                cellSpacing="2"
                cellPadding="2"
                style={style.birthTable}
              >
                <tbody>
                  <tr>
                    <td className="" style={style.rasiBirth}>{Navamsam.a12}</td>
                    <td className="" style={style.rasiBirth}>{Navamsam.a1}</td>
                    <td className="" style={style.rasiBirth}> {Navamsam.a2}</td>
                    <td className="" style={style.rasiBirth}> {Navamsam.a3}
                    </td>
                  </tr>
                  <tr>
                    <td className="" style={style.rasiBirth}>{Navamsam.a11}</td>
                    <td
                      className=""
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                      style={style.rasiBirth}
                    >
                      <p className="" style={style.rasiChart}>
                        {/* {" "} */}
                        நவாம்சம்
                      </p>
                    </td>
                    <td className="" style={style.rasiBirth}>{Navamsam.a4} </td>
                  </tr>
                  <tr>
                    <td className="" valign="top" style={style.rasiBirth}>{Navamsam.a10}</td>
                    <td className="" valign="top" style={style.rasiBirth}>{Navamsam.a5}</td>
                  </tr>
                  <tr>
                    <td
                      className=" "
                      valign="top"
                      style={style.rasiBirth}>
                     {Navamsam.a9}
                    </td>
                    <td className="" valign="top" style={style.rasiBirth}>{Navamsam.a8}</td>
                    <td className="" valign="top" style={style.rasiBirth}>{Navamsam.a7}</td>
                    <td className="" valign="top" style={style.rasiBirth}>{Navamsam.a6}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="container-fluid m-0 p-0 text-center my-1"
            style={{ ...style.qus, ...style.borderTop }}
          >
            <span className="">
              இந்த ஜாதகம் www.onepagehoroscope.com இணைய தளத்தில்
              உருவாக்கப்பட்டது...
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
