
import React from "react";
// import "../header/Menubar.css";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import HoroscopeView from "../birth-table-view/Horoscope-View";


function Horoscope_table_View (){
    return (
        <div className="">
            <Menubar />
            <HoroscopeView />
            <Footer />
        </div>
    );
}

export default Horoscope_table_View;