import "./birth-content.css"

export default function BirthHoroscopeContent(){
    return(
        <>
        <div className="vc_empty_space" style={{height: "80px"}}>
            <span className="vc_empty_space_inner"></span>
            </div>
        <div className="container">
		<div className="row justify-content-center">
			<div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon"> 	 
                    <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
                    			</div>
					<h3 className="mb-3">Tamil Birth Chart</h3>
					<p  className="text-muted">Gives astrology planet position at the <br/>time of your birth.</p>
				</div>
            
			</div>
			
         <div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon"> 	 <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
					</div>
					<h3 className="mb-3">Nirayana Spudam</h3>
					<p className="mb-4 text-muted">Each plant position with degree, star and star guru .</p>
				</div>
			</div>
			<div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon"> 	 <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
					</div>
					<h3 className="mb-3">Rasi & Navamsam</h3>
					<p className="mb-4 text-muted">Rasi and navamsam there two basic chat with bavaga maruthal.</p> 
				</div>
			</div>
         <div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon"> 	 <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
					</div>
					<h3 className="mb-3">Panjang Details</h3>
					<p className="mb-4 text-muted">We are thirukanitha panjang for prediting your panjang details at the line of <br /> your birth.   </p> 
				</div>
			</div>
			<div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon">	 <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
					</div>
					<h3 className="mb-3">Dasa Details</h3>
					<p className="mb-4 text-muted">We give 25 years of your dasa details from current data, details inculde, Nadapu dasa, Maga thisa irupu, and More.</p> 
				</div>
			</div>
         <div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon"> 	 <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
					</div>
					<h3 className="mb-3">Sarvashtakavargam</h3>
					<p className="mb-4 text-muted">Sarvashtakavargam means eight classNamees.That is, Sun, Moon, Mars, Mercury, Jupiter, Venus, Saturn and Lagna form eight classNamees.</p> 
				</div>
			</div>
         <div className="icon-box-item text-center col-lg-4 col-md-6 mb-4">
				<div className="rounded shadow py-5 px-4">
					<div className="icon"> 	 <img src={require('./img/icon.png')} className="w-75" alt="tamil birth chart" />	
					</div>
					<h3 className="mb-3">Numerology Details</h3>
					<p className="mb-4 text-muted">Numerology details include lucky number, lucky color, lucky stone, lucky date.</p> 
				</div>
			</div>
        
		</div>
	</div>
        </>
        
    )
}