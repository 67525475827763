import { useState } from "react"
import "./Footer.css"
import axios from "axios"
// import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import {Link } from "react-router-dom";



export default function Footer (){
    return(
        <div className="section-sm">
           <div className="container">
                <div className="row justify-content-between">
                    <Footer1 />
                    <Footer2 />
                    <Footer3 />
                </div>
                <div className="row align-items-center mt-5 text-center text-md-start">
                        <Footer4 />  
                </div>
           </div>
        </div>
        
    )
}

function Footer1 (){
    return(
            <div className="col-lg-2 col-md-4 col-6 mb-4">
				<div className="footer-widget">
					<h5 className="mb-4 text-success font-secondary">Product</h5>
					<ul className="list-unstyled">
						<li className="mb-2"><Link to="/Birth-Horoscope" className="nav-link">Birth Horoscope</Link></li>
						<li className="mb-2"><Link to="/Marriage-matching" className="nav-link">Marriage Matching</Link></li>
						<li className="mb-2"><Link to="/Marriage-Bio-Data" className="nav-link">Marriage Bio Data</Link></li>
						<li className="mb-2"><Link to="/Baby-Name-List" className="nav-link">Baby Name List</Link></li>
						<li className="mb-2"><Link to="/Blog/categories/Tamil/Blog-Lists" className="nav-link">Blog</Link></li>
					</ul>
				</div>
			</div>
    )
}

function Footer2 (){
    return (
        <div className="col-lg-2 col-md-4 col-6 mb-4">
				<div className="footer-widget">
					<h5 className="mb-4 text-success font-secondary">Help</h5>
					<ul className="list-unstyled">
						<li className="mb-2"><Link to="/contact-us" className="nav-link" >Contact Us</Link></li>
					</ul>
					<ul className="list-unstyled">
						<li className="mb-2"><Link to="/About-us" className="nav-link">About us</Link></li>
					</ul>
					<ul className="list-unstyled">
						<li className="mb-2"><Link to="/FAQ" className="nav-link" >FAQ</Link></li>
					</ul>
				</div>
			</div>
    )
}

function Footer3 (){
	const [subscribeEmail,setSUbscribeEmail]=useState('');

	const isEmailValid = (email) => {
		// Regular expression for basic email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	  };
	  

	  const submitSubscribeForm = () => {
		if (!subscribeEmail) {
		  Notify.failure('Enter the Email');
		} else if (!isEmailValid(subscribeEmail)) {
		  Notify.failure('Enter a valid email address');
		} else {
		  axios.post('https://ctsvsolutions.com/onepage-Api/public/api/subscribe', { email: subscribeEmail })
			.then(response => {
			  if (response.data.status === true) {
				Notify.success('Subscribed successfully');
				setSUbscribeEmail('');
			  } else {
				Notify.success('Already subscribed');
				setSUbscribeEmail('');
			  }
			})
			.catch(error => {
			  console.error('Error during subscription:', error);
			  Notify.failure('Subscription failed');
			});
		}
	  };
		
    return(
        <div className="col-lg-4 col-md-12 newsletter-form">
				<div style={{backgroundColor:"#f4f4f4",padding:"35px"}}>
					<h5 className="mb-4 text-success font-secondary">Subscribe</h5>
					<div className="pe-0 pe-xl-5">
						<form id="subscribe_form" name="mc-embedded-subscribe-form" target="">
							<div className="input-group mb-3">
                                <input type="text" value={subscribeEmail} onChange={(e)=>{setSUbscribeEmail(e.target.value)}} className="form-control shadow-none bg-white border-end-0" placeholder="Email address" />
                                <span className="input-group-text border-0 p-0">
                                    <button className="input-group-text border-0 bg-success" onClick={submitSubscribeForm} type="button" id="subscribe" name="subscribe" aria-label="Subscribe for Newsletter"><i className="fas fa-arrow-right"></i></button>
                                </span>
                            </div>
							<div style={{position:"absolute",left:"-5000px"}} aria-hidden="true">
                                <input type="text" name="b_463ee871f45d2d93748e77cad_a0a2c6d074" tabIndex="-1" />
                            </div>
						</form>
					</div>
					<p className="mb-0">Know you astrology software from our best Predicion.</p>
				</div>
			</div>
    )
}

function Footer4(){
    return(
    <>
        <div className="col-lg-3">
            <Link to ="/" >
                <img loading="prelaod" decoding="async" className="img-fluid" width="200" src={require('./img/one_page_logo.webp')} alt="one_page_logo" /> 
            </Link>
        </div>
		<div className="col-lg-5 col-md-6 mt-4 mt-lg-0">
			<ul className="list-unstyled list-inline mb-0 text-lg-center">
				<li className="list-inline-item me-4"><Link to ="/privacy-policy" className="nav-link text-black" href="privacy_policy.php">Privacy Policy</Link></li>
				<li className="list-inline-item me-4"><Link to ="/Terms-condition" className="nav-link text-black" href="terms_and_condition.php">Terms &amp; Conditions</Link></li>
				<li className="list-inline-item me-4"><Link to ="/Cancellation-policy" className="nav-link text-black" href="cancellation.php">Cancellation Policy</Link></li>
			</ul>
		</div>
		<div className="col-lg-4 col-md-6 text-md-end mt-4 mt-md-0">
    <ul className="list-unstyled list-inline mb-0 social-icons">
        <li className="list-inline-item me-3"><a title="Explore Facebook Profile" className="nav-link text-black" href="https://www.facebook.com/onepagehoroscope/"><i className="fab fa-facebook-f"></i></a></li>
        <li className="list-inline-item me-3"><a title="Explore Twitter Profile" className="nav-link text-black" href="https://twitter.com/onepage321"><i className="fab fa-twitter"></i></a></li>
        <li className="list-inline-item me-3"><a title="Explore Instagram Profile" className="nav-link text-black" href="https://www.instagram.com/one_page_horoscope/"><i className="fab fa-instagram"></i></a></li>
        <li className="list-inline-item me-3"><a title="Explore YouTube Profile" className="nav-link text-black" href="https://www.youtube.com/channel/UCbDTBLIU3Mp6983K8k3ZKNg/"><i className="fab fa-youtube"></i></a></li>
        <li className="list-inline-item me-3"><a title="Explore LinkedIn Profile" className="nav-link text-black" href="https://linkedin.com/showcase/97871433/admin/feed/posts/"><i className="fab fa-linkedin"></i></a></li>
    </ul>
</div>

    </>
    )
}