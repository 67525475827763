import getepayPortal from "../../tamil/Getepay_pg_react";

export const OnepagePayment = (OnePagePaymentOption) => {
  // console.log(OnePagePaymentOption);

  const { buyerMobilenumber,email,buyerName,user_id, customerDataId,RedirectUrl,price,tnxNote} = OnePagePaymentOption;

  const data = {
    mid: "993377",
    amount:price,
    merchantTransactionId: "sd12121",
    transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    terminalId: "getepay.merchant131068@icici",
    udf1: buyerMobilenumber,
    udf2: email,
    udf3: buyerName,
    udf4: user_id,
    udf5: customerDataId,
    udf6: "",
    udf7: "",
    udf8: "",
    udf9: "",
    udf10: "",
    ru: RedirectUrl,
    callbackUrl: "",
    currency: "INR",
    paymentMode: "ALL",
    bankId: "",
    txnType: "single",
    productType: "IPG",
    txnNote: tnxNote,
    vpa: "getepay.merchant131068@icici",
  };

  const Config = {
    mid: "993377",
    "Getepay Terminal Id": "getepay.merchant131068@icici",
    "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    "Getepay Url": "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
  };

  // console.log(data);
  getepayPortal(data, Config);
};
