import React from "react";
import "../header/Menubar.css";
import Menubar from "../header/Menubar";
import Carousel from "../banner-slider/Carousel";
import Typing from "../auto-typing/Typing";
import BirthHoroscopeForm from "../Birth-jadhagam-form/BirthHoroscopeForm";
import OnepageConatiner from "../janana-jadhagam-form/Janana-jadhagam";
import MgContainer from "../marriage-matching/Marriage-matching";
// import BiodataConatiner from "../Biodata-container/";
import BabyNameContainer from "../BabyNameContainer/babyNameContainer";
import BioDataContainer from "../Biodata-container/BioDataContainer";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";

function Index() {
  if (window.location.hostname === 'www.onepagehoroscope.com') {
    window.location.href = 'https://onepagehoroscope.com' + window.location.pathname + window.location.search;
}

  return (
    <div className="">
      <div>
        <Helmet>
          <title>Free Tamil Onepage Horoscope - ஜாதகம்</title>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,maximum-scale=5"
          />
          <meta
            name="description"
            content="Free Tamil Onepage Horoscope is astrology software company. Onepage Horoscope also provide the software for numerology and birth chart, Marriage Matching."
          />
          <meta
            name="keywords"
            content="தமிழ் ஜாதகம், tamil horoscope,jathagam tamil, one page jathagam, online tamil jathagam, pirappu jathagam in tamil, tamil jathagam,horoscope in tamil, free horoscope in tamil, free tamil jathagam, ஜாதகம், tamil jathagam online free, tamil jathagam online, jathagam in tamil jathagam online tamil, jathagam, one page horoscope, free jathagam online, jathagam download"
          />
          <meta name="author" content="one page horoscope software" />
          <link rel="canonical" href="https://onepagehoroscope.com" />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content="one page horoscope" />
          <meta
            property="og:description"
            content=" onepage horoscope is astrology software company for all online Users. birthchar, online Marriage Matching, Thirumanaporutam, Free Tamil Jathagam - ஜாதகம், onepage astrology also provide software for janana jadhagam."
          />
          <meta
            property="og:image"
            content="https://onepagehoroscope.com//og-image.png"
          />
          <meta property="og:url" content="https://onepagehoroscope.com" />
        </Helmet>
        <Menubar />
        <Carousel />
        <Typing />
        <BirthHoroscopeForm />
        <OnepageConatiner />
        <MgContainer />
        <BioDataContainer />
        <BabyNameContainer/>
        <Footer />
      </div>
    </div>
  );
}

export default Index;