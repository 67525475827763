import React from "react"; 
import BlurImage from "./Img/blur.png";
export default function  BioTemplate1({ value }){
const personalInfo=value.personal_info;
const Navamsam=value.amsam_array;
const amsamSlash=value.amsam_slash;
const pavagaMaruthal=value.pavaga_maruthal;
const RasiChart=value.rasi_array;
const RasiSlash=value.rasivalue;
// console.log(`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`);

   const style={
      border: {
         borderTop: "1px solid #793508 !important"
       },
         sheet: {
           width: "20.5cm",
           minHeight: "29.1cm",
           margin: "10px auto",
           borderRadius: "5px",
         //   background: "#fff",
         //   boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
           border: "5px solid #793508",
           backgroundColor: '#FDE3D6',
           color: '#793508',
           borderStyle: 'double',
        
         },
     
         "@page": {
           size: "A4",
           margin: 0
         },
         "@media print": {
           sheet: {
             margin: 0,
             border: "initial",
             borderRadius: "initial",
             width: "initial",
             minHeight: "initial",
             boxShadow: "initial",
             pageBreakAfter: "always"
           }
         },
         sheetPrint: {
           height: "1090px",
           border: "5px solid #793508",
           backgroundColor: "#FDE3D6",
           color: "#793508",
           borderStyle: "double",
           margin: "5mm"
         },
         qus: {
           fontSize: "12.5px",
           fontWeight: "bold"
         },
         ans: {
           fontSize: "11.5px"
         },
         birthTable: {
           width: "100%",
           background: "#fff",
           fontSize: "12px",
           marginBottom: "1px",
           border: "1px solid #793508",
           position: "relative"
         },
         laknamAfter: {
           content: "''",
           width: "23px",
           border: "1px solid #793508",
           position: "absolute",
           top: "7px",
           left: "-4px",
           transform: "rotate(136deg)"
         },
         laknamBefore: {
           content: "''",
           width: "35px",
           border: "1px solid #793508",
           position: "absolute",
           top: "11px",
           left: "-6px",
           transform: "rotate(136deg)"
         },
         rasiChart: {
           textAlign: "center",
           fontSize: "12px",
           fontWeight: "600",
           color: "#793508"
         },
         rasiBirth: {
           border: "1px solid #793508 !important",
           width: "70px",
           height: "80px",
           paddingLeft: "12px",
           position: "relative",
         //   paddingLeft: "15px"
         }
        
       }
     
    return(
<>
<div id="bio-modal" >
      <section className=" m-auto mt-2" id="" style={style.sheet}>
         <div className="container-fluid" id="page1">
            <div className="head text-center">
               <p className="m-0 p-0">உ</p>
               <p className="m-0 p-0"></p>
            </div>
            <div className="row">
               <div className="col-12 text-center m-0 p-0">
                  {/* <span className="fw-bold border-bottom"><span>{personalInfo.name}</span>-<span className="text-primary">  விவரம்</span></span> */}
               </div>
               <div className="col-7 pb-2">
                  <div className="row">
                  <div className="col-5 qus" style={style.qus}>
                  பெயர்  <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     {personalInfo.name}
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        பாலினம் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     {personalInfo.gender}
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        பிறந்த தேதி <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.birth_date }
                      </div>
                     <div className="col-5 qus" style={style.qus}>
                        பிறந்த நேரம் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.birth_time }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        பிறந்த ஊர் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.birth_place }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        இருப்பிடம் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                      { personalInfo.current_location}
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        படிப்பு <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.education }
                     </div>

                     <div className="col-5 qus" style={style.qus}>
                        பணி விவரங்கள் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.work_details }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        மாத வருமானம் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.income }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        ஜாதி <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.caste }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        குலம்/கோத்ரம்/வீடு <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.kulam }
                     </div>


                     <div className="col-5 qus" style={style.qus}>
                        உயரம் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.height }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        எடை <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.weight }
                     </div>
                     <div className="col-5 qus" style={style.qus}>
                        நிறம் <span className="float-end">:</span>
                     </div>
                     <div className="col-7 p-0" style={style.ans}>
                     { personalInfo.color }
                     </div>

                  </div>
               </div>
               <div className="col-5">
                  <div className="row">
                     <div className="col-12 p-1">
                        <div className="profile-photo text-center" style={{ height: "250px" }}>
 
                        {/* <img src={`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`} className="img-fluid" alt="sss" /> */}
 <img src={`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`} className="img-fluid" alt="sss" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="row ">
               <div className="col-12 pb-2">
                  <div className="text-center my-1">
                     <span className="fw-bold border-bottom">குடும்ப விவரம்</span>
                  </div>
                  <div>
                     <div className="row">
                        <div className="col-6">
                           <div className="row">
                              <div className="col-6 qus" style={style.qus}>
                                 தந்தை பெயர் <span className="float-end">:</span>
                              </div>
                              <div className="col-6 p-0" style={style.ans}>
                              { personalInfo.father_name }
                              </div>

                              <div className="col-6 qus" style={style.qus}>
                                 தந்தை பணி விபரம் <span className="float-end">:</span>
                              </div>
                              <div className="col-6 p-0" style={style.ans}>
                              { personalInfo.father_occupation }
                              </div>
                           </div>
                        </div>
                        <div className="col-6">
                           <div className="row">
                              <div className="col-6 qus" style={style.qus}>
                                 தாய் பெயர் <span className="float-end">:</span>
                              </div>
                              <div className="col-6 p-0" style={style.ans}>
                              { personalInfo.mother_name }
                              </div>
                              <div className="col-6 qus" style={style.qus}>
                                 தாய் பணி விபரம் <span className="float-end">:</span>
                              </div>
                              <div className="col-6 p-0" style={style.ans}>
                              { personalInfo.mother_occupation }
                              </div>
                           </div>
                        </div>
                        <div className="col-3 qus" style={style.qus}>
                           உடன் பிறந்தவர்கள் <span className="float-end">:</span>
                        </div>
                        <div className="col-9 p-0" style={style.ans}>
                        {personalInfo.sibling.map((sibling) => sibling.value).join(', ')}
                        </div>
                        <div className="col-3 qus" style={style.qus}>
                           தொலைப்பேசி எண் <span className="float-end">:</span>
                        </div>
                        <div className="col-3 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-3 qus" style={style.qus}>
                           வாட்ஸ்ஆப் எண் <span className="float-end">:</span>
                        </div>
                        <div className="col-3 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-3 qus" style={style.qus}>
                           முகவரி <span className="float-end">:</span>
                        </div>
                        <div className="col-9 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-3 qus" style={style.qus}>
                           சொத்து விபரம் <span className="float-end">:</span>
                        </div>
                        <div className="col-9 p-0" style={style.ans}>
                        {personalInfo.assets_details}
                        
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div className="text-center my-1">
                        <span className="fw-bold border-bottom">ஜாதக விவரம்</span>
                     </div>
                  </div>
                  <div className="col-6 pb-2">
                     <div className="row">
                        <div className="col-6 qus" style={style.qus}>
                           லக்னம்<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           ராசி - நட்சத்திரம்<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           தமிழ் தேதி<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>

                        <div className="col-6 qus" style={style.qus}>
                           பஷம்/திதி <span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>

                        <div className="col-6 qus" style={style.qus}>
                           யோகம்<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           கரணம்<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           திதி சூன்யம்<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                     </div>
                  </div>
                  <div className="col-6 pb-2">
                     <div className="row">

                        <div className="col-6 qus" style={style.qus}>
                           உதயாதி நாழிகை <span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           சூர்ய உதயம்<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           சூர்ய அஸ்தம் <span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>

                        <div className="col-6 qus" style={style.qus}>
                           யோகா நட்சத்திரம் <span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           யோகி<span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           அவயோக நட்சத்திரம் <span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                        <div className="col-6 qus" style={style.qus}>
                           அவயோகி <span className="float-end">:</span>
                        </div>
                        <div className="col-6 p-0" style={style.ans}>
                        <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                        </div>
                     </div>
                  </div>
                  <div className="col-3 qus" style={style.qus}>
                     ஜாதக நிலை <span className="float-end">:</span>
                  </div>
                  <div className="col-9 p-0" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                  </div>
                  <div className="col-3 qus" style={style.qus} >
                     பொருந்தும் நட்சத்திரம் <span className="float-end">:</span>
                  </div>
                  <div className="col-9 p-0 text-break" style={style.ans}>
                  <img
                              src={BlurImage}
                              alt="Logo"
                              style={{ height: "15px", width: "50%" }}
                            />
                                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div className="text-center my-1">
                        <span className="fw-bold border-bottom">ஜாதக கட்டம்</span>
                     </div>
                  </div>
                  <div className="col-6 pb-2">
                  <table className="birth-table" style={ style.birthTable } cellSpacing="2" cellPadding="2">
                        <tbody>
                           <tr>
                              <td className={`rasi-birth ${RasiSlash.r12}`} style={style.rasiBirth}> <img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                              <td className={`rasi-birth ${RasiSlash.r1}`} style={style.rasiBirth}> <img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                              <td className={`rasi-birth ${RasiSlash.r2}`} style={style.rasiBirth}> <img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                              <td className={`rasi-birth ${RasiSlash.r3}`} style={style.rasiBirth}><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                           </tr>
                           <tr>
                              <td className={`rasi-birth ${RasiSlash.r11}`} style={style.rasiBirth}> <img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                              <td className="rasi-birth c style={style.rasiChart}hart-birth" width="220" height="220" colSpan="2" rowSpan="2">
                                 <p className="rasi-chart"> ராசி </p>
                              </td>
                              <td className={`rasi-birth ${RasiSlash.r4}`} style={style.rasiBirth}> <img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                           </tr>
                           <tr>
                              <td className={`rasi-birth ${RasiSlash.r10}`} style={style.rasiBirth} valign="top"><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                              <td className={`rasi-birth ${RasiSlash.r5}`} style={style.rasiBirth} valign="top"><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                           </tr>
                           <tr>
                            <td className={`rasi-birth ${RasiSlash.r9}`} style={style.rasiBirth} valign="top"><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                              <td className={`rasi-birth ${RasiSlash.r8}`} style={style.rasiBirth} valign="top"><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /> </td>
                              <td className={`rasi-birth ${RasiSlash.r7}`} style={style.rasiBirth} valign="top"><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /> </td>
                              <td className={`rasi-birth ${RasiSlash.r6}`} style={style.rasiBirth} valign="top"><img src={BlurImage}  alt="Logo" style={{ height: "60px", width: "60px" }} /> </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div className="col-6 pb-2">
                  <table className="birth-table" style={ style.birthTable } cellSpacing="2" cellPadding="2">
                        <tbody>
                           <tr>
                              <td className={`rasi-birth ${amsamSlash.a12}`} style={style.rasiBirth}> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /></td>
                              <td className={`rasi-birth ${amsamSlash.a1}`} style={style.rasiBirth}> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /> </td>
                              <td className={`rasi-birth ${amsamSlash.a2}`} style={style.rasiBirth}> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /></td>
                              <td className={`rasi-birth ${amsamSlash.a3}`} style={style.rasiBirth}> 
                               <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /></td>
                           </tr>
                           <tr>
                              <td className={`rasi-birth ${amsamSlash.a11}`} style={style.rasiBirth}> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /> </td>
                              <td className="rasi-birth c style={style.rasiChart}hart-birth" width="220" height="220" colSpan="2" rowSpan="2">
                                 <p className="rasi-chart"> நவாம்சம் </p>
                              </td>
                              <td className={`rasi-birth ${amsamSlash.a4}`} style={style.rasiBirth}> 
                     <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      />
                      </td>
                           </tr>
                           <tr>
                              <td className={`rasi-birth ${amsamSlash.a10}`} style={style.rasiBirth} valign="top">  <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /> </td>
                              <td className={`rasi-birth ${amsamSlash.a5}`} style={style.rasiBirth} valign="top">  <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /></td>
                           </tr>
                           <tr>
                              <td className={`rasi-birth ${amsamSlash.a9}`} style={style.rasiBirth} valign="top"> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /> </td>
                              <td className={`rasi-birth ${amsamSlash.a8}`} style={style.rasiBirth} valign="top"> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /> </td>
                              <td className={`rasi-birth ${amsamSlash.a7}`} style={style.rasiBirth} valign="top"> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /> </td>
                              <td className={`rasi-birth ${amsamSlash.a6}`} style={style.rasiBirth} valign="top"> <img
                        src={BlurImage}
                        alt="Logo"
                        style={{ height: "60px", width: "60px" }}
                      /></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div className="container-fluid m-0 p-0 text-center border-top" style={style.border}>
                  <span className="">இந்த ஜாதகம் www.onepagehoroscope.com இணைய தளத்தில் உருவாக்கப்பட்டது...</span>
               </div>
            </div>
         </div>
      </section>
   </div>
</>
    );
}

