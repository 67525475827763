import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";

export default function RuthuJathagamForm() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // console.log(location.pathname);
    if (location.pathname === "/Tamil-jathagam-book") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [location]);
  const closeModal = () => {
    setShowModal(false);
  };
  const [dayOptions] = useState(
    [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0"))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0"))
  );
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const endYear = 1951;
    return Array.from(
      { length: currentYear - endYear + 1 },
      (_, index) => currentYear - index
    );
  });

  const [hourOptions] = useState(
    [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0"))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0"))
  );
  const [birthPlace, setBirthPlace] = useState("");
  const [userName, setUserName] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmpm, setSelectedAmpm] = useState("");
  const [nameError, setNameError] = useState("");
  const [birthplaceError, setBirthplaceError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthHourError, setBirthHourError] = useState("");
  const [birthMinuteError, setBirthMinuteError] = useState("");
  const [birthAmpmError, setBirthAmpmError] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [FatherNameError, setFatherNameError] = useState("");
  const [MotherNameError, setMotherNameError] = useState("");

  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

  const handleSuggestionSelect = (selectedPlace) => {
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const parts = selectedPlace.place_name.split(",");
    const displayName = parts.slice(0, 3).join(",");
    const location = displayName;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");

    setSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey =
        "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=10`;

      try {
        const response = await fetch(url);
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };

  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const validation = () => {
    let IsValid = true;
    if (!userName) {
      setNameError("Field is required");
      IsValid = false;
    } else {
      setNameError("");
    }
    if (!selectedDay || !selectedMonth || !selectedYear) {
      setBirthdateError("Field is required");
      IsValid = false;
    } else {
      setBirthdateError("");
    }
    if (!selectedHour) {
      setBirthHourError("Field is required");
      IsValid = false;
    } else {
      setBirthHourError("");
    }

    if (!selectedMinute) {
      setBirthMinuteError("Field is required");
      IsValid = false;
    } else {
      setBirthMinuteError("");
    }

    if (!selectedAmpm) {
      setBirthAmpmError("Field is required");
      IsValid = false;
    } else {
      setBirthAmpmError("");
    }
    if (!birthPlace) {
      setBirthplaceError("Field is required");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }
    let LatitudeError = document.getElementById("atflt").value;

    if (!LatitudeError) {
      setBirthplaceError("Again Select the BirthPlace");
      IsValid = false;
    } else {
      setBirthplaceError("");
    }

    if (!FatherName) {
      setFatherNameError("Father Name is Required");
      IsValid = false;
    } else {
      setFatherNameError("");
    }
    if (!MotherName) {
      setMotherNameError("Mother Name is Required");
      IsValid = false;
    } else {
      setMotherNameError("");
    }
    return IsValid;
  };
  const HoroscopeFormSubmit = (e) => {
    e.preventDefault();
    if (validation() === true) {
      const formdata = {
        username: userName,
        gender: 'Female',
        userDateofBirth: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        userBirthplace: birthPlace,
        latitude: document.getElementById("atflt").value,
        longitude: document.getElementById("atflg").value,
        FatherName:FatherName,
        MotherName:MotherName
      };
      Loading.dots("Your Horoscope is processing...");
      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Random-Ruthu-Jathagam",
          formdata
        )
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
                formdata
              )
              .then((response) => {
                if (response.status === 200) {
                  Loading.remove();
                  customRedirect("/Ruthu-Jathagam-Confirm-Details", response.data);
                  const value = JSON.stringify(response.data.data);
                  localStorage.setItem("RuthuVal", value);
                  const formdatavalue = JSON.stringify(formdata);
                  localStorage.setItem("Ruthuformdata", formdatavalue);
                  const userInfo = {
                    user_id: null,
                    customerDataId: null,
                  };
                  const UserInfoValues = JSON.stringify(userInfo);
                  localStorage.setItem("RuthuUserInfo", UserInfoValues);
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
    useEffect(() => {
      const storedValue = localStorage.getItem("Ruthuformdata");
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue);
        const dateofBirth = parsedValue.userDateofBirth;
        const [year, month, day] = dateofBirth.split("-");

        const Birthtime = parsedValue.userBirthtime;
        const timeComponents = Birthtime.split(" ");
        const time = timeComponents[0];
        const ampm = timeComponents[1];
        const [hour, minute] = time.split(":");

        setUserName(parsedValue.username);

        setSelectedPlaces(parsedValue.userBirthplace);
        setSelectedDay(day);
        setSelectedMonth(month);
        setSelectedYear(year);
        setSelectedHour(hour);
        setSelectedMinute(minute);
        setSelectedAmpm(ampm);
        setFatherName(parsedValue.FatherName)
        setMotherName(parsedValue.MotherName)
        setBirthPlace(parsedValue.userBirthplace);
        document.getElementById("atflg").value = parsedValue.longitude;
        document.getElementById("atflt").value = parsedValue.latitude;
      }
    }, []);
  return (
    <div
      className=""
      style={{
        backgroundColor: "#f8f9fa",
        padding: "20px",
        marginTop: "120px",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <h1 className="cate-title fs-3">ருது ஜாதகம் - Ruthu jathagam in tamil </h1>
          <div
            className="col-md-8 col-12 border py-5 px-3 rounded-3 shadow-5 mt-5"
            style={{ backgroundColor: "#C7DACF" }}
          >
            <form id="#">
              <div className="row mb-3 mt-0">
                <div className="col-md-6 ">
                  <label
                    htmlFor="name"
                    className={`form-label fw-bold fs-6 ${
                      nameError && "is-invalid"
                    }`}
                    style={{ color: "#0E1D2A" }}
                  >
                    பெயர்
                  </label>
                  <input
                    type="text"
                    placeholder="பெயர்"
                    id="Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="form-control p-2"
                  />
                  {nameError && (
                    <div className="invalid-feedback fw-bold">{nameError}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="Birth_place"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    ருதுவான இடம்
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      birthplaceError && "is-invalid"
                    }`}
                    id="Birth_place"
                    placeholder="ருதுவான இடத்தை தேர்வு செய்யவும்"
                    onChange={handleLocationChange}
                    value={birthPlace}
                  />
                  {birthplaceError && (
                    <div className="invalid-feedback  ">{birthplaceError}</div>
                  )}

                  <input
                    id="atflt"
                    className="pfipbx"
                    name="the_lat"
                    type="hidden"
                    placeholder="Latitude"
                  />
                  <input
                    id="atflg"
                    className="pfipbx"
                    name="the_lng"
                    type="hidden"
                    placeholder="Longitude"
                  />
                  <ul>
                    {suggestedPlaces.map((place, index) => {
                      if (place.place_name) {
                        const listItemStyle = {
                          padding: "5px 10px",
                          cursor: "pointer",
                          fontSize: "16px",
                          borderBottom: "1px dashed #ddd",
                        };

                        // Split the place_name and get the first three parts
                        const parts = place.place_name.split(",");
                        const displayName = parts.slice(0, 3).join(",");

                        return (
                          <p
                            style={listItemStyle}
                            key={index}
                            onClick={() => handleSuggestionSelect(place)}
                          >
                            {displayName}
                          </p>
                        );
                      }
                      return null; // Handle the case where place.place_name is falsy
                    })}
                  </ul>
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor=""
                  className="form-label fw-bold fs-6 "
                  style={{ color: "#0E1D2A" }}
                >
                  {" "}
                  ருதுவான தேதி
                </label>
                <div className="col-md-4">
                  <label
                    htmlFor="day"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    தேதி
                  </label>
                  <select
                    id="day"
                    className={`form-select  ${birthdateError && "is-invalid"}`}
                    value={selectedDay}
                    onChange={(e) => setSelectedDay(e.target.value)}
                  >
                    <option value="">தேதி</option>
                    {dayOptions.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                  {birthdateError && (
                    <div className="invalid-feedback ">{birthdateError}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="month"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    மாதம்
                  </label>
                  <select
                    id="month"
                    className={`form-select  ${birthdateError && "is-invalid"}`}
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value="">மாதம்</option>
                    {monthOptions.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  {birthdateError && (
                    <div className="invalid-feedback ">{birthdateError}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="year"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    வருடம்
                  </label>
                  <select
                    id="year"
                    className={`form-select  ${birthdateError && "is-invalid"}`}
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    <option value="">வருடம்</option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {birthdateError && (
                    <div className="invalid-feedback ">{birthdateError}</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label
                  htmlFor="hour"
                  className="form-label fw-bold fs-6"
                  style={{ color: "#0E1D2A" }}
                >
                  ருதுவான நேரம்
                </label>
                <div className="col-md-4">
                  <label
                    htmlFor="hour"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    மணி
                  </label>
                  <select
                    id="hour"
                    className={`form-select ${birthHourError && "is-invalid"}`}
                    style={{ color: "#0E1D2A" }}
                    value={selectedHour}
                    onChange={(e) => setSelectedHour(e.target.value)}
                  >
                    <option value="">நேரம்</option>
                    {hourOptions.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                  {birthHourError && (
                    <div className="invalid-feedback  fw-bold">
                      {birthHourError}
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="minute"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    நிமிடம்
                  </label>
                  <select
                    id="minute"
                    className={`form-select  ${
                      birthMinuteError && "is-invalid"
                    }`}
                    value={selectedMinute}
                    onChange={(e) => setSelectedMinute(e.target.value)}
                  >
                    <option value="">நிமிடம்</option>
                    {minuteOptions.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  {birthMinuteError && (
                    <div className="invalid-feedback  fw-bold">
                      {birthMinuteError}
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  <label
                    htmlFor="Ampm"
                    className="form-label fw-bold fs-6"
                    style={{ color: "#0E1D2A" }}
                  >
                    காலம்
                  </label>
                  <select
                    id="Ampm"
                    className={`form-select ${birthAmpmError && "is-invalid"}`}
                    value={selectedAmpm}
                    onChange={(e) => setSelectedAmpm(e.target.value)}
                  >
                    <option value="">காலம்</option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                  {birthAmpmError && (
                    <div className="invalid-feedback  ">{birthAmpmError}</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label
                    htmlFor="name"
                    className={`form-label fw-bold fs-6 ${
                      FatherNameError && "is-invalid"
                    }`}
                    style={{ color: "#0E1D2A" }}
                  >
                    தந்தை பெயர்
                  </label>
                  <input
                    type="text"
                    placeholder="தந்தை பெயர்"
                    id="Name"
                    value={FatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    className="form-control"
                  />
                  {FatherNameError && (
                    <div className="invalid-feedback fw-bold">
                      {FatherNameError}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="name"
                    className={`form-label fw-bold fs-6 ${
                      MotherNameError && "is-invalid"
                    }`}
                    style={{ color: "#0E1D2A" }}
                  >
                    தாய் பெயர்
                  </label>
                  <input
                    type="text"
                    placeholder="தாய் பெயர்"
                    id="Name"
                    value={MotherName}
                    onChange={(e) => setMotherName(e.target.value)}
                    className="form-control"
                  />
                  {MotherNameError && (
                    <div className="invalid-feedback fw-bold">
                      {MotherNameError}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mx-auto mt-3">
                  <button
                    className=" w-100"
                    type="button"
                    style={{
                      backgroundColor: "#270E49",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={HoroscopeFormSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
