import react from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";

export default function AboutUs() {
  return (
    <>
      <Menubar />
      <AboutContent />
      <Footer />
    </>
  );
}

const AboutContent = () => {
  return (
    <>
      <section className="page-header banner bg-tertiary">
        <div className="container-fluid">
          <div className="row">
            <div className="col-8 mx-auto text-center">
              <h1
                className="mb-3 text-capitalize fw-bold"
                style={{ color: "#b2012f" }}
              >
                About Us
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title">
                <p className="text-primary text-uppercase fw-bold fs-2 mb-3 text-success">
                  About One Page Horoscope
                </p>
                <div className="content pe-0 pe-lg-5">
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    <span className="fs-3 "> • </span> Combine astrology
                    calculation solution with technology, along with detailed
                    individual birth chart at low price.
                  </p>
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    {" "}
                    <span className="fs-3 "> • </span> Onepage Horoscope
                    Services.
                  </p>
                  <p style={{ textIndent: "0.5cm" }}>Birth chart</p>
                  <p style={{ textIndent: "0.5cm" }}>Marriage Matching</p>
                  <p style={{ textIndent: "0.5cm" }}>Marriage Bio-data</p>
                </div>
                <p className="text-success text-uppercase fw-bold fs-2 mb-3">
                  Birth Chart
                </p>
                <div className="vc_empty_space" style={{ height: "20px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <div className="text-center">
                  <img
                    src={require("./Img/one_page_horoscope-birth-shart-slider.jpg")}
                    alt="Your Alt Text"
                    width="800px"
                  />
                </div>
                <div className="vc_empty_space" style={{ height: "40px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <div className="content pe-0 pe-lg-5">
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    <span className="fs-3 "> • </span> Birth chart (or) jathagam
                    is one person individual planet position calculation at the
                    time of birth.{" "}
                  </p>
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    <span className="fs-3 "> • </span> This birth chart is
                    calculating depends on birth place, birth date and birth
                    time.{" "}
                  </p>
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    <span className="fs-3 "> • </span> Onepage horoscope gives
                    birth chart based on Tamil thirukanitha panjang calculation,
                    used by monthly in South India.
                  </p>
                </div>
                <p className="text-success text-uppercase fw-bold fs-2 mb-3">
                  Marriage Matching
                </p>
                <div className="vc_empty_space" style={{ height: "20px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <div className="text-center">
                  <img
                    src={require("./Img/one-page-horoscope-marriage-matching-slider.png")}
                    alt=""
                    width="800px"
                  />
                </div>
                <div className="vc_empty_space" style={{ height: "40px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <div className="content pe-0 pe-lg-5">
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    <span className="fs-3 "> • </span>Compare and predict bride
                    and groom, life, relationship, health, Profession and future
                    based on their birth chart in one of the common structures
                    before marriage in India.{" "}
                  </p>
                  <p className="text-dark" style={{ fontSize: "20px" }}>
                    <span className="fs-3 "> • </span>Onepage horoscope gives
                    marriage matching predictions about bride and groom star
                    match, dasa match, and dosham, and provides a report and
                    result.
                  </p>
                </div>
                <p className="text-success text-uppercase fw-bold fs-2 mb-3">Marriage Bio-data</p>
            <div className="vc_empty_space" style={{ height: '20px' }}><span className="vc_empty_space_inner"></span></div>
            <div className="text-center">
            <img
                    src={require("./Img/one-page-horoscope-marriage-matching-slider.png")}
                    alt=""
                    width="800px"
                  />
            </div>
            <div className="vc_empty_space" style={{ height: '40px' }}><span className="vc_empty_space_inner"></span></div>
            <div className="content pe-0 pe-lg-5">
              <p className="text-dark" style={{ fontSize: '20px' }}><span className="fs-3 "> • </span>Bio-data refers to everything about you, including yourself, family, profession, etc. </p>
              <p className="text-dark" style={{ fontSize: '20px' }}><span className="fs-3 "> • </span>Creating marriage bio-data is easy for everyone, but it's difficult to integrate their horoscope within the structure. </p>
              <p className="text-dark" style={{ fontSize: '20px' }}><span className="fs-3 "> • </span>We, Onepage Horoscope, provide your bio-data for marriage with your family details, profession and salary, family wealth, along with your personal horoscope and stars.</p>
            </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
