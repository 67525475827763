import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { useNavigate } from "react-router-dom";

const editProfileStyles = {
  container: {
    marginTop: "50px",
  },
  card: {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "none",
  },
  cardBody: {
    padding: "40px",
  },
  heading: {
    color: "#333",
  },
  formLabel: {
    fontWeight: "bold",
    marginBottom: "8px",
  },
  formControl: {
    border: "1px solid #ced4da",
    borderRadius: "4px",
  },
  btnPrimary: {
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  },
  btnPrimaryHover: {
    backgroundColor: "#0056b3",
    borderColor: "#0056b3",
  },
};

export default function EditProfile() {
    const navigate = useNavigate();

    const [UserEmail, setUserEmail] = useState("");
    const [UserMobileNumber, setUserMobileNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [NameError, setNameError] = useState("");
    const [EmailError, setUserEmailError] = useState("");
    const [MobileNumberError, setUserMobileNumberError] = useState("");
  
    useEffect(() => {
        Loading.dots();
      const Token = localStorage.getItem("authToken");
  
      axios
        .get("https://www.ctsvsolutions.com/onepage-Api/public/api/myProfile", {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          setUserEmail(response.data.email);
          setUserName(response.data.user_name);
          setUserMobileNumber(response.data.mobile_number);
          Loading.remove();
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          Loading.remove();
        });
    }, []);
  
    const CheckValidation = () => {
        let CanIMove = true;
      
        // Name validation
        if (!userName) {
          setNameError("Name is Required");
          CanIMove = false;
        } else {
          setNameError("");
        }
      
        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!UserEmail) {
          setUserEmailError("Email is Required");
          CanIMove = false;
        } else if (!emailRegex.test(UserEmail)) {
          setUserEmailError("Invalid Email Format");
          CanIMove = false;
        } else {
          setUserEmailError("");
        }
      
        // Mobile number validation
        const mobileRegex = /^\d{10}$/;
        if (!UserMobileNumber) {
          setUserMobileNumberError("Mobile Number is Required");
          CanIMove = false;
        } else if (!mobileRegex.test(UserMobileNumber)) {
          setUserMobileNumberError("Invalid Mobile Number Format (Must be 10 digits)");
          CanIMove = false;
        } else {
          setUserMobileNumberError("");
        }
      
        return CanIMove;
      };
      
      const handleSubmit = (e) => {
        e.preventDefault();
        if (CheckValidation()) {
            Loading.dots();
          const token = localStorage.getItem('authToken');
          const UpdateData = {
            email: UserEmail,
            mobileNumber: UserMobileNumber,
            userName: userName,
          };
      
          axios.post('https://www.ctsvsolutions.com/onepage-Api/public/api/update-Profile', UpdateData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => {
            console.log(response.data.status);
                Notify.success('Profile Updated Successfully...!');
                navigate('/user', { replace: true });
                Loading.remove();
            

          })
          .catch(error => {
            console.error('Error updating profile:', error);
            Loading.remove();

          });
        }
      };
      
  
    return (
      <div style={editProfileStyles.container}>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div style={editProfileStyles.card} className="card shadow">
              <div style={editProfileStyles.cardBody} className="card-body">
                <h2 style={editProfileStyles.heading} className="text-center mb-4">
                  Complete Your Profile
                </h2>
                <form>
                  <div className="mb-3">
                    <label htmlFor="name" style={editProfileStyles.formLabel}>
                      Name:
                    </label>
                    <input
                      type="text"
                      style={editProfileStyles.formControl}
                      className="form-control"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      id="name"
                      name="name"
                    />
                    <div style={{ color: "red" }}>{NameError}</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" style={editProfileStyles.formLabel}>
                      Email:
                    </label>
                    <input
                      type="email"
                      style={editProfileStyles.formControl}
                      className="form-control"
                      id="email"
                      name="email"
                      value={UserEmail}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                    />
                    <div style={{ color: "red" }}>{EmailError}</div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="mobile" style={editProfileStyles.formLabel}>
                      Mobile:
                    </label>
                    <input
                      type="tel"
                      style={editProfileStyles.formControl}
                      className="form-control"
                      id="mobile"
                      name="mobile"
                      value={UserMobileNumber}
                      onChange={(e) => {
                        setUserMobileNumber(e.target.value);
                      }}
                    />
                    <div style={{ color: "red" }}>{MobileNumberError}</div>
                  </div>
                  <button
                    type="button"
                    style={editProfileStyles.btnPrimary}
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}

                  >
                    Update Profile
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }