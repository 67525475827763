import React from "react";
import BirthHoroscopeForm from "../tamil/Birth-jadhagam-form/BirthHoroscopeForm"
// import BirthHoroscopeForm from "../Birth-jadhagam-form/BirthHoroscopeForm";
// import BirthHoroscopeContent from "../birth-horoscope-content/birth-content";
// import Footer from "../footer/Footer";
import Menubar from "../tamil/header/Menubar";

function MobileOnepage(){

    return(
        <>
        <Menubar/>
        <div style={{ marginTop:"180px" }}>
            <BirthHoroscopeForm/>
        </div>
       
        </>
    );
}
export default MobileOnepage;