  import React from "react";
  import { Navigate } from 'react-router-dom';
  import { Routes, Route } from "react-router-dom";
  import Index from './components/tamil/pages/Index';
  import BirthHoroscope from './components/tamil/pages/BirthHoroscope';
  import MarriageMatching from './components/tamil/pages/MarriageMatching';
  import HoroscopeTableView from './components/tamil/pages/horoscope_table_view';
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap/dist/js/bootstrap.bundle';
  import 'lazysizes';
    import HoroscopeBook from "./components/tamil/pages/HoroscopeBook";
  import 'lazysizes/plugins/parent-fit/ls.parent-fit';
  import MarriageMatchigView from "./components/tamil/porutham-table-view/Porutham-View";
  import BabyName from "./components/tamil/pages/BabyName";
  import BabyNameViewPage from "./components/tamil/pages/BabyNameViewPage";
  import BabyNameTemplate from "./components/tamil/BabyNameTemplate/BabyNameTemplate";
  import MarriageBioData from "./components/tamil/pages/BioData";
  import ContactUs from "./components/tamil/Contact/Contact";
  import PrivacyPolicy from "./components/tamil/Privacy-policy/PrivacyPolicy";
  import BioDataView from "./components/tamil/Bio-Data-View/BioDataView";
  import BioTemplate1 from "./components/tamil/BioData-Templates/BioTemplate1";
  import AboutUs from "./components/tamil/About/About";
  import Cancellation from "./components/tamil/Cancellation/Cancellation";
  import TermCondition from "./components/tamil/TermsAndCondition/TermsAndCondition";
  import FAQ from "./components/tamil/FAQ/FAQ";
  import Error404 from "./components/tamil/Error/404";
  import SampleTemplate from "./components/tamil/sample-template/Sample-Template";
  import OnepageSampleRedirect from "./components/tamil/Redirect/onePageSampleRedirect";
  import MarriageMatchingRedirect from "./components/tamil/Redirect/mgSampleRedirect";
  import BiodataRedirect from "./components/tamil/Redirect/BioDataRedirect";
  import BlogList from "./components/tamil/Blog/BlogList";
  import BlogView from "./components/tamil/Blog/BlogView";
  import OnePagePaymentSuccess from "./components/tamil/payment-success/onepagePaymentSuccess";
  import MgPaymentSuccess from "./components/tamil/payment-success/MgpaymentSuccess";
  import BioPaymentSuccess from "./components/tamil/payment-success/BioPaymentSuccess";
  import BabyPaymentSuccess from "./components/tamil/payment-success/BabyNameSuccess";
  import MobileOnepage from "./components/Mobile/MobileOnePage";
  import DeleteAccount from "./components/Mobile/DeleteAccount";
  import BabySampleRedirect from "./components/tamil/Redirect/BabySampleRedirect";
  import ScrollToTop from "./components/tamil/Scroll/Scroll";
  import Offer from "./components/Offer/offer";
  import MgOffer from "./components/Offer/Mgoffer";
  import BabyOffer from "./components/Offer/BabyOffer";
  import RuthuJathagam from "./components/tamil/pages/RuthuJathagam";
  import RuthuJathagamView from "./components/tamil/RuthuJathagamView/RuthuJathagamView";

  // user dASHBOARD
  import Login from "./components/userDashboard/Login/Login";
  // import UserIndex from "./components/userDashboard/view/Index"
  import Dashboard from "./components/userDashboard/pages/Dashboard";
  import OnepageReport from "./components/userDashboard/pages/OnepageReport";
  import MarriageMatchingReport from "./components/userDashboard/pages/MarriageMatchingReport";
  // import BioReportpages from "./components/userDashboard/pages/BioDataReport";
  import BabyNameReport from "./components/userDashboard/pages/BabyNameReport";
  import BioReport from "./components/userDashboard/pages/BiodataReport"
  import Onepageform from "./components/userDashboard//pages/onepageHoroscopeForm";
  import CreateMarriageMatching from "./components/userDashboard/pages/createMarriageMatching";
  import CreateBabyName from "./components/userDashboard/pages/CreateBabyName";
  import CreateBiodata from "./components/userDashboard/pages/CreateBiodata";
  import Profile from "./components/userDashboard/pages/EdituserProfile"
import HoroscopeBookView from "./components/tamil/HoroscopeBookView/HoroscopeBookView";
  //admin
  import AdminLogin from "./components/Admin/Login/AdminLogin";
  import AdminReport from "./components/Admin/Report/Report";

  function App() {

    const Auth = ({ children }) => {
      if (localStorage.getItem("authToken")) {
        return children;
      }
      return <Navigate to="/Login" />;
    };

    return (
      <div className="App">
        <ScrollToTop />
        <Routes>
          {/* USER DashBoard */}

          <Route path="/user" element={<Auth> <Dashboard /> </Auth>} />

          <Route path="/One-page-Report" element={<Auth> <OnepageReport /> </Auth>} />
          <Route path="/Marriage-Matching-Report" element={<Auth> <MarriageMatchingReport /> </Auth>} />
          <Route path="/BioData-Report" element={<Auth> <BioReport /> </Auth>} />
          <Route path="/Baby-Name-Report" element={<Auth> <BabyNameReport /> </Auth>} />
          <Route path="/Genarate-Onepage-Horoscope" element={<Auth> <Onepageform /> </Auth>} />
          <Route path="/Genarate-Marriage-Matching" element={<Auth> <CreateMarriageMatching /> </Auth>} />
          <Route path="/Genarate-Baby-Name-List" element={<Auth> <CreateBabyName /> </Auth>} />
          <Route path="/Genarate-Bio-Data" element={<Auth> <CreateBiodata /> </Auth>} />
          <Route path="/Update-Profile" element={<Auth> <Profile /> </Auth>} />
          <Route path="/Login" element={<Login />} />

          {/* admin */}
          <Route path="/Admin-Login" element={<AdminLogin />} />
          <Route path="/Admin-Report" element={<AdminReport />} />

          {/* website */}
          <Route path="/offer/:user_id/:data_id/" element={<Offer />} />
          <Route path="/Baby-offer/:user_id/:data_id/" element={<BabyOffer />} />
          <Route path="/Mg-offer/:user_id/:data_id/" element={<MgOffer />} />

          <Route path="Horoscope-Sample-Template" element={<SampleTemplate />} />
          <Route path="/" element={<Index />} />
          <Route path="/Mobile-view-form" element={<MobileOnepage />} />
          <Route path="/OnePage-ThankYou" element={<OnePagePaymentSuccess />} />
          <Route path="/Marriage-Matching-ThankYou" element={<MgPaymentSuccess />} />
          <Route path="/BioData-ThankYou" element={<BioPaymentSuccess />} />
          <Route path="/BabyName-ThankYou" element={<BabyPaymentSuccess />} />
          <Route path="/Blog/categories/Tamil/Blog-Lists" element={<BlogList />} />
          <Route path="/Blog/categories/Tamil/Blog-View" element={<BlogView />} />
          <Route path="/Birth-horoscope" element={<BirthHoroscope />} />
          <Route path="/Birth-Table-view" element={<HoroscopeTableView />} />
          <Route path="/Marriage-matching" element={<MarriageMatching />} />
          <Route path="/Baby-Name-List" element={<BabyName />} />
          <Route path="/Marriage-Matching-view" element={<MarriageMatchigView />} />
          <Route path="/Baby-Name-view" element={<BabyNameViewPage />} />
          <Route path="/Baby-Name-template" element={<BabyNameTemplate />} />
          <Route path="/Marriage-Bio-Data" element={< MarriageBioData />} />
          <Route path="/Bio-Data-View" element={<BioDataView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/Bio-Template1" element={<BioTemplate1 />} />
          <Route path="/About-us" element={<AboutUs />} />
          <Route path="/Cancellation-policy" element={<Cancellation />} />
          <Route path="/Terms-condition" element={<TermCondition />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/account-delete" element={<DeleteAccount />} />
          <Route path="/onepage-sample-success" element={<OnepageSampleRedirect />} />
          <Route path="/Mg-sample-success" element={<MarriageMatchingRedirect />} />
          <Route path="/Bio-sample-success" element={<BiodataRedirect />} />
          <Route path="/BabyName-sample-success" element={<BabySampleRedirect />} />
          <Route path="/Tamil-Jathagam-Book" element={<HoroscopeBook />} />
          <Route path="/Tamil-Jathagam-Confirm-Details" element={<HoroscopeBookView />} />
          <Route path="/Ruthu-Jathagam" element={<RuthuJathagam />} />
          <Route path="/Ruthu-Jathagam-Confirm-Details" element={<RuthuJathagamView />} />




          <Route path="/index.php" element={<Navigate to="/" />} />
          <Route path="/login.php" element={<Navigate to="/Login" />} />
          <Route path="/birthchart.php" element={<Navigate to="/Birth-horoscope" />} />
          <Route path="/marriagematching.php" element={<Navigate to="/Marriage-matching" />} />
          <Route path="/marriagebiodata.php" element={<Navigate to="/Marriage-Bio-Data" />} />
          <Route path="/babyname.php" element={<Navigate to="/Baby-Name-List" />} />
          <Route path="/contact.php" element={<Navigate to="/contact-us" />} />
          <Route path="/privacy-policy.php" element={<Navigate to="/privacy-policy" />} />
          <Route path="/aboutus.php" element={<Navigate to="/About-us" />} />
          <Route path="/cancellation.php" element={<Navigate to="/Cancellation-policy" />} />
          <Route path="/terms-and-condition.php" element={<Navigate to="/Terms-condition" />} />
          <Route path="/faq.php" element={<Navigate to="/FAQ" />} />
          <Route path="/blog.php" element={<Navigate to="/Blog/categories/Tamil/Blog-Lists" />} />
          <Route path="/blog-details.php" element={<Navigate to="/Blog/categories/Tamil/Blog-View" />} />
        </Routes>
      </div>
    );
  }

  export default App;