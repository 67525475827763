import React from "react";

import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import RuthuJathagamForm from "../Ruthu-Jathagam/RuthuJathagamForm";

function RuthuJathagam() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Ruthu-Jathagam": "/Ruthu-Jathagam",
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <div className="">
      <div>
      <Helmet>
        <title>Ruthu jathagam | ருது ஜாதகம் | Ruthu jathagam in tamil</title>

          <meta
            name="description"
            content=""
          />
          <meta
            name="keywords"
            content="ruthu jathagam, ருது ஜாதகம், ruthu jathagam for marriage matching, ruthu jathagam horoscope in tamil, ruthu jathagam in tamil, ruthu jathagam online, ruthu jathagam in tamil free"
          />
          <meta name="robots" content="index,follow" />
          {canonical()}
          <script type="application/ld+json">
          {`
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Ruthu jathagam",
            "image": "",
            "description": "Ruthu jathagam",
            "brand": {
              "@type": "Brand",
              "name": "One Page Horoscope"        
          `}
        </script>
        </Helmet>
        <Menubar />
<RuthuJathagamForm></RuthuJathagamForm>
        <Footer />
      </div>
    </div>
  );
}

export default RuthuJathagam;