
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import BabyNameView from "../Baby-Name-View/BabyNameView";
import { NavLink, useLocation } from "react-router-dom";
function BabyNameViewPage(){
    const location = useLocation();
    const value = JSON.parse(localStorage.getItem("BabyNameValues"));
    return (
<>
<Menubar />
<BabyNameView value={value}/>
<Footer/>
</>
    );
}
export default BabyNameViewPage;