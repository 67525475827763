import React from "react";
import { useState, useEffect, history } from "react";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import ReactPaginate from "react-js-pagination";
import axios from "axios";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import getepayPortal from "../../tamil/Getepay_pg_react";
import { useNavigate } from "react-router-dom";

import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import BioTemplate1 from "../../tamil/BioData-Templates/BioTemplate1";
import BioTemplate2 from "../../tamil/BioData-Templates/BioTemplate2";
import BioTemplate3 from "../../tamil/BioData-Templates/BioTemplate3";
import BioTemplate4 from "../../tamil/BioData-Templates/BioTemplate4";
import BioTemplate5 from "../../tamil/BioData-Templates/BioTemplate5";
import BioTemplate6 from "../../tamil/BioData-Templates/BioTemplate6";

export default function BioDataReport() {
  const navigate = useNavigate();
  const [ReportData, setReportData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ReportData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1);
  };
  useEffect(() => {
    Loading.dots();
    const Token = localStorage.getItem("authToken");

    fetch("https://www.ctsvsolutions.com/onepage-Api/public/api/BioData", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReportData(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        Loading.remove();
      });
  }, []);

  const DownloadPdf = (choosedTemplate, value) => {
    switch (choosedTemplate) {
      case "BiosampleTemplate1":
        return <BioTemplate1 value={value} />;
      case "BiosampleTemplate2":
        return <BioTemplate2 value={value} />;
      case "BiosampleTemplate3":
        return <BioTemplate3 value={value} />;
      case "BiosampleTemplate4":
        return <BioTemplate4 value={value} />;
      case "BiosampleTemplate5":
        return <BioTemplate5 value={value} />;
      default:
        return <BioTemplate6 value={value} />;
    }
  };

  const GetBioInfoFromReport = async (data) => {
    const Token = localStorage.getItem("authToken");
    let user_id = data.user_id_fk;
    let customer_data_id = data.id;
    const Data = {
      user_id: user_id,
      customer_data_id: customer_data_id,
      template_id: data.template,
    };

    try {
      const response = await axios.post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/Bio-data-Details",
        Data,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      const BiodataVal = response.data.data;
      const Siblings = BiodataVal.sibling;

      const MyVal = Siblings.split(",");
      const SiblingsArray = MyVal.map((Val) => {
        return { value: Val, label: Val };
      });

      const birthDateComponents = BiodataVal.birth_date.split("-");
      const birthTimeComponents = BiodataVal.birth_time.split(/:|\s/);

      return {
        user_id: data.user_id_fk,
        customer_id: data.id,
        userName: BiodataVal.name,
        userGender: BiodataVal.gender,
        BirthTime: BiodataVal.birth_time,
        BirthDate: BiodataVal.birth_date,
        BirthDay: birthDateComponents[0],
        BirthMonth: birthDateComponents[1],
        BirthYear: birthDateComponents[2],
        BirthHour: birthTimeComponents[0],
        BirthMinute: birthTimeComponents[1],
        BirthAmPm: birthTimeComponents[2],
        BirthPlace: BiodataVal.birth_place,
        Education: BiodataVal.education,
        WorkDetail: BiodataVal.work_detail,
        MonthlySalary: BiodataVal.income,
        Height: BiodataVal.height,
        weight: BiodataVal.weight,
        Color: BiodataVal.color,
        Caste: BiodataVal.caste,
        kulam_KulaDeivam: BiodataVal.kulam,
        fatherName: BiodataVal.father_name,
        MotherName: BiodataVal.mother_name,
        FatherWorkDetails: BiodataVal.father_occupation,
        MotherWorkDetails: BiodataVal.mother_occupation,
        Siblings: SiblingsArray,
        AssetsDetail: BiodataVal.asset_details,
        MobileNumber: BiodataVal.mobile_number,
        WhatsappNumber: BiodataVal.whatsapp_number,
        Email: BiodataVal.email,
        CurrentLocation: BiodataVal.current_location,
        Address: BiodataVal.address,
        latitudeValue: BiodataVal.latitude,
        LongitudeValue: BiodataVal.longitude,
        Imageurl: BiodataVal.image,
      };
    } catch (error) {
      console.error("Error in API call:", error.message);
      throw error; // Re-throw the error to be caught in the calling function
    }
  };

  const ViewPaidPdf = async (data) => {
    try {
      const BioDataFormData = await GetBioInfoFromReport(data);

      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Bio-Data",
          BioDataFormData
        )
        .then((response) => {
          console.log("final api response", response.data);
          const value = response.data;

          const choosedTemplate = data.template;
          const printElement = ReactDOMServer.renderToString(
            DownloadPdf(choosedTemplate, value)
          );
          html2pdf()
            .from(printElement)
            .set({
              image: { type: "jpeg", quality: 1 },
              dpi: 300,
              renderer: "canvas",
              html2canvas: { scale: 2 },
            })
            .save();
          Loading.remove();
        });
    } catch (error) {
      console.error("Error in ViewPaidPdf:", error.message);
    }
  };

  const GetPaid = async (report) => {
    try {
      const BioDataFormData = await GetBioInfoFromReport(report);

      const BioUserInfo = {
        user_id: BioDataFormData.user_id,
        customerDataId: BioDataFormData.customer_id,
      };
      const BioDetails = JSON.stringify(BioUserInfo);
      localStorage.setItem("BioUserInfo", BioDetails);
      const response = await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Bio-Data",
        BioDataFormData
      );

      console.log("final api response", response.data);
      const value = response.data;
      const formdatavalue = JSON.stringify(value);

      localStorage.setItem("BioValues", formdatavalue);
      navigate("/Bio-Data-View");
    } catch (error) {
      console.error("Error in GetPaid:", error);
    }
  };

  return (
    <div>
    {/* <BioTemplate1 value={value}/> */}
      <div>
        <h5 className="text-stat text-primary">Bio Data Report</h5>
      </div>
      <div className="card border-0">
        <div className="card-body ">
          <div className="row">
            <div className="col-md-12 m-auto  table-responsive">
              <div className="mb-3">
                <label htmlFor="itemsPerPage">Items per page:</label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={ReportData.length}>All</option>
                </select>
              </div>
              <table class="table text-nowrap table-bordered table-hover">
                <thead>
                  <tr className="">
                    <th scope="col" className="px-4 bg-info text-white">
                      S.no
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Name
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Gender
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Date of Birth
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Birth Time
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Birth place
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      status
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      Package
                    </th>
                    <th scope="col" className="px-4 bg-info text-white">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((Report, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{Report.name}</td>
                      <td>{Report.gender}</td>
                      <td>{Report.birth_date}</td>
                      <td>{Report.birth_time}</td>
                      <td>
                        {Report.city +
                          "," +
                          Report.state +
                          "," +
                          Report.country}
                      </td>
                      <td>{Report.status}</td>
                      <td>{Report.package}</td>
                      <td>
                        {Report.status === "PAID" ? (
                          <button
                            type="button"
                            onClick={() => ViewPaidPdf(Report)}
                            className="btn btn-success"
                          >
                            View
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => GetPaid(Report)}
                          >
                            Pay & Download
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                activePage={activePage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={ReportData.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
