import "./Marriage-matching.css"
import { Link } from "react-router-dom";
export default function Marriage_matching_index(){
    return(
        <div className="about-section bg-tertiary overflow-hidden" id="porutham">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mt-5">
                        <div>
                            <source type="image/webp" srcSet="images/web/marriage-matching-one-page-horoscope.webp" />
                            <img src={require('./img/marriage-matching-one-page-horoscope.webp')} className="form-img" alt="thirumana porutham" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-5">
                        <div className="section-title">
                            <h2 className="text-success fs-1 fw-bold mb-3">திருமண பொருத்தம்</h2>
                            <div className="lead mb-0 fw-bold">
                                <p>திருமணம் என்பது ஆயிரம் காலத்து பயிர் என்று பெரியோர்கள் கூறுவதுண்டு , மணமக்கள் இருவரும் திருமணம் செய்து கொள்ள , இவர்களது பிறப்பு ஜாதகத்தை சேர்த்து பொருத்தம் பார்ப்பதற்கும் நமது சேவையை பயன் படுத்தி கொள்ளலாம்.</p>
                                <p>நமது இணைய தளத்தில் நட்சத்திர பொருத்தம் , செவ்வாய் தோஷ ஆய்வு , தசா சந்திப்பு , மற்றும் பாபசாம்யம் ஆகியவற்றை உள்ளடக்கியது.</p>
                            </div>
                            <Link to="Marriage-matching" type="button" className="btn-darkred mt-4" >பொருத்தம் பார்க்க<span style={{fontSize:"14px"}} className="ms-2 fas fa-arrow-right"></span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}