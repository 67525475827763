import React from "react";
import "./Porutham-View.css";
import Menubar from "../header/Menubar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState,useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";  
import ReactDOMServer from "react-dom/server";
import Footer from "../footer/Footer";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import MgPaidTemplate from "../Mg-Paid-Template/MgPaidTemplate";
import MgSampleTemlate from "../Marriage-matching-sample/MgSampleTemlate";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import getepayPortal from "../Getepay_pg_react";

export default function PoruthamView() {
  const value = JSON.parse(localStorage.getItem("mgValues"));
  // console.log(value);
  return (
    <>
      <Menubar />
      <Porutham_Table_1 value={value} />
      <Porutham_Content value={value} />
      <PoruthamViewRasiChart value={value} />
      {/* <MgPaidTemplate value={value} /> */}
      <OrderButton value={value} />
    <Footer/>
    </>
  );
}

function Porutham_Table_1(value) {
 const personal_info= value.value.personal_info;
 const maleJathagamInfo=value.value.male_jathagam_info;
 const FemaleJathagamInfo=value.value.female_jathagam_info;

// console.log(11);
// console.log(FemaleJathagamInfo);
  return (
    <div style={{ marginTop: "150px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <h1 className="cate-title fs-3">பஞ்சாங்க விபரங்கள்</h1>
          <div className="col-lg-6">
            <table
              className="w-100 table-bordered text-dark mt-5 border-dark"
              id="male_detail"
            >
              <tbody>
                <tr>
                  <th colSpan="2" className="fw-bold fs-4 text-center p-2">
                    மணமகன்
                  </th>
                </tr>
                <tr>
                  <td className="fw-bold w-50 p-2">பெயர்</td>
                  <td>{personal_info.male_name}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-2"> பிறந்த தேதி </td>
                  <td>{personal_info.maleDateofBirth}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-2"> பிறந்த நேரம் </td>
                  <td>{personal_info.male_birth_time}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-2"> பிறந்த ஊர் </td>
                  <td>{personal_info.male_birthplace}</td>
                </tr>
                <tr>
                  <td className="fw-bold w-50 p-2">நட்சத்திரம்/பாதம்</td>
                  <td>{personal_info.male_natchathiram}- {personal_info.male_patham}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-2">ராசி</td>
                  <td>{personal_info.male_rasi}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-2">லக்னம்</td>
                  <td>{personal_info.male_laknam}</td>  
                </tr>
                <tr>
                  <td className="fw-bold p-2">மிருகம்</td>
                  <td>{maleJathagamInfo.male_mirugam}</td>  
                </tr>
                <tr>
                  <td className="fw-bold p-2">கணம்</td>
                  <td>{maleJathagamInfo.mlale_ganam_tamil}</td>  
                </tr>
                <tr>
                  <td className="fw-bold p-2">ரஜ்ஜு</td>
                  <td>
                    {maleJathagamInfo.male_rajju_type_tamil}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-6">
            <table
              className="w-100 table-bordered text-dark mt-5 border-dark"
              id="female_detail"
            >
              <tbody>
                <tr>
                  <th colSpan="2" className="fw-bold fs-4 text-center p-2">
                    மணமகள்
                  </th>
                </tr>
                <tr>
                  <td className="fw-bold w-50 p-2">பெயர்</td>
                  <td>{personal_info.female_name}</td>
                </tr>
                <tr>
                  <td className="fw-bold  p-2"> பிறந்த தேதி </td>
                  <td>{personal_info.female_dob}</td>
                </tr>
                <tr>
                  <td className="fw-bold  p-2"> பிறந்த நேரம் </td>
                  <td>{personal_info.female_birth_time}</td>
                </tr>
                <tr>
                  <td className="fw-bold  p-2"> பிறந்த ஊர் </td>
                  <td>{personal_info.female_birthplace}</td>
                </tr>
                <tr>
                  <td className="fw-bold w-50  p-2">நட்சத்திரம்/பாதம்</td>
                  <td>{personal_info.female_natchathiram}- {personal_info.female_patham}</td>
                </tr>
                <tr>
                  <td className="fw-bold  p-2">ராசி</td>
                  <td>{personal_info.female_rasi}</td>
                </tr>
                <tr>
                  <td className="fw-bold  p-2">லக்னம்</td>
                  <td>{personal_info.female_laknam}</td> 
                </tr>
                <tr>
                  <td className="fw-bold  p-2">மிருகம்</td>
                  <td>{FemaleJathagamInfo.female_mirugam}</td> 
                </tr>
                <tr>
                  <td className="fw-bold  p-2">கணம்</td>
                  <td>{FemaleJathagamInfo.femlale_ganam_tamil}</td> 
                </tr>
                <tr>
                  <td className="fw-bold  p-2">ரஜ்ஜு</td>
                  <td> {FemaleJathagamInfo.female_rajju_type_tamil}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function Porutham_Content() {
  return (
    <>
      <section >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h1 className="cate-title fs-3">1.நட்சத்திர பொருத்தம் ஆய்வு</h1>
              <div className="vc_empty_space" style={{ height: "40px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <h6 className="fw-bold">நட்சத்திர பொருத்தம்:</h6>
              <p className="fs-7 text-muted fw-bold">
                நட்சத்திர பொருத்தங்களில் தினம், கணம், யோனி, ராசி, ரஜ்ஜு இவை
                ஐந்தும் முக்கியமானவை. ஆண், பெண் இருவரது ராசி அதிபதி
                ஒருவரானாலும், அல்லது அவர்கள் நட்பானாலும், சமசப்தம ரசிகளானாலும்
                ரஜ்ஜு வேதை கணம் ராசிப் பொருத்தங்கள் இல்லாவிட்டாலும் விவாகம்
                செய்யலாம்.பெண்ணுக்கும் பிள்ளைக்கும் ஜாதகம்மிராவிட்டால் நாம
                நட்சத்திரத்திற்கு பொருத்தம் பார்க்கவும்.இருவர் ஜாதக ரீதியாகவும்
                பொருத்தம் பார்ப்பது மிக அவசியம்.
              </p>
              <div className="card p-2 mt-3 text-center fw-bold">
                <p className="mt-2">
                  ஜாதக ராசிப்படி 12 க்கு{" "}
                  <span className="blur-eff"> பொருத்தங்கள் உண்டு.</span>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <h1 className="cate-title fs-3">2.செவ்வாய் ஆய்வு</h1>
              <div className="vc_empty_space" style={{ height: "40px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <h6 className="fw-bold">செவ்வாய் தோஷப் பொருத்தம்:</h6>
              <p className="fs-7 text-muted fw-bold">
                ஒவ்வொரு ஜாதகத்திலும் செவ்வாய் தோஷத்திற்கு மிகவும் முக்கியத்துவம்
                கொடுக்கப்பட வேண்டும். திருமண பொருத்தம் பார்க்கும் பொழுது
                செவ்வாய் கிரகத்தினுடைய நிலை முக்கியத்துவம் பெறுகிறது. செவ்வாய்
                ஏழாம் ஸ்தானத்திலோ அல்லது எட்டாம் ஸ்தானத்திலோ உள்ளதினால் செவ்வாய்
                தோஷம் உண்டு என்று பலரும் சொல்லுவார்கள். ஏழாம் அல்லது எட்டாம்
                ஸ்தானத்திலுள்ள செவ்வாய் தோஷத்திற்கு பல விலக்குகள் உள்ளதாக ஆதார
                பூர்வமான நூல்கள் கூறுகின்றன.இந்த முறையில் உள்ள செவ்வாய் தோஷ
                நிர்ணயம் கீழே கொடுக்கப்பட்டுள்ளது.
              </p>
              <table
                className="table table-bordered border-dark text-center"
                id="sevvai_thosam"
              >
                <thead className="">
                  <tr>
                    <th>தோஷம்</th>
                    <th>பெண் </th>
                    <th>ஆண் </th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  <tr>
                    <td>செவ்வாய் </td>
                    <td className="blur-eff">
                      <i className="fa-solid fa-check"></i> AAAAAAA
                    </td>
                    <td className="blur-eff">
                      <i className="fa-solid fa-xmark"></i> BBBBBBB
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h1 className="cate-title fs-3">3.தசா சந்தி ஆய்வு </h1>
              <div className="vc_empty_space" style={{ height: "50px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <h6>தசா சந்திப்பு பொருத்தம்:</h6>
              <p className="fs-7 text-justify my-2">
                ஆண் அல்லது பெண் இருவருக்கும்{" "}
                <span style={{ fontSize: "17px" }}>18</span> மாத காலத்தில் தசா
                மாறுதல் ஏற்ப்பட்டால் அதனை தசா சந்தி என்று ஐதீகம்.
              </p>
              <p className="fs-7 text-justify my-2">
                இருவருக்கும் ஒரே தசா இருந்தால் கூட்டு தசா ஏற்படும்.
              </p>
              <p className="fs-7 text-justify my-2">
                இருவருக்கும் வெவ்வேறு தசா இருந்தால் உத்தமம்.
              </p>
              <div className="vc_empty_space" style={{ height: "25px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <h6>
                தசா சந்திப்பு பெண்ணுக்கும் ஆணுக்கும்{" "}
                <span className="blur-eff">AAAAAA</span>.{" "}
              </h6>
            </div>
            <div className="col-lg-6 text-center">
              <h1 className="cate-title fs-3">
                4.பொருத்தத்தின் விளக்கமும் சிபாரிசும்
              </h1>
              <table className="table table-bordered border-dark text-center">
                <thead>
                  <tr>
                    <th>சோதனை </th>
                    <th>பலன்கள் </th>
                  </tr>
                </thead>
                <tbody className="text-dark ">
                  <tr>
                    <td>நட்சத்திர பொருத்தம்</td>
                    <td className="blur-eff">SSSSSSSSS</td>
                  </tr>
                  <tr>
                    <td>செவ்வாய் தோஷப் பொருத்தம்</td>
                    <td className="blur-eff">DDDDDDDDDDDD</td>
                  </tr>
                </tbody>
              </table>
              <h6>
                பொருத்தம் <span className="blur-eff">ASDFGS GHJKLMNBVCX</span>.{" "}
              </h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
  function PoruthamViewRasiChart(value) {
    // console.log('value');
    const Mgstyles = {
      birthTable: {
        width: '100%',
        background: '#fffee0',
        position: 'relative',
      },
      rasiBirth: {
        border: '2px solid #ff9933 ',
        width: '100px',
        height: '100px',
        fontSize: '15px',
        color: '#000',
        paddingLeft: '5px',
        position: 'relative',
      },
      rasiBirthHover: {
        backgroundColor: '#e4eefe',
      },
      rasiChart: {
        fontSize: '20px',
        fontWeight: '600',
      },
      laknamStyle1: {
        after: {
          content: '',
          width: '23px',
          border: '1px solid #ff9933',
          position: 'absolute',
          top: '7px',
          left: '-4px',
          transform: 'rotate(136deg)',
        },
        before: {
          content: '',
          width: '35px',
          border: '1px solid #ff9933',
          position: 'absolute',
          top: '11px',
          left: '-6px',
          transform: 'rotate(136deg)',
        },
      },
      mediaQuery1024: {
        rasiBirth: {
          width: '75px',
          height: '75px',
          fontSize: '9px',
          paddingLeft: '5px',
          fontWeight: 'bold',
        },
      },
      chartBirth: {
        position: 'relative',
      },
      mediaQuery768: {
        rasiBirth: {
          width: '80px',
          height: '80px',
          fontSize: '10px',
          fontWeight: 'bold',
          paddingLeft: '12px',
        },
      },
      mediaQuery500: {
        rasiBirth: {
          width: '80px',
          height: '90px',
          fontSize: '10px',
          fontWeight: 'bold',
          paddingLeft: '5px',
        },
      },
      mediaQuery375: {
        rasiBirth: {
          width: '70px',
          height: '70px',
          fontSize: '8px',
          fontWeight: 'bold',
          paddingLeft: '5px',
        },
        laknamStyle1After: {
          content: '',
          width: '23px',
          border: '1px solid #ff9933',
          position: 'absolute',
          top: '7px',
          left: '-4px',
          transform: 'rotate(136deg)',
        },
        laknamStyle1Before: {
          content: '',
          width: '19px',
          border: '1px solid #ff9933',
          position: 'absolute',
          top: '4px',
          left: '-4px',
          transform: 'rotate(136deg)',
        },
      },
    };
    
    const MaleRasihart=value.value.male_rasi_table;
    // console.log(MaleRasihart);
    const FemaleChart=value.value.female_rasi_table;
    return (
      <>
      <div className="container">
      <div className="row justify-content-center">
      <div className="col-md-5">
      <h1 className="text-danger fs-3 mt-5 text-center">ஆண் ராசி கட்டம்</h1>
      <div className="table-responsive">
      <table  style={Mgstyles.birthTable} className="birth-table" cellSpacing="2" cellPadding="2">
         <tbody>
            <tr>
               <td style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_meenam}</td>
               <td  style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_mesham}</td>
               <td style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_rishbam}</td>
               <td style={Mgstyles.rasiBirth}  className="">{MaleRasihart.male_mithunam}</td>
            </tr>
            <tr>
               <td style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_kumbam}</td>
               <td className=" chart-birth text-center" width="220" height="220" colSpan="2" rowSpan="2">
                  <p style={Mgstyles.rasiChart} className="">ஆண்</p>
               </td>
               <td style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_kadagam}</td>
            </tr>
            <tr>
               <td style={Mgstyles.rasiBirth}  className="">{MaleRasihart.male_magaram  }</td>
               <td style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_simmam}</td>
            </tr>
            <tr>
               <td style={Mgstyles.rasiBirth} className="">{MaleRasihart.male_dhanush}</td>
               <td style={Mgstyles.rasiBirth}  className="" valign="middle">{MaleRasihart.male_viruchagam}</td>
               <td style={Mgstyles.rasiBirth} className="" valign="middle">{MaleRasihart.male_thulam}</td>
               <td style={Mgstyles.rasiBirth} className="" valign="middle">{MaleRasihart.male_kanni}</td>
            </tr>
         </tbody>
      </table>
      </div>
   </div>
      <div  className="col-md-5">
          <h1 className="text-danger fs-3 mt-5 text-center">பெண் ராசி கட்டம்</h1>
        <div className="table-responsive">
        <table  style={Mgstyles.birthTable} className="" cellSpacing="2" cellPadding="2">
         <tbody>
            <tr>
               <td style={Mgstyles.rasiBirth} className="">{FemaleChart.female_meenam}</td>
               <td style={Mgstyles.rasiBirth} className="">{FemaleChart.female_mesham}</td>
               <td style={Mgstyles.rasiBirth} className="">{FemaleChart.female_rishbam}</td>
               <td style={Mgstyles.rasiBirth} className="">{FemaleChart.female_mithunam}</td>
            </tr>
            <tr>
               <td style={Mgstyles.rasiBirth}  className="">{FemaleChart.female_kumbam}</td>
               <td className=" chart-birth text-center" width="220" height="220" colSpan="2" rowSpan="2">
                  <p  style={Mgstyles.rasiChart} className="">பெண் </p>
               </td>
               <td  style={Mgstyles.rasiBirth}className="">{FemaleChart.female_kadagam}</td>
            </tr>
            <tr>
               <td style={Mgstyles.rasiBirth} className="">{FemaleChart.female_magaram  }</td>
               <td style={Mgstyles.rasiBirth} className="">{FemaleChart.female_simmam}</td>
            </tr>
            <tr>
               <td  style={Mgstyles.rasiBirth} className="">{FemaleChart.female_dhanush}</td>
               <td style={Mgstyles.rasiBirth}  className="" valign="middle">{FemaleChart.female_viruchagam}</td>
               <td style={Mgstyles.rasiBirth} className="" valign="middle">{FemaleChart.female_thulam}</td>
               <td style={Mgstyles.rasiBirth} className="" valign="middle">{FemaleChart.female_kanni}</td>
            </tr>
         </tbody>
      </table>
        </div>
      </div>
      </div>
      </div>
    </>
    );
  }
  function OrderButton({ value }) {
    const [buyerMobilenumber, setMobilenumber] = useState("");
    const [email, setEmail] = useState("");
    const [buyerName, setBuyername] = useState("");
    const [mobilenumberError, setMobieNumberError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [buyerNameError, setBuyerNameError] = useState("");

    const userInfoJSON = localStorage.getItem("MgUserInfo");
    let initialUserId = null;
    let initialCustomerDataId = null;
    
    const [user_id, setUserId] = useState(initialUserId);
    const [customerDataId, setcustomerDataId] = useState(initialCustomerDataId);

    if (userInfoJSON) {
      const userInfo = JSON.parse(userInfoJSON);
      initialUserId = userInfo.user_id;
      initialCustomerDataId = userInfo.customerDataId;
    }

    const pdfJSX = () => {
      return <MgSampleTemlate value={value} />;
    };

  
    
    useEffect(() => {
      const userInfo = JSON.parse(localStorage.getItem("MgUserInfo"));
      console.log(userInfo); 
      if (userInfo) {
        setUserId(userInfo.user_id);
        setcustomerDataId(userInfo.customerDataId);
      }
    }, []);

    const saveFreePdf = (pdfData, user_id, customerDataId) => {
      const pdfDataVal = {
        pdfData: pdfData,
        userId: user_id,
        customerId: customerDataId,
        FileName:"mg_sample"
      };
      const Whatsappfileinfo = {    
        userId: user_id,
        customerId: customerDataId,
        columnName:"sample_pdf"
      };
      axios
        .post("https://ctsvsolutions.com/onepage-Api/public/api/save-sample-pdf", pdfDataVal)
        .then((response) => {
          axios
            .post(
              "https://ctsvsolutions.com/onepage-Api/public/api/Send-SampleIn-whatsapp",
              Whatsappfileinfo
            )
            .then((response) => {
              axios
                .post(
                  "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                  Whatsappfileinfo
                )
                .then((response) => {
                  Loading.remove();
                  Notify.success("Sample pdf file sended succussfully");
                  window.location='/Mg-sample-success';
                })
                .catch((error) => {
                  console.error("Error sending WhatsApp message:", error);
                });
            })
            .catch((error) => {
              console.error("Error sending WhatsApp file:", error);
            });
        })
        .catch((error) => {
          console.error("Error saving PDF:", error);
        });
    };
  
    const validateInput = () => {
      let hasError = false;
  
      if (!email) {
        setEmailError("Field is required");
        hasError = true;
      } else {
        setEmailError("");
      }
  
      if (!buyerName) {
        setBuyerNameError("Field is required");
        hasError = true;
      } else {
        setBuyerNameError("");
      }
  
      if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
        setMobieNumberError("Field is required and must be 10 digits");
        hasError = true;
      } else {
        setMobieNumberError("");
      }
  
      return !hasError;
    };
  
    const insertData = () => {
      const mGGeomenery=localStorage.getItem("mgformdata");
      const GeoLocation=JSON.parse(mGGeomenery);
      const Male_latitude=GeoLocation.Malelatitude;
      const Male_longitude=GeoLocation.Malelongitude;
      const feMale_latitude=GeoLocation.femalelatitude;
      const feMale_longitude=GeoLocation.femalelongitude;
      const userData = {
        buyerName: buyerName,
        buyerEmail: email,
        buyerMobileNo: buyerMobilenumber,
        maleName: value.personal_info.male_name,
        FemaleName:value.personal_info.female_name,
        MaleDateofBirth: value.personal_info.maleDateofBirth,
        FemaleDateofBirth:value.personal_info.female_dob,
        MaleBirthTime: value.personal_info.male_birth_time,
        FemaleBirthTime: value.personal_info.female_birth_time,
        MalebirthPlace: value.personal_info.male_birthplace,
        FemaleBirthPlace:value.personal_info.female_birthplace,
        Malelatitude:Male_latitude,
        Malelongitude:Male_longitude,
        femalelatitude:feMale_latitude,
        femalelongitude:feMale_longitude
      };
      Loading.hourglass("Marriage matching is processing...", {
        backgroundColor: "rgba(0,0,0,0.8)",
      });
      const storedUserInfo = localStorage.getItem("MgUserInfo");
      const userInfo = JSON.parse(storedUserInfo);
  
      const user_data_id = userInfo.user_id;
      const customer_data_id = userInfo.customerDataId;
      if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
        axios
        .post("https://ctsvsolutions.com/onepage-Api/public/api/Check-Sample-Eligible", { userInfo })
        .then((response) => {
            const samplePdf=response.sample_pdf;
            if(samplePdf === null || samplePdf==="")
            {
              const printElement = ReactDOMServer.renderToString(pdfJSX());
              html2pdf()
                .from(printElement)
                .set({
                  image: { type: 'jpeg', quality: 1 },
                  dpi: 300, 
                  renderer: 'canvas', 
                  html2canvas:  { scale: 2 },
                })
                .save();
          
              html2pdf()
              .from(printElement)
              .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300, 
                renderer: 'canvas', 
                html2canvas:  { scale: 2 },
              })
              .outputPdf()
              .then((pdfData) => {
                const base64Data = btoa(pdfData);
                saveFreePdf(base64Data, user_id, customerDataId);
              });
            }
            else{
              const printElement = ReactDOMServer.renderToString(pdfJSX());
              html2pdf()
                .from(printElement)
                .set({
                  image: { type: 'jpeg', quality: 1 },
                  dpi: 300, 
                  renderer: 'canvas', 
                  html2canvas:  { scale: 2 },
                })
                .save();
                Loading.remove();
            }
        })
      }
      else{
        PdfSampleProcess(userData);
      }
  
    };
  
    const PdfSampleProcess=(userData)=>{
      axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Mg-Statement-customerData-insert", { userData })
      .then((response) => {
        const user_data_id = response.data.data.userId;
        const customer_data_id = response.data.data.customerDataId;
        const userInfo = {
          user_id: user_data_id,
          customerDataId: customer_data_id,
        };
        const UserInfoValues = JSON.stringify(userInfo);
        localStorage.setItem("MgUserInfo", UserInfoValues);
        setcustomerDataId(customer_data_id);
        setUserId(user_data_id);
        const printElement = ReactDOMServer.renderToString(pdfJSX());
        html2pdf()
          .from(printElement)
          .set({
            image: { type: 'jpeg', quality: 1 },
            dpi: 300, 
            renderer: 'canvas', 
            html2canvas:  { scale: 2 },
          })
          .save();
    
        html2pdf()
        .from(printElement)
        .set({
          image: { type: 'jpeg', quality: 1 },
          dpi: 300, 
          renderer: 'canvas', 
          html2canvas:  { scale: 2 },
        })
        .outputPdf()
        .then((pdfData) => {
          const base64Data = btoa(pdfData);
          saveFreePdf(base64Data, user_data_id, customer_data_id);
        });
      
      })
      .catch((error) => {
        console.error("Error inserting data:", error);
      });
      
    }


    const GetsamplePdf = () => {
      if (validateInput()) {
        insertData();
      }
    };
  
    const navigate = useNavigate();
  
    const customRedirect = (route, data) => {
      navigate(route, { state: data });
    };
  
    const editForm = () => {
      const outputvalue = JSON.parse(localStorage.getItem("mgformdata"));
      customRedirect("/Marriage-matching", outputvalue);
      // console.log(outputvalue);
    };
  

    const handlepay = () => {
      if (validateInput()) {
        const storedUserInfo = localStorage.getItem("MgUserInfo");
        const userInfo = JSON.parse(storedUserInfo);
    
        const user_data_id = userInfo.user_id;
        const customer_data_id = userInfo.customerDataId;
    
        if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
          proceedToPayment();
        } else {
          if ((user_data_id === null || user_data_id === "") && (customer_data_id === null || customer_data_id === "")) { // Fix the condition here
            const mGGeomenery=localStorage.getItem("mgformdata");
            const GeoLocation=JSON.parse(mGGeomenery);
            const Male_latitude=GeoLocation.Malelatitude;
            const Male_longitude=GeoLocation.Malelongitude;
            const feMale_latitude=GeoLocation.femalelatitude;
            const feMale_longitude=GeoLocation.femalelongitude;
            const userData = {
              buyerName: buyerName,
              buyerEmail: email,
              buyerMobileNo: buyerMobilenumber,
              maleName: value.personal_info.male_name,
              FemaleName:value.personal_info.female_name,
              MaleDateofBirth: value.personal_info.maleDateofBirth,
              FemaleDateofBirth:value.personal_info.female_dob,
              MaleBirthTime: value.personal_info.male_birth_time,
              FemaleBirthTime: value.personal_info.female_birth_time,
              MalebirthPlace: value.personal_info.male_birthplace,
              FemaleBirthPlace:value.personal_info.female_birthplace,
              Malelatitude:Male_latitude,
              Malelongitude:Male_longitude,
              femalelatitude:feMale_latitude,
              femalelongitude:feMale_longitude
            };
            // console.log(userData);
            axios
              .post("https://ctsvsolutions.com/onepage-Api/public/api/Mg-Statement-customerData-insert", {
                userData,
              })
              .then((response) => {
                const userId = response.data.data.userId;
                const customerDataId = response.data.data.customerDataId;
                const userInfo = {
                  user_id: userId,
                  customerDataId: customerDataId,
                };
                const UserInfoValues = JSON.stringify(userInfo);
                localStorage.setItem("MgUserInfo", UserInfoValues);
                setcustomerDataId(customerDataId);
                setUserId(userId);
                proceedToPayment(); // Move this inside the then block
              });
          } else {
            proceedToPayment();
          }
        }
      }
    };
    
      const proceedToPayment = () => {
        const storedUserInfo = localStorage.getItem("MgUserInfo");
        const userInfo = JSON.parse(storedUserInfo);
    
        const user_id = userInfo.user_id;
        const customerDataId = userInfo.customerDataId;
        const data = {
          mid: "993377",
          amount: "149.00",
          merchantTransactionId: "sd12121",
          transactionDate: "Mon Oct 03 13:54:33 IST 2022",
          terminalId: "getepay.merchant131068@icici",
          udf1: buyerMobilenumber,
          udf2: email,
          udf3: buyerName,
          udf4: user_id,
          udf5: customerDataId,
          udf6: "",
          udf7: "",
          udf8: "",
          udf9: "",
          udf10: "",
          ru: "https://ctsvsolutions.com/onepage-Api/public/mG-Payment",
          callbackUrl: "",
          currency: "INR",
          paymentMode: "ALL",
          bankId: "",
          txnType: "single",
          productType: "IPG",
          txnNote: "Onepage-marriage matchings",
          vpa: "getepay.merchant131068@icici",
        }; 
      
      const Config = {
        "mid":"993377",
        "Getepay Terminal Id": "getepay.merchant131068@icici",
        "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
        "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
          "Getepay Url":
            "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
        };
        // console.log(data);
        getepayPortal(data, Config);
  }
  const paidpdfConvertion = () => {
    return <MgPaidTemplate value={value} />;
  };

  //after the payment
  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];
    const paymentId=queryParamsObject["payment_id"];
    if (payment_status === "Filure") {
      Report.warning(
        "Notiflix Warning",
        '"The peoples who want to live comfortably without producing and fatigue; they are doomed to lose their dignity, then liberty, and then independence and destiny." <br/><br/>- Mustafa Kemal Ataturk',
        "Okay"
      );
    }
    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Success",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Marriage Matching is processing...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
    
          const storedUserInfo = localStorage.getItem("MgUserInfo");
          const userInfo = JSON.parse(storedUserInfo);
    
          const printElement = ReactDOMServer.renderToString(
            paidpdfConvertion()
          );
          html2pdf()
            .from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            })
            .save();
    
          html2pdf().from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            }).outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
            });
        }
      );
    }
  }
      const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName:'marriage_matching'
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"pdf_file"
    };
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
               
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo={
                      'user_id':user_id,
                      'customer_data_id':customerDataId,
                      'feedback':clientAnswer                
                   }
                    axios
                    .post(
                      "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                      RatingInfo
                    )
                    .then((response) => {
                      // window.location='Marriage-Matching';
                      window.location.replace("/Marriage-Matching-ThankYou");
                    });

                  },
                  (clientAnswer) => {
                    window.location.replace("/Marriage-Matching-ThankYou");
                  },
              
                );
                Notify.success("Sample pdf file sended succussfully");
                
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };


    return (
      <>
    
    {/* <MarriageMatchingTemplate value={value}/> */}
        <div
          className="modal fade"
          id="staticBackdrop1"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Download Free Pdf
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container py-3 rounded shadow-lg" id="package">
                  <form className="mt-5" id="free-pdf-form">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label text-dark fw-bold"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Mail"
                        name="email"
                        id="email1"
                        aria-describedby="emailHelp"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span id="email_error1" style={{ color: "red" }}>
                        {emailError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label  
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name1"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={buyerName}
                        onChange={(e) => setBuyername(e.target.value)}
                      />
                      <span id="name_error1" style={{ color: "red" }}>
                        {buyerNameError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        name="mobile_number"
                        className="form-control"
                        pattern="[6789][0-9]{9}"
                        placeholder="Enter Your Number"
                        id="mobile_number1"
                        value={buyerMobilenumber}
                        onChange={(e) => setMobilenumber(e.target.value)}
                      />
                      <span id="mobile_number_error1" style={{ color: "red" }}>
                        {mobilenumberError}
                      </span>
                    </div>
                    <button
                      type="button"
                      name="free"
                      id="free_pdf"
                      className="text-center but1 mt-3"
                      onClick={GetsamplePdf}
                    >
                      Print
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* pay popup */}
  
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Download Pdf
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container py-3 rounded shadow-lg" id="package">
                  <div className="p-3">
                    <table className="table m-0 table-bordered border-secondary">
                      <tbody>
                        <tr>
                          <th className="text-dark fs-6 px-2">விலை</th>
                          <td className="text-center text-dark">Rs.323.00</td>
                        </tr>
                        <tr>
                          <th className="text-dark fs-6 px-2">
                            Discount- 54% 
                          </th>
                          <td className="text-center text-dark">Rs.174.00</td>
                        </tr>
                        <tr>
                          <th className="text-dark fs-6 px-2">சலுகை விலை</th>
                          <td className="text-center text-dark">Rs.149</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <form
                    className="mt-2"
                    action=""
                    method="post"
                    id="payment_form"
                  >
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label text-dark fw-bold"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Mail"
                        name="email"
                        id="email"
                        aria-describedby="emailHelp"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        // required
                      />
                      <span id="name_error1" style={{ color: "red" }}>
                        {emailError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        onChange={(e) => setBuyername(e.target.value)}
                        value={buyerName}
                      />
  
                      <span id="name_error1" style={{ color: "red" }}>
                        {buyerNameError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Whatsapp Number
                      </label>
                      <input
                        type="text"
                        name="mobile_number"
                        className="form-control"
                        pattern="[6789][0-9]{9}"
                        placeholder="Enter Your Whatsapp Number"
                        id="mobile_number"
                        value={buyerMobilenumber}
                        onChange={(e) => setMobilenumber(e.target.value)}
                      />
                      <span id="name_error1" style={{ color: "red" }}>
                        {mobilenumberError}
                      </span>
                    </div>
                    <button
                      type="button"
                      name="pay"
                      id="pay"
                      onClick={handlepay}
                      className="text-center but1 mt-3"
                    >
                      Buy Now
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="floating-button w-100">
          <div className="container bg-white p-3 rounded shadow-lg w-100">
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  editForm();
                }}
              >
                ✎ Edit
              </button>
              {/* <button
                className="btn btn-warning"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop1"
              >
                <i className="fa-solid fa-download"></i> Free Sample PDF
              </button> */}
              <button
                className="btn btn-success"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <i className="fa-solid fa-download"></i> Download PDF
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
