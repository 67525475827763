import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useNavigate } from 'react-router-dom';



import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
export default function Onepageform() {
  const navigate = useNavigate();
  const styles = {
    form: {
      backgroundColor: "#f2f2f2", // Update background color
      borderRadius: "8px", // Update border radius
      padding: "30px", // Update padding
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Update box shadow
    },
    input: {
      fontFamily: "Arial, sans-serif", // Update font family
      outline: "none",
      backgroundColor: "#eee", // Update background color
      width: "100%",
      border: "1px solid #ccc", // Update border color
      margin: "0 0 15px",
      padding: "12px", // Update padding
      boxSizing: "border-box",
      fontSize: "14px", // Update font size
    },
    select: {
      backgroundColor: "#eee", // Update background color
      border: "1px solid #ccc", // Update border color
      padding: "12px", // Update padding
      color: "#333",
      fontSize: "14px", // Update font size
    },
    button: {
      backgroundColor: "#4caf50", // Update background color
      color: "#fff",
      border: "none",
      padding: "15px 25px", // Update padding
      fontSize: "14px", // Update font size
      borderRadius: "8px", // Update border radius
      cursor: "pointer",
    },
    label: {
      color: "#333", // Update label color
      fontSize: "14px", // Update font size
      fontWeight:"bold"
    },
  };
  const [dayOptions] = useState([...Array(31).keys()].map((day) => String(day + 1).padStart(2, '0')));
  const [monthOptions] = useState([...Array(12).keys()].map((month) => String(month + 1).padStart(2, '0')));
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => startYear + year
    );
  });
  const [hourOptions] = useState([...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, '0')));
const [minuteOptions] = useState([...Array(60).keys()].map((minute) => String(minute).padStart(2, '0')));
  const [birthPlace, setBirthPlace] = useState("");
  const [userName, setUserName] = useState("");
  const [userGender, setUserGender] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmpm, setSelectedAmpm] = useState("");
  const [nameError, setNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [birthplaceError, setBirthplaceError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthHourError, setBirthHourError] = useState("");
  const [birthMinuteError, setBirthMinuteError] = useState("");
  const [birthAmpmError, setBirthAmpmError] = useState("");


  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

 const handleSuggestionSelect = (selectedPlace) => {
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const parts = selectedPlace.place_name.split(",");
    const displayName = parts.slice(0, 3).join(",");
    const location = displayName;

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");

    setSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey =
        "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=10`;

      try {
        const response = await fetch(url);
   
        if (response.ok) {
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };



  // const navigate = useNavigate(); 


const validation=()=>{
let IsValid=true;
if (!userName) {
  setNameError("Field is required");
  IsValid=false;
} else {
  setNameError("");
}

if (!userGender) {
  setGenderError("Field is required");
  IsValid=false;
} else {
  setGenderError("");
}

if (!selectedDay || !selectedMonth || !selectedYear) {
  setBirthdateError("Field is required");
  IsValid=false;
} else {
  setBirthdateError("");
}

if (!selectedHour) {
  setBirthHourError("Field is required");
  IsValid=false;
} else {
  setBirthHourError("");
}

if (!selectedMinute) {
  setBirthMinuteError("Field is required");
  IsValid=false;
} else {
  setBirthMinuteError("");
}

if (!selectedAmpm) {
  setBirthAmpmError("Field is required");
  IsValid=false;
} else {
  setBirthAmpmError("");
}

if (!birthPlace) {
  setBirthplaceError("Field is required");
  IsValid=false;
} else {
  setBirthplaceError("");
}
let LatitudeError=document.getElementById('atflt').value;

if(!LatitudeError){
  setBirthplaceError('Again Select the BirthPlace');
  IsValid=false;
}else{
  setBirthplaceError('');
}
return IsValid;
}


const from_submit_button = (e) => {
  e.preventDefault();
  if (validation() === true) {
    const formdata = {
      username: userName,
      gender: userGender,
      userDateofBirth: `${selectedDay}-${selectedMonth}-${selectedYear}`,
      userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
      userBirthplace: birthPlace,
      latitude: document.getElementById("atflt").value,
      longitude: document.getElementById("atflg").value,
    
    };
    const Token =localStorage.getItem('authToken');
    
    axios
      .post(
        'https://www.ctsvsolutions.com/onepage-Api/public/api/Add-Onepage-Horoscope',
        { formdata },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          Accept: "application/json",
          },
        }
      )
      .then((response) => {
        const ApiResponse=response.data.status;
        if(ApiResponse === true){
          Notify.success('Your Data Added to  Your report,you can pay and download it...');
          navigate('/One-page-Report', { replace: true });
          
        }
        console.log(response);
      });
  }
};

  return (
    <div className="col-12">
      <div className="card border-0" style={{backgroundColor:"#fff"}}>
        <div className="card-body ">
          <div className="row">
            <div className="col-12 text-center  fs-6 fw-bold">
              <h3 className='text-danger fw-bold '>ஜனன ஜாதகம் </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 m-auto">
              <form style={styles.form}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label style={styles.label}>Name</label>
                    <input
                      style={styles.input}
                      type="text"
                      placeholder="பெயர்"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      className={`${ nameError && "is-invalid"}`}
                    />
                      {nameError && (
                        <div className="invalid-feedback fw-bold">
                          {nameError}
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-md-6">
                    <label style={styles.label}>Gender</label>
                    <select
                      style={styles.select}
                      className={`form-select ${genderError && "is-invalid"}`}
                      aria-label="Default select example"
                      value={userGender}
                      onChange={(e) => setUserGender(e.target.value)}
                    >
                       <option value="">பாலினம்</option>
                        <option value="Male">Male - ஆண்</option>
                        <option value="Female">Female - பெண்</option>
                    </select>
                    {genderError && (
                        <div className="invalid-feedback  fw-bold">
                          {genderError}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <label style={styles.label}>Birth Place</label>
                    <input style={styles.input} 
                    type="text"
                    onChange={handleLocationChange}
                    value={birthPlace}
                    className={` rounded-0 dSuggest  ${
                      birthplaceError && "is-invalid"
                    }`}

                     />
                      {birthplaceError && (
                    <div className="invalid-feedback  fw-bold">
                      {birthplaceError}
                    </div>
                  )}
                    <input
                    id="atflt"
                    className="pfipbx"
                    name="the_lat"
                    type="hidden"
                    placeholder="Latitude"
                  />
                  <input
                    id="atflg"
                    className="pfipbx"
                    name="the_lng"
                    type="hidden"
                    placeholder="Longitude"
                  />
                  <ul>
                  {suggestedPlaces.map((place, index) => {
                    if (place.place_name) {
                      const listItemStyle = {
                        padding: "5px 10px",
                        cursor: "pointer",
                        fontSize: "16px",
                        borderBottom: "1px dashed #ddd",
                      };

                      // Split the place_name and get the first three parts
                      const parts = place.place_name.split(",");
                      const displayName = parts.slice(0, 3).join(",");

                      return (
                        <p
                          style={listItemStyle}
                          key={index}
                          onClick={() => handleSuggestionSelect(place)}
                        >
                          {displayName}
                        </p>
                      );
                    }
                    return null; // Handle the case where place.place_name is falsy
                  })}
                </ul>
                  </div>
                </div>
                <div className="row">
                  <label style={styles.label}>Date of Birth </label>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select ${ birthdateError && "is-invalid"}`}
                      aria-label="Default select example"
                      value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}
                    >
                        <option value="">தேதி</option>
                        {dayOptions.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                    </select>
                    {birthdateError && (
                        <div className="invalid-feedback  fw-bold">
                          {birthdateError}
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select ${ birthdateError && "is-invalid" }`}
                      aria-label="Default select example"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                        <option value="">மாதம்</option>
                        {monthOptions.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select ${
                        birthdateError && "is-invalid"
                      }`}
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      aria-label="Default select example"
                    >
                       <option value="">வருடம்</option>
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="row mt-4">
                  <label style={styles.label}>Birth Time</label>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select  ${
                        birthHourError && "is-invalid"
                      }`}
                      value={selectedHour}
                      onChange={(e) => setSelectedHour(e.target.value)}
                      aria-label="Default select example"
                    >
                       <option value="">நேரம்</option>
                        {hourOptions.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                    </select>
                    {birthHourError && (
                        <div className="invalid-feedback fw-bold">
                          {birthHourError}
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select ${
                        birthMinuteError && "is-invalid"
                      }`}
                      value={selectedMinute}
                      onChange={(e) => setSelectedMinute(e.target.value)}
                      aria-label="Default select example"
                    >
                       <option value="">நிமிடம்</option>
                        {minuteOptions.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                    </select>
                    {birthMinuteError && (
                        <div className="invalid-feedback  fw-bold">
                          {birthMinuteError}
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-md-4 p-1">
                    <select
                      style={styles.select}
                      className={`form-select ${
                        birthAmpmError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      value={selectedAmpm}
                      onChange={(e) => setSelectedAmpm(e.target.value)}
                    >
                      <option value="">காலம்</option>
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                    </select>
                    {birthAmpmError && (
                        <div className="invalid-feedback  fw-bold">
                          {birthAmpmError}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mt-4">
                  <button 
                   onClick={from_submit_button}
                  style={styles.button}

                  >Generate</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
