import "./Janana-jadhagam.css";

import {Link } from "react-router-dom";

export default function JananaJadhagam_index(){
    return(
        <div id="onepagehoroscope">
            <div id="onepage">
               <div className="container ">
                  <div className="row">
                     <div className="col-lg-6 col-md-6 col-12  order-2 order-md-1 mt-5">
                        <div className="section-title">
                           <h2 className="text-success fs-1 fw-bold mb-3">ஜனன ஜாதகம்</h2>
                           <div className="lead mb-0 fw-bold">
                              <p>ஒருவரது பிறந்த நேரம், தேதி, பிறந்த இடம் கொண்டு அந்த நேரத்திற்க்கான கிரக நிலையை கணிப்பது ஒருவரின் ஜனன ஜாதகம் ஆகும்.</p>
                              <p>நமது இணையதளத்தில் கிடைக்கின்ற ஒரு பக்க பிறப்பு ஜாதகமானது பஞ்சாங்க விபரம் , 25 வருட தசாபுத்தி , சர்வாஷ்டவர்க்கம் , ராசி மற்றும் நவாம்சம் உள்ளடங்கியுள்ளது.</p>
                           </div>
                           <Link to="/Birth-horoscope" type="button" className="btn-darkred mt-4" >ஜாதகம் எடுக்க<span style={{fontSize:"14px"}} className="ms-2 fas fa-arrow-right"></span></Link>
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2 mt-5">
                        <div>
                           <img src={require('./img/birth-horoscope-one-page-horoscope.webp')} className="form-img" alt='img'/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
    );
}