import "./mrge-matching-content.css";


export default function MarriageMatching_content() {
    return(
        <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="fw-bold fs-2 mb-3" style={{ fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif", color: "#b2012f" }}>
                Marriage Matching
              </h2>
              <div className="content pe-0 pe-lg-5">
                <p style={{ fontSize: "18px", fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif", color: "#000" }}>
                  <span className="fs-3"> • </span> Marriage matching is a decision taken by the bride and groom before they get married, jathaga porutham. This matching includes their marriage life, children, understanding, health, wealth, etc. In South India, they call it horoscope matching.
                </p>
              </div>
              <p className="mb-3" style={{ fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif", color: "#000", fontWeight: 500, fontSize: "1.5rem" }}>
                What onepage gives in marriage matching:
              </p>
              <div className="content pe-0 pe-lg-5">
                <p className="text-dark" style={{ fontSize: "20px" }}>
                  <span className="fs-3"> • </span> Marriage matching includes 4 matchings,
                </p>
                <p style={{ textIndent: "0.5cm", color: "#07c" }}>Star Matching.</p>
                <p style={{ textIndent: "0.5cm", color: "#07c" }}>Dhosam Matching.</p>
                <p style={{ textIndent: "0.5cm", color: "#07c" }}>Dasa matching.</p>
                <p style={{ textIndent: "0.5cm", color: "#07c" }}>Basic Matching (Jathaga poruthasm)</p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={require('./img/marriage matching.webp')} className="w-75 text-center" alt="marriage matching" />
            </div>
            <div className="section-title">
              <div className="col-lg-12">
                <p className="fw-bold fs-2 mb-3" style={{ fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif", color: "#b2012f" }}>
                  Thirumana Porutham - Star Matching (nakshatra porutham)
                </p>
                <div className="content pe-0 pe-lg-5">
                  <p style={{ fontSize: "18px", fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif", color: "#000" }}>
                    <span className="fs-3"> • </span>We compare the bride and groom stars and give them a detailed report on star matching. This star matching includes Dina porutham, Gana porutham, Mahendra Porutham, Yoni porutham, Rasi porutham, Rajju porutham, Vethai Porutham, Nadi porutham, Mara porutham
                  </p>
                </div>
                <p className="fw-bold fs-2 mb-3" style={{ fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif", color: "#b2012f" }}>
                  Thirumana Porutham - Dhosam Matching
                </p>
                <div className="content pe-0 pe-lg-5">
                  <p style={{ fontSize: "18px", fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif", color: "#000" }}>
                    <span className="fs-3"> • </span>By birth, bride and groom dhosam have been fixed like chevvai dhosam, Ragu kethu dhosam, and all.
                  </p>
                  <p style={{ fontSize: "18px", fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif", color: "#000" }}>
                    <span className="fs-3"> • </span>We analyze both reports and give results based on their birth.
                  </p>
                </div>
                <p className="fw-bold fs-2 mb-3" style={{ fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif", color: "#b2012f" }}>
                  Thirumana Porutham - Dasa Matching
                </p>
                <div className="content pe-0 pe-lg-5">
                  <p style={{ fontSize: "18px", fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif", color: "#000" }}>
                    <span className="fs-3"> • </span>Birth chart itself fixes the date of birth of the bride and groom in marriage matching.
                  </p>
                  <p style={{ fontSize: "18px", fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif", color: "#000" }}>
                    <span className="fs-3"> • </span>We have marriage matching check and analyze using technology whether they have kootu dasa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}