function footer(){
    return(
        <>
            <footer className="sticky-footer bg-white">
                                <div className="container my-auto">
                                    <div className="copyright text-center my-auto">
                                        <span>Copyright &copy; onepageHoroscopr.com</span>
                                    </div>
                                </div>
                            </footer>
        </>
    );
}
export default footer;