import React from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
export default function BlogList(){
    return(
        <>
        <Menubar/>
        <BlogContent/>
        <Footer/>
        </>
    );
}
const BlogContent = () => {
    return (
      <>
        <section style={{ marginTop: '180px' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <Link to="/Blog/categories/Tamil/Blog-View">
                      <div
                        className="card rounded-3 p-3"
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                        data-aos="fade"
                      >
                        <article className="blog-post">
                          <div className="post-slider slider-sm rounded">
                            <img
                              loading="lazy"
                              decoding="async"
                              src={require("./images/eye-hand-space_508732-95.jpg")}
                              alt="Post Thumbnail"
                            />
                          </div>
                          <div className="pt-4">
                            <p className="mb-3 fw-bold " style={{ textDecoration:"none" }}>23 Jun, 2023</p>
                            <p
                              className="text-black fw-bold"
                              style={{ fontFamily: 'Poppins, sans-serif', fontSize: '15px',textDecoration:"none" }}
                            >
                              Learn about the 12 house of Astrology in Tamil
                            </p>
                            <Link  to="/Blog/categories/Tamil/Blog-View"
                             
                              className="px-2 py-2 fw-bold text-white btn btn-success"

                              style={{ backgroundColor: '#51b56d', fontSize: '13px',textDecoration:"none" }}
                              aria-label="Read the full article by clicking here"
                            >
                              READ MORE
                            </Link>
                          </div>
                        </article>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mt-2">
                <div className="widget">
                  <h5 className="widget-title">
                    <span>Latest Article</span>
                  </h5>
  
                  <ul className="list-unstyled widget-list">
                    <li className="d-flex widget-post align-items-center">
                      <Link to="/Blog/categories/Tamil/Blog-Lists" className="text-black" >
                        <div className="widget-post-image flex-shrink-0 me-3">
                          <img
                            className="rounded"
                            loading="lazy"
                            decoding="async"
                            src={require('./images/eye-hand-space_508732-95.jpg')}
                            alt="Post Thumbnail"
                          />
                        </div>
                      </Link>
                    
                    </li>
                    <div className="flex-grow-1">
                        <h5 className="h6 mb-0 mt-2" style={{ fontSize: '15px' }}>
                          <Link className="text-black" style={{ textDecoration:"none" }} to="/Blog/categories/Tamil/Blog-View">
                            Learn about the 12 house of Astrology in Tamil.
                          </Link>
                        </h5>
                        <small>June 29, 2023</small>
                      </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };