import { useState } from "react";
import React, { useRef, useEffect } from "react";

import axios from "axios";
import "../../tamil/Bio-Data-Form/BioDataForm.css";
import "croppie/croppie.css";
import Croppie from "croppie";
import Select from "react-select";

const croppieOptions = {
  showZoomer: true,
  enableOrientation: true,
  mouseWheelZoom: "ctrl",
  viewport: {
    width: 200,
    height: 200,
    type: "square",
  },
  boundary: {
    width: 250,
    height: 250,
  },
};

export default function BiodataForm() {
  const [croppedImage, setCroppedImage] = useState(null);
  const fileInputRef = useRef(null);
  const croppieRef = useRef(null);
  let croppieInstance = null;
  const [uploadedImage, setUploadedImage] = useState(null);
  const [step, setStep] = useState(1);

  // const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    if (step === 5) {
      if (!croppieInstance) {
        croppieInstance = new Croppie(croppieRef.current, croppieOptions);
      }

      // Bind the uploaded image to the Croppie instance if it exists
      if (uploadedImage) {
        croppieInstance.bind({ url: uploadedImage });
      }
    }

    return () => {
      if (croppieInstance) {
        croppieInstance.destroy();
        croppieInstance = null;
      }
    };
  }, [step, uploadedImage]);

  const handleFileUpload = () => {
    const reader = new FileReader();
    const file = fileInputRef.current.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUploadedImage(reader.result);
      // console.log(reader.result);
    };
  };

  const handleCrop = () => {
    if (croppieInstance) {
      croppieInstance.result("base64").then((base64) => {
        setCroppedImage(base64);
        alert("image cropied succussfully");
      });
    } else {
      alert("kindly upload the image");
    }
  };

  const [selectedSiblings, setSelectedSiblings] = useState([]);

  const handleSiblingsChange = (selectedOptions) => {
    if (selectedOptions.length <= 5) {
      setSelectedSiblings(selectedOptions);
    }
  };

  const [dayOptions] = useState(
    [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0"))
  );
  const [monthOptions] = useState(
    [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0"))
  );

  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => startYear + year
    );
  });
  const [hourOptions] = useState(
    [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0"))
  );
  const [minuteOptions] = useState(
    [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0"))
  );
  const weightOptions = [];
  for (let i = 40; i <= 180; i++) {
    weightOptions.push(i + " kg");
  }

  const HeightOptions = [];
  for (let i = 100; i <= 220; i++) {
    HeightOptions.push(i + "cm");
  }

  const [userName, setUserName] = useState("");
  const [userGender, setUserGender] = useState("");
  const [BirthDay, setBirthDay] = useState("");
  const [BirthMonth, setBirthMonth] = useState("");
  const [BirthYear, setBirthYear] = useState("");
  const [BirthHour, setBirthHour] = useState("");
  const [BirthMinute, setBirthMinute] = useState("");
  const [BirthAmPm, setAmPm] = useState("");
  const [BirthPlace, setBirthPlace] = useState("");
  const [Education, SetEducation] = useState("");
  const [WorkDetail, setWorkDetail] = useState("");
  const [MonthlySalary, setMonthlySalary] = useState("");
  const [Height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [Color, setColor] = useState("");
  const [Caste, setCaste] = useState("");
  const [kulam_KulaDeivam, setKulam_KulaDeivam] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [MotherName, setMoterName] = useState("");
  const [FatherWorkDetails, setFatherWorkDetails] = useState("");
  const [MotherWorkDetails, setMotherWorkDetails] = useState("");
  const [AssetsDetail, setAssetsDetails] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [CurrentLocation, setCurrentLocation] = useState("");
  const [Address, setAddress] = useState("");
  // const [image, setImage] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [latitudeValue, setLatitude] = useState("");
  const [LongitudeValue, setLongitude] = useState("");
  //validation states
  const [userNameError, setUserNameError] = useState("");
  // console.log(userNameError);
  const [GenderError, setGenderError] = useState("");
  const [DayError, setDayError] = useState("");
  const [MonthError, setMonthError] = useState("");
  const [BirthYearError, setBirthYearError] = useState("");
  const [BirthHourError, setBirthHourError] = useState("");
  const [BirthMinutError, setBirthMinuteError] = useState("");
  const [AmPmError, setAmPmError] = useState("");
  const [BirthPlaceError, setBirthPlaceError] = useState("");
  const [EducationError, setEducationError] = useState("");
  const [workDetailError, setWorkDetailError] = useState("");
  const [MonthlySalaryError, setMonthlySalaryError] = useState("");
  const [HeightError, setHeightError] = useState("");
  const [WeightError, setWeightError] = useState("");
  const [colorError, setColorError] = useState("");
  const [casteError, setCasteError] = useState("");
  const [kulam_KulaDeivamError, setkulam_KulaDeivamError] = useState("");
  const [FatherNameError, setFatherNameError] = useState("");
  const [MotherNameError, setMotherNameError] = useState("");
  const [FatherWorkDetailsError, setFatherWorkDetailsError] = useState("");
  const [MotherWorkDetailsError, serMotherWorkDetailsError] = useState("");
  const [SiblingError, setSiblingsError] = useState("");
  const [AssetsError, setAssetsError] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");
  const [WhatsappNumberError, setWhatsAppNumberError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [CurerntLocationError, setCurrentLocationError] = useState("");
  const [AddressError, setAddressError] = useState("");
  const [ImageError, setImageError] = useState("");

  const siblingOptions = [
    { value: "தம்பி-1", label: "தம்பி-1" },
    { value: "தம்பி-2", label: "தம்பி-2" },
    { value: "அக்கா-1", label: "அக்கா-1" },
    { value: "அக்கா-2", label: "அக்கா-2" },
    { value: "அண்ணன்-1", label: "அண்ணன்-1" },
    { value: "அண்ணன்-2", label: "அண்ணன்-2" },
    { value: "தங்கை-1", label: "தங்கை-1" },
    { value: "தங்கை-2", label: "தங்கை-2" },
    { value: "அக்கா சுபம்-1", label: "அக்கா சுபம்-1" },
    { value: "அக்கா சுபம்-2", label: "அக்கா சுபம்-2" },
    { value: "அண்ணன் சுபம்-1", label: "அண்ணன் சுபம்-1" },
    { value: "அண்ணன் சுபம்-2", label: "அண்ணன் சுபம்-2" },
    { value: "தங்கை சுபம்- 1", label: "தங்கை சுபம்- 1" },
    { value: "தங்கை சுபம்- 2", label: "தங்கை சுபம்- 2" },
    { value: "யாருமில்லை", label: "யாருமில்லை" },
  ];

 

  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

  const handleSuggestionSelect = (selectedPlace) => {
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const parts =selectedPlace.place_name.split(',');
    const displayName = parts.slice(0, 3).join(',');
    const location = displayName

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthPlaceError("");

    setSuggestedPlaces([]);
    setLatitude(latitude);
    setLongitude(longitude);
    // document.getElementById("atflg").value = longitude;
    // document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey = "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=5`;
  
      try {
        const response = await fetch(url);
        // console.log(response);
        if (response.ok) {
        
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };



  const prevStep = () => {
    setStep(step - 1);
  };

  const Step1Validation = () => {
    let isValid = true;

    if (userName === "") {
      setUserNameError("This Field is required");
      isValid = false;
    } else {
      setUserNameError("");
    }
    if (userGender === "") {
      setGenderError("Field is required");
      isValid = false;
    } else {
      setGenderError("");
    }
    if (BirthDay === "") {
      setDayError("field is required");
      isValid = false;
    } else {
      setDayError("");
    }
    if (BirthMonth === "") {
      setMonthError("Field is required");
      isValid = false;
    } else {
      setMonthError("");
    }
    if (BirthYear === "") {
      setBirthYearError("Field is required");
      isValid = false;
    } else {
      setBirthYearError("");
    }
    if (BirthHour === "") {
      setBirthHourError("Filed is required");
      isValid = false;
    } else {
      setBirthHourError("");
    }
    if (BirthMinute === "") {
      setBirthMinuteError("field is required");
      isValid = false;
    } else {
      setBirthMinuteError("");
    }
    if (BirthAmPm === "") {
      setAmPmError("field is required");
      isValid = false;
    } else {
      setAmPmError("");
    }
    if (BirthPlace === "" || latitudeValue === "" || LongitudeValue === "") {
      setBirthPlaceError("Field is required");
      isValid = false;
    } else {
      setBirthPlaceError("");
    }
    if (isValid === true) {
      setStep(step + 1);
    }
  };

  const step2Validation = () => {
    let isValid = true;

    if (Education === "") {
      setEducationError("Field is required");
      isValid = false;
    } else {
      setEducationError("");
    }
    if (WorkDetail === "") {
      setWorkDetailError("Field is required");
      isValid = false;
    } else {
      setWorkDetailError("");
    }
    if (MonthlySalary < 1) {
      setMonthlySalaryError("field is required");
      isValid = false;
    } else {
      setMonthlySalaryError("");
    }
    if (Height === "") {
      setHeightError("field is required");
      isValid = false;
    } else {
      setHeightError("");
    }
    if (weight === "") {
      setWeightError("Field is required");
      isValid = false;
    } else {
      setWeightError("");
    }
    if (Color === "") {
      setColorError("field is required");
      isValid = false;
    } else {
      setColorError("");
    }
    if (Caste === "") {
      setCasteError("Filed is required");
      isValid = false;
    } else {
      setCasteError("");
    }
    if (kulam_KulaDeivam === "") {
      setkulam_KulaDeivamError("field is required");
      isValid = false;
    } else {
      setkulam_KulaDeivamError("");
    }
    if (isValid === true) {
      setStep(step + 1);
    }
  };

  const step3Validation = () => {
    let isValid = true;
    if (fatherName === "") {
      setFatherNameError("field is required");
      isValid = false;
    } else {
      setFatherNameError("");
    }
    if (MotherName === "") {
      setMotherNameError("field is required");
      isValid = false;
    } else {
      setMotherNameError("");
    }
    if (FatherWorkDetails === "") {
      setFatherWorkDetailsError("field is required");
      isValid = false;
    } else {
      setFatherWorkDetailsError("");
    }
    if (MotherWorkDetails === "") {
      serMotherWorkDetailsError("Field is required");
      isValid = false;
    } else {
      serMotherWorkDetailsError("");
    }
    if (selectedSiblings.length === 0) {
      setSiblingsError("Filed is required");
      isValid = false;
    } else {
      setSiblingsError("");
    }
    if (AssetsDetail === "") {
      setAssetsError("Field is required");
      isValid = false;
    } else {
      setAssetsError("");
    }
    // return isValid;
    if (isValid === true) {
      setStep(step + 1);
    }
  };
  const step4Validation = () => {
    let isValid = true;

    if (MobileNumber.length !== 10) {
      setMobileNumberError("Mobile Number must be 10 digits");
      isValid = false;
    } else {
      setMobileNumberError("");
    }
    if (WhatsappNumber.length !== 10) {
      setWhatsAppNumberError("whatsapp number should be 10 digit");
      isValid = false;
    } else {
      setWhatsAppNumberError("");
    }
    if (Email === "") {
      setEmailError("fiels is Required");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (CurrentLocation === "") {
      setCurrentLocationError("Filed is required");
      isValid = false;
    } else {
      setCurrentLocationError("");
    }
    if (Address === "") {
      setAddressError("field is required");
      isValid = false;
    } else {
      setAddressError("");
    }

    if (isValid === true) {
      setStep(step + 1);
    }
  };
  const step5Validation = () => {
    if (!uploadedImage) {
      setImageError("Field is required");
    } else {
      setImageError("");
    }
    return true;
  };

  const checkCrop = () => {
    if (!croppedImage) {
      return false;
    } else {
      return true;
    }
  };

  const BiodataFormSubmit = (e) => {
    e.preventDefault();
    if (step5Validation()) {
      if (checkCrop() === true) {
        formInsert();
      } else {
        alert("click the crop button to crop image");
      }
    }
  };
  // const navigate = useNavigate();

  const formInsert = () => {
    // Loading.dots();
    // Loading.dots("Bio Data is Loading...");  
   const  token=localStorage.getItem('authToken');
  //  console.log(token);
    const BioDataFormData = {
      userName: userName,
      userGender: userGender,
      BirthTime: BirthHour + ":" + BirthMinute + " " + BirthAmPm,
      BirthDate: BirthDay + "-" + BirthMonth + "-" + BirthYear,
      BirthDay: BirthDay,
      BirthMonth: BirthMonth,
      BirthYear: BirthYear,
      BirthHour: BirthHour,
      BirthMinute: BirthMinute,
      BirthAmPm: BirthAmPm,
      BirthPlace: BirthPlace,
      Education: Education,
      WorkDetail: WorkDetail,
      MonthlySalary: MonthlySalary,
      Height: Height,
      weight: weight,
      Color: Color,
      Caste: Caste,
      kulam_KulaDeivam: kulam_KulaDeivam,
      fatherName: fatherName,
      MotherName: MotherName,
      FatherWorkDetails: FatherWorkDetails,
      MotherWorkDetails: MotherWorkDetails,
      Siblings: selectedSiblings,
      AssetsDetail: AssetsDetail,
      MobileNumber: MobileNumber,
      WhatsappNumber: WhatsappNumber,
      Email: Email,
      CurrentLocation: CurrentLocation,
      Address: Address,
      latitudeValue: latitudeValue,
      LongitudeValue: LongitudeValue,
      Imageurl: croppedImage,
   
    };
// console.log(BioDataFormData);
    axios
      .post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/Add-Bio-data",
        BioDataFormData,
    
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response1) => {
       window.location.href="/BioData-Report";
      })
      .catch((error) => {
        console.error(
          "Error in Axios POST for Marriage-Matching-Random:",
          error
        );
      });
  };

  return (
    <div className="form_marriage">
      <div className="container">
        <div className="text-center">
          <h4 className="text-danger text-center fw-bold">
            Marriage Bio Data{" "}
          </h4>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-8 col-12">
            <form id="msform">
              {/* <fieldset> */}
              {step === 1 && (
                <div>
                  <fieldset>
                    <div className="text-center form_heading p-2">
                      <h2 className="fs-title">Basic Details</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பெயர்:
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              userNameError && "is-invalid"
                            }`}
                            maxLength="20"
                            id="user_name"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            name="user_name"
                            placeholder="Enter Your Name"
                          />
                          {userNameError && (
                            <div className="invalid-feedback fw-bold">
                              {userNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பாலினம்:
                          </label>
                          <select
                            name="gender"
                            id="gender"
                            className={`form-select ${
                              GenderError && "is-invalid"
                            }`}
                            onChange={(e) => {
                              setUserGender(e.target.value);
                            }}
                            value={userGender}
                          >
                            <option value="">Select</option>
                            <option value="ஆண்">ஆண்</option>
                            <option value="பெண்">பெண்</option>
                          </select>
                          {GenderError && (
                            <div className="invalid-feedback fw-bold">
                              {GenderError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பிறந்த தேதி:
                          </label>
                          <select
                            id="date"
                            name="date"
                            className={`form-select ${
                              DayError && "is-invalid"
                            }`}
                            value={BirthDay}
                            onChange={(e) => {
                              setBirthDay(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {dayOptions.map((day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </select>
                          {DayError && (
                            <div className="invalid-feedback fw-bold">
                              {DayError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பிறந்த மாதம்:
                          </label>
                          <select
                            name="month"
                            id="month"
                            className={`form-select ${
                              MonthError && "is-invalid"
                            }`}
                            value={BirthMonth}
                            onChange={(e) => {
                              setBirthMonth(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {monthOptions.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                          {MonthError && (
                            <div className="invalid-feedback fw-bold">
                              {MonthError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பிறந்த வருடம்:
                          </label>
                          <select
                            name="year"
                            id="year"
                            className={`form-select ${
                              BirthYearError && "is-invalid"
                            }`}
                            value={BirthYear}
                            onChange={(e) => {
                              setBirthYear(e.target.value);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            <option value="">வருடம்</option>
                            {yearOptions.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          {BirthYearError && (
                            <div className="invalid-feedback fw-bold">
                              {BirthYearError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            மணி:
                          </label>
                          <select
                            name="hour"
                            id="hour"
                            className={`form-select ${
                              BirthHourError && "is-invalid"
                            }`}
                            value={BirthHour}
                            onChange={(e) => {
                              setBirthHour(e.target.value);
                            }}
                          >
                            <option value=""> Select</option>
                            {hourOptions.map((hour) => (
                              <option key={hour} value={hour}>
                                {hour}
                              </option>
                            ))}
                          </select>
                          {BirthHourError && (
                            <div className="invalid-feedback fw-bold">
                              {BirthHourError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            நிமிடம்:
                          </label>
                          <select
                            name="minute"
                            id="minute"
                            className={`form-select ${
                              BirthMinutError && "is-invalid"
                            }`}
                            value={BirthMinute}
                            onChange={(e) => {
                              setBirthMinute(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {minuteOptions.map((minute) => (
                              <option key={minute} value={minute}>
                                {minute}
                              </option>
                            ))}
                          </select>

                          {BirthMinutError && (
                            <div className="invalid-feedback fw-bold">
                              {BirthMinutError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            காலம்:
                          </label>
                          <select
                            name="ampm"
                            id="ampm"
                            className={`form-select ${
                              AmPmError && "is-invalid"
                            }`}
                            value={BirthAmPm}
                            onChange={(e) => {
                              setAmPm(e.target.value);
                            }}
                          >
                            <option value="">காலம்</option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>

                          {AmPmError && (
                            <div className="invalid-feedback fw-bold">
                              {AmPmError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பிறந்த ஊர்:
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              BirthPlaceError && "is-invalid"
                            }`}
                            value={BirthPlace}
                            onChange={handleLocationChange}
                            placeholder="பிறந்த இடத்தை தேர்வு செய்க"
                          ></input>
                          {BirthPlaceError && (
                            <div className="invalid-feedback fw-bold">
                              {BirthPlaceError}
                            </div>
                          )}
                          <input
                            id="atflg"
                            className="pfipbx"
                            name="the_lng"
                            type="hidden"
                            value={LongitudeValue}
                            placeholder="Longitude"
                          />
                          <input
                            id="atflt"
                            className="pfipbx"
                            name="cf_1164"
                            type="hidden"
                            value={latitudeValue}
                            placeholder="latitude"
                          />
                              <ul>
            {suggestedPlaces.map((place, index) => {
              if (place.place_name) {
                const listItemStyle = {
                  padding: "5px 10px",
                  cursor: "pointer",
                  fontSize: "16px",
                  borderBottom: "1px dashed #ddd",
                };

                // Split the place_name and get the first three parts
                const parts = place.place_name.split(',');
                const displayName = parts.slice(0, 3).join(',');

                return (
                  <p
                    style={listItemStyle}
                    key={index}
                    onClick={() => handleSuggestionSelect(place)}
                  >
                    {displayName}
                  </p>
                );
              }
              return null; // Handle the case where place.place_name is falsy
            })}
          </ul>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="action-button"
                      onClick={Step1Validation}
                    >
                      Next
                    </button>
                    {/* <button className="action-button" onClick={nextStep}>Next</button> */}
                    {/* <section> */}
                  </fieldset>
                </div>
              )}

              {step === 2 && (
                <div>
                  <fieldset>
                    <div className="text-center form_heading p-2">
                      <h2 className="fs-title">Job & Education</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            படிப்பு:
                          </label>
                          <input
                            type="text"
                            value={Education}
                            onChange={(e) => {
                              SetEducation(e.target.value);
                            }}
                            id="education"
                            maxLength="30"
                            name="education"
                            placeholder="Education Details"
                            className={`form-control ${
                              EducationError && "is-invalid"
                            }`}
                          />
                          {EducationError && (
                            <div className="invalid-feedback fw-bold">
                              {EducationError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            பணி விவரம்:
                          </label>
                          <select
                            name="work_detail"
                            id="work_detail"
                            value={WorkDetail}
                            placeholder="Monthly Income"
                            onChange={(e) => {
                              setWorkDetail(e.target.value);
                            }}
                            className={`form-select ${
                              workDetailError && "is-invalid"
                            }`}
                          >
                            <option value="">Select</option>
                            <option value="IT">IT</option>
                            <option value="NON-I">NON-IT</option>
                            <option value="Civil and Arch">
                              Civil and Arch
                            </option>
                            <option value="Bank Sector">Bank Sector</option>
                            <option value="Government Job">
                              Government Job
                            </option>
                            <option value="Private Job">Private Job</option>
                            <option value="Sales">Sales</option>
                            <option value="Others">Others</option>
                            <option value="Business">Business</option>
                            <option value="Foreign">Foreign</option>
                            <option value="Doctor">Doctor</option>
                            <option value="Education">Education</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Research">Research</option>
                            <option value="Lawyer">Lawyer</option>
                            <option value="Artist">Artist</option>
                            <option value="Chef">Chef</option>
                            <option value="Athlete">Athlete</option>
                            <option value="Actor/Actress">Actor/Actress</option>
                            <option value="Journalist">Journalist</option>
                            <option value="Fashion Designer">
                              Fashion Designer
                            </option>
                          </select>
                          {workDetailError && (
                            <div className="invalid-feedback fw-bold">
                              {workDetailError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            மாத வருமானம்:
                          </label>
                          <input
                            type="Number"
                            value={MonthlySalary}
                            onChange={(e) => {
                              setMonthlySalary(e.target.value);
                            }}
                            id="income"
                            name="income"
                            className={`form-control ${
                              MonthlySalaryError && "is-invalid"
                            }`}
                          />
                          {MonthlySalaryError && (
                            <div className="invalid-feedback fw-bold">
                              {MonthlySalaryError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-center form_heading p-2">
                      <h2 className="fs-title">Personal Details</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            உயரம்:
                          </label>
                          <select
                            name="height"
                            id="height"
                            className={`form-select ${
                              HeightError && "is-invalid"
                            }`}
                            value={Height}
                            onChange={(e) => {
                              setHeight(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {HeightOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {HeightError && (
                            <div className="invalid-feedback fw-bold">
                              {HeightError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            எடை:
                          </label>
                          <select
                            name="weight"
                            id="weight"
                            className={`form-select ${
                              WeightError && "is-invalid"
                            }`}
                            value={weight}
                            onChange={(e) => {
                              setWeight(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {weightOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {WeightError && (
                            <div className="invalid-feedback fw-bold">
                              {WeightError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            நிறம்:
                          </label>
                          <select
                            name="color"
                            id="color"
                            className={`form-select ${
                              colorError && "is-invalid"
                            }`}
                            value={Color}
                            onChange={(e) => {
                              setColor(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="சிவப்பு">சிவப்பு </option>
                            <option value="மாநிறம்">மாநிறம் </option>
                            <option value="மாநிறத்தை விட குறைவு">
                              மாநிறத்தை விட குறைவு
                            </option>
                          </select>
                          {colorError && (
                            <div className="invalid-feedback fw-bold">
                              {colorError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            ஜாதி:
                          </label>
                          <input
                            type="text"
                            value={Caste}
                            onChange={(e) => {
                              setCaste(e.target.value);
                            }}
                            id="caste"
                            name="caste"
                            maxLength="30"
                            className={`form-control ${
                              casteError && "is-invalid"
                            }`}
                          />
                          {casteError && (
                            <div className="invalid-feedback fw-bold">
                              {casteError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            குலம்/கோத்ரம்/வீடு:
                          </label>
                          <input
                            type="text"
                            value={kulam_KulaDeivam}
                            onChange={(e) => {
                              setKulam_KulaDeivam(e.target.value);
                            }}
                            maxLength="30"
                            id="kulam"
                            name="kulam"
                            className={`form-control ${
                              kulam_KulaDeivamError && "is-invalid"
                            }`}
                          />
                          {kulam_KulaDeivamError && (
                            <div className="invalid-feedback fw-bold">
                              {kulam_KulaDeivamError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="previous-1 action-button-previous"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="action-button"
                      onClick={step2Validation}
                    >
                      Next
                    </button>
                  </fieldset>
                </div>
              )}

              {step === 3 && (
                <div>
                  <fieldset>
                    <div className="text-center form_heading p-2">
                      <h2 className="fs-title">Family Details</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            தந்தை பெயர்:
                          </label>
                          <input
                            type="text"
                            value={fatherName}
                            onChange={(e) => {
                              setFatherName(e.target.value);
                            }}
                            name="father_name"
                            maxLength="20"
                            id="father_name"
                            className={`form-control ${
                              FatherNameError && "is-invalid"
                            }`}
                          />
                          {FatherNameError && (
                            <div className="invalid-feedback fw-bold">
                              {FatherNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            தாய் பெயர்:
                          </label>
                          <input
                            type="text"
                            value={MotherName}
                            onChange={(e) => {
                              setMoterName(e.target.value);
                            }}
                            name="mother_name"
                            maxLength="20"
                            id="mother_name"
                            className={`form-control ${
                              MotherNameError && "is-invalid"
                            }`}
                          />
                          {MotherNameError && (
                            <div className="invalid-feedback fw-bold">
                              {MotherNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            தந்தை பணிவிவரம் :
                          </label>
                          <input
                            type="text"
                            maxLength="25"
                            value={FatherWorkDetails}
                            onChange={(e) => {
                              setFatherWorkDetails(e.target.value);
                            }}
                            name="father_occupation"
                            id="father_occupation"
                            className={`form-control ${
                              FatherWorkDetailsError && "is-invalid"
                            }`}
                          />
                          {FatherWorkDetailsError && (
                            <div className="invalid-feedback fw-bold">
                              {FatherWorkDetailsError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            தாய் பணிவிவரம் :
                          </label>
                          <input
                            type="text"
                            maxLength="25"
                            value={MotherWorkDetails}
                            onChange={(e) => {
                              setMotherWorkDetails(e.target.value);
                            }}
                            id="mother_occupation"
                            name="mother_occupation"
                            className={`form-control ${
                              MotherWorkDetailsError && "is-invalid"
                            }`}
                          />
                          {MotherWorkDetailsError && (
                            <div className="invalid-feedback fw-bold">
                              {MotherWorkDetailsError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            உடன் பிறந்தவர்கள்
                          </label>
                          <Select
                            options={siblingOptions}
                            isMulti
                            isSearchable
                            noOptionsMessage={() => "Nodata found"}
                            value={selectedSiblings}
                            onChange={handleSiblingsChange}
                            maxMenuHeight={5 * 30}
                            className={`form-select ${
                              SiblingError && "is-invalid"
                            }`}
                          />
                          {SiblingError && (
                            <div className="invalid-feedback fw-bold">
                              {SiblingError}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            சொத்து விபரம்:
                          </label>
                          <textarea
                            rows="2"
                            maxLength="75"
                            autoComplete="off"
                            name="assets"
                            id="assets"
                            value={AssetsDetail}
                            className={`form-control ${
                              AssetsError && "is-invalid"
                            }`}
                            onChange={(e) => {
                              setAssetsDetails(e.target.value);
                            }}
                          ></textarea>
                          {AssetsError && (
                            <div className="invalid-feedback fw-bold">
                              {AssetsError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="previous-3 action-button-previous"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="action-button"
                      // onClick={step3Validation}
                      onClick={step3Validation}
                    >
                      Next
                    </button>
                  </fieldset>
                </div>
              )}

              {/* Step 4: Family Details */}
              {step === 4 && (
                <div>
                  <fieldset>
                    <div className="text-center form_heading p-2">
                      <h2 className="fs-title">Contact Details</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            தொலைப்பேசி எண்:
                          </label>
                          <input
                            type="number"
                            name="mobile_number"
                            value={MobileNumber}
                            onChange={(e) => {
                              setMobileNumber(e.target.value);
                            }}
                            id="mobile_number"
                            className={`form-control ${
                              MobileNumberError && "is-invalid"
                            }`}
                          />
                          {MobileNumberError && (
                            <div className="invalid-feedback fw-bold">
                              {MobileNumberError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            வாட்ஸ்ஆப் எண்:
                          </label>
                          <input
                            type="number"
                            value={WhatsappNumber}
                            onChange={(e) => {
                              setWhatsappNumber(e.target.value);
                            }}
                            name="whastapp_number"
                            minLength="10"
                            maxLength="10"
                            id="whastsapp_number"
                            className={`form-control ${
                              WhatsappNumberError && "is-invalid"
                            }`}
                          />
                          {WhatsappNumberError && (
                            <div className="invalid-feedback fw-bold">
                              {WhatsappNumberError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className=" col-md-6 col-12">
                          <div className="my-1 text-start">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Email:
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              value={Email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              className={`form-control ${
                                EmailError && "is-invalid"
                              }`}
                            />
                            {EmailError && (
                              <div className="invalid-feedback fw-bold">
                                {EmailError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" col-md-6 col-12">
                          <div className="my-1 text-start">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              இருப்பிடம்
                            </label>
                            <input
                              type="text"
                              name="current_location"
                              id="current_location"
                              maxLength="15"
                              value={CurrentLocation}
                              onChange={(e) => {
                                setCurrentLocation(e.target.value);
                              }}
                              className={`form-control ${
                                CurerntLocationError && "is-invalid"
                              }`}
                            />
                            {CurerntLocationError && (
                              <div className="invalid-feedback fw-bold">
                                {CurerntLocationError}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className=" col-12">
                        <div className="my-1 text-start">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            முகவரி:
                          </label>
                          <textarea
                            maxLength="50"
                            rows="2"
                            autoComplete="off"
                            value={Address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            name="address"
                            id="address"
                            className={`form-control ${
                              AddressError && "is-invalid"
                            }`}
                          ></textarea>
                          {AddressError && (
                            <div className="invalid-feedback fw-bold">
                              {AddressError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="previous-3 action-button-previous"
                      onClick={prevStep}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="action-button"
                      // onClick={nextStep}
                      onClick={step4Validation}
                    >
                      Next
                    </button>

                    {/* <input type="button" name="previous" class="previous-3 action-button-previous" value="Previous" /> */}
                    {/* <input type="button" name="next" class="action-button" value="Next" id="step-4"/> */}
                  </fieldset>
                </div>
              )}
              {step === 5 && (
                <div>
                  <fieldset>
                    <div className="text-center form_heading p-2">
                      <h2 className="fs-title">Profile Picture</h2>
                    </div>
                    <div className="mt-4">
                      <div ref={croppieRef}></div>
                      <input
                        type="file"
                        id="files"
                        accept="image/*"
                        className={`form-control ${ImageError && "is-invalid"}`}
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                      />
                      {ImageError && (
                        <div className="invalid-feedback fw-bold">
                          {ImageError}
                        </div>
                      )}
                      <hr />
                      <button
                        type="button"
                        className="action-button-previous"
                        onClick={(e) => {
                          handleCrop();
                        }}
                      >
                        Crop
                      </button>
                    </div>

                    <button
                      className="previous-4 action-button-previous"
                      onClick={() => setStep(step - 1)}
                    >
                      Previous
                    </button>
                    <button
                      className="submit action-button"
                      onClick={BiodataFormSubmit}
                      type="button"
                    >
                      Submit
                    </button>
                  </fieldset>
                </div>
              )}

              {/* </fieldset> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
