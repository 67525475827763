import react from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";

export default function FAQ() {
  return (
    <>
      <Menubar />
      <FAQContent />
      <Footer />
    </>
  );
}

const FAQContent = () => {
  return (
    <>
      <section className="page-header bg-tertiary banner">
        <div className="container">
          <div className="row">
            <div className="col-8 mx-auto text-center">
              <h1 className="mb-3 text-capitalize fw-bold" style={{ color: "#b2012f" }}>
                FAQ
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 mt-3">
          <div className="section-title text-center mb-5 pb-2">
            <p className="text-primary text-uppercase fw-bold mb-3">Questions You Have</p>
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="accordion shadow rounded py-5 px-0 px-lg-4 bg-white position-relative" id="accordionFAQ">
            <div className="accordion-item p-1 mb-2">
              <h2 className="accordion-header accordion-button fw-bold h5 border-0 active" id="heading-ebd23e34fd2ed58299b32c03c521feb0b02f19d9" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-ebd23e34fd2ed58299b32c03c521feb0b02f19d9" aria-expanded="true" aria-controls="collapse-ebd23e34fd2ed58299b32c03c521feb0b02f19d9">
                What is the price for birth horoscope?
              </h2>
              <div id="collapse-ebd23e34fd2ed58299b32c03c521feb0b02f19d9" className="accordion-collapse collapse border-0 show" aria-labelledby="heading-ebd23e34fd2ed58299b32c03c521feb0b02f19d9" data-bs-parent="#accordionFAQ">
                <div className="accordion-body py-0 content">Birth horoscope price in one page horoscope is 100 Rs, best offer we provide a small period offer of 50%.</div>
              </div>
            </div>
            <div className="accordion-item p-1 mb-2">
              <h2 className="accordion-header accordion-button fw-bold h5 border-0" id="heading-a443e01b4db47b3f4a1267e10594576d52730ec1" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-a443e01b4db47b3f4a1267e10594576d52730ec1" aria-expanded="false" aria-controls="collapse-a443e01b4db47b3f4a1267e10594576d52730ec1">
                Horoscope calculation used in One page Horoscope?
              </h2>
              <div id="collapse-a443e01b4db47b3f4a1267e10594576d52730ec1" className="accordion-collapse collapse border-0" aria-labelledby="heading-a443e01b4db47b3f4a1267e10594576d52730ec1" data-bs-parent="#accordionFAQ">
                <div className="accordion-body py-0 content">We use thirukanitham calculation for calculating each astrology product.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
