import React from "react";

import CounterContainer from "../counter/Counter"
export default function Indexpage() {

  return (
    <>
      <div className="card border-0">
        <div className="card-body">
          <h5>
            Dashboard
          </h5>
          <div className="row">
            
            <CounterContainer/>
          </div>

                  </div>
      </div>
    </>
  );
}
