import React from "react";
// import "../header/Menubar.css";
import Menubar from "../header/Menubar";
import MarriageMactchingForm from "../marriage-matching-form/marriage-matching-form";
import MarriageMatchingContent from "../marriage-matching-content/mrge-matching-content";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


function MarriageMatching() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Marriage-matching": "/Marriage-matching",
      // Add more canonical paths if needed
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };

  return (
    <div className="">
      <div>
        <Helmet>
        <title> Marriage Matching | திருமண பொருத்தம் | Jathagam porutham</title>

          <meta
            name="description"
            content="Marriage Matching Find Marriage Maching online. Compare  and nakshatras to find Uthamam, Madhyamam or Adhamam based on 10 Poruthams. marriage matching."
          />
          <meta
            name="keywords"
            content="jathaga porutham, jathagam porutham, jathaka porutham in tamil, marriage matching by date of birth, porutham, rasi porutham, rasi porutham for marriage, rasi porutham in tamil, star matching for marriage, thirumana porutham, thirumana porutham in tamil, ஜாதக பொருத்தம், திருமண பொருத்தம், திருமண பொருத்தம் பார்த்த பார்த்தல்"
          />
          <meta name="robots" content="index,follow" />
          {canonical()}
          <script type="application/ld+json">
          {`
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name": "Marriage Matching",
            "image": "",
            "description": "Marriage Matching Find Marriage Maching online. Compare and nakshatras to find Uthamam, Madhyamam or Adhamam based on 10 Poruthams. marriage matching.",
            "brand": {
              "@type": "Brand",
              "name": "One Page Horoscope"          
          `}
        </script>
        </Helmet>
        <Menubar />
        <MarriageMactchingForm />
        <MarriageMatchingContent />
        <Footer />
      </div>
    </div>
  );
}
// const canonical = () => {
//   const base_url = "https://onepagehoroscope.com/";
//   const current_path = Location.pathname;

//   const canonicalPaths = {
//     "/Marriage-matching": "/Marriage-matching",
//   };

//   const current_relative_path = current_path.replace(base_url, "");

//   return (
//     <link
//       rel="canonical"
//       href={
//         base_url +
//         (canonicalPaths[current_relative_path] || current_relative_path)
//       }
//     />
//   );
// };
export default MarriageMatching;