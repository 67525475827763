// import react from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import axios from 'axios';
import React, { useState } from 'react';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { Notify } from 'notiflix/build/notiflix-notify-aio';


export default function contact() {
  return (
    <>
      <Menubar />
      <ContactForm />
      <Footer />
    </>
  );
}

const style = {
    h2: {
      color: "#040404",
      fontFamily: "Rubik, sans-serif",
      fontWeight: 700,
    },
  
    p: {
      color: "#888",
    },
  };


const ContactForm = () => {
  const [userName, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [MobileNumber, setMobileNumber] = useState('');
  const [NameError, setNameError] = useState('');
  const [EmailError, setEmailError] = useState('');
  const [MobileNumberError, setMobileNumberError] = useState('');
  const [MessageError, setMessageError] = useState('');

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setMobileNumber('');
    setNameError('');
    setEmailError('');
    setMobileNumberError('');
    setMessageError('');
  };

  const validation = () => {
    let isValid = true;
    if (!userName) {
      setNameError('Name is Required');
      isValid = false;
    }
    if (!Email) {
      setEmailError('Email is Required');
      isValid = false;
    }
    if (!MobileNumber) {
      setMobileNumberError('Mobile Number is Required');
      isValid = false;
    }
    if (!message) {
      setMessageError('Message is Required');
      isValid = false;
    }
    return isValid;
  };

  const FormSubmit = () => {
    if (validation()) {
      const contactInfo = {
        name: userName,
        mobile_number: MobileNumber, 
        message: message,
        email: Email,
      };
      Loading.dots();
      axios.post('https://ctsvsolutions.com/onepage-Api/public/api/Contact-us', { contactInfo })
        .then((response) => {
          Loading.remove();
          resetForm();
          Notify.success('Form submitted succussfully');
          

        })
        .catch((error) => {
          console.error('Error submitting the form:', error);
          Loading.remove();
        });
    }
  };



  return (
    <>
      <section className="page-header bg-tertiary banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-8 mx-auto text-center">
              <h1
                className="mb-3 text-capitalize fw-bold"
                style={{ color: "#b2012f" }}
              >
                Contact Us
              </h1>
              <div className="section-title text-center">
                <h2 style={style.h2}>let's get connected</h2>
                <p style={style.p}>
                  {/* Lorem ipsum dolor sit, consectetur adipiscing elit. Egestas
                  cursus pellentesque dignissim dui, congue etiam. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 m-auto mt-5">
            <div className="shadow rounded p-3 bg-white">
              <div className="row justify-content-center">
                <div className="col-12 mb-4 text-center py-3">
                  <h4 style={style.h2}>Leave Us A Message</h4>
                </div>
                <div className="col-lg-10">
                  <div className="contact-form">
                  <form>
                    <div className="form-group pb-2">
                      <label htmlFor="user_name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="user_name"
                        name="user_name"
                        value={userName}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                      {NameError && <p className="text-danger">{NameError}</p>}
                    </div>
                    <div className="form-group pb-2">
                      <label htmlFor="mobile_number" className="form-label">
                        Phone No
                      </label>
                      <input
                        type="number"
                        className="form-control shadow-none"
                        id="mobile_number"
                        name="mobile_number"
                        value={MobileNumber}
                        onChange={(e) => { setMobileNumber(e.target.value) }}
                      />
                      {MobileNumberError && <p className="text-danger">{MobileNumberError}</p>}
                    </div>
                    <div className="form-group pb-2">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="email"
                        name="email"
                        value={Email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                      {EmailError && <p className="text-danger">{EmailError}</p>}
                    </div>
                    <div className="form-group pb-2">
                      <label htmlFor="user_message" className="form-label">
                        Write Message
                      </label>
                      <textarea
                        className="form-control shadow-none"
                        id="user_message"
                        name="user_message"
                        rows="3"
                        value={message}
                        onChange={(e) => { setMessage(e.target.value) }}
                      ></textarea>
                      {MessageError && <p className="text-danger">{MessageError}</p>}
                    </div>
                    <button
                      className="btn btn-success mt-2 w-100"
                      type="button"
                      onClick={FormSubmit}
                    >
                      Send Message
                    </button>
                  </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
