import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import "./Typing.css"

export default function Typing() {
  const typingRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typingRef.current, {
      strings: ["Birth Horoscope", "Marriage Matching", "Marriage Biodata"],
      typeSpeed: 70,
      backSpeed: 80,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="container heading mt-5">
      <h2 className="text-start">
        <span className="text-danger">Onepage Horoscope gives </span>
        <span className="text-success banner_heading" ref={typingRef}> </span>
        <span className="text-danger"> with in 2 minutes</span>
      </h2>
    </div>
  );
}
