// import React from "react";
import "./BabyNameForm.css";
import axios from "axios";
import { Loading } from 'notiflix/build/notiflix-loading-aio';

import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
function BabyNameForm() {
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedAmpm, setSelectedAmpm] = useState("");
  const [userGender, setUserGender] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [initial, setInitial] = useState("father");
  const [birthPlace, setBirthPlace] = useState("");
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);

  const [birthplaceError, setBirthplaceError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthHourError, setBirthHourError] = useState("");
  const [birthMinuteError, setBirthMinuteError] = useState("");
  const [birthAmpmError, setBirthAmpmError] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setYearError] = useState("");
  const [FatherNameError, setFatherNameError] = useState("");
  const [motherNameError, setMotherNameError] = useState("");

    const [dayOptions] = useState([...Array(31).keys()].map((day) => String(day + 1).padStart(2, '0')));
  const [monthOptions] = useState([...Array(12).keys()].map((month) => String(month + 1).padStart(2, '0')));
  const [yearOptions] = useState(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1950;
    return [...Array(currentYear - startYear + 1).keys()].map(
      (year) => startYear + year
    );
  });
  const [hourOptions] = useState([...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, '0')));
  const [minuteOptions] = useState([...Array(60).keys()].map((minute) => String(minute).padStart(2, '0')));

  // const handleSuggestionSelect = (selectedPlace) => {
  //   const { geometry, properties } = selectedPlace;
  //   const latitude = geometry.coordinates[1];
  //   const longitude = geometry.coordinates[0];
  //   const district = properties.city;
  //   const state = properties.state;
  //   const country = properties.country;
  //   const location = `${district}, ${state}, ${country}`;

  //   const updatedSelectedPlaces = [...selectedPlaces, location];

  //   setSelectedPlaces(updatedSelectedPlaces);
  //   setBirthPlace(location);
  //   setBirthplaceError("");

  //   setSuggestedPlaces([]);

  //   document.getElementById("atflg").value = longitude;
  //   document.getElementById("atflt").value = latitude;
  // };

  const navigate = useNavigate();
  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const handleLocationChange = (event) => {
    const currentValue = event.target.value;
    setBirthPlace(currentValue);
    fetchSuggestions(currentValue);
  };

  const handleSuggestionSelect = (selectedPlace) => {
    // const { geometry, properties } = selectedPlace;
    const latitude = selectedPlace.center[1];
    const longitude = selectedPlace.center[0];

    const parts =selectedPlace.place_name.split(',');
    const displayName = parts.slice(0, 3).join(',');
    const location = displayName

    const updatedSelectedPlaces = [...selectedPlaces, location];

    setSelectedPlaces(updatedSelectedPlaces);
    setBirthPlace(location);
    setBirthplaceError("");

    setSuggestedPlaces([]);

    document.getElementById("atflg").value = longitude;
    document.getElementById("atflt").value = latitude;
  };

  const fetchSuggestions = async (currentValue) => {
    if (currentValue.length >= 3) {
      const apiKey = "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=5`;
  
      try {
        const response = await fetch(url);
        console.log(response);
        if (response.ok) {
        
          const data = await response.json();
          setSuggestedPlaces(data.features);
        } else {
          console.error("Error fetching suggestions from the API");
          setSuggestedPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions from the API: ", error);
        setSuggestedPlaces([]);
      }
    }
  };
  
  const Formvalidation = () => {
    let isValid = true;
   let latitudeValidation=document.getElementById('atflt').value; 
   
    if (!birthPlace) {
      setBirthplaceError("field is required");
      isValid = false;
    } else {
      setBirthplaceError("");
    }
    if(!latitudeValidation){
      setBirthplaceError("Again Select the Birth Place");
      isValid = false;
     }
     else{
      setBirthplaceError("");
     }
    if (!userGender) {
      setGenderError("field is required");
      isValid = false;
    } else {
      setGenderError("");
    }
    if (!selectedDay) {
      setBirthdateError("field is required");
      isValid = false;
    } else {
      setBirthdateError("");
    }
    if (!selectedMonth) {
      setMonthError("field is required");
      isValid = false;
    } else {
      setMonthError("");
    }
    if (!selectedYear) {
      setYearError("field is required");
      isValid = false;
    } else {
      setYearError("");
    }
    if (!selectedHour) {
      setBirthHourError("field is required");
      isValid = false;
    } else {
      setBirthHourError("");
    }
    if (!selectedMinute) {
      setBirthMinuteError("field is required");
      isValid = false;
    } else {
      setBirthMinuteError("");
    }
    if (!selectedAmpm) {
      setBirthAmpmError("field is required");
      isValid = false;
    } else {
      setBirthAmpmError("");
    }
    if (!fatherName) {
      setFatherNameError("field is required");
      isValid = false;
    } else {
      setFatherNameError("");
    }
    if (!MotherName) {
      setMotherNameError("field is required");
      isValid = false;
    } else {
      setMotherNameError("");
    }
    // isValid = false;
    return isValid;
  };


  const BabyNameFormSubmit = (e) => {
    if(Formvalidation()){
      const BabyNameList = {
        BirthMonth:selectedMonth,
        BirthYear:selectedYear,
        BirthDay:selectedDay,
        BirthDate:`${selectedYear}-${selectedMonth}-${selectedDay}`,
        BirthTime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        BirthPlace:birthPlace,
        initial:initial,
        father_name:fatherName,
        mother_name:MotherName,
        gender:userGender,
        latitude: document.getElementById("atflt").value,
        longitude: document.getElementById("atflg").value,
      };
      Loading.dots('Baby Name is Loading .....');

      axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List-Random", BabyNameList)
      .then((response1) => {
        if (response1.status === 200) {
          axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List", BabyNameList)
            .then((response2) => {
              if (response2.status === 200) {
                Loading.remove();
                const value = JSON.stringify(response2.data.data);
                localStorage.setItem('BabyNameValues', value);
                const formdatavalue = JSON.stringify(BabyNameList);
                localStorage.setItem('babyNameformdata', formdatavalue);
                const BabyUserInfo={
                  'user_id':"",
                  'customerDataId':""
                }
                const Baby_user_info = JSON.stringify(BabyUserInfo);
                localStorage.setItem('BabayUserInfo', Baby_user_info);
                customRedirect('/Baby-Name-view', response2.data);
              } else {
                console.error("Error in Response 2:", response2);
              }
            })
            .catch((error) => {
              console.error("Error in Axios POST for Marriage-Matching:", error);
            });
        } else {
          console.error("Error in Response 1:", response1);
        }
      })
      .catch((error) => {
        console.error("Error in Axios POST for Marriage-Matching-Random:", error);
      });
    
    }
    }

    useEffect(() => {
      const storedValue = localStorage.getItem('babyNameformdata');
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue);
        const dateofBirth = parsedValue.BirthDate;
        const [day, month, year] = dateofBirth.split('-');
        
        const Birthtime = parsedValue.BirthTime;
        const timeComponents = Birthtime.split(' ');
        const time = timeComponents[0];
        const ampm = timeComponents[1];
        const [hour, minute] = time.split(':');
    
        // setUserName(parsedValue.username);
        setUserGender(parsedValue.gender);
        // setSelectedPlaces(parsedValue.BirthPlace);
        setSelectedDay(parsedValue.BirthDay);
        setSelectedMonth(parsedValue.BirthMonth);
        setSelectedYear(parsedValue.BirthYear);
        setSelectedHour(hour);
        setSelectedMinute(minute);
        setSelectedAmpm(ampm);
        setBirthPlace(parsedValue.BirthPlace);
        setFatherName(parsedValue.father_name);
        setMotherName(parsedValue.mother_name);
        setInitial(parsedValue.initial);
        document.getElementById("atflg").value = parsedValue.longitude;
        document.getElementById("atflt").value = parsedValue.latitude;
      }
    }, []);

  return (
    <>
      <section style={{ marginTop: "150px" }}>
        <div className="container-fluid p-0">
          <h1 className="cate-title ">
            Numerology baby names - நியூமெராலஜி குழந்தை பெயர்கள்{" "}
          </h1>
          <div className="row justify-content-center">
            <div className="col-lg-7 ">
              {/* <h1 className="text-center fs-4"></h1> */}
              <form method="" id="signUpForm" className="shadow" target="">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="formGroupExampleInput" className="form-label">
                        பிறந்த ஊர்
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          birthplaceError && "is-invalid"
                        }`}
                        id="birth_place"
                        name="birth_place"
                        onChange={handleLocationChange}
                        value={birthPlace}
                        placeholder="Enter the Birth place"
                      />
                      {birthplaceError && (
                      <div className="invalid-feedback fw-bold">
                        {birthplaceError}
                      </div>
                    )}
                      <input
                        id="atflt"
                        className="pfipbx"
                        name="the_lat"
                        type="hidden"
                        placeholder="Latitude"
                      />
                      <input
                        id="atflg"
                        className="pfipbx"
                        name="the_lng"
                        type="hidden"
                        placeholder="Longitude"
                      />

          <ul>
            {suggestedPlaces.map((place, index) => {
              if (place.place_name) {
                const listItemStyle = {
                  padding: "5px 10px",
                  cursor: "pointer",
                  fontSize: "16px",
                  borderBottom: "1px dashed #ddd",
                };

                // Split the place_name and get the first three parts
                const parts = place.place_name.split(',');
                const displayName = parts.slice(0, 3).join(',');

                return (
                  <p
                    style={listItemStyle}
                    key={index}
                    onClick={() => handleSuggestionSelect(place)}
                  >
                    {displayName}
                  </p>
                );
              }
              return null; // Handle the case where place.place_name is falsy
            })}
          </ul>

                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="">
                      <label htmlFor="formGroupExampleInput" className="form-label">
                        பாலினம்:
                      </label>
                      <select
                        // className="form-select valid"
                        className={`form-select ${genderError && "is-invalid"}`}
                        aria-label="Default select example"
                        id="b_gender"
                        name="gender"
                        value={userGender}
                        onChange={(e) => {
                          setUserGender(e.target.value);
                        }}
                      >
                        <option value="">select</option>
                        <option value="Male-ஆண்">Boy-ஆண்</option>
                        <option value="Female-பெண்">Girl-பெண்</option>
                      </select>
                      {genderError && (
                      <div className="invalid-feedback fw-bold">
                        {genderError}
                      </div>
                    )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="formGroupExampleInput"
                    className=" form-label mt-2"
                  >
                    பிறந்த தேதி :
                  </label>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <label htmlFor="formGroupExampleInput" className=" form-label">
                      தேதி :
                    </label>
                    <select
                      className={`form-select ${
                        birthdateError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      name="fin_day"
                      id="fin_day"
                      value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}
                    >
                      <option value="">தேதி</option>
                      {dayOptions.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                    {birthdateError && (
                      <div className="invalid-feedback fw-bold">
                        {birthdateError}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <label htmlFor="formGroupExampleInput" className=" form-label">
                      மாதம்:
                    </label>
                    <select
                      className={`form-select ${monthError && "is-invalid"}`}
                      aria-label="Default select example"
                      id="fin_month"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                      <option value="">மாதம்</option>
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                    {monthError && (
                      <div className="invalid-feedback fw-bold">
                        {monthError}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <label htmlFor="formGroupExampleInput" className=" form-label">
                      வருடம்:
                    </label>
                    <select
                      className={`form-select ${yearError && "is-invalid"}`}
                      aria-label="Default select example"
                      name="fin_year"
                      id="fin_year"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      <option value="">வருடம்</option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                       {yearError && (
                      <div className="invalid-feedback fw-bold">
                        {yearError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-1 mt-1">
                  <label
                    htmlFor="formGroupExampleInput"
                    className=" form-label mt-2"
                  >
                    பிறந்த நேரம்:
                  </label>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <label htmlFor="formGroupExampleInput" className=" form-label">
                      Hour:
                    </label>
                    <select
                      className={`form-select ${
                        birthHourError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      id="fin_hour"
                      value={selectedHour}
                      onChange={(e) => setSelectedHour(e.target.value)}
                    >
                      <option value="">நேரம்</option>
                      {hourOptions.map((hour) => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </select>
                    {birthHourError && (
                      <div className="invalid-feedback fw-bold">
                        {birthHourError}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <label htmlFor="formGroupExampleInput" className=" form-label">
                      Minute:
                    </label>
                    <select
                      className={`form-select ${
                        birthMinuteError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      id="fin_minute"
                      value={selectedMinute}
                      onChange={(e) => setSelectedMinute(e.target.value)}
                    >
                      <option value="">நிமிடம்</option>
                      {minuteOptions.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </select>
                       {birthMinuteError && (
                      <div className="invalid-feedback fw-bold">
                        {birthMinuteError}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12">
                    <label htmlFor="formGroupExampleInput" className=" form-label">
                      AM/PM:
                    </label>
                    <select
                      className={`form-select ${
                        birthAmpmError && "is-invalid"
                      }`}
                      aria-label="Default select example"
                      id="fin_ampm"
                      value={selectedAmpm}
                      onChange={(e) => setSelectedAmpm(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                    {birthAmpmError && (
                      <div className="invalid-feedback fw-bold">
                        {birthAmpmError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12">
                    <label
                      className="form-check-label fs-5"
                      htmlFor="flexCheckDefault"
                    >
                      குழந்தையின் இனிசியல் :
                    </label>
                    <div className="my-2">
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input-lg valid"
                          id="f_initial"
                          value="father"
                          name="initial"
                          checked={initial === "father"}
                          onChange={() => {
                            setInitial("father");
                          }}
                        />
                        <label
                          className="form-check-label fs-6"
                          htmlFor="f_initial"
                        >
                          தந்தை பெயர்
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input-lg"
                          id="f_m_initial"
                          value="both"
                          name="initial"
                          checked={initial === "both"}
                          onChange={() => {
                            setInitial("both");
                          }}
                        />
                        <label
                          className="form-check-label fs-6"
                          htmlFor="f_m_initial"
                        >
                          தந்தை + தாய் பெயர்
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="my-2">
                      <label htmlFor="formGroupExampleInput" className="form-label">
                        தந்தை பெயர்:
                      </label>
                      <input
                        type="text"
                        // className="form-control valid"
                        className={`form-control ${FatherNameError && "is-invalid"}`}
                        id="f_name"
                        name="f_name"
                        placeholder="Enter Father Name"
                        value={fatherName}
                        onChange={(e) => {
                          setFatherName(e.target.value);
                        }}
                      />
                       {FatherNameError && (
                      <div className="invalid-feedback fw-bold">
                        {FatherNameError}
                      </div>
                    )}
                    </div>
                   
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="my-2">
                      <label htmlFor="formGroupExampleInput" className="form-label">
                        தாய் பெயர்:
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          motherNameError && "is-invalid"
                        }`}
                        id="m_name"
                        name="m_name"
                        placeholder="Enter Mother Name"
                       
                        value={MotherName}
                        onChange={(e) => {
                          setMotherName(e.target.value);
                        }}
                      />
                          {motherNameError && (
                      <div className="invalid-feedback fw-bold">
                        {motherNameError}
                      </div>
                    )}
                    </div>
                  
                  </div>
                </div>              
                <div className="form-footer d-flex">
                  <button
                    type="button"
                    id="nextBtn"
                    onClick={BabyNameFormSubmit}
                  >
                    submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default BabyNameForm;
