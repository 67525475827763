import React from "react";
import backgroundImage from "./img/marriage_biodata_4.png";
export default function RuthuPaidTemplate({value}) {
  const styles = {
    sheet: {
      width: "20.50cm",
      height: "29.5cm",
      margin: "auto",
      color: "#5C4033",
      fontWeight: "500",
    },
    sheet2: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    },

    headingBottom: {
      borderBottom: "2px solid #5C4033",
    },

    panchangDetailEven: {
      backgroundColor: "#fffce6",
    },

    tableBorder1: {
      border: "2px solid #5C4033",
    },

    contentBorder: {
      borderBottom: "2px solid #5C4033",
    },

    birthTable: {
      width: "100%",
      background: "#fffce6",
      fontSize: "12px",
      marginBottom: "1px",
      border: "1px solid #5C4033",
      position: "relative",
      fontWeight: "500",
    },

    birthTableLaknamAfter: {
      content: "",
      width: "23px",
      border: "1px solid #5C4033",
      position: "absolute",
      top: "7px",
      left: "-4px",
      transform: "rotate(136deg)",
    },

    birthTableLaknamBefore: {
      content: "",
      width: "35px",
      border: "1px solid #5C4033",
      position: "absolute",
      top: "11px",
      left: "-6px",
      transform: "rotate(136deg)",
    },

    rasiChart: {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "600",
      color: "#5C4033",
    },

    rasiBirth: {
      border: "1px solid #5C4033",
      width: "70px",
      height: "75px",
      paddingLeft: "12px",
      position: "relative",
    },
  };
 const personal_info=value.personal_info;

 const TamilDay=personal_info.tamil_day.split(' ');

 const TamilYear=TamilDay[0];
 const TamilMonth=TamilDay[2];
 const TamilDate=TamilDay[3];
 const TamilKilamai=personal_info.birth_day.split('-');
  const TamilKilamaiDate=TamilKilamai[1];
  const BirthTime=personal_info.birth_time.split(' ');
  const BirthHour=BirthTime[0];
  let Kalam=BirthTime[1];
     if(Kalam=="AM" || Kalam =="am" || Kalam =="Am"  ){
      Kalam="காலை";
     }else{
      Kalam="மாலை";
     }
     const Anthiram = value.anthiram;
     const AnthiramKiragam = value.current_dhasa;
   const AmsamChart=value.amsam_array;
    const AmsamSlash=value.amsam_slash;
    const Numerology=value.Numerology;       
    const paralgal=value.paralgal;
    const pavagaMaruthalChart=value.pavaga_maruthal_table;
    const NadapuThasaValues=value.thasa_values;
  return (
    <div>
      <section
        className="sheet sheet-2"
        style={{ ...styles.sheet, ...styles.sheet2 }}
      >
        <div
          className="container-fluid"
          style={{ marginTop: "20px", padding: "0px 35px" }}
        >
          <div
            className="text-center m-auto mb-5"
            style={{ width: "18px", height: "10px" }}
          >
            <img
              src={require("./img/pillayar_suli.png")}
              className="img-fluid"
              alt="pillayar"
            />
          </div>
          <div className="text-center" style={{ height: "130px" }}>
            <img
              src={require("./img/vinayagar.png")}
              className="img-fluid rounded-circle"
              alt="vinayagar"
            />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="text-center mb-3">
                <span
                  className="fw-bold heading-bottom fs-5"
                  style={styles.headingBottom}
                >
                  ருது ஜாதகம்
                </span>
              </div>
              <div className="content-border" style={styles.contentBorder}>
                <p className="text-center">
                  நிகழும்{" "}
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                    {TamilYear}
                  </span>{" "}
                  வருடம் 
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                  {TamilMonth}
                  </span>{" "}
                   மாதம்   <span className="fw-bold" style={{ fontSize: "17px" }}>  {TamilDate} </span>  ஆம் நாள்
                  <br />
                  (ஆங்கில தேதி{" "}
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                   {personal_info.birth_date}
                  </span>
                  ), <span className="fw-bold">{TamilKilamaiDate}</span>யான சுப
                  தினத்தில்{" "}
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                    {Kalam} {BirthHour}
                  </span>{" "}
                  மணிக்கு உயர் திரு{" "}
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                  {personal_info.father_name}
                  </span>{" "}
                  அவர்களுக்கும் ,திருமதி{" "}
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                  {personal_info.mother_name}
                  </span>{" "}
                  அவர்களின் குமாரி{" "}
                  <span className="fw-bold" style={{ fontSize: "17px" }}>
                  {personal_info.name}
                  </span>{" "}
                  ருதுவான நேரத்தை குறிக்கும் <span className="fw-bold" style={{ fontSize: "17px" }}>
                     ருது ஜாதகம்.
                  </span>
                </p>
              </div>
              <div className="text-center my-3">
                <span
                  className="fw-bold heading-bottom"
                  style={styles.headingBottom}
                >
                  பஞ்சாங்க விபரம்
                </span>
              </div>
              <div className="table-border-1" style={styles.tableBorder1}>
                <table className="table table-borderless panchang-detail mb-0 fw-bold">
                  <tbody>
                    <tr>
                      <td style={styles.panchangDetailEven}>ருதுவான இடம்</td>
                      <td style={styles.panchangDetailEven}>
                      {value.personal_info.birth_place}
                      </td>
                    </tr>
                    <tr>
                      <td>ருதுவான தேதி</td>
                      <td>{value.personal_info.birth_date}</td>
                    </tr>
                    <tr>
                      <td style={styles.panchangDetailEven}>ருதுவான நேரம்</td>
                      <td style={styles.panchangDetailEven}>{value.personal_info.birth_time}</td>
                    </tr>
                    <tr>
                      <td>நட்சத்திரம்</td>
                      <td>{value.personal_info.natchathiram}</td>
                    </tr>
                    <tr>
                      <td style={styles.panchangDetailEven}>ராசி</td>
                      <td style={styles.panchangDetailEven}>{value.personal_info.rasi}</td>
                    </tr>
                    <tr>
                      <td> திதி</td>
                      <td>{value.personal_info.thithi}</td>
                    </tr>
                    <tr>
                      <td style={styles.panchangDetailEven}>கரணம்</td>
                      <td style={styles.panchangDetailEven}>{value.personal_info.karanam}</td>
                    </tr>
                    <tr>
                      <td>லக்னம்</td>
                      <td>{value.personal_info.laknam}</td>
                    </tr>
                    <tr>
                      <td style={styles.panchangDetailEven}>யோகம்</td>
                      <td style={styles.panchangDetailEven}>{value.personal_info.yogam}</td>
                    </tr>
                    <tr>
                      <td>பஷம்</td>
                      <td>{value.personal_info.patcham}</td>
                    </tr>
                    <tr>
                      <td style={styles.panchangDetailEven}>யோக நட்சத்திரம்</td>
                      <td style={styles.panchangDetailEven}>{value.personal_info.yoga_natchathiram}</td>
                    </tr>
                    <tr>
                      <td>அவயோக நட்சத்திரம்</td>
                      <td>{value.personal_info.avayogi_natchathiram}</td>
                    </tr>
                    <tr>
                      <td style={styles.panchangDetailEven}>யோகி</td>
                      <td style={styles.panchangDetailEven}>{value.personal_info.yogi}</td>
                    </tr>
                    <tr>
                      <td>அவயோகி</td>
                      <td>{value.personal_info.avayogi}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="sheet sheet-2"
        style={{ ...styles.sheet, ...styles.sheet2 }}
      >
        <div
          className="container-fluid"
          style={{ marginTop: "60px", padding: "0px 35px" }}
        >
          <div className="text-center mb-3">
            <span
              className="fw-bold heading-bottom"
              style={styles.headingBottom}
            >
              நிராயன கிரக பாத சாரம்
            </span>
          </div>
          <div className="row">
            <div className="col-6 p-0">
              <div
                className="table-border-1 p-1 m-1"
                style={styles.tableBorder1}
              >
                <table
                  width="100%"
                  className="text-center table-bordered"
                  style={{ fontSize: "11px" }}
                >
                  <thead>
                    <tr>
                      <th width="15%">கிரகம்</th>
                      <th width="15%">தீர்காம்சம்</th>
                      <th width="55%" style={{ fontSize: "10.4px" }}>
                        ராசி - நட்சத்திரம் - பாதம்
                      </th>
                      <th width="15%">அதிபதி</th>
                    </tr>
                  </thead>
            
                  <tbody>
                  {Array.isArray(value.tableData)
                    ? value.tableData.slice(0, 5).map((item, index) => (
                        <tr key={index}>
                          <td className="py-1">{item.kiragam}</td>
                          <td className="py-1">{item.Degree}</td>
                          <td className="py-1">
                            {item.rasi +
                              "-" +
                              item.natchathiram +
                              "-" +
                              item.patham}
                          </td>
                          <td className="py-1">{item.natchathira_athipathi}</td>
                        </tr>
                      ))
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6 p-0">
              <div
                className="table-border-1 p-1 m-1"
                style={styles.tableBorder1}
              >
                <table
                  width="100%"
                  className="text-center table-bordered"
                  style={{ fontSize: "11px" }}
                >
                  <thead>
                    <tr>
                      <th width="15%">கிரகம்</th>
                      <th width="15%">தீர்காம்சம்</th>
                      <th width="55%" style={{ fontSize: "10.4px" }}>
                        ராசி - நட்சத்திரம் - பாதம்
                      </th>
                      <th width="15%">அதிபதி</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Array.isArray(value.tableData)
                    ? value.tableData.slice(5, 10).map((item, index) => (
                        <tr key={index}>
                          <td className="py-1">{item.kiragam}</td>
                          <td className="py-1">{item.Degree}</td>
                          <td className="py-1">
                            {item.rasi +
                              "-" +
                              item.natchathiram +
                              "-" +
                              item.patham}
                          </td>
                          <td className="py-1">{item.natchathira_athipathi}</td>
                        </tr>
                      ))
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="text-center my-3">
            <span className="fw-bold heading-bottom">ராசி & நவாம்சம்</span>
          </div>
          <div className="row">
            <div className="col-6 p-0">
              <div class="table-border-1 p-1 m-1" style={styles.tableBorder1}>
              <table className="birth-table" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td className={`rasi-birth ${value.rasivalue.r12}`}>
                      {value.rasi_array.r12}
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r1}`}>
                      {value.rasi_array.r1}
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r2}`}>
                      {value.rasi_array.r2}
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r3}`}>
                      {value.rasi_array.r3}
                    </td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${value.rasivalue.r11}`}>
                      {value.rasi_array.r11}
                    </td>
                    <td
                      className="rasi-birth chart-birth"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart"> ராசி </p>
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r4}`}>
                      {value.rasi_array.r4}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth ${value.rasivalue.r10}`}
                      valign="top"
                    >
                      {value.rasi_array.r10}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r5}`}
                      valign="top"
                    >
                      {value.rasi_array.r5}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth ${value.rasivalue.r9}`}
                      valign="top"
                    >
                      {value.rasi_array.r9}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r8}`}
                      valign="top"
                    >
                      {value.rasi_array.r8}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r7}`}
                      valign="top"
                    >
                      {value.rasi_array.r7}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r6}`}
                      valign="top"
                    >
                      {value.rasi_array.r6}
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <div className="col-6 p-0">
              <div class="table-border-1 p-1 m-1" style={styles.tableBorder1}>
              <table className="birth-table" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a12}`}>{AmsamChart.a12}</td>
                    <td className={`rasi-birth ${AmsamSlash.a1}`}>{AmsamChart.a1}</td>
                    <td className={`rasi-birth ${AmsamSlash.a2}`}>{AmsamChart.a2}</td>
                    <td className={`rasi-birth ${AmsamSlash.a3}`}>{AmsamChart.a3}</td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a11}`}>{AmsamChart.a11}</td>
                    <td
                      className="rasi-birth chart-birth"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart"> நவாம்சம் </p>
                    </td>
                    <td className={`rasi-birth ${AmsamSlash.a4}`}>{AmsamChart.a4}</td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a10}`} valign="top">{AmsamChart.a10}</td>
                    <td className={`rasi-birth ${AmsamSlash.a5}`} valign="top">{AmsamChart.a5}</td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a9}`} valign="top">{AmsamChart.a9}</td>
                    <td className={`rasi-birth ${AmsamSlash.a8}`} valign="top">{AmsamChart.a8}</td>
                    <td className={`rasi-birth ${AmsamSlash.a7}`} valign="top">{AmsamChart.a7}</td>
                    <td className={`rasi-birth ${AmsamSlash.a6}`} valign="top">{AmsamChart.a6}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
          <div className="text-center my-3">
            <span
              className="fw-bold heading-bottom"
              style={styles.headingBottom}
            >
              நடப்பு தசை / புத்தி கால விவரங்கள்:-
            </span>
          </div>
          <div className="row">
            <div className="col-6 p-0">
              <div class="table-border-1 p-1 m-1" style={styles.tableBorder1}>
                <table
                  width="100%"
                  className="text-center table-bordered"
                  style={{ fontSize: "11px" }}
                >
                  <thead>
                  <tr>
                    <th width="10%">திசை</th>
                    <th width="20%">புத்தி</th>
                    <th width="20%">தொடக்கம்</th>
                    <th width="20%">முடிவு</th>
                    <th width="30%" style={{ fontSize: "10.5px" }}>
                      வயது <br /> வரு-மா-நா
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{AnthiramKiragam.thasa1}</td>
                    <td>{Anthiram.antharam_1[0]}</td>
                    <td>{Anthiram.antharam_1_st_date[0]}</td>
                    <td>{Anthiram.antharam_2_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[0]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa2}</td>
                    <td>{Anthiram.antharam_2[0]}</td>
                    <td>{Anthiram.antharam_2_st_date[0]}</td>
                    <td>{Anthiram.antharam_3_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[1]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa3}</td>
                    <td>{Anthiram.antharam_3[0]}</td>
                    <td>{Anthiram.antharam_3_st_date[0]}</td>
                    <td>{Anthiram.antharam_4_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[2]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa4}</td>
                    <td>{Anthiram.antharam_4[0]}</td>
                    <td>{Anthiram.antharam_4_st_date[0]}</td>
                    <td>{Anthiram.antharam_5_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[3]}</td>
                  </tr>

                  <tr>
                    <td>{AnthiramKiragam.thasa5}</td>
                    <td>{Anthiram.antharam_5[0]}</td>
                    <td>{Anthiram.antharam_5_st_date[0]}</td>
                    <td>{Anthiram.antharam_6_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[4]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa6}</td>
                    <td>{Anthiram.antharam_6[0]}</td>
                    <td>{Anthiram.antharam_6_st_date[0]}</td>
                    <td>{Anthiram.antharam_7_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[5]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa7}</td>
                    <td>{Anthiram.antharam_7[0]}</td>
                    <td>{Anthiram.antharam_7_st_date[0]}</td>
                    <td>{Anthiram.antharam_8_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[6]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa8}</td>
                    <td>{Anthiram.antharam_8[0]}</td>
                    <td>{Anthiram.antharam_8_st_date[0]}</td>
                    <td>{Anthiram.antharam_9_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[7]}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-6 p-0">
              <div class="table-border-1 p-1 m-1" style={styles.tableBorder1}>
                <table
                  width="100%"
                  className="text-center table-bordered"
                  style={{ fontSize: "11px" }}
                >
                  <thead>
                  <tr>
                    <th width="10%">திசை</th>
                    <th width="20%">புத்தி</th>
                    <th width="20%">தொடக்கம்</th>
                    <th width="20%">முடிவு</th>
                    <th width="30%" style={{ fontSize: "10.5px" }}>
                      வயது <br /> வரு-மா-நா
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                  <td>{AnthiramKiragam.thasa9}</td>
                    <td>{Anthiram.antharam_9[0]}</td>
                    <td>{Anthiram.antharam_9_st_date[0]}</td>
                    <td>{Anthiram.antharam_10_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[8]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa10}</td>
                    <td>{Anthiram.antharam_10[0]}</td>
                    <td>{Anthiram.antharam_10_st_date[0]}</td>
                    <td>{Anthiram.antharam_11_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[9]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa11}</td>
                    <td>{Anthiram.antharam_11[0]}</td>
                    <td>{Anthiram.antharam_11_st_date[0]}</td>
                    <td>{Anthiram.antharam_12_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[10]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa12}</td>
                    <td>{Anthiram.antharam_12[0]}</td>
                    <td>{Anthiram.antharam_12_st_date[0]}</td>
                    <td>{Anthiram.antharam_13_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[11]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa13}</td>
                    <td>{Anthiram.antharam_13[0]}</td>
                    <td>{Anthiram.antharam_13_st_date[0]}</td>
                    <td>{Anthiram.antharam_14_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[12]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa14}</td>
                    <td>{Anthiram.antharam_14[0]}</td>
                    <td>{Anthiram.antharam_14_st_date[0]}</td>
                    <td>{Anthiram.antharam_15_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[13]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa15}</td>
                    <td>{Anthiram.antharam_15[0]}</td>
                    <td>{Anthiram.antharam_15_st_date[0]}</td>
                    <td>{Anthiram.antharam_16_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[14]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa16}</td>
                    <td>{Anthiram.antharam_16[0]}</td>
                    <td>{Anthiram.antharam_16_st_date[0]}</td>
                    <td>{Anthiram.antharam_17_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[15]}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="content-border" style={styles.contentBorder}>
              <p className="mb-2" style={{ fontSize: "14px" }}>
              பாவக மாறுதல்:
              {NadapuThasaValues.thasa_value} திசை இருப்பு {NadapuThasaValues.thasa_varudam} வருடம்  {NadapuThasaValues.thasa_matham} மாதம் {NadapuThasaValues.thasa_day} நாட்கள்
              </p>
            </div>
            <div className="text-center">
              <p style={{ fontSize: "14px" }}>
                www.onepagehoroscope.com எங்களிடம் <br /> ஜனன ஜாதகம் | திருமண
                பொறுத்தம் | திருமண சுய விபரம் (bio data) <br /> குழந்தை பெயர்
                பட்டியல் | ஜாதக புத்தகம் | ருது ஜாதகம்
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
