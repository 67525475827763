import React from "react";
import backgroundImage from "./Img/bio_data.png";
import BlurImage from "./Img/blur.png";


export default function BioTemplate2({ value }) {
  const personalInfo=value.personal_info;
  const Navamsam=value.amsam_array;
  const amsamSlash=value.amsam_slash;
  const pavagaMaruthal=value.pavaga_maruthal;
  const RasiChart=value.rasi_array;
  const RasiSlash=value.rasivalue;
  const style = {
    sheet: {
      width: "20.50cm",
      height: "29.5cm",
      margin: "auto",
    },

    sheet1: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat, repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    },

    sheet2: {
      backgroundColor: "#ffe0c4",
      color: "#3b0354",
      borderStyle: "dashed",
      borderColor: "#3b0354",
    },

    imgFluid: {
      height: "100%",
    },

    birthTable: {
      width: "100%",
      background: "#fff",
      fontSize: "12px",
      marginBottom: "1px",
      border: "1px solid #3b0354",
      position: "relative",
    },

    laknamAfter: {
      content: "",
      width: "23px",
      border: "1px solid #3b0354",
      position: "absolute",
      top: "7px",
      left: "-4px",
      transform: "rotate(136deg)",
    },

    laknamBefore: {
      content: "",
      width: "35px",
      border: "1px solid #3b0354",
      position: "absolute",
      top: "11px",
      left: "-6px",
      transform: "rotate(136deg)",
    },

    rasiChart: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "600",
      color: "#3b0354",
    },

    rasiBirth: {
      border: "1px solid #3b0354",
      width: "70px",
      height: "75px",
      paddingLeft: "12px",
      position: "relative",
    },

    qus: {
      fontSize: "13px",
      fontWeight: "bold",
    },

    ans: {
      fontSize: "12px",
      fontWeight: "bold",
    },

    borderTop: {
      borderTop: "1px solid #3b0354",
    },

    borderBottom: {
        borderBottom: "1px solid #3b0354",
      },
  };

  return (
    <>
      <div style={{ ...style.sheet, ...style.sheet1 }} className="mt-2">
        <div className="container-fluid position-relative">
          <div className="row">
            <div
              className="col-12 p-1"
              style={{ position: "absolute", top: "235px" }}
            >
              <div className="text-center " style={{ height: "440px" }}>
  
  <img src={`https://ctsvsolutions.com/onepage-Api/public/assets/images/prof_img/${personalInfo.image}`} className="img-fluid rounded-circle" alt="Profile" />
</div>

              
            </div>
            
            <div
              className="col-12 p-1 text-white fw-bold"
              style={{ position: "absolute", top: "765px", left: "195px" }}
            >
              
              <div style={{ width: "48%" }}>
                <div className="row">
                  <div className="col-12 ps-5 text-center my-5 fs-4">
                    {/* Render details using styles from the style object */}
                    <div>{personalInfo.name}</div>
                    <div>  { personalInfo.birth_date }( { personalInfo.birth_time })</div>
                    <div>{ personalInfo.birth_place }</div>
                    <div>{ personalInfo.education }</div>
                    <div>{ personalInfo.caste }</div>
                    <div> {personalInfo.mobile_number} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ ...style.sheet, ...style.sheet2 }}>
        <div className="container-fluid" style={{ paddingTop:"5px" }}>
          <div className="row">
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold" style={style.borderBottom} >{personalInfo.name} சுய விபரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              பாலினம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            {personalInfo.gender}
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              பணி விவரங்கள் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.work_details }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
            மாத வருமானம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.income }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              குலம்/கோத்ரம்/வீடு <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.kulam }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              உயரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.height }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              எடை <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.weight }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              நிறம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.color }
            </div>
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold" style={style.borderBottom}>குடும்ப விவரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தந்தை பெயர் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.father_name }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தாய் பெயர் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.mother_name }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தந்தை பணி விபரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.father_occupation }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தாய் பணி விபரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            { personalInfo.mother_occupation }
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              உடன் பிறந்தவர்கள் <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}>
            {personalInfo.sibling.map((sibling) => sibling.value).join(', ')}
            </div>
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold" style={style.borderBottom}>ஜாதக விவரம்</span>
              </div>
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              லக்னம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              உதயாதி நாழிகை <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              ராசி - நட்சத்திரம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              சூர்ய உதயம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தமிழ் தேதி<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              சூர்ய அஸ்தம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              பஷம்/திதி <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              யோக நட்சத்திரம் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              யோகம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              யோகி<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              கரணம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              அவயோக நட்சத்திரம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              திதி சூன்யம்<span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              அவயோகி <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              ஜாதக நிலை <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold" style={style.borderBottom}>தொடர்பு விவரம்</span>
              </div>
              
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              தொலைப்பேசி எண் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              வாட்ஸ்ஆப் எண் <span className="float-end">:</span>
            </div>
            <div className="col-3 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              முகவரி <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />
            </div>
            <div className="col-3 qus my-1" style={style.qus}>
              சொத்து விபரம் <span className="float-end">:</span>
            </div>
            <div className="col-9 ans p-0 my-1" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>
            <div className="col-2 qus my-1 fw-bold" style={{ fontSize: "13px" }}>
              பொருந்தும் நட்சத்திரம் <span className="float-end">:</span>
            </div>
            <div className="col-10 ans p-0 my-1 text-break" style={style.ans}>
            <img src={BlurImage} alt="Logo"   style={{ height: "15px", width: "50%" }} />            </div>

            <div className="col-12">
              <div className="text-center my-1">
                <span className="fw-bold" style={style.borderBottom}>ஜாதக கட்டம்</span>
              </div>
            </div>
            <div className="col-6 pb-2 my-1">
              <table
                className=""
                cellSpacing="2"
                cellPadding="2"
                style={style.birthTable}
              >
                <tbody>
                  <tr>
                    <td className="" style={style.rasiBirth}>
                    <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
               
                    </td>
                  </tr>
                  <tr>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td
                      className=" chart-birth"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                      style={style.rasiBirth}
                    >
                      <p className="rasi-chart" style={style.rasiChart}>
                       
                        ராசி
                      </p>
                    </td>
                    <td className="" style={style.rasiBirth}>
                    <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                  </tr>
                  <tr>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" valign="top" style={style.rasiBirth}> <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                     
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" style={style.rasiBirth} ><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /> </td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-6 pb-2 my-1">
              <table
                className=""
                cellSpacing="2"
                cellPadding="2"
                style={style.birthTable}
              >
                <tbody>
                  <tr>
                    <td className="" style={style.rasiBirth}>
                    <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /> </td>
                    <td className="" style={style.rasiBirth}>
                    <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                  </tr>
                  <tr>
                    <td className="" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className=" chart-birth" width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                      style={style.rasiBirth}
                    >
                      <p className="rasi-chart" style={style.rasiChart}>
                       நவாம்சம்
                      </p>
                    </td>
                    <td className="" style={style.rasiBirth}>
                    <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                  </tr>
                  <tr>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                     
                    </td>
                  </tr>
                  <tr>
                    <td
                      className=""
                      valign="top"
                      style={style.rasiBirth}
                    >
                    <img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} /></td>
                    <td className="" valign="top" style={style.rasiBirth}><img src={BlurImage} alt="Logo" style={{ height: "60px", width: "60px" }} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="container-fluid m-0 p-0 text-center my-1"
            style={{ ...style.qus, ...style.borderTop }}
          >
            <span className="">
              இந்த ஜாதகம் www.onepagehoroscope.com இணைய தளத்தில்
              உருவாக்கப்பட்டது...
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
