import React, { useState } from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getepayPortal from "../Getepay_pg_react";

export default function HoroscopeBookView() {
  const Navigate = useNavigate();
  const value = JSON.parse(localStorage.getItem("HoroscopeBookVal"));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [Address, setAdress] = useState("");
  const [District, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [NameError, setNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");
  const [StreetError, setStreetError] = useState("");
  const [AddressEror, setAddressError] = useState("");
  const [DistrictError, setDistrictError] = useState("");
  const [PinCodeError, setPincodeError] = useState("");

  const ApproveValidation = () => {
    let isValid = true;

    if (!name) {
      setNameError("Name is Required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Email
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid Email Address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Mobile Number
    if (!/^\d{10}$/.test(mobile_number)) {
      setMobileNumberError("Invalid Mobile Number");
      isValid = false;
    } else {
      setMobileNumberError("");
    }

    // Street
    if (!streetName) {
      setStreetError("Street Name is Required");
      isValid = false;
    } else {
      setStreetError("");
    }

    // Address
    if (!Address) {
      setAddressError("Address is Required");
      isValid = false;
    } else {
      setAddressError("");
    }

    // District
    if (!District) {
      setDistrictError("District is Required");
      isValid = false;
    } else {
      setDistrictError("");
    }

    if (!/^\d{6}$/.test(pinCode)) {
      setPincodeError("Invalid Pin Code");
      isValid = false;
    } else {
      setPincodeError("");
    }

    return isValid;
  };

  const PlaceOrderButton = () => {
    const Order = JSON.parse(localStorage.getItem("Bookformdata"));
    // console.log(Order);
    if (ApproveValidation() === true) {
        const userData = {
            buyerName: Order.username,
            buyerEmail: email,
            buyerMobileNo: mobile_number,
            DateofBirth: value.personal_info.birth_date,
            birthTime: value.personal_info.birth_time,
            gender: value.personal_info.gender,
            birthPlace: value.personal_info.birth_place,
            Street_name: streetName,
            Address: Address,
            District: District,
            pincode: pinCode,
            father_name: Order.FatherName,
            mother_name: Order.MotherName,
            Amount: 454,
            
        };
        console.log('res',userData);
        const FindUser = JSON.parse(localStorage.getItem("BookuserInfo"));
       const user_data_id=FindUser.user_id;
       const customer_data_id=FindUser.customerDataId;
        if((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)){
            proceedToPayment();
        }else{
            axios.post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Horoscope-Book-Order",
                userData
            ).then((response) => {
                const BookuserInfo = {
                    user_id: response.data.data.userId,
                    customerDataId: response.data.data.customerDataId
                };
                localStorage.setItem('BookuserInfo', JSON.stringify(BookuserInfo));
                proceedToPayment();
            }).catch((error) => {
                console.error("Error placing order:", error);
            });
            
        }
   
    }
};
  const proceedToPayment = () => {
    const storedUserInfo = localStorage.getItem("BookuserInfo");
    const userInfo = JSON.parse(storedUserInfo);
    const user_id = userInfo.user_id;
    const customerDataId = userInfo.customerDataId;
    const data = {
      mid: "993377",
      amount: "454.00",
      merchantTransactionId: "sd12121",
      transactionDate: "Mon Oct 03 13:54:33 IST 2022",
      terminalId: "getepay.merchant131068@icici",
      udf1: mobile_number,
      udf2: email,
      udf3: name,
      udf4: user_id,
      udf5: customerDataId,
      udf6: "",
      udf7: "",
      udf8: "",
      udf9: "",
      udf10: "",
      ru: "https://ctsvsolutions.com/onepage-Api/public/Horoscope-payment",
      callbackUrl: "",
      currency: "INR",
      paymentMode: "ALL",
      bankId: "",
      txnType: "single",
      productType: "IPG",
      txnNote: "Horoscope Book-Order",
      vpa: "getepay.merchant131068@icici",
    };

    const Config = {
      mid: "993377",
      "Getepay Terminal Id": "getepay.merchant131068@icici",
      "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
      "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
      "Getepay Url":
        "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
    };
    // console.log(data);
    getepayPortal(data, Config);
  };
  const Edit = () => {
    Navigate("/Tamil-Jathagam-Book");
  };
  return (
    <div>
      <Menubar></Menubar>
      <div>
        <div style={{ marginTop: "160px" }}>
          <div className="container">
            <div className="row justify-content-between">
              <h1 className="text-danger fs-5 text-center fw-bold mb-4 tableview">
                பிறந்த தேதி பஞ்சாங்கம்
              </h1>
              <div className="col-lg-6">
                <table className=" table-bordered text-dark w-100" id="panjag">
                  <tbody>
                    <tr>
                      <th colSpan="2" className="text-center">
                        பஞ்சாங்க விபரம்.
                      </th>
                    </tr>
                    <tr style={{ backgroundColor: "#E1F5FE" }}>
                      <td className="fw-bold"> பெயர் </td>
                      <td>{value.personal_info.name}</td>
                    </tr>

                    <tr>
                      <td className="fw-bold"> பிறந்த தேதி </td>
                      <td>{value.personal_info.birth_date}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> பிறந்த நேரம் </td>
                      <td>{value.personal_info.birth_time}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> பிறந்த ஊர் </td>
                      <td>{value.personal_info.birth_place}</td>
                    </tr>
                    <tr>
                      <td>தமிழ் தேதி</td>
                      <td>{value.personal_info.birth_day}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> லக்கனம்</td>
                      <td>{value.personal_info.laknam}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> ராசி</td>
                      <td>{value.personal_info.rasi}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> நட்சத்திரம் </td>
                      <td>{value.personal_info.natchathiram}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-6">
                <table className=" table-bordered text-dark w-100" id="panjag">
                  <tbody>
                    <tr>
                      <th colSpan="2" className="text-center">
                        பஞ்சாங்க விபரம்.
                      </th>
                    </tr>
                    <tr>
                      <td className="fw-bold"> திதி </td>
                      <td>{value.personal_info.thithi}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> யோகம் </td>
                      <td>{value.personal_info.yogam}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> கரணம் </td>
                      <td>{value.personal_info.karanam}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> அயனாம்சம் </td>
                      <td>லஹரி</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> யோக நட்சத்திரம் </td>
                      <td>{value.personal_info.yoga_natchathiram}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> யோகி </td>
                      <td>{value.personal_info.yogi}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold"> அவயோக நட்சத்திரம் </td>
                      <td>{value.personal_info.avayogi_natchathiram}</td>
                    </tr>
                    <tr>
                      <td className="fw-bold">அவயோகி</td>
                      <td>{value.personal_info.avayogi}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* model */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Order Confirmation
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container py-3 rounded shadow-lg" id="package">
                  <div className="">
                    <div className="row">
                      <div className="col-sm-7 col-12 order-2 order-sm-1 ">
                        <div className="my-3">
                          <tablle width={'100%'} className="table table-borderless" >
                            <tr>
                              <td className="w-50 p-0">
                                <h6 className="text-primary fw-bold">
                                  price<span className="float-end">:</span>
                                </h6>
                              </td>
                              <td className="w-50 p-0 text-danger fw-bold" style={{ verticalAlign: 'middle' }}>300</td>
                            </tr>
                            <tr>
                              <td className="p-0">
                                <h6 className="text-primary fw-bold">
                                  GST 18%<span className="float-end">:</span>
                                </h6>
                              </td>
                              <td className="p-0 text-danger fw-bold" style={{ verticalAlign: 'middle' }}>54</td>
                            </tr>
                            <tr>
                              <td className="p-0">
                                <h6 className="text-primary fw-bold">                                 
                                Courier
                                  <span className="float-end">:</span>
                                </h6>
                              </td>
                              <td className="p-0 text-danger fw-bold" style={{ verticalAlign: 'middle' }}>80</td>
                            </tr>
                            <tr>
                              <td className="p-0">
                                <h6 className="text-primary fw-bold">                                 
                                packing
                                  <span className="float-end">:</span>
                                </h6>
                              </td>
                              <td className="p-0 text-danger fw-bold" style={{ verticalAlign: 'middle' }}>20</td>
                            </tr>
                         {/* <hr className=""></hr> */}
                            <tr>
                              <td className="p-0 ">
                                <h6 className="text-primary fw-bold">                                 
                                Total price
                                  <span className="float-end">:</span>
                                </h6>
                              </td>
                              <td className="p-0 text-danger fw-bold" style={{ verticalAlign: 'middle' }}>454</td>
                            </tr>
                          </tablle>
                        </div>
                        <h6 className="text-info fw-bold">
                          Address Confirmation:
                          <span className="text-danger fw-bold"></span>
                        </h6>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            ></input>
                            {NameError && (
                              <span className="text-danger">{NameError}</span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">
                              Email Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            ></input>
                            {EmailError && (
                              <span className="text-danger">{EmailError}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={mobile_number}
                              onChange={(e) => {
                                setMobileNumber(e.target.value);
                              }}
                            ></input>
                            {MobileNumberError && (
                              <span className="text-danger">
                                {MobileNumberError}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">
                              Street Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={streetName}
                              onChange={(e) => {
                                setStreetName(e.target.value);
                              }}
                            ></input>
                            {StreetError && (
                              <span className="text-danger">{StreetError}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">Address</label>
                            <textarea
                              className="form-control"
                              placeholder="Address"
                              id="floatingTextarea"
                              value={Address}
                              onChange={(e) => {
                                setAdress(e.target.value);
                              }}
                            ></textarea>
                            {AddressEror && (
                              <span className="text-danger">{AddressEror}</span>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">
                              District
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={District}
                              onChange={(e) => {
                                setDistrict(e.target.value);
                              }}
                            ></input>
                            {DistrictError && (
                              <span className="text-danger">
                                {DistrictError}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="text-dark fw-bold">
                              Pin Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={pinCode}
                              onChange={(e) => {
                                setPinCode(e.target.value);
                              }}
                            ></input>
                            {PinCodeError && (
                              <span className="text-danger">
                                {PinCodeError}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 my-4">
                            <button
                              type="button"
                              onClick={PlaceOrderButton}
                              className="btn btn-success"
                            >
                              Place Order
                            </button>
                          </div>
                          {/* <div className="col-12">
                            <h2 className="text-primary">Pay Before Check</h2>
                            <p>
                              Name:
                              <span className="text-info  text-break">
                                {" "}
                                {name}
                              </span>
                            </p>
                            <p>
                              Street Name:{" "}
                              <span className="text-info text-break">
                                {" "}
                                {streetName}
                              </span>
                            </p>
                            <p>
                              Address:
                              <span className="text-info text-break">
                                {" "}
                                {Address}
                              </span>
                            </p>
                            <p>
                              District:
                              <span className="text-info text-break">
                                {" "}
                                {District}
                              </span>
                            </p>
                            <p>
                              Pin code:
                              <span className="text-info text-break">
                                {" "}
                                {pinCode}
                              </span>
                            </p>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-sm-5 col-12 order-1 order-sm-2">
                        <div className="card p-0 border-0">
                          <div className="card-body p-0">
                            <h1 className="text-info fw-bold ">
                              <span className="border-bottom-danger">
                                Sample Book
                              </span> 
                            </h1>
                            <div style={{ height: "250px" }}>
                            <iframe width="100%" height="250" src="https://www.youtube.com/embed/wRJCL4OtGZU?si=cym5LBW_KHqrf7Jn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            {/* <iframe width="100%" height="250" src="https://www.youtube.com/embed/7ARL-uGrcsw?si=PjcY-Yr9NdXjPc5x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                              {/* <iframe
                                width="100%"
                                height="250"
                                src="https://www.youtube.com/embed/7ARL-uGrcsw?si=lfdp5zdTDaMUDRwu&autoplay=1"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="text-info">
                You will receive the book within 7 working days.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="floating-button w-100">
          <div className="container bg-white p-3 rounded shadow-lg w-100">
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={Edit}
              >
                ✎ Edit
              </button>

              <button
                className="btn btn-success"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <i className="fa-solid fa-download"></i> Order Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
