import React from "react";
import { useState ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";


export default function HoroscopeBookForm() {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        // console.log(location.pathname);
        if (location.pathname === '/Tamil-jathagam-book') {

            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [location]);
    const closeModal = () => {
        setShowModal(false);
    };
    const [dayOptions] = useState(
        [...Array(31).keys()].map((day) => String(day + 1).padStart(2, "0"))
      );
      const [monthOptions] = useState(
        [...Array(12).keys()].map((month) => String(month + 1).padStart(2, "0"))
      );
      const [yearOptions] = useState(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 1950;
        return [...Array(currentYear - startYear + 1).keys()].map(
          (year) => startYear + year
        );
      });
      const [hourOptions] = useState(
        [...Array(12).keys()].map((hour) => String(hour + 1).padStart(2, "0"))
      );
      const [minuteOptions] = useState(
        [...Array(60).keys()].map((minute) => String(minute).padStart(2, "0"))
      );
      const [birthPlace, setBirthPlace] = useState("");
      const [userName, setUserName] = useState("");
      const [userGender, setUserGender] = useState("");
      const [suggestedPlaces, setSuggestedPlaces] = useState([]);
      const [selectedPlaces, setSelectedPlaces] = useState([]);
      const [selectedDay, setSelectedDay] = useState("");
      const [selectedMonth, setSelectedMonth] = useState("");
      const [selectedYear, setSelectedYear] = useState("");
      const [selectedHour, setSelectedHour] = useState("");
      const [selectedMinute, setSelectedMinute] = useState("");
      const [selectedAmpm, setSelectedAmpm] = useState("");
      const [nameError, setNameError] = useState("");
      const [genderError, setGenderError] = useState("");
      const [birthplaceError, setBirthplaceError] = useState("");
      const [birthdateError, setBirthdateError] = useState("");
      const [birthHourError, setBirthHourError] = useState("");
      const [birthMinuteError, setBirthMinuteError] = useState("");
      const [birthAmpmError, setBirthAmpmError] = useState("");
      const [FatherName,setFatherName]=useState('');
      const[MotherName,setMotherName]=useState('');
      const [FatherNameError,setFatherNameError]=useState('');
      const [MotherNameError,setMotherNameError]=useState('');
         
      const [formdata, setformdata] = useState({
        username: "",
        gender: "",
        userDateofBirth: "",
        userBirthtime: "",
        userBirthplace: "",
        latitude: "",
        longitude: "",
      });
    
      const handleLocationChange = (event) => {
        const currentValue = event.target.value;
        setBirthPlace(currentValue);
        fetchSuggestions(currentValue);
      };
    
      const handleSuggestionSelect = (selectedPlace) => {
        // const { geometry, properties } = selectedPlace;
        const latitude = selectedPlace.center[1];
        const longitude = selectedPlace.center[0];
    
        const parts = selectedPlace.place_name.split(",");
        const displayName = parts.slice(0, 3).join(",");
        const location = displayName;
    
        const updatedSelectedPlaces = [...selectedPlaces, location];
    
        setSelectedPlaces(updatedSelectedPlaces);
        setBirthPlace(location);
        setBirthplaceError("");
    
        setSuggestedPlaces([]);
    
        document.getElementById("atflg").value = longitude;
        document.getElementById("atflt").value = latitude;
      };
    
      const fetchSuggestions = async (currentValue) => {
        if (currentValue.length >= 3) {
          const apiKey =
            "pk.eyJ1IjoiY3RzdnNvbHV0aW9ucyIsImEiOiJjbG1yZzYzcWYwODF5Mm1tbWxjMGF1NjRpIn0.w9205pfsM7t-DYvDyCgR5w";
          const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${currentValue}.json?access_token=${apiKey}&limit=10`;
    
          try {
            const response = await fetch(url);
            console.log(response);
            if (response.ok) {
              const data = await response.json();
              setSuggestedPlaces(data.features);
            } else {
              console.error("Error fetching suggestions from the API");
              setSuggestedPlaces([]);
            }
          } catch (error) {
            console.error("Error fetching suggestions from the API: ", error);
            setSuggestedPlaces([]);
          }
        }
      };
    
      const navigate = useNavigate();
    
      const customRedirect = (route, data) => {
        navigate(route, { state: data });
      };
    
      const validation = () => {
        let IsValid = true;
        if (!userName) {
          setNameError("Field is required");
          IsValid = false;
        } else {
          setNameError("");
        }
    
        if (!userGender) {
          setGenderError("Field is required");
          IsValid = false;
        } else {
          setGenderError("");
        }
    
        if (!selectedDay || !selectedMonth || !selectedYear) {
          setBirthdateError("Field is required");
          IsValid = false;
        } else {
          setBirthdateError("");
        }
    
        if (!selectedHour) {
          setBirthHourError("Field is required");
          IsValid = false;
        } else {
          setBirthHourError("");
        }
    
        if (!selectedMinute) {
          setBirthMinuteError("Field is required");
          IsValid = false;
        } else {
          setBirthMinuteError("");
        }
    
        if (!selectedAmpm) {
          setBirthAmpmError("Field is required");
          IsValid = false;
        } else {
          setBirthAmpmError("");
        }
    
        if (!birthPlace) {
          setBirthplaceError("Field is required");
          IsValid = false;
        } else {
          setBirthplaceError("");
        }
        let LatitudeError = document.getElementById("atflt").value;
    
        if (!LatitudeError) {
          setBirthplaceError("Again Select the BirthPlace");
          IsValid = false;
        } else {
          setBirthplaceError("");
        }
        
        if (!FatherName) {
            setFatherNameError("Father Name is Required");
            IsValid = false;
          } else {
            setFatherNameError("");
          }
          if (!MotherName) {
            setMotherNameError("Mother Name is Required");
            IsValid = false;
          } else {
            setMotherNameError("");
          }
        return IsValid;
      };
   const HoroscopeFormSubmit=(e)=>{
    e.preventDefault();
    if (validation() === true) {
      const formdata = {
        username: userName,
        gender: userGender,
        userDateofBirth: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        userBirthtime: `${selectedHour}:${selectedMinute} ${selectedAmpm}`,
        userBirthplace: birthPlace,
        FatherName:FatherName,
        MotherName:MotherName,
        latitude: document.getElementById("atflt").value,
        longitude: document.getElementById("atflg").value,
      };
// console.log('1',formdata);
      Loading.dots("Your Horoscope is processing...");

      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/Horoscope-Book-Random",
          formdata
        )
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
                formdata
              )
              .then((response) => {
                if (response.status === 200) {
                  Loading.remove();
                  customRedirect("/Tamil-Jathagam-Confirm-Details", response.data);
                  const value = JSON.stringify(response.data.data);
                  localStorage.setItem("HoroscopeBookVal", value);
                  const formdatavalue = JSON.stringify(formdata);
                  localStorage.setItem("Bookformdata", formdatavalue);
                  const userInfo = {
                    user_id: null,
                    customerDataId: null,
                  };
                  const UserInfoValues = JSON.stringify(userInfo);
                  localStorage.setItem("BookuserInfo", UserInfoValues);
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
      }
  useEffect(() => {
    const storedValue = localStorage.getItem("Bookformdata");
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      const dateofBirth = parsedValue.userDateofBirth;
      const [year, month, day] = dateofBirth.split("-");

      const Birthtime = parsedValue.userBirthtime;
      const timeComponents = Birthtime.split(" ");
      const time = timeComponents[0];
      const ampm = timeComponents[1];
      const [hour, minute] = time.split(":");

      setUserName(parsedValue.username);
      setUserGender(parsedValue.gender);
      setSelectedPlaces(parsedValue.userBirthplace);
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedAmpm(ampm);
      setFatherName(parsedValue.FatherName)
      setMotherName(parsedValue.MotherName)
      setBirthPlace(parsedValue.userBirthplace);
      document.getElementById("atflg").value = parsedValue.longitude;
      document.getElementById("atflt").value = parsedValue.latitude;
    }
  }, []);
  return (
    
    <div className="" style={{ backgroundColor: '#f8f9fa', padding: '20px',marginTop:'120px' }}>

      <div className="container">
{showModal && (
    <>
        <div className="modal show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <div className="modal-header" style={{ backgroundColor: '#85B8EF', color: '#040101', borderBottom: 'none' }}>
                        <h5 className="modal-title fw-bold p-2"  id="exampleModalLabel" style={{ backgroundColor: '#85B8EF', color: '#040101', borderBottom: 'none' }}>ஜாதக புத்தகம்</h5>
                        <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                    </div>
                    <div className="modal-body" style={{ color: '#333', fontSize: '16px' }}>
                        <div className="text-center">
                            <img src={require('../header/img/one_page_logo.webp')} width={200} height={200} alt="logo"></img>
                        </div>
                        <p className="fw-bold"> <i class="fa-solid fa-star" style={{color:'#DDB524'}}></i> &nbsp;எதிர் காலம் குறித்து ஜோதிடரிடம் ஜாதகம் பார்ப்பதற்கு உங்களுடைய ஜாதக புத்தகம்  உங்கள் இல்லம் தேடி வந்து சேரும். </p>
        <p className="fw-bold"> <i class="fa-solid fa-star" style={{color:'#DDB524'}}></i>&nbsp; பல்வேறு ஜோதிடர்கள் மூலம் மேம்படுத்த பட்ட onepagehoroscope.com இன் துல்லியமான கணிப்புடன் பெற்று கொள்ளுங்கள்.</p>
        <p className="fw-bold">  <i class="fa-solid fa-star" style={{color:'#DDB524'}}></i> &nbsp; நீங்கள் நிரப்பும் தகவல்களை ஒரு முறைக்கு இருமுறை சரி பார்த்து நிரப்பி புத்தகத்தை பெற்று கொள்ளுங்கள்</p>
        <p className="fw-bold">  <i class="fa-solid fa-star" style={{color:'#DDB524'}}></i>&nbsp; 54 பக்கம் கொண்ட ஜாதக புத்தகம் </p>
        <p className="fw-bold text-primary "><span className="border-bottom-primary border-2">சிறப்பம்சங்கள்</span>  </p> 
        <div className="" style={{textIndent:'0.1cm'}}>
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp; நிராயன ஸ்புடங்கள் </p> 
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp; ஷோடஷ வர்க்காதிபர்கள் </p> 
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp;16 வர்க்க சக்கரங்கள்  </p> 
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp; 9 அஷ்ட வர்கம்  </p> 
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp; உடுமாக திசை -120 வருடங்கள்  </p> 
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp; அந்தரம் -30 வருடங்கள் </p> 
        <p className="fw-bold">  <i class="fa-solid fa-hand-point-right text-danger" ></i>&nbsp; நியூமராலஜி </p> 
        </div> 
      </div>
      <div className="modal-footer" style={{ backgroundColor: '#f9f9f9', borderTop: '1px solid #ccc', padding: '10px 15px', textAlign: 'right' }}>
      <div class="d-grid gap-2 col-6 mx-auto">
  <button type="button" className="btn btn-success p-2" onClick={() => setShowModal(false)}>ஜாதகம் பார்க்க </button>
</div>
       
      </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>
)}


        <div className="row justify-content-center">
        <h1 className="cate-title fs-3">Tamil Jathagam Book - தமிழ் ஜாதகம் புத்தகம்</h1>

          <div className="col-md-8  col-12 border py-5 rounded-3 mt-5" style={{backgroundColor:'#D8D7D7'}}>
            <form id="#">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="name" className={`form-label fw-bold fs-6 ${
                        nameError && "is-invalid"
                      }`} style={{color:'#102156'}}>
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="பெயர்"
                    id="Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="form-control"
                  />
                  {nameError && (
                      <div className="invalid-feedback fw-bold">
                        {nameError}
                      </div>
                    )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="gender" className="form-label fw-bold fs-6"  style={{color:'#102156'}}>
                    Gender
                  </label>
                  <select id="gender" className={`form-select ${
                        genderError && "is-invalid"
                      }`}   
                       value={userGender}
                      onChange={(e) => setUserGender(e.target.value)}>
                    <option value="">பாலினம்</option>
                      <option value="Male">Male - ஆண்</option>
                      <option value="Female">Female - பெண்</option>
                  </select>
                  {genderError && (
                      <div className="invalid-feedback">{genderError}</div>
                    )}
                </div>
              </div>
              <div className="row mb-3">
              <label htmlFor="" className="form-label fw-bold fs-6 " style={{color:'#102156'}}> Date of Birth
                  </label>
                <div className="col-md-4">
                  <label htmlFor="day" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Day
                  </label>
                  <select id="day" className={`form-select  ${
                        birthdateError && "is-invalid"
                      }`}  value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}>
                         <option value="">தேதி</option>
                      {dayOptions.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                  </select>
                  {birthdateError && (
                      <div className="invalid-feedback ">{birthdateError}</div>
                    )}
                </div>
                <div className="col-md-4">
                  <label htmlFor="month" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Month
                  </label>
                  <select id="month" className={`form-select  ${
                        birthdateError && "is-invalid"
                      }`}  value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}>
                     <option value="">மாதம்</option>
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                  </select>
                  {birthdateError && (
                      <div className="invalid-feedback ">{birthdateError}</div>
                    )}
                </div>
                <div className="col-md-4">
                  <label htmlFor="year" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Year
                  </label>
                  <select id="year" className={`form-select  ${
                        birthdateError && "is-invalid"
                      }`} value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}>
                         <option value="">வருடம்</option>
                    {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                  </select>
                  {birthdateError && (
                      <div className="invalid-feedback ">{birthdateError}</div>
                    )}
                </div>
              </div>
              <div className="row mb-3">
              <label htmlFor="hour" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Birth Time
                  </label>
                <div className="col-md-4">
                  <label htmlFor="hour" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Hour
                  </label>
                  <select id="hour" className={`form-select ${
                        birthHourError && "is-invalid"
                      }`} style={{color:'#102156'}}
                    value={selectedHour}
                    onChange={(e) => setSelectedHour(e.target.value)}>
                        <option value="">நேரம்</option>
                  {hourOptions.map((hour) => (
                    
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                  </select>
                  {birthHourError && (
                      <div className="invalid-feedback  fw-bold">
                        {birthHourError}
                      </div>
                    )}
                </div>
                <div className="col-md-4">
                  <label htmlFor="minute" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Minute
                  </label> 
                  <select id="minute" className={`form-select  ${
                        birthMinuteError && "is-invalid"
                      }`}  value={selectedMinute}
                      onChange={(e) => setSelectedMinute(e.target.value)}>
                     <option value="">நிமிடம்</option>
                      {minuteOptions.map((minute) => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                  </select>
                  {birthMinuteError && (
                      <div className="invalid-feedback  fw-bold">
                        {birthMinuteError}
                      </div>
                    )}
                </div>
                <div className="col-md-4">
                  <label htmlFor="Ampm" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    AM/PM
                  </label>
                  <select id="Ampm" className={`form-select ${
                        birthAmpmError && "is-invalid"
                      }`} value={selectedAmpm}
                      onChange={(e) => setSelectedAmpm(e.target.value)}>
                     <option value="">காலம்</option>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                  </select>
                  {birthAmpmError && (
                      <div className="invalid-feedback  ">{birthAmpmError}</div>
                    )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="name" className={`form-label fw-bold fs-6 ${
                        FatherNameError && "is-invalid"
                      }`} style={{color:'#102156'}}>
                  Father Name
                  </label>
                  <input
                    type="text"
                    placeholder="தந்தை பெயர்"
                    id="Name"
                    value={FatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    className="form-control"
                  />
                  {FatherNameError && (
                      <div className="invalid-feedback fw-bold">
                        {FatherNameError}
                      </div>
                    )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="name" className={`form-label fw-bold fs-6 ${
                        MotherNameError && "is-invalid"
                      }`} style={{color:'#102156'}}>
                  Mother Name
                  </label>
                  <input
                    type="text"
                    placeholder="தாய் பெயர்"
                    id="Name"
                    value={MotherName}
                    onChange={(e) => setMotherName(e.target.value)}
                    className="form-control"
                  />
                  {MotherNameError && (
                      <div className="invalid-feedback fw-bold">
                        {MotherNameError}
                      </div>
                    )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <label htmlFor="Birth_place" className="form-label fw-bold fs-6" style={{color:'#102156'}}>
                    Birth Place
                  </label>
                  
                  <input
                    type="text"
                    className={`form-control ${
                        birthplaceError && "is-invalid"
                      }`}
                    id="Birth_place"
                    placeholder="பிறந்த இடத்தை தேர்வு செய்யவும்"
                    onChange={handleLocationChange}
                    value={birthPlace}
                  />
                   {birthplaceError && (
                  <div className="invalid-feedback  ">{birthplaceError}</div>
                )}


                   <input
                  id="atflt"
                  className="pfipbx"
                  name="the_lat"
                  type="hidden"
                  placeholder="Latitude"
                />
                <input
                  id="atflg"
                  className="pfipbx"
                  name="the_lng"
                  type="hidden"
                  placeholder="Longitude"
                />
                <ul>
                  {suggestedPlaces.map((place, index) => {
                    if (place.place_name) {
                      const listItemStyle = {
                        padding: "5px 10px",
                        cursor: "pointer",
                        fontSize: "16px",
                        borderBottom: "1px dashed #ddd",
                      };

                      // Split the place_name and get the first three parts
                      const parts = place.place_name.split(",");
                      const displayName = parts.slice(0, 3).join(",");

                      return (
                        <p
                          style={listItemStyle}
                          key={index}
                          onClick={() => handleSuggestionSelect(place)}
                        >
                          {displayName}
                        </p>
                      );
                    }
                    return null; // Handle the case where place.place_name is falsy
                  })}
                </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mx-auto mt-3">
                  <button
                    className=" w-100"
                    type="button"
                    style={{ backgroundColor: '#270E49', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer' }}
                    onClick={HoroscopeFormSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
