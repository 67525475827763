import axios from "axios";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import OnepagePaidTemplate from "../../tamil/onepagePaidTemplate/onepagePaidtemplate";
import MgPaidTemplate from "../../tamil/Mg-Paid-Template/MgPaidTemplate";
import BabyNameTemplate from "../../tamil/BabyNameTemplate/BabyNameTemplate";

// Function to convert data to PDF
export const OnePagePaidPdfConvertion = (data) => {
  // console.log('data is',data);
  Loading.hourglass("Pdf is generating.....");

  // Extracting date components
  const dateComponents = data.birth_date.split("-");
  const day = dateComponents[0];
  const month = dateComponents[1];
  const year = dateComponents[2];
  const formattedDate = `${year}-${month}-${day}`;

  // Creating form data
  const formdata = {
    username: data.name,
    gender: data.gender,
    userDateofBirth: formattedDate,
    userBirthtime: data.birth_time,
    userBirthplace: data.birth_place,
    latitude: data.latitude,
    longitude: data.longitude,
  };

  // Making API call to get data
  axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope", formdata)
    .then((response) => {
      // Function to generate PDF using the received data
      const paidPdf = () => {
        return <OnepagePaidTemplate value={response.data.data} />;
      };

      const printElement = ReactDOMServer.renderToString(paidPdf());

      // Configuring PDF options
      html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .outputPdf()
        .then((pdfData) => {
          // Converting PDF data to base64 and saving the PDF
          const base64Data = btoa(pdfData);
          saveOnepagePaidPdf(base64Data, data.user_id_fk, data.data_id);
        });
    });
};

// Function to save the generated PDF
const saveOnepagePaidPdf = (pdfData, user_id, customerDataId) => {
  const pdfDataVal = {
    pdfData: pdfData,
    userId: user_id,
    customerId: customerDataId,
    FileName: "one_page",
  };

  const DataInfo = {
    userId: user_id,
    customerId: customerDataId,
    columnName: "pdf_file",
  };

  // Making API call to save the PDF
  axios.post("https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
    .then((response) => {
      Notify.success("Pdf saved Successfully....!!!");

      // Sending the saved PDF to WhatsApp
      axios.post("https://www.ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp", DataInfo)
        .then((response) => {
          Notify.success("Pdf sent to WhatsApp....!!!");

          // Sending the saved PDF via Email
          axios.post("https://www.ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email", DataInfo)
            .then((response) => {
              Loading.remove();
              Notify.success("PDF file sent successfully");
            })
            .catch((error) => {
              console.error("Error sending PDF via Email:", error);
            });
        })
        .catch((error) => {
          console.error("Error sending PDF to WhatsApp:", error);
        });
    })
    .catch((error) => {
      console.error("Error saving PDF:", error);
    });
};


// marriage matching
export const MarriageMatchingPdfConverTion = (data) => {

    Loading.hourglass("Pdf is generating.....");
    const BirthDate=data.birth_date.split(',');
    const maleBirthDate =BirthDate[0].split("-");
    const FemaleBirthDate = BirthDate[1].split("-");
    const State = data.birth_place.split(",");
    const MaleState = State[0];
    const FemaleState = State[1];
    const Latitude = data.latitude.split(",");
    const Longitude = data.longitude.split(",");
    const BirthTime = data.birth_time.split(",");
    const MaleBirthTime = BirthTime[0];
    const FemaleBirthTime = BirthTime[1];
    const MaleLatitude = Latitude[0];
    const FemaleLatitude = Latitude[1];
    const femaleLongitude = Longitude[0];
    const MaleLogitude = Longitude[1];
    const Name = data.name.split(",");
    const MaleName = Name[0];
    const FemaleName = Name[1];
    const BirthPlace = data.birth_place.split(",");
    const MaleBirthPlace = BirthPlace[0] +','+MaleState;
    const femaleBirthplace = BirthPlace[1] +','+FemaleState;
    const Maleday = maleBirthDate[2];
    const Malemonth = maleBirthDate[1];
    const Maleyear = maleBirthDate[0];
    const Femaleday = FemaleBirthDate[2];
    const FemaleMonth = FemaleBirthDate[1];
    const FemaleYear = FemaleBirthDate[0];
    const femaleDatateofBirth = `${FemaleYear}-${FemaleMonth}-${Femaleday}`;
    const MaleDateofBirth = `${Maleyear}-${Malemonth}-${Maleday}`;

    const marriageMatchingFormData = {
      maleName: MaleName,
      MaleDateofBirth: MaleDateofBirth,
      MaleBirthtime: MaleBirthTime,
      MaleBirthplace: MaleBirthPlace,
      Malelatitude: MaleLatitude,
      Malelongitude: MaleLogitude,
      FemaleName: FemaleName,
      femaleBirthplace: femaleBirthplace,
      feMaleDateofBirth: femaleDatateofBirth,
      FemaleBirthTime: FemaleBirthTime,
      femalelatitude: FemaleLatitude,
      femalelongitude: femaleLongitude,
    };
    // console.log("valur",marriageMatchingFormData);
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Marriage-Matching",
        marriageMatchingFormData
      )
      .then((response) => {
        const paidPdf = () => {
          return <MgPaidTemplate value={response.data.data} />;
        };

        const printElement = ReactDOMServer.renderToString(paidPdf());

        html2pdf().from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            }).outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
            SaveMgpdf(base64Data, data.user_id_fk, data.data_id);
            });
        
      });
  };
  const SaveMgpdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData, 
      userId: user_id,
      customerId: customerDataId,
      FileName:'marriage_matching'
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"pdf_file"
    };
    axios
      .post(" https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            " https://www.ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                " https://www.ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
               
                Loading.remove();
                Notify.success("Sample pdf file sended succussfully");
                
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  export const BabyNamePdf = async (data) => {
    Loading.hourglass("Pdf is generating.....");
    // console.log(data);
  
    const dateComponents = data.birth_date.split("-");
    const selectedDay = dateComponents[0];
    const selectedMonth = dateComponents[1];
    const selectedYear = dateComponents[2];
    const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;
  
    const BabyNameList = {
      BirthMonth: selectedMonth,
      BirthYear: selectedYear,
      BirthDay: selectedDay,
      BirthDate: formattedDate,
      BirthTime: data.birth_time,
      BirthPlace: data.birth_place + "," + data.state,
      initial: data.initial,
      father_name: data.father_name,
      mother_name: data.mother_name,
      gender: data.gender,
      latitude: data.latitude,
      longitude: data.longitude,
    };
  
    try {
      const response = await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-List",
        BabyNameList
      );
  
      console.log("baby name list", response.data.data);
  
      const paidPdf = () => {
        return <BabyNameTemplate value={response.data.data} />;
      };
  
      const printElement = ReactDOMServer.renderToString(paidPdf());
  
      const pdfData = await html2pdf()
        .from(printElement)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2 },
        })
        .outputPdf();
  
      const base64Data = btoa(pdfData);
  
      await SaveBabyNamePdf(base64Data, data.user_id_fk, data.data_id);
    } catch (error) {
      console.error("Error generating PDF:", error);
      Loading.remove();
      Notify.error("Error generating PDF. Please try again.");
    }
  };
  
  const SaveBabyNamePdf = async (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "baby_name", // Update the FileName if needed
    };
  
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
  
    try {
      const saveResponse = await axios.post(
        "https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
        pdfDataVal
      );
  
      await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-pdf-Whastapp",
        DataInfo
      );
  
      await axios.post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-Email",
        DataInfo
      );
  
      Loading.remove();
      Notify.success("Sample PDF file sent successfully");
    } catch (error) {
      console.error("Error saving or sending PDF:", error);
    }
  };
  


