import React, { useEffect, useState ,useRef} from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

const Counter = ({ value=0, title, iconClass, cardColor, iconColor,onClick }) => {
  const counterRef = useRef();

  useEffect(() => {
    $(counterRef.current)
      .prop('Counter', 0)
      .animate(
        {
          Counter: value ?? 0 
        },
        {
          duration: 3500,
          easing: 'swing',
          step: function (now) {
            $(this).text(Math.ceil(now));
          }
        }
      );
  }, [value]);


  const counterStyle = {
    background: cardColor || "#fff",
    fontFamily: "'Noto Sans JP', sans-serif",
    textAlign: "center",
    width: "210px",
    padding: "0 0 25px",
    margin: "0 auto 15px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
    position: "relative",
  };

  const counterValueStyle = {
    color: "#fff",
    background: cardColor || "linear-gradient(to right, #19bbd2, #2778ee)",
    fontSize: "38px",
    fontWeight: "300",
    padding: "0 0 3px",
    margin: "0 0 25px",
    borderRadius: "10px 10px 0 0",
    display: "block",
  };

  const h3Style = {
    color: cardColor || "#2778ee",
    fontSize: "18px",
    fontWeight: "900",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
    margin: "0 0 25px",
  };

  const counterIconStyle = {
    color: iconColor || "#fff",
    background: cardColor || "linear-gradient(to right, #19bbd2, #2778ee)",
    fontSize: "40px",
    lineHeight: "60px",
    width: "65px",
    height: "65px",
    margin: "0 auto",
    borderRadius: "10px",
  };

  return (

    <div className={`col-md-3 col-sm-6 counter`} style={counterStyle}>
       
      <div className="counter"  onClick={onClick}>
        <span className="counter-value" style={counterValueStyle}>
          {value}
        </span>
        <h3 style={h3Style}>{title}</h3>
        <div className="counter-icon" style={counterIconStyle}>
          <i className={`fa ${iconClass}`}></i>
        </div>
      </div>
    </div>
  );
};

const CounterContainer = () => {
  const [ApiResponse,setApiResponse]=useState('');
  useEffect(() => {
    const GetCount = async () => {
      try {
        const Token = localStorage.getItem('authToken');
  
        const ApiUrl = "https://www.ctsvsolutions.com/onepage-Api/public/api/Report-Count";
        const ApiHeader = {
          Authorization: `Bearer ${Token}`,
          Accept: "application/json",
        };
  
        const response = await fetch(ApiUrl, { headers: ApiHeader });
  
        if (!response.ok) {
          throw new Error('Error in API');
        }
  
        const responseData = await response.json();
  
        // Ensure that the response data is an object with the expected properties
        if (
          responseData &&
          responseData.data &&
          responseData.data.onepage !== undefined &&
          responseData.data.marriage_matching !== undefined &&
          responseData.data.biodata !== undefined &&
          responseData.data.baby_name !== undefined
        ) {
          setApiResponse(responseData.data);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error:', error.message);
      } finally {
        // Any cleanup code if needed
      }
    };
  
    GetCount();
  }, []);
  const navigate=useNavigate();
  const redirect = (path) => {
    navigate(path);
  };
  return (
    <div className="container">
      <div className="row">
        <Counter
          value={ApiResponse.onepage ? ApiResponse.onepage: 0} 
          title="One page"
          iconClass="fa-house"
          cardColor="linear-gradient(to right, #8f70e7, #c452ef)"
          iconColor="#fff"
          onClick={() => redirect('/Genarate-Onepage-Horoscope')}
        />
        <Counter
          value={ApiResponse.marriage_matching ?? 0}
          title="Marriage matching"
          iconClass="fa-heart"
          cardColor="linear-gradient(to right, #e84a94, #ae379b)"
          iconColor="#fff"
          onClick={()=>{redirect('/Genarate-Marriage-Matching')}}
        />
        <Counter
            value={ApiResponse.biodata ?ApiResponse.biodata: 0} 
          title="Bio Data"
          iconClass="fa-users"  
          cardColor="linear-gradient(to right, #fecb4b, #e69814)"
          iconColor="#fff"
          onClick={()=>{redirect('/Genarate-Bio-Data')}}
        />
        <Counter
          value={ApiResponse.baby_name ?? 0} 
          title="Baby Name List"
          iconClass="fa-baby"
          cardColor="linear-gradient(to right, #19bbd2, #2778ee)"
          iconColor="#fff"
          onClick={()=>{redirect('/Genarate-Baby-Name-List')}}
        />
      </div>
    </div>
  );
};

export default CounterContainer;
