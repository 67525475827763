import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import BiodataViewPage from "./BiodataViewPage";

export default function BioDataView() {
  return (
    <>
      <div className="">
        <Menubar /> 
        <BiodataViewPage /> 
        <Footer />
      </div>
    </>
  );
}
