import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";
// import Popup from 'react-popup';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import BioTemplate1 from "../BioData-Templates/BioTemplate1";
// import ReactDOMServer from "react-dom/server";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import BioTemplate1Sample from "../BioData-Templates/BioTemplate1sample";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import BioTemplate4 from "../BioData-Templates/BioTemplate4";
import BioTemplate4sample from "../BioData-Templates/BioTemplate4sample";
import BioTemplate5 from "../BioData-Templates/BioTemplate5";
import BioTemplate5sample from "../BioData-Templates/BioTemplate5sample";
import BioTemplate6 from "../BioData-Templates/BioTemplate6";
import BioTemplate2 from "../BioData-Templates/BioTemplate2";
import BioTemplate2sample from "../BioData-Templates/BioTemplate2sample";
import BioTemplate6sample from "../BioData-Templates/BioTemplate6sample";
import BioTemplate3 from "../BioData-Templates/BioTemplate3";
import BioTemplate3sample from "../BioData-Templates/BioTemplate3sample";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import getepayPortal from "../Getepay_pg_react";

import html2canvas from "html2canvas";


export default function BiodataViewPage() {

  const value = JSON.parse(localStorage.getItem("BioValues"));

  return (
    <>
      <Table value={value} />
{/* <BioTemplate1 value={value}/> */}
      <MyCarousel value={value} />
    </>
  );
}

function Table({ value }) {


  const editForm = () => {
    window.location = "/Marriage-Bio-Data";
  };
  const personalInfo = value.personal_info;
  return (
    <>
      <div style={{ marginTop: "170px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <table className="table table-bordered text-dark">
                <thead>
                  <tr>
                    <th
                      style={{ backgroundColor: "#81D4FA" }}
                      colSpan="2"
                      className="text-center"
                    >
                      திருமண சுயவிபரம் தகவல்
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fw-bold"> பெயர் </td>
                    <td>{personalInfo.name}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      பாலினம்{" "}
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {" "}
                      {personalInfo.gender}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">பிறந்த தேதி </td>
                    <td>{personalInfo.birth_date}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      பிறந்த நேரம்
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {personalInfo.birth_time}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">பிறந்த ஊர்:</td>
                    <td className="text-break">{personalInfo.birth_place}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      படிப்பு:
                    </td>
                    <td
                      className="text-break"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      {personalInfo.education}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">ராசி :</td>
                    <td>{personalInfo.rasi}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      நட்சத்திரம் :
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {personalInfo.natchathiram}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">லக்னம் :</td>
                    <td>{personalInfo.laknam} </td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      பணி விவரம்:
                    </td>
                    <td
                      className="text-break"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      {personalInfo.work_details}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">வருமானம்: </td>
                    <td>{personalInfo.income}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      ஜாதி:
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {personalInfo.caste}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">குலம்/கோத்ரம்: </td>
                    <td className="text-break">{personalInfo.kulam}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      உயரம்:
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {personalInfo.height}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">எடை : </td>
                    <td>{personalInfo.weight}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      நிறம்:
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {personalInfo.color}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">தந்தை பெயர்:</td>
                    <td>{personalInfo.father_name}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      தாய் பெயர்:
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {personalInfo.mother_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">தந்தை தொழில்:</td>
                    <td>{personalInfo.father_occupation}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      தாய் தொழில்:
                    </td>
                    <td
                      className="text-break"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      {personalInfo.mother_occupation}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">உடன் பிறந்தவர்கள்:</td>
                    <td className="text-break">
                      {personalInfo.sibling
                        .map((sibling) => sibling.value)
                        .join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      முகவரி:
                    </td>
                    <td
                      className="text-break"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      {personalInfo.user_address}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">தொலைப்பேசி எண்:</td>
                    <td>{personalInfo.mobile_number} </td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#E1F5FE" }}
                    >
                      வாட்ஸ்அப் எண்:
                    </td>
                    <td style={{ backgroundColor: "#E1F5FE" }}>
                      {" "}
                      {personalInfo.whatsapp_number}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Email:</td>
                    <td>{personalInfo.email} </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="fw-bold text-center">
                      <button
                        className="btn btn-success px-5"
                        onClick={editForm}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const MyCarousel = ({ value }) => {
  const personalInfo = value.personal_info;

  const sliderRef = useRef(null);
  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  const [email, setEmail] = useState(personalInfo.email);
  const [buyerMobilenumber, setMobilenumber] = useState(
    personalInfo.whatsapp_number
  );

  const [mobilenumberError, setmobilenumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerName, setBuyername] = useState(personalInfo.name);
  const [buyerNameError, setBuyerNameError] = useState("");
  const [currentTemplate, setTemplate] = useState("");

  const Template = localStorage.getItem("paidTemplate");
  useEffect(() => {
    if (Template) {
      setpaidTemplate(Template);
      console.log("live template is", Template);
    }
  }, [Template]);

  const [paidTemplate, setpaidTemplate] = useState(Template ? Template : "");

  const sampleTemplate = () => {
    if (currentTemplate === "BiosampleTemplate1") {
      return <BioTemplate1Sample value={value} />;
    }
    if (currentTemplate === "BiosampleTemplate2") {
      return <BioTemplate2sample value={value} />;
    }
    if (currentTemplate === "BiosampleTemplate3") {
      return <BioTemplate3sample value={value} />;
    }
    if (currentTemplate === "BiosampleTemplate4") {
      return <BioTemplate4sample value={value} />;
    }
    if (currentTemplate === "BiosampleTemplate5") {
      return <BioTemplate5sample value={value} />;
    }
    if (currentTemplate === "BiosampleTemplate6") {
      return <BioTemplate6sample value={value} />;
    }
  };

  const validatePopupFileld = () => {
    let isValid = true;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!buyerName) {
      setBuyerNameError("Name is required");
      isValid = false;
    } else {
      setBuyerNameError("");
    }
    if (buyerMobilenumber.length < 10) {
      setmobilenumberError("Mobile number must be 10 digits");
      isValid = false;
    } else {
      setmobilenumberError("");
    }
    return isValid;
  };

  const pdfProcess = async () => {
    const printElement = sampleTemplate();
    const container = document.createElement("div");
    document.body.appendChild(container);

    try {
      const canvas = await html2canvas(container, { useCORS: true });
      const imageData = canvas.toDataURL("image/jpeg", 1.0);

      const root = ReactDOM.createRoot(container);
      root.render(printElement);

      await new Promise((resolve) => setTimeout(resolve, 500));

      const storedUserInfo = localStorage.getItem("BioUserInfo");
      const userInfo = JSON.parse(storedUserInfo);

      await html2pdf()
        .from(container)
        .set({
          image: { type: "jpeg", quality: 1, data: imageData },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2, useCORS: true },
        })
        .save();

      Loading.remove();
      Notify.success("Sample pdf file downloaded successfully");
    } catch (error) {
      console.error("Error generating PDF:", error);
      Loading.remove();
      Notify.error("Error generating PDF");
    } finally {
      document.body.removeChild(container);
    }
  };

  const insertsampleData = () => {
    const storedUserInfo = localStorage.getItem("BioUserInfo");
    const userInfo = JSON.parse(storedUserInfo);

    const user_data_id = userInfo.user_id;
    const customer_data_id = userInfo.customerDataId;
    const userData = {
      buyerName: buyerName,
      buyerEmail: email,
      buyerMobileNo: buyerMobilenumber,
      Package: "marriage_bio_data",
      Amount: "249",
      Userid: user_data_id,
      CustomerId: customer_data_id,
      Template: currentTemplate,
    };
    Loading.hourglass("Bio Data is processing...", {
      backgroundColor: "rgba(0,0,0,0.8)",
    });

    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Check-Template", {
        userData,
      })
      .then((result) => {
        let status = result.data.status;
        if (status === true) {
          axios
            .post(
              "https://ctsvsolutions.com/onepage-Api/public/api/Bio-statement-insert",
              { userData }
            )
            .then((response) => {
              GetSamplePdfProcess();
            });
        } else {
          pdfProcess();
        }
      });

    const GetSamplePdfProcess = async () => {
      const printElement = sampleTemplate();
      const container = document.createElement("div");
      document.body.appendChild(container);
      try {
        const canvas = await html2canvas(container, { useCORS: true });
        const imageData = canvas.toDataURL("image/jpeg", 1.0);

        const root = ReactDOM.createRoot(container);
        root.render(printElement);

        await new Promise((resolve) => setTimeout(resolve, 500));
        const storedUserInfo = localStorage.getItem("BioUserInfo");
        const userInfo = JSON.parse(storedUserInfo);
        html2pdf()
          .from(container)
          .set({
            image: { type: "jpeg", quality: 1, data: imageData },
            dpi: 300,
            renderer: "canvas",
            html2canvas: { scale: 2, useCORS: true },
          })
          .save();
        html2pdf()
          .from(container)
          .set({
            image: { type: "jpeg", quality: 1 },
            dpi: 300,
            renderer: "canvas",
            html2canvas: { scale: 2, useCORS: true },
          })
          .outputPdf()
          .then((pdfData) => {
            const base64Data = btoa(pdfData);
            savesamplePdf(
              base64Data,
              userInfo.user_id,
              userInfo.customerDataId
            );
          });
      } catch (error) {
        console.error("Error during PDF conversion:", error);
      } finally {
        document.body.removeChild(container);
      }
    };

    const savesamplePdf = (pdfData, user_id, customerDataId) => {
      const pdfDataVal = {
        pdfData: pdfData,
        userId: user_id,
        customerId: customerDataId,
        FileName: currentTemplate,
      };
      const Whatsappfileinfo = {
        userId: user_id,
        customerId: customerDataId,
        columnName: "sample_pdf",
      };
      axios
        .post(
          "https://ctsvsolutions.com/onepage-Api/public/api/save-sample-pdf",
          pdfDataVal
        )
        .then((response) => {
          axios
            .post(
              "https://ctsvsolutions.com/onepage-Api/public/api/Bio-sample-whatsapp",
              Whatsappfileinfo
            )
            .then((response) => {
              axios
                .post(
                  "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                  Whatsappfileinfo
                )
                .then((response) => {
                  Loading.remove();
                  Notify.success("Sample pdf file sended succussfully");
                  window.location = "/Bio-sample-success";
                })
                .catch((error) => {
                  console.error("Error sending WhatsApp message:", error);
                });
            })
            .catch((error) => {
              console.error("Error sending WhatsApp file:", error);
            });
        })
        .catch((error) => {
          console.error("Error saving PDF:", error);
        });
    };
  };

  const sampleTemplateprint = () => {
    if (validatePopupFileld()) {
      insertsampleData();
    }
  };
  const PaymentPopup = () => {
    if (validatePopupFileld()) {
      insertPaidData();
    }
  };

  const checkTemplate = () => {
    if (paidTemplate === "Template1") {
      return "BiosampleTemplate1";
    } else if (paidTemplate === "Template2") {
      return "BiosampleTemplate2";
    } else if (paidTemplate === "Template3") {
      return "BiosampleTemplate3";
    } else if (paidTemplate === "Template4") {
      return "BiosampleTemplate4";
    } else if (paidTemplate === "Template5") {
      return "BiosampleTemplate5";
    } else {
      return "BiosamleTemplate6";
    }
  };

  const paymentProcess = (user_id, customerDataId) => {
    // const storedUserInfo = localStorage.getItem("BioUserInfo");
    // const userInfo = JSON.parse(storedUserInfo);
    // console.log(userInfo);
    const data = {
      mid: "993377",
      amount: amount(),
      merchantTransactionId: "sd12121",
      transactionDate: "Mon Oct 03 13:54:33 IST 2022",
      terminalId: "getepay.merchant131068@icici",      
      udf1: buyerMobilenumber,
      udf2: checkTemplate(),
      udf3: buyerName,
      udf4: user_id,
      udf5: customerDataId,
      udf6: "",
      udf7: "",
      udf8: "",
      udf9: "",
      udf10: "",
      ru: "https://ctsvsolutions.com/onepage-Api/public/Bio-Payment",
      callbackUrl: "",
      currency: "INR",
      paymentMode: "ALL",
      bankId: "",
      txnType: "single",
      productType: "IPG",
      txnNote: "onepage-BioData",
      vpa: "getepay.merchant131068@icici",
    };

    const Config = {
      mid: "993377",
      "Getepay Terminal Id": "getepay.merchant131068@icici",
      "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
      "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
      "Getepay Url":
        "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice",
    };
    // console.log(data);
    getepayPortal(data, Config);
  };

  const amount = () => {
    if (
      paidTemplate === "Template1" ||
      paidTemplate === "Template4" ||
      paidTemplate === "Template5" ||
      paidTemplate === "Template6"
    ) {
      return 249;
    } else {
      return 249;
    }
  };
  const insertPaidData = () => {
    const storedUserInfo = localStorage.getItem("BioUserInfo");
    const user = JSON.parse(storedUserInfo);

    const user_id = user.user_id;
    const customer_data_id = user.customerDataId;

    const ChoosedTemplate = JSON.stringify(paidTemplate);
    localStorage.setItem("paidTemplate", ChoosedTemplate);

    const userInfo = {
      userId: user_id,
      customerId: customer_data_id,
      Template: checkTemplate(),
    };

    const userData = {
      buyerName: buyerName,
      buyerEmail: email,
      buyerMobileNo: buyerMobilenumber,
      Package: "marriage_bio_data",
      Amount: amount(),
      Userid: user_id,
      CustomerId: customer_data_id,
      Template: checkTemplate(),
    };

    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Check-Template", {
        userData,
      })
      .then((response) => {
        let status = response.data.status;
        if (status === true) {
          axios
            .post(
              "https://ctsvsolutions.com/onepage-Api/public/api/Bio-statement-insert",
              { userData }
            )
            .then((response) => {
              paymentProcess(user_id, customer_data_id);
            });
        } else {
          paymentProcess(user_id, customer_data_id);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];

    if (payment_status === "Filure") {
      Report.warning(
        "Notiflix Warning",
        '"The peoples who want to live comfortably without producing and fatigue; they are doomed to lose their dignity, then liberty, and then independence and destiny." <br/><br/>- Mustafa Kemal Ataturk',
        "Okay"
      );
    }
    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Succuss",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Bio data is processing...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          paidPdfProcess();
        }
      );
    }
  }
  const paidpdfConvertion = () => {
    let trimmedTemplate = paidTemplate.trim().replace(/^"|"$/g, "");
    switch (trimmedTemplate) {
      case "Template1":
        return <BioTemplate1 value={value} />;
      case "Template2":
      
        return <BioTemplate2 value={value} />;
      case "Template3":
        
        return <BioTemplate3 value={value} />;
      case "Template4":
      
        return <BioTemplate4 value={value} />;
      case "Template5":
      
        return <BioTemplate5 value={value} />;
      case "Template6":
        return <BioTemplate6 value={value} />;
      default:
      
        return <BioTemplate1 value={value} />;
    }
  };

  const paidPdfProcess = async () => {
    const printElement = paidpdfConvertion();
    const container = document.createElement("div");
    document.body.appendChild(container);
    try {
      const canvas = await html2canvas(container, { useCORS: true });
      const imageData = canvas.toDataURL("image/jpeg", 1.0);
      const root = ReactDOM.createRoot(container);
      root.render(printElement);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const storedUserInfo = localStorage.getItem("BioUserInfo");
      const userInfo = JSON.parse(storedUserInfo);
      // console.log(userInfo);
      html2pdf()
        .from(container)
        .set({
          image: { type: "jpeg", quality: 1, data: imageData },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2, useCORS: true },
        })
        .save();
      html2pdf()
        .from(container)
        .set({
          image: { type: "jpeg", quality: 1 },
          dpi: 300,
          renderer: "canvas",
          html2canvas: { scale: 2, useCORS: true },
        })
        .outputPdf()
        .then((pdfData) => {
          const base64Data = btoa(pdfData);
          savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
        });
    } catch (error) {
      console.error("Error during PDF conversion:", error);
    } finally {
      document.body.removeChild(container);
    }
  };

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: paidTemplate.trim().replace(/^"|"$/g, ""),
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf",
        pdfDataVal
      )
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experience?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      user_id: user_id,
                      customer_data_id: customerDataId,
                      feedback: clientAnswer,
                    };
                    axios
                      .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        // Use window.location.replace to replace the current history entry
                        window.location.replace("/BioData-ThankYou");
                      });
                  },
                  (clientAnswer) => {
                    // Use window.location.replace to replace the current history entry
                    window.location.replace("/BioData-ThankYou");
                  }
                );
                Notify.success("Sample pdf file sent successfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="text-center fs-5 text-danger shadow fw-bold">
          Choose the Bio Data Design
        </div>
        {/* <div className="text-center my-3">
          <button className="btn btn-primary mx-2" onClick={handlePrev}>
            <GrLinkPrevious />
          </button>

          <button className="btn btn-primary mx-2" onClick={handleNext}>
            <GrLinkNext />
          </button>
        </div> */}
        <Slider className="shadow mt-1" {...carouselSettings} ref={sliderRef}>
          <div className="card card-slider border-0 ">
            <div className="card-img-top">
              <h6 className="text-center mt-2">
                <span class="text-danger">Template-1</span>{" "}
                <span class="badge bg-secondary">1 page</span>
              </h6>

              <img
                src={require("./img/Template1.webp")}
                className="img-thumbnail"
                alt="Onepage-Bio-data-Template1"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) => {
                  setpaidTemplate("Template1");
                }}
              />
            </div>
            <div className="card-footer">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  onClick={(e) => {
                    setTemplate("BiosampleTemplate1");
                  }}
                >
                  sample
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  className="float-end btn btn-success"
                  onClick={(e) => {
                    setpaidTemplate("Template1");
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </div>

          <div className="card card-slider border-0">
            <div className="card-img-top">
              <h6 className="text-center mt-2">
                <span class="text-danger">Premium</span>{" "}
                <span class="badge bg-secondary">2 page</span>
              </h6>
              <img
                src={require("./img/Template2.webp")}
                className="img-thumbnail"
                alt="Onepage-Bio-data-Template2"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) => {
                  setpaidTemplate("Template2");
                }}
              />
            </div>
            <div className="card-footer">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  onClick={(e) => {
                    setTemplate("BiosampleTemplate2");
                  }}
                >
                  sample
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  className="float-end btn btn-success"
                  onClick={(e) => {
                    setpaidTemplate("Template2");
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </div>

          <div className="card card-slider border-0">
            <div className="card-img-top">
              <h6 className="text-center mt-2">
                <span class="text-danger">Premium</span>{" "}
                <span class="badge bg-secondary">2 page</span>
              </h6>
              <img
                src={require("./img/template3.webp")}
                className="img-thumbnail"
                alt="Onepage-Bio-data-Template3"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) => {
                  setpaidTemplate("Template3");
                }}
              />
            </div>
            <div className="card-footer">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  onClick={(e) => {
                    setTemplate("BiosampleTemplate3");
                  }}
                >
                  sample
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  className="float-end btn btn-success"
                  onClick={(e) => {
                    setpaidTemplate("Template3");
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </div>

          <div className="card card-slider border-0">
            <div className="card-img-top">
              <h6 className="text-center mt-2">
                <span class="text-danger">Template-4</span>{" "}
                <span class="badge bg-secondary">1 page</span>
              </h6>
              <img
                src={require("./img/template4.webp")}
                className="img-thumbnail"
                alt="Onepage-Bio-data-Template4"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) => {
                  setpaidTemplate("Template4");
                }}
              />
            </div>
            <div className="card-footer">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  onClick={(e) => {
                    setTemplate("BiosampleTemplate4");
                  }}
                >
                  sample
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  className="float-end btn btn-success"
                  onClick={(e) => {
                    setpaidTemplate("Template4");
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </div>

          <div className="card card-slider border-0">
            <div className="card-img-top">
              <h6 className="text-center mt-2">
                <span class="text-danger">Template-5</span>{" "}
                <span class="badge bg-secondary">1 page</span>
              </h6>
              <img
                src={require("./img/template5.webp")}
                className="img-thumbnail"
                alt="Onepage-Bio-data-Template5"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) => {
                  setpaidTemplate("Template5");
                }}
              />
            </div>
            <div className="card-footer">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  onClick={(e) => {
                    setTemplate("BiosampleTemplate5");
                  }}
                >
                  sample
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  className="float-end btn btn-success"
                  onClick={(e) => {
                    setpaidTemplate("Template5");
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </div>

          <div className="card card-slider border-0">
            <div className="card-img-top mt-2">
              <h6 className="text-center">
                <span class="text-danger">Template-6</span>{" "}
                <span class="badge bg-secondary">1 page</span>
              </h6>
              <img
                src={require("./img/template6.webp")}
                className="img-thumbnail"
                alt="Onepage-Bio-data-Template6"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={(e) => {
                  setpaidTemplate("Template6");
                }}
              />
            </div>
            <div className="card-footer">
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop1"
                  onClick={(e) => {
                    setTemplate("BiosampleTemplate6");
                  }}
                >
                  sample
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  className="float-end btn btn-success"
                  onClick={(e) => {
                    setpaidTemplate("Template6");
                  }}
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </Slider>
        <div className="text-center my-3">
          <button className="btn btn-primary mx-2" onClick={handlePrev}>
            <GrLinkPrevious />
          </button>

          <button className="btn btn-primary mx-2" onClick={handleNext}>
            <GrLinkNext />
          </button>
        </div>
      </div>

      {/* //popups for sample */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="staticBackdropLabel">
                Download Free Pdf
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container py-3 rounded shadow" id="package">
                <form className="mt-5" id="free-pdf-form">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-dark fw-bold"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Mail"
                      name="email"
                      id="email1"
                      aria-describedby="emailHelp"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <span id="email_error1" style={{ color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name1"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      value={buyerName}
                      onChange={(e) => setBuyername(e.target.value)}
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {buyerNameError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Whatsapp Number
                    </label>
                    <input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      pattern="[6789][0-9]{9}"
                      placeholder="Enter Your Number"
                      id="mobile_number1"
                      value={buyerMobilenumber}
                      onChange={(e) => setMobilenumber(e.target.value)}
                    />
                    <span id="mobile_number_error1" style={{ color: "red" }}>
                      {mobilenumberError}
                    </span>
                  </div>
                  <button
                    type="button"
                    name="free"
                    id="free_pdf"
                    className="text-center but1 mt-3"
                    onClick={sampleTemplateprint}
                  >
                    Print
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* paid popup */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="staticBackdropLabel">
                Payment
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container py-3 rounded shadow-lg" id="package">
                <div className="p-3">
                  <table className="table m-0 table-bordered border-secondary">
                    {/* <tbody>
                      <tr>
                        <th className="text-dark fs-6 px-2">விலை</th>
                        <td className="text-center text-dark">Rs. 216/-</td>
                      </tr>
                      <tr>
                        <th className="text-dark fs-6 px-2">
                          Discount- 54% (216-117)
                        </th>
                        <td className="text-center text-dark">Rs. 99/-</td>
                      </tr>
                      <tr>
                        <th className="text-dark fs-6 px-2">சலுகை விலை</th>
                        <td className="text-center text-dark">Rs. 99/-</td>
                      </tr>
                    </tbody> */}
                  </table>
                </div>
                <form
                  className="mt-2"
                  action=""
                  method="post"
                  id="payment_form"
                >
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-dark fw-bold"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Mail"
                      name="email"
                      id="email"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      // required
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      onChange={(e) => setBuyername(e.target.value)}
                      value={buyerName}
                    />

                    <span id="name_error1" style={{ color: "red" }}>
                      {buyerNameError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Whatsapp Number
                    </label>
                    <input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      pattern="[6789][0-9]{9}"
                      placeholder="Enter Your Whatsapp Number"
                      id="mobile_number"
                      value={buyerMobilenumber}
                      onChange={(e) => setMobilenumber(e.target.value)}
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {mobilenumberError}
                    </span>
                  </div>
                  <button
                    type="button"
                    name="pay"
                    id="pay"
                    onClick={PaymentPopup}
                    className="text-center but1 mt-3"
                  >
                    Buy Now
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
