import "./BioData-Container.css";
import { Link } from "react-router-dom";
export default function BioDataContainer(){
    return (
        <div className="about-section bg-tertiary  overflow-hidden" id="Biodata">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 ">
                        <div className="section-title">
                            <h2 className="text-success fs-1 fw-bold mb-3">சுயவிபரம் ஜாதகத்துடன்<br />(திருமணத்திற்காக)</h2>
                            <div className="lead mb-0 fw-bold">
                                <p>திருமணத்திற்காக வரன் தேடும் பொழுது மணமகன் மற்றும் மணமகள் அவர்களைப் பற்றியும் அவர்களது குடும்பம் பொருளாதாரம் சகோதரர்கள் படிப்பு , வேளை , ஆகியவற்றை அறிவது அவசியம். </p>
                                <p> மேற்கண்ட விபரங்கள் சேர்த்து தனி நபரின் பிறப்பு ஜாதகத்தையும் உள்ளடக்கியது நமது சுயவிபர ஜாதகம்.  </p>
                            </div>
                            <Link to="/Marriage-Bio-Data" type="button" className="btn-darkred mt-4" >சுயவிபரம் எடுக்க <span style={{fontSize:"14px"}} className="ms-2 fas fa-arrow-right"></span></Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <img src={require('./img/marriage-bio-data-one-page-horoscope.webp')} className="form-img" alt="marriage biodata format" />
                    </div>
                </div>
            </div>
        </div>
    )
}