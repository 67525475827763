import react from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";

export default function Cancellation() {
  return (
    <>
      <Menubar />
      <CancellationContent />
      <Footer />
    </>
  );
}

const CancellationContent = () => {
  return (
    <>
      <div className="container" style={{ marginTop: "120px" }} id="privacy">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mt-5 mb-3 p-5 border-0 shadow-lg">
              <h1 className="text-center text fw-bold" style={{ color: "#b2012f" }}>
                Cancellation and refund policy
              </h1>
              <p>
                Horoscope is generated as per the data provided by the user. In
                terms of the user creating a horoscope for wrong data, the user
                is responsible. Once the horoscope is generated, that particular
                horoscope is not eligible for product cancellation and refund.
              </p>
              <p>
                In case the horoscope generated by the user from
                www.onepagehoroscope.com has incorrect calculations based on the
                provided data, the user can apply for a refund or product
                cancellation. We will confirm with our astrology expert and
                process the product cancellation and refund if the calculation
                is found to be wrong. The refund amount will be reversed to the
                user's account within 7 Working Days.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
