import encryptEas from "./components/encryptEas";
import decryptEas from "./components/decryptEas";

const getepayPortal = (data, config) => {
  const JsonData = JSON.stringify(data);

  var ciphertext = encryptEas(
    JsonData,
    config["Getepay Key"],
    config["Getepay IV"]
  );
  var newCipher = ciphertext.toUpperCase();

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    mid: data.mid,
    terminalId: data.terminalId,
    req: newCipher,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(config["Getepay Url"], requestOptions)
    .then((response) => response.text()
   
    )
    .then((result) => {

      console.log("900",requestOptions, config["Getepay Url"])
      var resultobj = JSON.parse(result);
      var responseurl = resultobj.response;
      var dataitem = decryptEas(
        responseurl,
        config["Getepay Key"],
        config["Getepay IV"]
      );
      dataitem = JSON.parse(dataitem);
      window.location.href = dataitem.paymentUrl;
    })
    .catch((error) => console.log("error", error));
};

export default getepayPortal;
