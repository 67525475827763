import React, { useState, useEffect } from "react";
import {Link } from "react-router-dom";
import Logo from "./images/logo.png"
export default function SideNav({ isToggled, onToggle }) {


    const [style, setStyle] = useState(
        "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
      );
    
      useEffect(() => {
        // Update the style when isToggled changes
        setStyle(
          isToggled
            ? "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion toggled"
            : "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
        );
      }, [isToggled]);
    
      const handleToggle = () => {
        // Toggle isToggled value and let the useEffect update the style
        onToggle(!isToggled);
      };
    
  
  return (
    <>
      <ul className={style} id="accordionSidebar">
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/"
        ></a>
          <div className="sidebar-brand-icon rotate-n-15">
            {/* <i className="fas fa-laugh-wink"></i> */}
          </div>
          <div className="sidebar-brand-text mx-3"> <img src={Logo} height="100%" alt="sidebar" width="100%"/></div>
          <div className="text-center d-none d-md-inline">
          <button
              id="sidebarToggle"
              className="rounded-circle border-0"
              onClick={handleToggle}
            ></button>  
          </div>
        {/*   <!-- Divider --> */}
        <hr className="sidebar-divider my-0" />

        {/*  <!-- Nav Item - Dashboard --> */}
        <li className="nav-item active">
          <Link to="/user" className="nav-link " >
          <i className="fa-solid fa-house"></i>
            <span >Dashboard</span>
            </Link>
        </li>

        {/*  <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/*   <!-- Heading --> */}
        <div className="sidebar-heading">products</div>

        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <Link
            className="nav-link collapsed"
          
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
              <i className="fa-solid fa-life-ring"></i>
            <span className="fw-bold">Onepage Horoscope</span>
          </Link>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Choose:</h6>
              <Link className="collapse-item" to="/Genarate-Onepage-Horoscope">
              Generate
              </Link>
              <Link className="collapse-item" to="/One-page-Report">
                Report
              </Link>
            </div>
          </div>
        </li>

        {/* marriage matching */}
        <li className="nav-item">
          <Link
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#marriage-matching-nav"
            aria-expanded="true"
            aria-controls="marriage-matching-nav"
          >
           <i className="fa-solid fa-heart-circle-plus fs-6"></i>
            <span className="fw-bold"> Marriage Matching</span>
          </Link>
          <div
            id="marriage-matching-nav"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Choose:</h6>
              <Link className="collapse-item" to="/Genarate-Marriage-Matching">
              Generate
              </Link>
              <Link className="collapse-item" to="/Marriage-Matching-Report">
                Report
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#Bio-data-nav"
            aria-expanded="true"
            aria-controls="Bio-data-nav"
          >
           <i className="fa-solid fa-address-book"></i>
            <span  className="fw-bold">Bio-data</span>
          </Link>
          <div
            id="Bio-data-nav"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Choose:</h6>
              <Link className="collapse-item" to="/Genarate-Bio-Data">
              Generate
              </Link>
              <Link className="collapse-item" to="/BioData-Report">
                Report
              </Link>
            </div>
          </div>    
        </li>

        <li className="nav-item">
          <Link
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#BabyName-nav"
            aria-expanded="true"
            aria-controls="BabyName-nav"
          >
          <i className="fa-solid fw-bold fa-baby"></i>

            <span  className="fw-bold">Baby Name List</span>
          </Link>
          <div
            id="BabyName-nav"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Choose:</h6>
              <Link className="collapse-item" to="/Genarate-Baby-Name-List">
              Generate
              </Link>
              <Link  className="collapse-item" to="/Baby-Name-Report">
                Report
              </Link  >
            </div>
          </div>
        </li>

        <hr className="sidebar-divider" />

        <hr className="sidebar-divider d-none d-md-block" />

        {/*  <!-- Sidebar Message --> */}
      </ul>
    </>
  );
}
  