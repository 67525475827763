import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { useNavigate } from "react-router-dom";

function TopNavbar({isToggled, onToggle }) {
        const [myName,setMyName]=useState('user01');
    const [localIsToggled, setLocalIsToggled] = useState(isToggled);

    useEffect(() => {
      setLocalIsToggled(isToggled);
    }, [isToggled]);
  
    const handleToggle = () => {
      setLocalIsToggled(!localIsToggled);
      onToggle(!localIsToggled);
    };
    const style={
        Navstyle:{
            backgroundColor:"#fff"
        }
    }
    const navigate = useNavigate();
  
    useEffect(() => {
        Loading.dots();
      const Token = localStorage.getItem("authToken");
  
      axios
        .get("https://www.ctsvsolutions.com/onepage-Api/public/api/myProfile", {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          const user_name=response.data.user_name;
          const mobile_number=response.data.mobile_number;
          const Email=response.data.email;
          setMyName(user_name);

          if((user_name === "" ||  user_name === null)  || (mobile_number ==="" || mobile_number ===null)||( Email ==="" ||Email===null ) ){
            navigate('/Update-Profile', { replace: true });
          }
          Loading.remove();

        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          Loading.remove();
        });
    }, []);
  
const Logout=()=>{
   localStorage.removeItem('authToken');
   navigate('/Login');
}

    return(
        <>
 <nav className="navbar navbar-expand navbar-light  topbar mb-4 static-top shadow" style={style.Navstyle}>

{/*  <!-- Sidebar Toggle (Topbar) --> */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-lg-none rounded-circle mr-3"
          onClick={handleToggle}
        >
          <i className="fa fa-bars"></i>
        </button>

{/*  <!-- Topbar Search --> */}


{/*  <!-- Topbar Navbar --> */}
<ul className="navbar-nav ml-auto">

    {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
   
    {/*  <!-- Nav Item - Alerts --> */}
    

    

    <div className="topbar-divider d-none d-sm-block">
  
    </div>

    {/* <!-- Nav Item - User Information --> */}
    
    <li className="nav-item dropdown no-arrow">
        
        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="mr-2  d-lg-inline text-gray-600 small"> {myName}</span>
            <img className="img-profile rounded-circle"
                src="img/undraw_profile.svg" />
        </a>
        {/*  <!-- Dropdown - User Information --> */}
        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown">
            <Link className="dropdown-item" to="/Update-Profile">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
            </Link>
            
            <div className="dropdown-divider"></div>
            <button onClick={Logout} 
            className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
            </button>
        </div>
    </li>

</ul>

</nav>
        </>
    );

}
export default TopNavbar;