import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import BiodataForm from "../Bio-Data-Form/BioDataForm";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function MarriageBioData() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Marriage-Bio-Data": "/Marriage-Bio-Data",
      // Add more canonical paths if needed
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };
  
  return (
    <>
      <Helmet>
        <title>திருமண சுயவிபரம் - Marriage Bio-Data</title>
        <meta
          name="description"
          content="Marriage Bio-Data format online Discover your ideal life partner online . Create your personalized profile on one page . Find love and compatibility."
        />
        <meta
          name="keywords"
          content="marriage biodata, marriage biodata format, online biodata for marriage, biodata for marriage in tamil, free biodata maker, free marriage biodata maker, marriage biodata template free download, bio data for marriage free"
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
        <script type="application/ld+json">
          {`
           "@context": "https://schema.org/", 
           "@type": "Product", 
           "name": "Marriage Biodata",
           "image": "",
           "description": "Marriage Bio-Data format online Discover your ideal life partner online. Create your personalized profile on one page. Find love and compatibility.",
           "brand": {
             "@type": "Brand",
             "name": "One Page Horoscope"
          `}
        </script>
      </Helmet>
      <Menubar />
      <BiodataForm />
      <BioDataContent/>
      <Footer />
    </>
  );
}


const BioDataContent=()=>{
  return(
    <>
    <div className="container" style={{ marginTop: "60px" }}>
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="section-title">
            <p
              className="fw-bold fs-2 mb-3"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif",
                color: "#b2012f",
              }}
            >
              Marriage Bio-Data
            </p>
            <div className="content pe-0 pe-lg-5">
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif",
                  color: "#000",
                }}
              >
                <span className="fs-3"> • </span>{" "}
                <span style={{ color: "#07c" }}>Marriage bio-data Format</span>{" "}
                usually contains information such as name, age, date of birth,
                religion, gotra, education, job details, profession of parents,
                siblings details, and hobbies. Most people also attach horoscope
                with their biodata, income, and partner expectations.
              </p>
            </div>
            <div className="content pe-0 pe-lg-5">
              <p className="text-dark" style={{ fontSize: "20px" }}>
                <span className="fs-3"> • </span> Marriage Bio-Data Given below:
              </p>
              <p style={{ textIndent: "0.5cm", color: "#07c" }}>
                Personal details
              </p>
              <p style={{ textIndent: "0.5cm", color: "#07c" }}>
                Family details.
              </p>
              <p style={{ textIndent: "0.5cm", color: "#07c" }}>
                Professional details.
              </p>
              <p style={{ textIndent: "0.5cm", color: "#07c" }}>
                Education details.
              </p>
              <p style={{ textIndent: "0.5cm", color: "#07c" }}>Photo</p>
            </div>
            <h2
              className="fw-bold fs-2 mb-3"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif",
                color: "#b2012f",
              }}
            >
              Marriage Bio-Data - Personal Details:
            </h2>
            <div className="content pe-0 pe-lg-5">
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif",
                  color: "#000",
                }}
              >
                <span style={{ color: "#07c" }}>Marriage Bio-Data Format</span>{" "}
                Marriage Bio-Data Format document will include basic details
                such as your name, gender, date of birth, address, the names of
                your parents and your email address. You'll also want to include
                information about your hobbies, passions, what you're good at
                and anything else you think the employer might need to
                understand you as a person.
              </p>
            </div>
            <h2
              className="fw-bold fs-2 mb-3"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif",
                color: "#b2012f",
              }}
            >
              Marriage Bio-Data - Family details:
            </h2>
            <div className="content pe-0 pe-lg-5">
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif",
                  color: "#000",
                }}
              >
                <span style={{ color: "#07c" }}>Marriage Bio-Data Format</span>{" "}
                Family details particulars are also important when writing
                marriage biodata. The complete information needs to be given of
                siblings with their spouse's name and occupation if married. You
                can start with the name of your Father and his occupation,
                Mother's name and her occupation, followed by names of brothers
                and sisters.
              </p>
            </div>
            <h2
              className="fw-bold fs-2 mb-3"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif",
                color: "#b2012f",
              }}
            >
              Marriage Bio-Data - Education details:
            </h2>
            <div className="content pe-0 pe-lg-5">
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif",
                  color: "#000",
                }}
              >
                <span style={{ color: "#07c" }}>Marriage Bio-Data Format</span>{" "}
                including your educational background in your biodata helps
                potential partners and their families to understand what level
                of education you have attained. This is important because some
                families may pick partners for their children based on their
                educational level.
              </p>
            </div>
            <h2
              className="fw-bold fs-2 mb-3"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif",
                color: "#b2012f",
              }}
            >
              Marriage Bio-Data - Professional details:
            </h2>
            <div className="content pe-0 pe-lg-5">
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif",
                  color: "#000",
                }}
              >
                <span style={{ color: "#07c" }}>Marriage Bio-Data Format</span>{" "}
                Professional details your profession is another important detail
                to include in your marriage biodata. This helps potential
                partners and their families to understand what you do for a
                living, the nature of your work, your work schedule, career
                growth potential, and earning potential.
              </p>
            </div>
            <h2
              className="fw-bold fs-2 mb-3"
              style={{
                fontFamily:
                  "Cambria, Cochin, Georgia, Times, Times New Roman, serif",
                color: "#b2012f",
              }}
            >
              Marriage Bio-Data - Photo:
            </h2>
            <div className="content pe-0 pe-lg-5">
              <p
                style={{
                  fontSize: "18px",
                  fontFamily: "Segoe UI,system-ui,-apple-system,sans-serif",
                  color: "#000",
                }}
              >
                <span style={{ color: "#07c" }}>Marriage Bio-Data Format</span>{" "}
                The first thing that you should include in your biodata is a
                recent photograph of you. Photographs are important because they
                help potential partners to put a face to the name, and can give
                them an idea of what you look like.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )

}