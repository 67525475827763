import react from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";

export default function TermCondition() {
  return (
    <>
      <Menubar />
      <TermConditionContent />
      <Footer />
    </>
  );
}

const TermConditionContent = () => {
  return (
    <>
      <div className="container banner" id="privacy">
        <div className="row">
          <div className="col-lg-10 col-md-10 col-12 m-auto">
            <div className="card mt-3 mb-3 p-3 border-0 shadow-lg">
              <h1 className="text-center fw-bold" style={{ color: "#b2012f" }}>
                TERMS OF USE
              </h1>
              <p className="fw-bold">Last updated March 28, 2023</p>
              <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                AGREEMENT TO OUR LEGAL TERMS
              </h4>
              <p>
                We are CTSV Solutions Private Limited( "<b>Company</b>" "
                <b>We</b>" "<b>Us</b>" "<b>Our</b>")
              </p>
              <p>
                We operate the website{" "}
                <a
                  href="https://onepagehoroscope.com/"
                  className="fw-bold text-success"
                >
                  https://onepagehoroscope.com/
                </a>{" "}
                (the "<b>Site</b>"), as well as any other related products and
                services that refer or link to these legal terms (the "
                <b>Legal Terms</b>") (collectively, the "<b>Services</b>").
              </p>
              <p>
                You can contact us by phone at +919787420717, email at
                reachus@onepagehoroscope.com, or by mail to
                <span className="question">__________</span>,
                <span className="question">__________</span>
                ,Â India.
              </p>
              <p>
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity ("
                <b>You</b>"), and CTSV Solutions Private Limited, concerning
                your access to and use of the Services. You agree that by
                accessing the Services, you have read, understood, and agreed to
                be bound by all of these Legal Terms.{" "}
                <b>
                  IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU
                  ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST
                  DISCONTINUE USE IMMEDIATELY.
                </b>
              </p>
              <p>
                Supplemental terms and conditions or documents that may be
                posted on the Services from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Legal
                Terms at any time and for any reason. We will alert you about
                any changes by updating the "Last updated" date of these Legal
                Terms, and you waive any right to receive specific notice of
                each such change. It is your responsibility to periodically
                review these Legal Terms to stay informed of updates. You will
                be subject to, and will be deemed to have been made aware of and
                to have accepted, the changes in any revised Legal Terms by your
                continued use of the Services after the date such revised Legal
                Terms are posted.
              </p>
              <p>
                All users who are minors in the jurisdiction in which they
                reside (generally under the age of 18) must have the permission
                of, and be directly supervised by, their parent or guardian to
                use the Services. If you are a minor, you must have your parent
                or guardian read and agree to these Legal Terms prior to you
                using the Services.
              </p>
              <p>
                We recommend that you print a copy of these Legal Terms for your
                records.
              </p>

              <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                TABLE OF CONTENTS
              </h4>
              <Link to="#services" className="text-success text-decoration-none">
        1. OUR SERVICES
      </Link>

              <a
                href="#INTELLECTUAL"
                className="text-success text-decoration-none"
              >
                2. INTELLECTUAL PROPERTY RIGHTS
              </a>
              <a
                href="#USER REPRESENTATIONS"
                className="text-success text-decoration-none"
              >
                3. USER REPRESENTATIONS
              </a>
              <a
                href="#USER REGISTRATION"
                className="text-success text-decoration-none"
              >
                4. USER REGISTRATION
              </a>
              <a
                href="#PURCHASES"
                className="text-success text-decoration-none"
              >
                5. PURCHASES AND PAYMENT
              </a>
              <a
                href="#CANCELLATION"
                className="text-success text-decoration-none"
              >
                6. CANCELLATION
              </a>
              <a
                href="#PROHIBITED"
                className="text-success text-decoration-none"
              >
                7. PROHIBITED ACTIVITIES
              </a>
              <a
                href="#USER GENERATED"
                className="text-success text-decoration-none"
              >
                8. USER GENERATED CONTRIBUTIONS
              </a>
              <a
                href="#CONTRIBUTIO"
                className="text-success text-decoration-none"
              >
                9. CONTRIBUTION LICENSE
              </a>
              <a
                href="#GUIDELINES"
                className="text-success text-decoration-none"
              >
                10. GUIDELINES FOR REVIEWS
              </a>
              <a
                href="#SERVICES MANAGEMENT"
                className="text-success text-decoration-none"
              >
                11. SERVICES MANAGEMENT
              </a>
              <a href="#PRIVACY" className="text-success text-decoration-none">
                12. PRIVACY POLICY
              </a>
              <a href="#TERM AND" className="text-success text-decoration-none">
                13. TERM AND TERMINATION
              </a>
              <a
                href="#MODIFICATIONS"
                className="text-success text-decoration-none"
              >
                14. MODIFICATIONS AND INTERRUPTIONS
              </a>
              <a
                href="#GOVERNING"
                className="text-success text-decoration-none"
              >
                15. GOVERNING LAW
              </a>
              <a href="#DISPUTE" className="text-success text-decoration-none">
                16. DISPUTE RESOLUTION
              </a>
              <a
                href="#CORRECTIONS"
                className="text-success text-decoration-none"
              >
                17. CORRECTIONS
              </a>
              <a
                href="#DISCLAIMER"
                className="text-success text-decoration-none"
              >
                18. DISCLAIMER
              </a>
              <a
                href="#LIMITATIONS"
                className="text-success text-decoration-none"
              >
                19. LIMITATIONS OF LIABILITY
              </a>
              <a
                href="#INDEMNIFICATION"
                className="text-success text-decoration-none"
              >
                20. INDEMNIFICATION
              </a>
              <a
                href="#USER DATA"
                className="text-success text-decoration-none"
              >
                21. USER DATA
              </a>
              <a
                href="#ELECTRONIC COMMUNICATIONS"
                className="text-success text-decoration-none"
              >
                22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </a>
              <a
                href="#MISCELLANEOUS"
                className="text-success text-decoration-none"
              >
                23. MISCELLANEOUS
              </a>
              <a
                href="#CONTACT US"
                className="text-success text-decoration-none"
              >
                24. CONTACT US
              </a>

              <div id="services">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  1. OUR SERVICES
                </h4>
                <p>
                  The information provided when using the Services is not
                  intended for distribution to or use by any person or entity in
                  any jurisdiction or country where such distribution or use
                  would be contrary to law or regulation or which would subject
                  us to any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Services from other locations do so on their own initiative
                  and are solely responsible for compliance with local laws, if
                  and to the extent local laws are applicable.
                </p>
              </div>
              <div id="INTELLECTUAL">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  2. INTELLECTUAL PROPERTY RIGHTS
                </h4>
                <h5 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  Our intellectual property
                </h5>
                <p>
                  We are the owner or the licensee of all intellectual property
                  rights in our Services, including all source code, databases,
                  functionality, software, website designs, audio, video, text,
                  photographs, and graphics in the Services (collectively, the
                  "Content"), as well as the trademarks, service marks, and
                  logos contained therein (the "Marks").
                </p>
                <p>
                  Our Content and Marks are protected by copyright and trademark
                  laws (and various other intellectual property rights and
                  unfair competition laws) and treaties in the United States and
                  around the world.
                </p>
                <p>
                  The Content and Marks are provided in or through the Services
                  "AS IS" for your personal, non-commercial use only.
                </p>
                <h5 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  Your use of our Services
                </h5>
                <p>
                  Subject to your compliance with these Legal Terms, including
                  the
                  <a href="" className="text-success text-decoration-none">
                    &nbsp;"PROHIBITED ACTIVITIES"&nbsp;
                  </a>
                  section below, we grant you a non-exclusive, non-transferable,
                  revocable license to:
                </p>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    {" "}
                    &nbsp;access the Services; and
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;download or print a copy of any portion of the Content
                    to which you have properly gained access.
                  </span>
                </div>
                <p>solely for your personal, non-commercial use.</p>
                <p>
                  Except as set out in this section or elsewhere in our Legal
                  Terms, no part of the Services and no Content or Marks may be
                  copied, reproduced, aggregated, republished, uploaded, posted,
                  publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior
                  written permission.
                </p>
                <p>
                  If you wish to make any use of the Services, Content, or Marks
                  other than as set out in this section or elsewhere in our
                  Legal Terms, please address your request to:
                  reachus@onepagehoroscope.com. If we ever grant you the
                  permission to post, reproduce, or publicly display any part of
                  our Services or Content, you must identify us as the owners or
                  licensors of the Services, Content, or Marks and ensure that
                  any copyright or proprietary notice appears or is visible on
                  posting, reproducing, or displaying our Content.
                </p>
                <p>
                  We reserve all rights not expressly granted to you in and to
                  the Services, Content, and Marks.
                </p>
                <p>
                  Any breach of these Intellectual Property Rights will
                  constitute a material breach of our Legal Terms and your right
                  to use our Services will terminate immediately.
                </p>

                <h5 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  Your submissions
                </h5>
                <p>
                  Please review this section and the
                  <a href="" className="text-success text-decoration-none">
                    &nbsp;"PROHIBITED ACTIVITIES"&nbsp;
                  </a>{" "}
                  section carefully prior to using our Services to understand
                  the (a) rights you give us and (b) obligations you have when
                  you post or upload any content through the Services.
                </p>
                <p>
                  <b>Submissions:</b> &nbsp;By directly sending us any question,
                  comment, suggestion, idea, feedback, or other information
                  about the Services ("Submissions"), you agree to assign to us
                  all intellectual property rights in such Submission. You agree
                  that we shall own this Submission and be entitled to its
                  unrestricted use and dissemination for any lawful purpose,
                  commercial or otherwise, without acknowledgment or
                  compensation to you.
                </p>
                <p>
                  <b>You are responsible for what you post or upload:</b> By
                  sending us Submissions through any part of the Services you:
                </p>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;confirm that you have read and agree with our
                    <a href="" className="text-success text-decoration-none">
                      "PROHIBITED ACTIVITIES"
                    </a>{" "}
                    and will not post, send, publish, upload, or transmit
                    through the Services any Submission that is illegal,
                    harassing, hateful, harmful, defamatory, obscene, bullying,
                    abusive, discriminatory, threatening to any person or group,
                    sexually explicit, false, inaccurate, deceitful, or
                    misleading;
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;to the extent permissible by applicable law, waive any
                    and all moral rights to any such Submission;
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;warrant that any such Submission is original to you or
                    that you have the necessary rights and licenses to submit
                    such Submissions and that you have full authority to grant
                    us the above-mentioned rights in relation to your
                    Submissions; and
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;warrant and represent that your Submissions do not
                    constitute confidential information.
                  </span>
                </div>
                <p>
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Services (or any portion thereof).
                </p>
              </div>
              <div id="USER REPRESENTATIONS">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  3. Â USER REPRESENTATIONS
                </h4>
                <p>
                  You may be required to register to use the Services. You agree
                  to keep your password confidential and will be responsible for
                  all use of your account and password. We reserve the right to
                  remove, reclaim, or change a username you select if we
                  determine, in our sole discretion, that such username is
                  inappropriate, obscene, or otherwise objectionable.
                </p>
              </div>
              <div id="USER REGISTRATION">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  4. Â USER REGISTRATION
                </h4>
                <p>
                  You may be required to register to use the Services. You agree
                  to keep your password confidential and will be responsible for
                  all use of your account and password. We reserve the right to
                  remove, reclaim, or change a username you select if we
                  determine, in our sole discretion, that such username is
                  inappropriate, obscene, or otherwise objectionable.
                </p>
              </div>
              <div id="PURCHASES">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  5. Â PURCHASES AND PAYMENT
                </h4>
                <p>We accept the following forms of payment:</p>
                <p>- Â Visa</p>
                <p>- Â Mastercard</p>
                <p>
                  You agree to provide current, complete, and accurate purchase
                  and account information for all purchases made via the
                  Services. You further agree to promptly update account and
                  payment information, including email address, payment method,
                  and payment card expiration date, so that we can complete your
                  transactions and contact you as needed. Sales tax will be
                  added to the price of purchases as deemed required by us. We
                  may change prices at any time. All payments shall be in INDIAN
                  Rupees.
                </p>
                <p>
                  You agree to pay all charges at the prices then in effect for
                  your purchases and any applicable shipping fees, and you
                  authorize us to charge your chosen payment provider for any
                  such amounts upon placing your order. We reserve the right to
                  correct any errors or mistakes in pricing, even if we have
                  already requested or received payment.
                </p>
                <p>
                  We reserve the right to refuse any order placed through the
                  Services. We may, in our sole discretion, limit or cancel
                  quantities purchased per person, per household, or per order.
                  These restrictions may include orders placed by or under the
                  same customer account, the same payment method, and/or orders
                  that use the same billing or shipping address. We reserve the
                  right to limit or prohibit orders that, in our sole judgment,
                  appear to be placed by dealers, resellers, or distributors.
                </p>
              </div>
              <div id="CANCELLATION">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  6. CANCELLATION
                </h4>
                <p>
                  You can cancel your subscription at any time by logging into
                  your account. Your cancellation will take effect at the end of
                  the current paid term.
                </p>
                <p>
                  If you are unsatisfied with our Services, please email us at
                  reachus@onepagehoroscope.com or call us at +919787420717.
                </p>
              </div>
              <div id="PROHIBITED">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  7. Â PROHIBITED ACTIVITIES
                </h4>
                <p>
                  You may not access or use the Services for any purpose other
                  than that for which we make the Services available. The
                  Services may not be used in connection with any commercial
                  endeavors except those that are specifically endorsed or
                  approved by us.
                </p>
                <p>As a user of the Services, you agree not to:</p>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Systematically retrieve data or other content from the
                    Services to create or compile, directly or indirectly, a
                    collection, compilation, database, or directory without
                    written permission from us.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Trick, defraud, or mislead us and other users,
                    especially in any attempt to learn sensitive account
                    information such as user passwords.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Circumvent, disable, or otherwise interfere with
                    security-related features of the Services, including
                    features that prevent or restrict the use or copying of any
                    Content or enforce limitations on the use of the Services
                    and/or the Content contained therein.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Disparage, tarnish, or otherwise harm, in our opinion,
                    us and/or the Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Use any information obtained from the Services in
                    order to harass, abuse, or harm another person.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Make improper use of our support services or submit
                    false reports of abuse or misconduct.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Use the Services in a manner inconsistent with any
                    applicable laws or regulations.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Engage in unauthorized framing of or linking to the
                    Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Upload or transmit (or attempt to upload or to
                    transmit) viruses, Trojan horses, or other material,
                    including excessive use of capital letters and spamming
                    (continuous posting of repetitive text), that interferes
                    with any party’s uninterrupted use and enjoyment of the
                    Services or modifies, impairs, disrupts, alters, or
                    interferes with the use, features, functions, operation, or
                    maintenance of the Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Engage in any automated use of the system, such as
                    using scripts to send comments or messages, or using any
                    data mining, robots, or similar data gathering and
                    extraction tools.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Delete the copyright or other proprietary rights
                    notice from any Content.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Attempt to impersonate another user or person or use
                    the username of another user.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Upload or transmit (or attempt to upload or to
                    transmit) any material that acts as a passive or active
                    information collection or transmission mechanism, including
                    without limitation, clear graphics interchange formats
                    ("gifs"), 1×1 pixels, web bugs, cookies, or other similar
                    devices (sometimes referred to as "spyware" or "passive
                    collection mechanisms" or "pcms").
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Interfere with, disrupt, or create an undue burden on
                    the Services or the networks or services connected to the
                    Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Harass, annoy, intimidate, or threaten any of our
                    employees or agents engaged in providing any portion of the
                    Services to you.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Attempt to bypass any measures of the Services
                    designed to prevent or restrict access to the Services, or
                    any portion of the Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Copy or adapt the Services' software, including but
                    not limited to Flash, PHP, HTML, JavaScript, or other code.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Except as permitted by applicable law, decipher,
                    decompile, disassemble, or reverse engineer any of the
                    software comprising or in any way making up a part of the
                    Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Except as may be the result of standard search engine
                    or Internet browser usage, use, launch, develop, or
                    distribute any automated system, including without
                    limitation, any spider, robot, cheat utility, scraper, or
                    offline reader that accesses the Services, or use or launch
                    any unauthorized script or other software.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Use a buying agent or purchasing agent to make
                    purchases on the Services.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Make any unauthorized use of the Services, including
                    collecting usernames and/or email addresses of users by
                    electronic or other means for the purpose of sending
                    unsolicited email, or creating user accounts by automated
                    means or under false pretenses.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Use the Services as part of any effort to compete with
                    us or otherwise use the Services and/or the Content for any
                    revenue-generating endeavor or commercial enterprise.
                  </span>
                </div>
              </div>
              <div id="USER GENERATED">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  8. USER GENERATED CONTRIBUTIONS
                </h4>
                <p>
                  The Services does not offer users to submit or post content.
                  We may provide you with the opportunity to create, submit,
                  post, display, transmit, perform, publish, distribute, or
                  broadcast content and materials to us or on the Services,
                  including but not limited to text, writings, video, audio,
                  photographs, graphics, comments, suggestions, or personal
                  information or other material (collectively, "Contributions").
                  Contributions may be viewable by other users of the Services
                  and through third-party websites. As such, any Contributions
                  you transmit may be treated in accordance with the Services'
                  Privacy Policy. When you create or make available any
                  Contributions, you thereby represent and warrant that:
                </p>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;The creation, distribution, transmission, public
                    display, or performance, and the accessing, downloading, or
                    copying of your Contributions do not and will not infringe
                    the proprietary rights, including but not limited to the
                    copyright, patent, trademark, trade secret, or moral rights
                    of any third party.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Services, and other users of the
                    Services to use your Contributions in any manner
                    contemplated by the Services and these Legal Terms.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;You have the written consent, release, and/or
                    permission of each and every identifiable individual person
                    in your Contributions to use the name or likeness of each
                    and every such identifiable individual person to enable
                    inclusion and use of your Contributions in any manner
                    contemplated by the Services and these Legal Terms.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions are not false, inaccurate, or
                    misleading.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions are not used to harass or threaten
                    (in the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions do not violate any applicable law,
                    regulation, or rule.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions do not violate the privacy or
                    publicity rights of any third party.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions do not violate any applicable law
                    concerning child pornography or otherwise intended to
                    protect the health or well-being of minors.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions do not include any offensive
                    comments that are connected to race, national origin,
                    gender, sexual preference, or physical handicap.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Your Contributions do not otherwise violate or link to
                    material that violates any provision of these Legal Terms or
                    any applicable law or regulation.
                  </span>
                </div>
                <div className="sub-content px-2">
                  <span className="fs-5 text-dark text-center">•</span>
                  <span className="text-dark">
                    &nbsp;Use the Services as part of any effort to compete with
                    us or otherwise use the Services and/or the Content for any
                    revenue-generating endeavor or commercial enterprise.
                  </span>
                </div>
                <p>
                  Any use of the Services in violation of the foregoing violates
                  these Legal Terms and may result in, among other things,
                  termination or suspension of your rights to use the Services.
                </p>
              </div>
              <div id="CONTRIBUTIO">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  9. CONTRIBUTION LICENSE
                </h4>
                <p>
                  You and Services agree that we may access, store, process, and
                  use any information and personal data that you provide
                  following the terms of the Privacy Policy and your choices
                  (including settings).
                </p>
                <p>
                  By submitting suggestions or other feedback regarding the
                  Services, you agree that we can use and share such feedback
                  for any purpose without compensation to you.
                </p>
                <p>
                  We do not assert any ownership over your Contributions. You
                  retain full ownership of all of your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area on the Services. You are solely responsible
                  for your Contributions to the Services, and you expressly
                  agree to exonerate us from any and all responsibility and to
                  refrain from any legal action against us regarding your
                  Contributions.
                </p>
              </div>
              <div id="GUIDELINES">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  10. GUIDELINES FOR REVIEWS
                </h4>
                <p>
                  We may provide you areas on the Services to leave reviews or
                  ratings. When posting a review, you must comply with the
                  following criteria: (1) you should have firsthand experience
                  with the person/entity being reviewed; (2) your reviews should
                  not contain offensive profanity, or abusive, racist,
                  offensive, or hateful language; (3) your reviews should not
                  contain discriminatory references based on religion, race,
                  gender, national origin, age, marital status, sexual
                  orientation, or disability; (4) your reviews should not
                  contain references to illegal activity; (5) you should not be
                  affiliated with competitors if posting negative reviews; (6)
                  you should not make any conclusions as to the legality of
                  conduct; (7) you may not post any false or misleading
                  statements; and (8) you may not organize a campaign
                  encouraging others to post reviews, whether positive or
                  negative.
                </p>
                <p>
                  We may accept, reject, or remove reviews in our sole
                  discretion. We have absolutely no obligation to screen reviews
                  or to delete reviews, even if anyone considers reviews
                  objectionable or inaccurate. Reviews are not endorsed by us
                  and do not necessarily represent our opinions or the views of
                  any of our affiliates or partners. We do not assume liability
                  for any review or for any claims, liabilities, or losses
                  resulting from any review. By posting a review, you hereby
                  grant to us a perpetual, non-exclusive, worldwide,
                  royalty-free, fully paid, assignable, and sublicensable right
                  and license to reproduce, modify, translate, transmit by any
                  means, display, perform, and/or distribute all content
                  relating to the review.
                </p>
              </div>
              <div id="SERVICES MANAGEMENT">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  11. SERVICES MANAGEMENT
                </h4>
                <p>
                  We reserve the right, but not the obligation, to: (1) monitor
                  the Services for violations of these Legal Terms; (2) take
                  appropriate legal action against anyone who, in our sole
                  discretion, violates the law or these Legal Terms, including
                  without limitation, reporting such user to law enforcement
                  authorities; (3) in our sole discretion and without
                  limitation, refuse, restrict access to, limit the availability
                  of, or disable (to the extent technologically feasible) any of
                  your Contributions or any portion thereof; (4) in our sole
                  discretion and without limitation, notice, or liability, to
                  remove from the Services or otherwise disable all files and
                  content that are excessive in size or are in any way
                  burdensome to our systems; and (5) otherwise manage the
                  Services in a manner designed to protect our rights and
                  property and to facilitate the proper functioning of the
                  Services.
                </p>
              </div>
              <div id="PRIVACY">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  12. PRIVACY POLICY
                </h4>
                <p>
                  We care about data privacy and security. Please review our
                  Privacy Policy:{" "}
                  <a
                    href="https://onepagehoroscope.com/privacy-policy.php"
                    className="text-success text-decoration-none"
                  >
                    https://onepagehoroscope.com/privacy-policy.php
                  </a>
                  . By using the Services, you agree to be bound by our Privacy
                  Policy, which is incorporated into these Legal Terms. Please
                  be advised the Services are hosted in India. If you access the
                  Services from any other region of the world with laws or other
                  requirements governing personal data collection, use, or
                  disclosure that differ from applicable laws in India, then
                  through your continued use of the Services, you are
                  transferring your data to India, and you expressly consent to
                  have your data transferred to and processed in India.
                </p>
              </div>
              <div id="TERM AND">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  13. TERM AND TERMINATION
                </h4>
                <p>
                  These Legal Terms shall remain in full force and effect while
                  you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF
                  THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE
                  DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND
                  USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES),
                  TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
                  WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
                  OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
                  APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
                  PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
                  CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
                  WARNING, IN OUR SOLE DISCRETION.
                </p>
                <p>
                  If we terminate or suspend your account for any reason, you
                  are prohibited from registering and creating a new account
                  under your name, a fake or borrowed name, or the name of any
                  third party, even if you may be acting on behalf of the third
                  party. In addition to terminating or suspending your account,
                  we reserve the right to take appropriate legal action,
                  including without limitation pursuing civil, criminal, and
                  injunctive redress.
                </p>
              </div>
              <div id="MODIFICATIONS">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  14. MODIFICATIONS AND INTERRUPTIONS
                </h4>
                <p>
                  We reserve the right to change, modify, or remove the contents
                  of the Services at any time or for any reason at our sole
                  discretion without notice. However, we have no obligation to
                  update any information on our Services. We will not be liable
                  to you or any third party for any modification, price change,
                  suspension, or discontinuance of the Services.
                </p>
                <p>
                  We cannot guarantee the Services will be available at all
                  times. We may experience hardware, software, or other problems
                  or need to perform maintenance related to the Services,
                  resulting in interruptions, delays, or errors. We reserve the
                  right to change, revise, update, suspend, discontinue, or
                  otherwise modify the Services at any time or for any reason
                  without notice to you. You agree that we have no liability
                  whatsoever for any loss, damage, or inconvenience caused by
                  your inability to access or use the Services during any
                  downtime or discontinuance of the Services. Nothing in these
                  Legal Terms will be construed to obligate us to maintain and
                  support the Services or to supply any corrections, updates, or
                  releases in connection therewith.
                </p>
              </div>
              <div id="GOVERNING">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  15. GOVERNING LAW
                </h4>
                <p>
                  These Legal Terms shall be governed by and defined following
                  the laws of India. CTSV Solutions Private Limited and yourself
                  irrevocably consent that the courts of India shall have
                  exclusive jurisdiction to resolve any dispute which may arise
                  in connection with these Legal Terms.
                </p>
              </div>
              <div id="DISPUTE">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  16. DISPUTE RESOLUTION
                </h4>
                <h5 className="fw-bold" style={{ color: "#b2012f" }}>
                  Informal Negotiations
                </h5>
                <p>
                  To expedite resolution and control the cost of any dispute,
                  controversy, or claim related to these Legal Terms (each a
                  "Dispute" and collectively, the "Disputes") brought by either
                  you or us (individually, a "Party" and collectively, the
                  "Parties"), the Parties agree to first attempt to negotiate
                  any Dispute (except those Disputes expressly provided below)
                  informally for at least __________ days before initiating
                  arbitration. Such informal negotiations commence upon written
                  notice from one Party to the other Party.
                </p>
                <h5 className="fw-bold" style={{ color: "#b2012f" }}>
                  Binding Arbitration
                </h5>
                <p>
                  Any dispute arising out of or in connection with these Legal
                  Terms, including any question regarding its existence,
                  validity, or termination, shall be referred to and finally
                  resolved by the International Commercial Arbitration Court
                  under the European Arbitration Chamber (Belgium, Brussels,
                  Avenue Louise, 146) according to the Rules of this ICAC,
                  which, as a result of referring to it, is considered as the
                  part of this clause. The number of arbitrators shall be
                  __________. The seat, or legal place, or arbitration shall be
                  __________. The language of the proceedings shall be
                  __________. The governing law of these Legal Terms shall be
                  substantive law of __________.
                </p>
                <h5 className="fw-bold" style={{ color: "#b2012f" }}>
                  Restrictions
                </h5>
                <p>
                  The Parties agree that any arbitration shall be limited to the
                  Dispute between the Parties individually. To the full extent
                  permitted by law, (a) no arbitration shall be joined with any
                  other proceeding; (b) there is no right or authority for any
                  Dispute to be arbitrated on a class-action basis or to utilize
                  class action procedures; and (c) there is no right or
                  authority for any Dispute to be brought in a purported
                  representative capacity on behalf of the general public or any
                  other persons.
                </p>
                <h5 className="fw-bold" style={{ color: "#b2012f" }}>
                  Exceptions to Informal Negotiations and Arbitration
                </h5>
                <p>
                  The Parties agree that the following Disputes are not subject
                  to the above provisions concerning informal negotiations
                  binding arbitration: (a) any Disputes seeking to enforce or
                  protect, or concerning the validity of, any of the
                  intellectual property rights of a Party; (b) any Dispute
                  related to, or arising from, allegations of theft, piracy,
                  invasion of privacy, or unauthorized use; and (c) any claim
                  for injunctive relief. If this provision is found to be
                  illegal or unenforceable, then neither Party will elect to
                  arbitrate any Dispute falling within that portion of this
                  provision found to be illegal or unenforceable and such
                  Dispute shall be decided by a court of competent jurisdiction
                  within the courts listed for jurisdiction above, and the
                  Parties agree to submit to the personal jurisdiction of that
                  court.
                </p>
              </div>
              <div id="CORRECTIONS">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  17. CORRECTIONS
                </h4>
                <p>
                  There may be information on the Services that contains
                  typographical errors, inaccuracies, or omissions, including
                  descriptions, pricing, availability, and various other
                  information. We reserve the right to correct any errors,
                  inaccuracies, or omissions and to change or update the
                  information on the Services at any time, without prior notice.
                </p>
              </div>
              <div id="DISCLAIMER">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  18. DISCLAIMER
                </h4>
                <p style={{ textTransform: "lowercase" }}>
                  THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.
                  YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE
                  RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                  WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
                  SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION,
                  THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
                  WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                  COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY
                  WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
                  WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                  MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
                  PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                  RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
                  UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
                  AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
                  STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                  TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES,
                  TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                  THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS
                  OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
                  DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                  CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
                  THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                  RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                  OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED
                  WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                  BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR
                  IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
                  BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
                  SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH
                  ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                  JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                </p>
              </div>
              <div id="LIMITATIONS">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  19. LIMITATIONS OF LIABILITY
                </h4>
                <p style={{ textTransform: "lowercase" }}>
                  IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                  LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                  CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                  DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                  OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF
                  WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                  LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
                  THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE
                  LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US OR . CERTAIN
                  US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS
                  ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                  CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
                  THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                  YOU MAY HAVE ADDITIONAL RIGHTS.
                </p>
              </div>
              <div id="INDEMNIFICATION">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  20. INDEMNIFICATION
                </h4>
                <p>
                  You agree to defend, indemnify, and hold us harmless,
                  including our subsidiaries, affiliates, and all of our
                  respective officers, agents, partners, and employees, from and
                  against any loss, damage, liability, claim, or demand,
                  including reasonable attorneys’ fees and expenses, made by any
                  third party due to or arising out of: (1) use of the Services;
                  (2) breach of these Legal Terms; (3) any breach of your
                  representations and warranties set forth in these Legal Terms;
                  (4) your violation of the rights of a third party, including
                  but not limited to intellectual property rights; or (5) any
                  overt harmful act toward any other user of the Services with
                  whom you connected via the Services. Notwithstanding the
                  foregoing, we reserve the right, at your expense, to assume
                  the exclusive defense and control of any matter for which you
                  are required to indemnify us, and you agree to cooperate, at
                  your expense, with our defense of such claims. We will use
                  reasonable efforts to notify you of any such claim, action, or
                  proceeding which is subject to this indemnification upon
                  becoming aware of it.
                </p>
              </div>
              <div id="USER DATA">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  21. USER DATA
                </h4>
                <p>
                  We will maintain certain data that you transmit to the
                  Services for the purpose of managing the performance of the
                  Services, as well as data relating to your use of the
                  Services. Although we perform regular routine backups of data,
                  you are solely responsible for all data that you transmit or
                  that relates to any activity you have undertaken using the
                  Services. You agree that we shall have no liability to you for
                  any loss or corruption of any such data, and you hereby waive
                  any right of action against us arising from any such loss or
                  corruption of such data.
                </p>
              </div>
              <div id="ELECTRONIC COMMUNICATIONS">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </h4>
                <p>
                  Visiting the Services, sending us emails, and completing
                  online forms constitute electronic communications. You consent
                  to receive electronic communications, and you agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email and on the Services,
                  satisfy any legal requirement that such communication be in
                  writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                  CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                  DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                  INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby
                  waive any rights or requirements under any statutes,
                  regulations, rules, ordinances, or other laws in any
                  jurisdiction which require an original signature or delivery
                  or retention of non-electronic records, or to payments or the
                  granting of credits by any means other than electronic means.
                </p>
              </div>
              <div id="MISCELLANEOUS">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  23. MISCELLANEOUS
                </h4>
                <p>
                  These Legal Terms and any policies or operating rules posted
                  by us on the Services or in respect to the Services constitute
                  the entire agreement and understanding between you and us. Our
                  failure to exercise or enforce any right or provision of these
                  Legal Terms shall not operate as a waiver of such right or
                  provision. These Legal Terms operate to the fullest extent
                  permissible by law. We may assign any or all of our rights and
                  obligations to others at any time. We shall not be responsible
                  or liable for any loss, damage, delay, or failure to act
                  caused by any cause beyond our reasonable control. If any
                  provision or part of a provision of these Legal Terms is
                  determined to be unlawful, void, or unenforceable, that
                  provision or part of the provision is deemed severable from
                  these Legal Terms and does not affect the validity and
                  enforceability of any remaining provisions. There is no joint
                  venture, partnership, employment or agency relationship
                  created between you and us as a result of these Legal Terms or
                  use of the Services. You agree that these Legal Terms will not
                  be construed against us by virtue of having drafted them. You
                  hereby waive any and all defenses you may have based on the
                  electronic form of these Legal Terms and the lack of signing
                  by the parties hereto to execute these Legal Terms.
                </p>
              </div>
              <div id="CONTACT US">
                <h4 className="mt-3 fw-bold" style={{ color: "#b2012f" }}>
                  24. CONTACT US
                </h4>
                <p>
                  In order to resolve a complaint regarding the Services or to
                  receive further information regarding use of the Services,
                  please contact us at:
                </p>
                <span className="fs-6 text-success">
                  CTSV Solutions Private Limited
                </span>
                <br />
                <span className="fs-6 text-success">__________</span>
                <br />
                <span className="fs-6 text-success">India</span>
                <br />
                <span className="fs-6 text-success">Phone: +919787420717</span>
                <br />
                <span className="fs-6 text-success">
                  reachus@onepagehoroscope.com
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
