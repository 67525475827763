import "./BabyNameTemplate.css";
export default function BabyNameTemplate({ value }) {

  const personalInfo = value.personal_info;
  const starName = value.starName;
  const CommonName=value.commonName;
  const BabyNameCount=value.name_count;

  return (
    <>
          <section className="sheet  border border-secondary mt-1">
        <div className="container-fluid p-0 m-0">
          <div>
            <img
              src={require("./images/baby_name_list_front.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      <section className="sheet  border border-secondary mt-1 sheet-1">
        <div className="container-fluid py-2">
          <div className="heading mb-3">
            <img
              src={require("./images/baby_name_heading_1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="card p-1 border-color my-1 bg-transparent">
            <table
              className="table table-borderless text-center fw-bold mb-0"
              id="letter_table"
            >
              <tr>
                <td colspan="2" style={{ padding: "10px 0" }} >
                  {personalInfo.natchathiram} நட்சத்திரத்தில் பிறந்தவர்களுக்கு
                  வைக்க வேண்டிய பெயரின்
                </td>
              </tr>
              <tr>
                <th style={{ padding: "10px 0",backgroundColor:'#BEE6F4' }}>
                  முதல் எழுத்து ஆங்கிலத்தில்
                </th>
                <th style={{ backgroundColor:'#BEE6F4' }}>முதல் எழுத்து தமிழில்</th>
              </tr>
              <tr>
                <td style={{ padding: "10px 0" }}>T, D</td>
                <td>{personalInfo.natchathira_letter_tamil} </td>
              </tr>
            </table>
          </div>

          <div className="heading my-2">
            <h2 className="fw-bold fs-6 headingh2">
              ஆங்கில எழுத்திற்குரிய நியூமராலஜி எண்கள்
            </h2>
          </div>
          <div className="card p-1 border-color bg-transparent">
            <table
              className="table table-borderless text-center fw-bold mb-0"
              id="numerology-table"
            >
              <tr>
                <th style={{ padding: "10px 0" }}>1</th>

                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
              </tr>
              <tr>
                <td style={{ padding: "10px 0", backgroundColor:'#BEE6F4' }}>A</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>B</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>C</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>D</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>E</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>U</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>O</td>
                <td  style={{ backgroundColor:'#BEE6F4' }}>P</td>
              </tr>
              <tr>
                <td style={{ padding: "10px 0" }}>I</td>
                <td>K</td>
                <td>G</td>
                <td>M</td>
                <td>H</td>
                <td>V</td>
                <td>Z</td>
                <td>F</td>
              </tr>
              <tr>
                <td style={{ padding: "10px 0",backgroundColor:'#BEE6F4' }}>Q</td>
                <td style={{ backgroundColor:'#BEE6F4' }}>R</td>
                <td style={{ backgroundColor:'#BEE6F4' }}>L</td>
                <td style={{ backgroundColor:'#BEE6F4' }}>T</td>
                <td style={{ backgroundColor:'#BEE6F4' }}>N</td>
                <td style={{ backgroundColor:'#BEE6F4' }}>W</td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px 0" }}>J</td>
                <td></td>
                <td>S</td>
                <td></td>
                <td>X</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ padding: "10px 0",backgroundColor:'#BEE6F4' }}>Y</td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
                <td style={{ backgroundColor:'#BEE6F4' }}></td>
              </tr>
            </table>
          </div>
          <div className="card bg-transparent border-color p-2 my-2 fw-bold">
            <ul className="child-emoji"
              style={{
                padding: 0,
                margin: 0,
                marginLeft: "20px",
                fontSize: "13px",
              }}
            >
              <li className="ps-3 my-1">
                கீழே தரப்பட்டுள்ள பெயரின் கூட்டு எண்கள் வருமாறு குழந்தைக்கு
                பெயர் வைக்கவும்.
              </li>
              <li className="ps-3 my-1">
                கீழே தரப்பட்டுள்ள எண்களானது பிறந்த தேதி, மாதம், வருடம்
                ஆகியவற்றின் அடிப்படையில் நியூமராலஜி நிபுணர்களால் தேர்வு
                செய்யப்பட்ட எண்களாகும்.
              </li>
              <li className="ps-3 my-1">
                இந்த எண்ணிற்க்கான பலன்கள் பெயர் லிஸ்ட்-ற்கு பிறகு வரும்
                கையேடு-வின் 14-ம் பக்கத்திலிருந்து தரப்பட்டுள்ளது. நீங்கள்
                தேர்வு செய்யும் பெயரின் கூட்டு எண்ணின் பலனை படித்து, மிக நல்ல
                பலன் உள்ள பெயர்களை தேர்வு செய்து வைக்கவும்.{" "}
              </li>
            </ul>
          </div>
          <div className="card bg-transparent border-color my-2 fw-bold">
            <table className="table table-borderless text-center fw-bold mb-0 align-middle">
              <tr>
                <td className="border-color-right">
                  தேதி எண் 4 <br /> கூட்டு எண் 4
                </td>
                <td class="border-color-right">
                  பெயர் எண் <br /> 7, 7
                </td>
                <td class="border-color-right">19</td>
                <td class="border-color-right">37</td>
                <td class="border-color-right">46</td>
                <td class="border-color-right">33</td>
                <td class="border-color-right">24</td>
                <td class="border-color-right">73</td>
                <td class="border-color-right">15</td>
                <td class="border-color-right">42</td>
                <td class="border-color-right">51</td>
                <td>60</td>
              </tr>
            </table>
          </div>
          <div className="card bg-transparent border-color p-2 my-2 fw-bold">
            <ul
              style={{
                padding: 0,
                margin: 0,
                marginLeft: "20px",
                fontSize: "13px",
              }}
            >
              <li className="ps-3 my-1">
                பின்வரும் பெயர் பட்டியலில் இல்லாத, உங்களுக்கு பிடித்த பெயரை,
                நியூமராலஜிப்படி கீழ்கண்டவாறு அமைத்துக் கொள்ளவும். பெயருடன்
                இனிஷியலையும் சேர்த்துகணக்கிட வேண்டும்.
              </li>
              <li className="ps-3 my-1">
                பின்வரும் பெயர் பட்டியலில் பெயர் திருத்தம் இருப்பின் தொடர்பு
                கொள்ளவேண்டிய தொலைபேசி எண்கள் 9787420717,9092020717 .{" "}
              </li>
            </ul>
          </div>
          <div className="heading my-1">
            <h2 className="fw-bold fs-6 headingh2">
              பெயரின் கூட்டு எண் கணக்கிடும் முறை (உதாரணம்)
            </h2>
          </div>
          <div
            className="card bg-transparent border-color p-2 my-2 fw-bold"
            style={{ color: "#1d83a5" }}
          >
            <div className="row">
              <div className="col-6">
                <div style={{ fontWeight: "bold", textIndent: "30px" }}>
                  G. R A J K U M A R <br />
                </div>
                <div style={{ fontWeight: "bold", textIndent: "30px" }}>
                  3. 2 1 1 2 6 4 1 2 = 22 <br />
                </div>
                <div style={{ textIndent: "102px", fontWeight: "bold" }}>
                  2 + 2 = 4
                </div>
              </div>
              <div className="col-6" style={{ paddingTop: "20px" }}>
                பெயரின் கூட்டு எண்{" "}
                <span style={{ marginLeft: "60px", fontWeight: "bold" }}>
                  {" "}
                  = 22
                </span>{" "}
                <br /> பெயரின் நியூமராலஜி எண்{" "}
                <span style={{ fontWeight: "bold" }}>= 4</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sheet  border border-secondary mt-2">
        <div className="container-fluid p-0 m-0">
          <div className="">
            <img
              src={require("./images/baby_name_list_2.png")}
              alt=""
              classNameName="img-fluid"
            />

            {/* <img src="img/baby_name_list_2.png" alt="" className="img-fluid"> */}
          </div>
        </div>
      </section>
      <section className="sheet  border border-secondary mt-2 sheet-1">
        <div className="container-fluid py-2">
          <div className="heading my-2">
            <img
              src={require("./images/baby_name_heading_2.png")}
              alt=""
              classNameName="img-fluid"
            />
            {/* <img src="img/baby_name_heading_2.png" alt="" className="img-fluid"> */}
          </div>
          <div className="card p-1 bg-transparent border-color m-auto mt-5">
            <table
              className="table table-borderless fw-bold mb-0"
              id="jadhaga-table"
            >
              <tr  >
                <td >
                  பாலினம் <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.gender}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  தந்தை பெயர்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.father_name}</td>
              </tr>
              <tr>
                <td>
                  தாய் பெயர் <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.mother_name}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த தேதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.birth_date} (உயிர் எண் {personalInfo.final_uyir}{" "}
                  & உடல் எண் {personalInfo.final_udal} )
                </td>
              </tr>
              <tr>
                <td>
                  பிறந்த நேரம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.birth_time}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த இடம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.birth_place}</td>
              </tr>
              <tr>
                <td>
                  பிறந்த நட்சத்திரம் - பிறந்த பாதம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>
                  {personalInfo.natchathiram} -{personalInfo.patham}
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த ராசி - ராசி அதிபதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.rasi}-{personalInfo.rasi_athipathi}{" "}
                </td>
              </tr>
              <tr>
                <td >
                  லக்கினம் - லக்கினாதிபதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td >
                  {personalInfo.laknam}-{personalInfo.laknam_athipathi}{" "}
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  அதிஷ்ட எண்கள்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.lucky_number} </td>
              </tr>
              <tr>
                <td>
                  நட்சத்திர எழுத்துக்கள்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>
                  {personalInfo.natchathira_letter_english},{" "}
                  {personalInfo.natchathira_letter_tamil}
                </td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த கிழமை{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.kilamai}</td>
              </tr>
              <tr>
                <td>
                  தமிழ் தேதி <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.TamilDate}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  பக்ஷம் - திதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.patcham}-{personalInfo.thithi}
                </td>
              </tr>
              <tr>
                <td>
                  சூரிய உதயம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.sunrise}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  சூரிய அஸ்தமனம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.sunset}</td>
              </tr>
              <tr>
                <td>
                  சூரிய உதயாதி நாழிகை
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.suryaUthahiNaligai}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  கரணம் <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.karanam}</td>
              </tr>
              <tr>
                <td>
                  நித்திய யோகம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.yogam}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  யோகி முனை - யோகி நட்சத்திரம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.yogi}- {personalInfo.YogiStar}
                </td>
              </tr>
              <tr>
                <td>
                  யோகி கிரகம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td>{personalInfo.yogi}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  அவயோகி நட்சத்திரம் - கிரகம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.avayogi}-{personalInfo.avayogi_star}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      {starName.map((group, groupIndex) => (
  <section className="sheet border border-secondary mt-2 sheet-1" key={groupIndex}>
    <div className="container-fluid py-2">
      <div className="heading my-2">
        <img
          src={require("./images/baby_name_heading_3.png")}
          alt=""
          className="img-fluid"
        />
      </div>
      <div className="heading my-3">
        <h2 className="fw-bold fs-6 headingh2">
          குழந்தை "{personalInfo.natchathiram}" நட்சத்திரத்தில் பிறந்து உள்ளதால் பொருந்தும் பெயர்கள்
        </h2>
      </div>
      <div className="card p-1 bg-transparent border-color m-auto">
      <table className="table table-borderless fw-bold mb-0" id="natchathira-name-table">
  <tr>
    <th></th>
    <th>நியூமெராலஜி எண்</th>
    <th></th>
    <th>பிரமிட் எண்</th>
  </tr>
  {group.name_split.map((name, index) => (
    <tr key={index}>
      <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit', padding: "4px" }}>{name}</td>
      <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit' }} >{group.name_number_split[index]}</td>
      <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit' }}>{group.tamil_name_split[index]}</td>
      <td className="pyramid_color" style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit' }}>{group.pyramid_value_split[index]}</td>
    </tr>
  ))}
</table>

      </div>
    </div>
  </section>
))}

{CommonName.map((group, groupIndex) => (
  <section className="sheet border border-secondary mt-2 sheet-1" key={groupIndex}>
    <div className="container-fluid py-2">
      <div className="heading my-2">
        <img
          src={require("./images/baby_name_heading_3.png")}
          alt=""
          className="img-fluid"
        />
      </div>
      <div className="heading my-3">
        <h2 className="fw-bold fs-6 headingh2">
          குழந்தை "{personalInfo.natchathiram}" நட்சத்திரத்தில் பிறந்து உள்ளதால் பொருந்தும் பெயர்கள்
        </h2>
      </div>
      <div className="card p-1 bg-transparent border-color m-auto">
        <table className="table table-borderless fw-bold mb-0" id="natchathira-name-table">
          <tr>
            <th></th>
            <th>நியூமெராலஜி எண்</th>
            <th></th>
            <th>பிரமிட் எண்</th>
          </tr>
          {group.comman_name_split.map((name, index) => (
            <tr key={index}>
  <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit', padding: "4px" }}>{name}</td>
               <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit' }}>{group.comman_name_number_split[index]}</td>
          
               <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit' }}>{group.comman_tamil_name_split[index]}</td>
            
               <td style={{ backgroundColor: index % 2 === 0 ? '#BEE6F4' : 'inherit' }}>{group.comman_pyramid_value_split[index]}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  </section>
  
))}
 <section className="sheet sheet-1">
        <div className="container-fluid py-2">
            <div className="heading my-2">
                {/* <img src="img/baby_name_heading.png" alt="" className="img-fluid"> */}
                <img
          src={require("./images/baby_name_heading.png")}
          alt=""
          className="img-fluid"
        /> 
            </div>
            <div className="card bg-transparent border-0">
                <div className="row">
                    <div className="col-8 m-auto">
                        <div className="card bg-transparent p-2 border-color p-2 my-2 fw-bold" style={{ color: "#1d83a5"}}>
                            <p className="">நட்சத்திரப் பெயர்கள் <span className="float-end">{BabyNameCount.common_name}</span></p>
                            <p className="">பொதுப் பெயர்கள் <span className="float-end">{BabyNameCount.total_name}</span></p>
                            <p className="mb-0">மொத்தப் பெயர்கள் <span className="float-end ">{BabyNameCount.total_name_list}</span></p>
                        </div>
                    </div>
                    <div className="col-8 m-auto">
                        <div className="card bg-transparent p-2 border-color py-4 my-2 fw-bold" style={{ color: "#1d83a5"}}>
                            <p className="my-0">எல்லா நலன்களும் பெற்று வாழ்வில் உயர்வடையப் பிரார்த்திக்கிறோம்</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}
