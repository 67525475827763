// import React from "react";
import "./BabyNameView.css";
import BabyNameTemplate from "../BabyNameTemplate/BabyNameTemplate";
// import TestSheet from "../BabyNameTemplate/TestSheet";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect } from "react";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import OnepagePaidTemplate from "../onepagePaidTemplate/onepagePaidtemplate";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import getepayPortal from "../Getepay_pg_react";
import BabyNameSample from "../BabyNameSample/BabyNameSample";

import axios from "axios";
import SampleTemplate from "../sample-template/Sample-Template";
export default function BabyNameView({ value }) {
  // console.log(value.personal_info);
  return (
    <>
      <BabyNameDetails value={value} />
      {/* <BabyNameSample value={value} /> */}
      {/* <BabyNameTemplate  value={value} /> */}
      <FixButton value={value} /> 
    </>
  );
}

function BabyNameDetails({ value }) {
  const personlInfo = value.personal_info;
  return (
    <>
      <section style={{ marginTop: "150px" }}>
        <div className="container">
          <div className="row justify-content-between">
            <h1
              className="text-danger fs-3 text-center tableview"
              style={{ marginTop: "20px" }}
            >
              Baby Name - குழந்தை பெயர் பட்டியல்
            </h1>
            <div className="col-lg-12">
              <table
                className="table table-bordered border text-dark"
                id="baby_view"
              >
                <tbody>
                  <tr>
                    <th colSpan="2" className="text-center">
                      குழந்தை விபரம்.
                    </th>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#bee6f4" }}
                    >
                      {" "}
                      பிறந்த தேதி{" "}
                    </td>
                    <td style={{ backgroundColor: "#bee6f4" }}>
                      {" "}
                      {personlInfo.birth_date}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold"> பிறந்த நேரம் </td>
                    <td> {personlInfo.birth_time} </td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#bee6f4" }}
                    >
                      {" "}
                      பிறந்த ஊர்{" "}
                    </td>
                    <td style={{ backgroundColor: "#bee6f4" }}>
                      {personlInfo.birth_place}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">தந்தை பெயர் </td>
                    <td> {personlInfo.father_name} </td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#bee6f4" }}
                    >
                      {" "}
                      தாய் பெயர்{" "}
                    </td>
                    <td style={{ backgroundColor: "#bee6f4" }}>
                      {" "}
                      {personlInfo.mother_name}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">இனிசியல் </td>
                    <td>{personlInfo.Initial} </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#bee6f4" }}> தமிழ் தேதி</td>
                    <td style={{ backgroundColor: "#bee6f4" }}>
                      {" "}
                      {personlInfo.TamilDate}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">லக்கனம்</td>
                    <td>{personlInfo.laknam}</td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#bee6f4" }}
                    >
                      ராசி
                    </td>
                    <td style={{ backgroundColor: "#bee6f4" }}>
                      {personlInfo.rasi}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold"> நட்சத்திரம்</td>
                    <td>
                      {" "}
                      {personlInfo.natchathiram} -{personlInfo.patham}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="fw-bold"
                      style={{ backgroundColor: "#bee6f4" }}
                    >
                      யோக நட்சத்திரம்
                    </td>
                    <td style={{ backgroundColor: "#bee6f4" }}>
                      {" "}
                      {personlInfo.YogiStar}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold"> நட்சத்திர எழுத்துக்கள் </td>
                    <td>
                      {" "}
                      {personlInfo.natchathira_letter_english}{" "}
                      {personlInfo.natchathira_letter_tamil}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function FixButton({ value }) {
  const [buyerMobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [buyerName, setBuyername] = useState("");
  const [mobilenumberError, setMobieNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerNameError, setBuyerNameError] = useState("");
  const userInfoJSON = localStorage.getItem("BabayUserInfo");

  // Initialize state variables with null
  const [user_id, setUserId] = useState(null);
  const [customerDataId, setcustomerDataId] = useState(null);

  useEffect(() => {
    if (userInfoJSON) {
      const userInfo = JSON.parse(userInfoJSON);
      console.log('value is',userInfoJSON);
      
      setUserId(userInfo.user_id);
      // console.log('user_id is',user_id);
      setcustomerDataId(userInfo.customerDataId);
    }
  }, [userInfoJSON]);
  
// console.log('outside',user_id);
    const paidpdfConvertion = () => {
    return <BabyNameTemplate value={value} />;
  };
  const validateInput = () => {
    let hasError = false;

    if (!email) {
      setEmailError("Field is required");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!buyerName) {
      setBuyerNameError("Field is required");
      hasError = true;
    } else {
      setBuyerNameError("");
    }

    if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
      setMobieNumberError("Field is required and must be 10 digits");
      hasError = true;
    } else {
      setMobieNumberError("");
    }

    return !hasError;
  };

 
  const handlePay = () => {
    if (validateInput()) {
      const babyGeometry = localStorage.getItem("babyNameformdata");
      const geoLocation = JSON.parse(babyGeometry);
      const latitude = geoLocation.latitude;
      const longitude = geoLocation.longitude;
  
      const userData = {
        buyerName,
        buyerEmail: email,
        buyerMobileNo: buyerMobilenumber,
        DateofBirth: value.personal_info.birth_date,
        birthTime: value.personal_info.birth_time,
        gender: value.personal_info.gender,
        father_name: value.personal_info.father_name,
        mother_name: value.personal_info.mother_name,
        birthPlace: value.personal_info.birth_place,
        Amount: 249,
        Package: "baby_name",
        latitude,
        longitude,
      };
  
      const storedUserInfo = localStorage.getItem("BabayUserInfo");
      const userInfo = JSON.parse(storedUserInfo);
  
      const user_data_id = userInfo.user_id;
      const customer_data_id = userInfo.customerDataId;
  
      if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
        proceedToPayment();
      } else {
        if (user_data_id === null && customer_data_id === null) {
          axios
            .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", {
              userData,
            })
            .then((response) => {
              const userId = response.data.data.userId;
              const customerDataId = response.data.data.customerDataId;
              const newUserData = {
                user_id: userId,
                customerDataId,
              };
              const userInfoValues = JSON.stringify(newUserData);
              localStorage.setItem("BabayUserInfo", userInfoValues);
              setcustomerDataId(customerDataId);
              setUserId(userId);
              proceedToPayment();
            });
        } else {
          proceedToPayment();
        }
      }
    }
  };
  
  const proceedToPayment = () => {
    const userInfoJSON = localStorage.getItem("BabayUserInfo");
    const userInfo = JSON.parse(userInfoJSON);
    let user_id=userInfo.user_id;
    let customer_id=userInfo.customerDataId;
    const data = {
      mid: "993377",
      amount: "249.00",
      merchantTransactionId: "sd12121",
      transactionDate: "Mon Oct 03 13:54:33 IST 2022",
      terminalId: "getepay.merchant131068@icici",
      udf1: buyerMobilenumber,
      udf2: user_id,
      udf3: buyerName,
      udf4: customer_id,
      udf5: "",
      udf6: "",
      udf7: "",
      udf8: "",
      udf9: "",
      udf10: "",
      ru: "https://ctsvsolutions.com/onepage-Api/public/Baby-Payment",
      callbackUrl: "",
      currency: "INR",
      paymentMode: "ALL",
      bankId: "",
      txnType: "single",
      productType: "IPG",
      txnNote: "One-Page Baby Name",
      vpa: "getepay.merchant131068@icici",
    }; 
  // console.log(data);
  const Config = {
    "mid":"993377",
    "Getepay Terminal Id": "getepay.merchant131068@icici",
    "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
      "Getepay Url":
        "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
    };
    getepayPortal(data, Config);
    // console.log(data);
  };

  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];
    const paymentId = queryParamsObject["payment_id"];
    if (payment_status === "Failure") {
      Report.warning(
        "Notiflix Warning",
        '"The peoples who want to live comfortably without producing and fatigue; they are doomed to lose their dignity, then liberty, and then independence and destiny." <br/><br/>- Mustafa Kemal Ataturk',
        "Okay"
      );
    }
    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Succuss",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Baby Name is Genarating...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          const storedUserInfo = localStorage.getItem("BabayUserInfo");
          const userInfo = JSON.parse(storedUserInfo);
                const printElement = ReactDOMServer.renderToString(
                  paidpdfConvertion()
                );
                html2pdf()
                  .from(printElement)
                  .set({
                    image: { type: "jpeg", quality: 1 },
                    dpi: 300,
                    renderer: "canvas",
                    html2canvas: { scale: 2 },
                  })
                  .save();

                html2pdf()
                  .from(printElement)
                  .set({
                    image: { type: "jpeg", quality: 1 },
                    dpi: 300,
                    renderer: "canvas",
                    html2canvas: { scale: 2 },
                  })
                  .outputPdf()
                  .then((pdfData) => {
                    const base64Data = btoa(pdfData);
                    savePaidPdf(
                      base64Data,
                      userInfo.user_id,
                      userInfo.customerDataId
                    );
                  });
              });
            }
    }
  
  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName: "baby_name",
    };
    // console.log(pdfDataVal);
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName: "pdf_file",
    };
    console.log(DataInfo);
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-pdf-Whastapp", DataInfo)
          .then((response) => {
            axios
              .post("https://ctsvsolutions.com/onepage-Api/public/api/Baby-Name-Email", DataInfo)
              .then((response) => {
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo = {
                      user_id: user_id,
                      customer_data_id: customerDataId,
                      feedback: clientAnswer,
                    };
                    axios
                      .post(
                        "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                        RatingInfo
                      )
                      .then((response) => {
                        window.location.replace("/BabyName-ThankYou");
                        // window.location = "/BabyName-ThankYou";
                      });
                  },
                  (clientAnswer) => {
                    window.location.replace("/BabyName-ThankYou");
                  }
                );
                Notify.success("BabyName pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  const navigate = useNavigate();
  
  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const editForm = () => {
    const outputvalue = JSON.parse(localStorage.getItem("babyNameformdata"));
    customRedirect("/Baby-Name-List", outputvalue);
    // console.log(outputvalue);
  };

  const savesamplePdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName:'baby_NameList_sample'
    };
    const Whatsappfileinfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"sample_pdf"
    };
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/save-sample-pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/Send-SampleIn-whatsapp",
            Whatsappfileinfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                Whatsappfileinfo
              )
              .then((response) => {
                Loading.remove(); 
                window.location="/BabyName-sample-success"
                Notify.success("Sample pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  const PdfSampleProcess = async (userData) => {
    try {
      const response = await axios.post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", { userData });
      const user_data_id = response.data.data.userId;
      const customer_data_id = response.data.data.customerDataId;
      const userInfo = {
        user_id: user_data_id,
        customerDataId: customer_data_id,
      };
      const UserInfoValues = JSON.stringify(userInfo);
      localStorage.setItem("BabayUserInfo", UserInfoValues);
      setcustomerDataId(customer_data_id);
      setUserId(user_data_id);
      
      
      await new Promise(resolve => setTimeout(resolve, 1000)); 
      
      const printElement = ReactDOMServer.renderToString(<BabyNameSample value={value} />);
      html2pdf()
        .from(printElement)
        .set({
          image: { type: 'jpeg', quality: 1 },
          dpi: 300,
          renderer: 'canvas',
          html2canvas: { scale: 2 },
        })
        .save();
  
      const pdfData = await html2pdf()
        .from(printElement)
        .set({
          image: { type: 'jpeg', quality: 1 },
          dpi: 300,
          renderer: 'canvas',
          html2canvas: { scale: 2 },
        })
        .outputPdf();
  
      const base64Data = btoa(pdfData);
      savesamplePdf(base64Data, user_data_id, customer_data_id);
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  }
  
  const insertData = () => {
    const BbayGeomenery=localStorage.getItem("babyNameformdata");
      const GeoLocation=JSON.parse(BbayGeomenery);
      const latitude=GeoLocation.latitude;
      const longitude=GeoLocation.longitude;

      const userData = {
        buyerName: buyerName,
        buyerEmail: email,
        buyerMobileNo: buyerMobilenumber,
        DateofBirth: value.personal_info.birth_date,
        birthTime: value.personal_info.birth_time,
        gender: value.personal_info.gender,
        father_name:value.personal_info.father_name,
        mother_name:value.personal_info.mother_name,
        birthPlace: value.personal_info.birth_place,
        Amount: 249,
        Package: "baby_name",
        latitude:latitude,
        longitude:longitude

      };
   
    Loading.hourglass("Generating sample Horoscope....", {
      backgroundColor: "rgba(0,0,0,0.8)",
    });
    const storedUserInfo = localStorage.getItem("BabayUserInfo");
    const userInfo = JSON.parse(storedUserInfo);

    const user_data_id = userInfo.user_id;
    const customer_data_id = userInfo.customerDataId;

    if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
      axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Check-Sample-Eligible", { userInfo })
      .then((response) => {
          const samplePdf=response.sample_pdf;
          if(samplePdf === null || samplePdf==="")
          {
            const printElement = ReactDOMServer.renderToString(<BabyNameSample value={value}/>);
            html2pdf()
              .from(printElement)
              .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300, 
                renderer: 'canvas', 
                html2canvas:  { scale: 2 },
              })
              .save();
        
            html2pdf()
            .from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300, 
              renderer: 'canvas', 
              html2canvas:  { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savesamplePdf(base64Data, user_id, customerDataId);
            });
          } 
          else{
            const printElement = ReactDOMServer.renderToString(<BabyNameSample value={value} />);
            html2pdf()
              .from(printElement)
              .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300, 
                renderer: 'canvas', 
                html2canvas:  { scale: 2 },
              })
              .save();
              Loading.remove();
     Notify.success('Pdf Saved Successfully...!');

          }
      })
    }
    else{
      PdfSampleProcess(userData);
    }

  };
  const samplePrintHandler = async () => {
    if (validateInput()) { 
      insertData();
    }
  };
  
  return (
    <>
    <div
          className="modal fade"
          id="staticBackdrop1"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Download Free Pdf
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container py-3 rounded shadow-lg" id="package">
                  <form className="mt-5" id="free-pdf-form">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label text-dark fw-bold"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Mail"
                        name="email"
                        id="email1"
                        aria-describedby="emailHelp"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span id="email_error1" style={{ color: "red" }}>
                        {emailError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name1"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={buyerName}
                        onChange={(e) => setBuyername(e.target.value)}
                      />
                      <span id="name_error1" style={{ color: "red" }}>
                        {buyerNameError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        name="mobile_number"
                        className="form-control"
                        pattern="[6789][0-9]{9}"
                        placeholder="Enter Your Number"
                        id="mobile_number1"
                        value={buyerMobilenumber}
                        onChange={(e) => setMobilenumber(e.target.value)}
                      />
                      <span id="mobile_number_error1" style={{ color: "red" }}>
                        {mobilenumberError}
                      </span>
                    </div>
                    <button
                      type="button"
                      name="free"
                      id="free_pdf"
                      className="text-center but1 mt-3"
                      onClick={samplePrintHandler}

                    >
                      Print
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    {/* payment popup */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="staticBackdropLabel">
                Download Pdf
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container py-3 rounded shadow-lg" id="package">
                <div className="p-3">
            
                </div>
                <form
                  className="mt-2"
                  action=""
                  method="post"
                  id="payment_form"
                >
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-dark fw-bold"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Mail"
                      name="email"
                      id="email"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      onChange={(e) => setBuyername(e.target.value)}
                      value={buyerName}
                    />

                    <span id="name_error1" style={{ color: "red" }}>
                      {buyerNameError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Whatsapp Number
                    </label>
                    <input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      pattern="[6789][0-9]{9}"
                      placeholder="Enter Your Whatsapp Number"
                      id="mobile_number"
                      value={buyerMobilenumber}
                      onChange={(e) => setMobilenumber(e.target.value)}
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {mobilenumberError}
                    </span>
                  </div>
                  <button
                    type="button"
                    name="pay"
                    id="pay"
                    onClick={handlePay}
                    className="text-center but1 mt-3"
                  >
                    Buy Now
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="floating-button w-100">
        <div className="container bg-white p-3 rounded shadow-lg w-100">
          <div className="d-grid gap-2 d-md-flex justify-content-md-center">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                editForm();
              }}
            >
              ✎ Edit
            </button>
            {/* <button
              className="btn btn-warning"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop1"
            >
              <i className="fa-solid fa-download"></i> Free Sample PDF
            </button> */}
            <button
              className="btn btn-success"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <i className="fa-solid fa-download"></i> Download PDF
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
