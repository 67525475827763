import "./Horoscope-View.css";

import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import SampleTemplate from "../sample-template/Sample-Template";
import axios from "axios";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
import OnepagePaidTemplate from "../onepagePaidTemplate/onepagePaidtemplate";
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

import getepayPortal from "../Getepay_pg_react";

export default function HoroscopeView() {


  const value = JSON.parse(localStorage.getItem("onepageValues"));

  return (
    <>
      <Table_1 value={value} />
      <Table_2 value={value} />
      <Table_3 value={value} />
      <FixButton value={value} />
    </>
  );
}
function Table_1({ value }) {
  return (
    <div style={{ marginTop: "160px" }}>
      <div className="container">
        <div className="row justify-content-between">
          <h1 className="text-danger fs-5 text-center fw-bold mb-4 tableview">
            பிறந்த தேதி பஞ்சாங்கம்
          </h1>
          <div className="col-lg-6">
            <table className=" table-bordered text-dark w-100" id="panjag">
              <tbody>
                <tr>
                  <th colSpan="2" className="text-center">
                    பஞ்சாங்க விபரம்.
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#E1F5FE" }}>
                  <td className="fw-bold"> பெயர் </td>
                  <td>{value.personal_info.name}</td>
                </tr>

                <tr>
                  <td className="fw-bold"> பிறந்த தேதி </td>
                  <td>{value.personal_info.birth_date}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> பிறந்த நேரம் </td>
                  <td>{value.personal_info.birth_time}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> பிறந்த ஊர் </td>
                  <td>{value.personal_info.birth_place}</td>
                </tr>
                <tr>
                  <td>தமிழ் தேதி</td>
                  <td>{value.personal_info.birth_day}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> லக்கனம்</td>
                  <td>{value.personal_info.laknam}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> ராசி</td>
                  <td>{value.personal_info.rasi}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> நட்சத்திரம் </td>
                  <td>{value.personal_info.natchathiram}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-6">
            <table className=" table-bordered text-dark w-100" id="panjag">
              <tbody>
                <tr>
                  <th colSpan="2" className="text-center">
                    பஞ்சாங்க விபரம்.
                  </th>
                </tr>
                <tr>
                  <td className="fw-bold"> திதி </td>
                  <td>{value.personal_info.thithi}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> யோகம் </td>
                  <td>{value.personal_info.yogam}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> கரணம் </td>
                  <td>{value.personal_info.karanam}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> அயனாம்சம் </td>
                  <td>லஹரி</td>
                </tr>
                <tr>
                  <td className="fw-bold"> யோக நட்சத்திரம் </td>
                  <td>{value.personal_info.yoga_natchathiram}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> யோகி </td>
                  <td>{value.personal_info.yogi}</td>
                </tr>
                <tr>
                  <td className="fw-bold"> அவயோக நட்சத்திரம் </td>
                  <td>{value.personal_info.avayogi_natchathiram}</td>
                </tr>
                <tr>
                  <td className="fw-bold">அவயோகி</td>
                  <td>{value.personal_info.avayogi}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function Table_2({ value }) {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="text-danger fs-3 fw-bold mt-3 text-center">
              நிராயன ஸ்புடம்
            </h1>
            <div className="table-responsive">
              <table
                className="w-100 table-bordered text-nowrap text-dark mt-3 blur-effect"
                id="tableview"
              >
                <thead>
                  <tr>
                    <th className="fs-6">கிரகம்</th>
                    <th className="fs-6">பாகை-கலை</th>
                    <th className="fs-6">நட்சத்திரம்</th>
                    <th className="fs-6">பாதம்</th>
                    <th className="fs-6">நட்-அதிபதி</th>
                    <th className="fs-6">ராசி</th>
                    <th className="fs-6">பாகை-கலை</th>
                    <th className="fs-6">நிலை</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(value.tableData) ? (
                    value.tableData.map((item, index) => (
                      <tr key={index}>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.kiragam}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.Degree}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.natchathiram}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.patham}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.natchathira_athipathi}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.rasi}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.degreeConversion}
                        </td>
                        <td
                          className={
                            index === 0 ||
                            index === 2 ||
                            index === 4 ||
                            index === 6 ||
                            index === 8 ||
                            index === 10
                              ? ""
                              : "blur-eff"
                          }
                        >
                          {item.nilai}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Table_3({ value }) {
  const styles = {
    birthTable: {
      width: '100%',
      background: '#fffee0',
      position: 'relative',
    },
    rasiBirth: {
      border: '2px solid #ff9933 ',
      width: '100px',
      height: '100px',
      fontSize: '15px',
      color: '#000',
      paddingLeft: '5px',
      position: 'relative',
    },
    rasiBirthHover: {
      backgroundColor: '#e4eefe',
    },
    rasiChart: {
      fontSize: '20px',
      fontWeight: '600',
    },
    laknamStyle1: {
      after: {
        content: '',
        width: '23px',
        border: '1px solid #ff9933',
        position: 'absolute',
        top: '7px',
        left: '-4px',
        transform: 'rotate(136deg)',
      },
      before: {
        content: '',
        width: '35px',
        border: '1px solid #ff9933',
        position: 'absolute',
        top: '11px',
        left: '-6px',
        transform: 'rotate(136deg)',
      },
    },
    mediaQuery1024: {
      rasiBirth: {
        width: '75px',
        height: '75px',
        fontSize: '9px',
        paddingLeft: '5px',
        fontWeight: 'bold',
      },
    },
    chartBirth: {
      position: 'relative',
    },
    mediaQuery768: {
      rasiBirth: {
        width: '80px',
        height: '80px',
        fontSize: '10px',
        fontWeight: 'bold',
        paddingLeft: '12px',
      },
    },
    mediaQuery500: {
      rasiBirth: {
        width: '80px',
        height: '90px',
        fontSize: '10px',
        fontWeight: 'bold',
        paddingLeft: '5px',
      },
    },
    mediaQuery375: {
      rasiBirth: {
        width: '70px',
        height: '70px',
        fontSize: '8px',
        fontWeight: 'bold',
        paddingLeft: '5px',
      },
      laknamStyle1After: {
        content: '',
        width: '23px',
        border: '1px solid #ff9933',
        position: 'absolute',
        top: '7px',
        left: '-4px',
        transform: 'rotate(136deg)',
      },
      laknamStyle1Before: {
        content: '',
        width: '19px',
        border: '1px solid #ff9933',
        position: 'absolute',
        top: '4px',
        left: '-4px',
        transform: 'rotate(136deg)',
      },
    },
  };
  const rasiChat = value.rasi_array;
  const Navamsam_chart = value.amsam_array;
  
  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <h1 className="text-danger fs-3 fw-bold my-5 text-center">
            ஜாதக கட்டம்
          </h1>
          <div className="col-md-5 my-2">
            <table  style={styles.birthTable}  className="birth-table" cellSpacing="2" cellPadding="2">
              <tbody>
                <tr>
                  <td style={styles.rasiBirth} className="">{rasiChat.r12}</td>
                  <td style={styles.rasiBirth} className="">{rasiChat.r1}</td>
                  <td style={styles.rasiBirth} className="">{rasiChat.r2}</td>
                  <td style={styles.rasiBirth} className="">{rasiChat.r3}</td>
                </tr>
                <tr>
                  <td className="" style={styles.rasiBirth}>{rasiChat.r11}</td>
                  <td
                    className="text-center"
                    width="220"
                    height="220"
                    colSpan="2"
                    rowSpan="2"
                  >
                    <p  style={styles.rasiChart} >ராசி</p>
                  </td>
                  <td style={styles.rasiBirth}>{rasiChat.r4}</td>
                </tr>
                <tr>
                  <td style={styles.rasiBirth}>{rasiChat.r10}</td>
                  <td style={styles.rasiBirth}>{rasiChat.r5}</td>
                </tr>
                <tr>
                  <td style={styles.rasiBirth}>{rasiChat.r9}</td>
                  <td style={styles.rasiBirth} valign="middle">
                    {rasiChat.r8}
                  </td>
                  <td style={styles.rasiBirth} valign="middle">
                    {rasiChat.r7}
                  </td>
                  <td style={styles.rasiBirth} valign="middle">
                    {rasiChat.r6}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-5 my-2">
            <table className="birth-table" style={styles.birthTable} cellSpacing="2" cellPadding="2">
              <tbody>
                <tr>
                  <td style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a12}</td>
                  <td style={styles.rasiBirth}  className=" blur-eff">{Navamsam_chart.a1}</td>
                  <td style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a2}</td>
                  <td style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a3}</td>
                </tr>
                <tr>
                  <td  style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a11}</td>
                  <td
                    className="text-center"
                    width="220"
                    height="220"
                    colSpan="2"
                    rowSpan="2"
                  >
                    <p style={styles.rasiChart} className="">அம்சம்</p>
                  </td>
                  <td style={styles.rasiBirth}  className=" blur-eff">{Navamsam_chart.a4}</td>
                </tr>
                <tr>
                  <td  style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a10}</td>
                  <td style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a5}</td>
                </tr>
                <tr>
                  <td style={styles.rasiBirth} className=" blur-eff">{Navamsam_chart.a9}</td>
                  <td style={styles.rasiBirth} className=" blur-eff" valign="middle">
                    {Navamsam_chart.a8}
                  </td>
                  <td  style={styles.rasiBirth}  className=" blur-eff" valign="middle">
                    {Navamsam_chart.a7}
                  </td>
                  <td style={styles.rasiBirth} className=" blur-eff" valign="middle">
                    {Navamsam_chart.a6}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

function FixButton({ value }) {
  const [buyerMobilenumber, setMobilenumber] = useState("");
  const [email, setEmail] = useState("");
  const [buyerName, setBuyername] = useState("");
  const [mobilenumberError, setMobieNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerNameError, setBuyerNameError] = useState("");

  const userInfoJSON = localStorage.getItem("userinfo");
  let initialUserId = null;
  let initialCustomerDataId = null;
  
  if (userInfoJSON) {
    const userInfo = JSON.parse(userInfoJSON);
    initialUserId = userInfo.user_id;
    initialCustomerDataId = userInfo.customerDataId;
  }
  
  const [user_id, setUserId] = useState(initialUserId);
  const [customerDataId, setcustomerDataId] = useState(initialCustomerDataId);
  
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userInfo); 
    if (userInfo) {
      setUserId(userInfo.user_id);
      setcustomerDataId(userInfo.customerDataId);
    }
  }, []);


  const pdfJSX = () => {
    return <SampleTemplate value={value} />;
  };

  const savesamplePdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName:'onepage_sample'
    };
    const Whatsappfileinfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"sample_pdf"
    };
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/save-sample-pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/Send-SampleIn-whatsapp",
            Whatsappfileinfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                Whatsappfileinfo
              )
              .then((response) => {
                Loading.remove(); 
                window.location="/onepage-sample-success"
                Notify.success("Sample pdf file sended succussfully");
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };

  const validateInput = () => {
    let hasError = false;

    if (!email) {
      setEmailError("Field is required");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!buyerName) {
      setBuyerNameError("Field is required");
      hasError = true;
    } else {
      setBuyerNameError("");
    }

    if (!buyerMobilenumber || buyerMobilenumber.length !== 10) {
      setMobieNumberError("Field is required and must be 10 digits");
      hasError = true;
    } else {
      setMobieNumberError("");
    }

    return !hasError;
  };


const PdfSampleProcess=(userData)=>{
  axios
  .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", { userData })
  .then((response) => {
    const user_data_id = response.data.data.userId;
    const customer_data_id = response.data.data.customerDataId;
    const userInfo = {
      user_id: user_data_id,
      customerDataId: customer_data_id,
    };
    const UserInfoValues = JSON.stringify(userInfo);
    localStorage.setItem("userInfo", UserInfoValues);
    setcustomerDataId(customer_data_id);
    setUserId(user_data_id);
    const printElement = ReactDOMServer.renderToString(pdfJSX());
    html2pdf()
      .from(printElement)
      .set({
        image: { type: 'jpeg', quality: 1 },
        dpi: 300, 
        renderer: 'canvas', 
        html2canvas:  { scale: 2 },
      })
      .save();

    html2pdf()
    .from(printElement)
    .set({
      image: { type: 'jpeg', quality: 1 },
      dpi: 300, 
      renderer: 'canvas', 
      html2canvas:  { scale: 2 },
    })
    .outputPdf()
    .then((pdfData) => {
      const base64Data = btoa(pdfData);
      savesamplePdf(base64Data, user_data_id, customer_data_id);
    });
  
  })
  .catch((error) => {
    console.error("Error inserting data:", error);
  });
  
}

  const insertData = () => {
    const OnepageGeomenery=localStorage.getItem("onepageformdata");
const GeoLocation=JSON.parse(OnepageGeomenery);
const latitude=GeoLocation.latitude;
const longitude=GeoLocation.longitude;
    const userData = {
      buyerName: buyerName,
      buyerEmail: email,
      buyerMobileNo: buyerMobilenumber,
      name: value.personal_info.name,
      DateofBirth: value.personal_info.birth_date,
      birthTime: value.personal_info.birth_time,
      gender: value.personal_info.gender,
      birthPlace: value.personal_info.birth_place,
      Package:'one_page',
      Amount:"99",
      latitude:latitude,
      longitude:longitude
    };
   
    Loading.hourglass("Generating sample Horoscope....", {
      backgroundColor: "rgba(0,0,0,0.8)",
    });
    const storedUserInfo = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(storedUserInfo);

    const user_data_id = userInfo.user_id;
    const customer_data_id = userInfo.customerDataId;

    if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
      axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Check-Sample-Eligible", { userInfo })
      .then((response) => {
          const samplePdf=response.sample_pdf;
          if(samplePdf === null || samplePdf==="")
          {
            const printElement = ReactDOMServer.renderToString(pdfJSX());
            html2pdf()
              .from(printElement)
              .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300, 
                renderer: 'canvas', 
                html2canvas:  { scale: 2 },
              })
              .save();
        
            html2pdf()
            .from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300, 
              renderer: 'canvas', 
              html2canvas:  { scale: 2 },
            })
            .outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savesamplePdf(base64Data, user_id, customerDataId);
            });
          }
          else{
            const printElement = ReactDOMServer.renderToString(pdfJSX());
            html2pdf()
              .from(printElement)
              .set({
                image: { type: 'jpeg', quality: 1 },
                dpi: 300, 
                renderer: 'canvas', 
                html2canvas:  { scale: 2 },
              })
              .save();
              Loading.remove();
          }
      })
    }
    else{
      PdfSampleProcess(userData);
    }

  };

  const printHandler = () => {
    if (validateInput()) {
      
      insertData();
    }
  };

  const navigate = useNavigate();

  const customRedirect = (route, data) => {
    navigate(route, { state: data });
  };

  const editForm = () => {
    const outputvalue = JSON.parse(localStorage.getItem("formdata"));
    customRedirect("/Birth-horoscope", outputvalue);
  };

  const handlepay = () => {
    if (validateInput()) {
      const storedUserInfo = localStorage.getItem("userInfo");
      const userInfo = JSON.parse(storedUserInfo);
  
      const user_data_id = userInfo.user_id;
      const customer_data_id = userInfo.customerDataId;
      if ((user_data_id !== "" && user_data_id !== null) && (customer_data_id !== "" && customer_data_id !== null)) {
        proceedToPayment();
      } else {
        if (user_data_id === null && customer_data_id === null) {
          const OnepageGeomenery=localStorage.getItem("onepageformdata");
          const GeoLocation=JSON.parse(OnepageGeomenery);
          const latitude=GeoLocation.latitude;
          const longitude=GeoLocation.longitude;
          const userData = {
            buyerName: buyerName,
            buyerEmail: email,
            buyerMobileNo: buyerMobilenumber,
            name: value.personal_info.name,
            DateofBirth: value.personal_info.birth_date,
            birthTime: value.personal_info.birth_time,
            gender: value.personal_info.gender,
            birthPlace: value.personal_info.birth_place,
            Amount:99,
            Package:'one_page',
            latitude:latitude,
            longitude:longitude
          };
          axios
            .post("https://ctsvsolutions.com/onepage-Api/public/api/Statement-customerData-insert", {
              userData,
            })
            .then((response) => {
              const userId = response.data.data.userId;
              const customerDataId = response.data.data.customerDataId;
              const userInfo = {
                user_id: userId,
                customerDataId: customerDataId,
              };
              const UserInfoValues = JSON.stringify(userInfo);
              localStorage.setItem("userInfo", UserInfoValues);
              setcustomerDataId(customerDataId);
              setUserId(userId);
              // console.log('its from 2nd');
              proceedToPayment(userId);
            });
        } else {
          // console.log('its from 3rd');
          proceedToPayment();
        }
      }
    }
  };
  
  
  const proceedToPayment = () => {
    const storedUserInfo = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(storedUserInfo);

    const user_id = userInfo.user_id;
    const customerDataId = userInfo.customerDataId;
    const data = {
      mid: "993377",
      amount: "99.00",
      merchantTransactionId: "sd12121",
      transactionDate: "Mon Oct 03 13:54:33 IST 2022",
      terminalId: "getepay.merchant131068@icici",
      udf1: buyerMobilenumber,
      udf2: email,
      udf3: buyerName,
      udf4: user_id,
      udf5: customerDataId,
      udf6: "",
      udf7: "",
      udf8: "",
      udf9: "",
      udf10: "",
      ru: "https://ctsvsolutions.com/onepage-Api/public/onepage-payment",
      callbackUrl: "",
      currency: "INR",
      paymentMode: "ALL",
      bankId: "",
      txnType: "single",
      productType: "IPG",
      txnNote: "Onepage Horoscope",
      vpa: "getepay.merchant131068@icici",
    }; 
  
  const Config = {
    "mid":"993377",
    "Getepay Terminal Id": "getepay.merchant131068@icici",
    "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
    "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
      "Getepay Url":
        "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
    };
    // console.log(data);
    getepayPortal(data, Config);
  };
  

  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const payment_status = queryParamsObject["payment_status"];
    const paymentId=queryParamsObject["payment_id"];
    if (payment_status === "Filure") {
      Report.warning(
        "Notiflix Warning",
        '"The peoples who want to live comfortably without producing and fatigue; they are doomed to lose their dignity, then liberty, and then independence and destiny." <br/><br/>- Mustafa Kemal Ataturk',
        "Okay"
      );
    }
    if (payment_status === "CrEdiT") {
      Report.success(
        "Payment Succuss",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        // "
        // "Thank you for your payment! Your transaction has been completed. You can now download your PDF document by clicking the button below:",
        "Download Pdf",
        () => {
          Loading.hourglass("Generating Birth Horoscope....", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
          

          const storedUserInfo = localStorage.getItem("userInfo");
          const userInfo = JSON.parse(storedUserInfo);
            
                  const printElement = ReactDOMServer.renderToString(
                    paidpdfConvertion()
                    );
                    html2pdf()
                    .from(printElement)
                    .set({
                      image: { type: 'jpeg', quality: 1 },
                      dpi: 300, 
                      renderer: 'canvas', 
                      html2canvas:  { scale: 2 },
                    })
                    .save();
                    
                    html2pdf().from(printElement) 
                    .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300, 
              renderer: 'canvas', 
              html2canvas:  { scale: 2 },
            }).outputPdf()
                      .then((pdfData) => {
                        const base64Data = btoa(pdfData);
                        savePaidPdf(base64Data,userInfo.user_id,userInfo.customerDataId);
                      });
        }
      );
    }
  }


  const paidpdfConvertion = () => {
    return <OnepagePaidTemplate value={value} />;
  };

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData,
      userId: user_id,
      customerId: customerDataId,
      FileName:'one_page'
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"pdf_file"
    };
    axios
      .post("https://ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                "https://ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
               
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo={
                      'user_id':user_id,
                      'customer_data_id':customerDataId,
                      'feedback':clientAnswer                
                   }
                    axios
                    .post(
                      "https://ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                      RatingInfo
                    )
                    .then((response) => {
                      window.location.replace("/OnePage-ThankYou");
                    });

                  },
                  (clientAnswer) => {
                    window.location.replace("/OnePage-ThankYou");
                  },
              
                );
                Notify.success("Sample pdf file sended succussfully");
                
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };
  return (
    <>
        <div
          className="modal fade"
          id="staticBackdrop1"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Download Free Pdf
                </h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container py-3 rounded shadow-lg" id="package">
                  <form className="mt-5" id="free-pdf-form">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label text-dark fw-bold"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Mail"
                        name="email"
                        id="email1"
                        aria-describedby="emailHelp"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span id="email_error1" style={{ color: "red" }}>
                        {emailError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name1"
                        name="name"
                        className="form-control"
                        placeholder="Enter Your Name"
                        value={buyerName}
                        onChange={(e) => setBuyername(e.target.value)}
                      />
                      <span id="name_error1" style={{ color: "red" }}>
                        {buyerNameError}
                      </span>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label text-dark fw-bold"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        name="mobile_number"
                        className="form-control"
                        pattern="[6789][0-9]{9}"
                        placeholder="Enter Your Number"
                        id="mobile_number1"
                        value={buyerMobilenumber}
                        onChange={(e) => setMobilenumber(e.target.value)}
                      />
                      <span id="mobile_number_error1" style={{ color: "red" }}>
                        {mobilenumberError}
                      </span>
                    </div>
                    <button
                      type="button"
                      name="free"
                      id="free_pdf"
                      className="text-center but1 mt-3"
                      onClick={printHandler}

                    >
                      Print
                      <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* pay popup */}

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="staticBackdropLabel">
                Download Pdf
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container py-3 rounded shadow-lg" id="package">
                <div className="p-3">
                  <table className="table m-0 table-bordered border-secondary">
                    <tbody>
                      <tr>
                        <th className="text-dark fs-6 px-2">விலை</th>
                        <td className="text-center text-dark">Rs. 216/-</td>
                      </tr>
                      <tr>
                        <th className="text-dark fs-6 px-2">
                          Discount- 54% (216-117)
                        </th>
                        <td className="text-center text-dark">Rs. 99/-</td>
                      </tr>
                      <tr>
                        <th className="text-dark fs-6 px-2">சலுகை விலை</th>
                        <td className="text-center text-dark">Rs. 99/-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <form
                  className="mt-2"
                  action=""
                  method="post"
                  id="payment_form"
                >
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-dark fw-bold"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Mail"
                      name="email"
                      id="email"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      // required
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      onChange={(e) => setBuyername(e.target.value)}
                      value={buyerName}
                    />

                    <span id="name_error1" style={{ color: "red" }}>
                      {buyerNameError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Whatsapp Number
                    </label>
                    <input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      pattern="[6789][0-9]{9}"
                      placeholder="Enter Your Whatsapp Number"
                      id="mobile_number"
                      value={buyerMobilenumber}
                      onChange={(e) => setMobilenumber(e.target.value)}
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {mobilenumberError}
                    </span>
                  </div>
                  <button
                    type="button"
                    name="pay"
                    id="pay"
                    onClick={handlepay}
                    className="text-center but1 mt-3"
                  >
                    Buy Now
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="floating-button w-100">
        <div className="container bg-white p-3 rounded shadow-lg w-100">
          <div className="d-grid gap-2 d-md-flex justify-content-md-center">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                editForm();
              }}
            >
              ✎ Edit
            </button>
            {/* <button
              className="btn btn-warning"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop1"
            >
              <i className="fa-solid fa-download"></i> Free Sample PDF
            </button> */}
            <button
              className="btn btn-success"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <i className="fa-solid fa-download"></i> Download PDF
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
