import "./onepagePaidTemplate.css";
export default function OnepagePaidTemplate({ value }) {
    const Anthiram = value.anthiram;
    const AnthiramKiragam = value.current_dhasa;
  const AmsamChart=value.amsam_array;
   const AmsamSlash=value.amsam_slash;
   const Numerology=value.Numerology;       
   const paralgal=value.paralgal;
   const pavagaMaruthalChart=value.pavaga_maruthal_table;
   const NadapuThasaValues=value.thasa_values;
//    console.log(paralgal);
  return (
    <div className="page" style={{ boxShadow: "none" }}>
      <div className="subpage" id="page1">
        <div className="head">
          <p>உ</p>
          <span>
            <b></b>
          </span>
          {value.personal_info.name}
          (ஜனன ஜாதகம்)
        </div>
        <div className="table-1-heading">
          ஜெனனீ ஜென்ம சௌக்கியானாம் வர்த்தனி குலசம்பதாம் பதவிபூர்வ புண்யாணாம்
          லிக்யதே ஜென்ம பத்ரிகாஸ்ரீஸ்ரீ
        </div>
        <table id="basic-info">
          <tr>
            <td width="34%">
              <table width="100%" className="table-jadhagam-1">
                <tr>
                  <th width="35%">பெயர்</th>
                  <td width="65%" className="name-font">
                    : <b> {value.personal_info.name}</b>
                  </td>
                </tr>
                <tr>
                  <th>பாலினம்</th>
                  <td> :{value.personal_info.gender}</td>
                </tr>
                <tr>
                  <th>பிறந்த தேதி</th>
                  <td>
                    :{" "}
                    <span className="numbers">
                      {value.personal_info.birth_date}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>பிறந்த நேரம்</th>
                  <td>
                    :{" "}
                    <span className="numbers">
                      {value.personal_info.birth_time}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>பிறந்த இடம்</th>
                  <td>:{value.personal_info.birth_place}</td>
                </tr>
                <tr>
                  <th>தமிழ் தேதி</th>
                  <td>: {value.personal_info.tamil_day}</td>
                </tr>
                <tr>
                  <th>திதி</th>
                  <td>:{value.personal_info.thithi}</td>
                </tr>
              </table>
            </td>
            <td width="28%">
              <table width="100%" className="table-jadhagam-2">
                <tr>
                  <th>கரணம்</th>
                  <td>: {value.personal_info.karanam}</td>
                </tr>
                <tr>
                  <th width="50%">ராசி </th>
                  <td width="50%">: {value.personal_info.rasi}</td>
                </tr>
                <tr>
                  <th>நட்சத்திரம்</th>
                  <td>: {value.personal_info.natchathiram}</td>
                </tr>
                <tr>
                  <th>லக்னம்</th>
                  <td>:{value.personal_info.laknam}</td>
                </tr>
                <tr>
                  <th>சூர்ய உதயம்</th>
                  <td>:{value.personal_info.sun_rise}</td>
                </tr>
                <tr>
                  <th>சூர்ய அஸ்தமம்</th>
                  <td>:{value.personal_info.sun_set}</td>
                </tr>
                <tr>
                  <th>யோகம்</th>
                  <td>:{value.personal_info.yogam}</td>
                </tr>
              </table>
            </td>
            <td width="38%">
              <table width="100%" className="table-jadhagam-3">
                <tr>
                  <th width="51%">பஷம்</th>
                  <td width="49%">:{value.personal_info.patcham} </td>
                </tr>
                <tr>
                  <th>திதி சூன்யம்</th>
                  <td width="49%">:{value.personal_info.thithi_suniyam} </td>
                </tr>
                <tr>
                  <th>யோக நட்சத்திரம்</th>
                  <td>:{value.personal_info.yoga_natchathiram} </td>
                </tr>
                <tr>
                  <th>அவயோக நட்சத்திரம்</th>
                  <td>:{value.personal_info.avayogi_natchathiram} </td>
                </tr>
                <tr>
                  <th>யோகி</th>
                  <td>:{value.personal_info.yogi}</td>
                </tr>
                <tr>
                  <th>அவயோகி</th>
                  <td>:{value.personal_info.avayogi}</td>
                </tr>
                <tr>
                  <th>நட்சத்திர எழுத்து</th>
                  <td>:{value.personal_info.natchathira_letter}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div className="table-2-heading">நிராயன கிரக பாத சாரம்</div>
        <table id="table-2">
          <tr>
            <td width="50%">
              <table width="100%" className="table-2-left">
                <thead>
                  <tr>
                    <th width="12%">கிரகம்</th>
                    <th width="8%">தீர்காம்சம்</th>
                    <th width="70%">ராசி - நட்சத்திரம் - பாதம்</th>
                    <th width="10%">அதிபதி</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(value.tableData)
                    ? value.tableData.slice(0, 5).map((item, index) => (
                        <tr key={index}>
                          <td>{item.kiragam}</td>
                          <td className="">{item.Degree}</td>
                          <td className="">
                            {item.rasi +
                              "-" +
                              item.natchathiram +
                              "-" +
                              item.patham}
                          </td>
                          <td>{item.natchathira_athipathi}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </td>
            <td width="50%">
              <table width="100%" className="table-2-right">
                <thead>
                  <tr>
                    <th width="12%">கிரகம்</th>
                    <th width="8%">தீர்காம்சம்</th>
                    <th width="70%">ராசி - நட்சத்திரம் - பாதம்</th>
                    <th width="10%">அதிபதி</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(value.tableData)
                    ? value.tableData.slice(5, 10).map((item, index) => (
                        <tr key={index}>
                          <td>{item.kiragam}</td>
                          <td className="">{item.Degree}</td>
                          <td className="">
                            {item.rasi +
                              "-" +
                              item.natchathiram +
                              "-" +
                              item.patham}
                          </td>
                          <td>{item.natchathira_athipathi}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="50%">
              <table className="birth-table" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td className={`rasi-birth ${value.rasivalue.r12}`}>
                      {value.rasi_array.r12}
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r1}`}>
                      {value.rasi_array.r1}
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r2}`}>
                      {value.rasi_array.r2}
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r3}`}>
                      {value.rasi_array.r3}
                    </td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${value.rasivalue.r11}`}>
                      {value.rasi_array.r11}
                    </td>
                    <td
                      className="rasi-birth chart-birth"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart"> ராசி </p>
                    </td>
                    <td className={`rasi-birth ${value.rasivalue.r4}`}>
                      {value.rasi_array.r4}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth ${value.rasivalue.r10}`}
                      valign="top"
                    >
                      {value.rasi_array.r10}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r5}`}
                      valign="top"
                    >
                      {value.rasi_array.r5}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`rasi-birth ${value.rasivalue.r9}`}
                      valign="top"
                    >
                      {value.rasi_array.r9}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r8}`}
                      valign="top"
                    >
                      {value.rasi_array.r8}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r7}`}
                      valign="top"
                    >
                      {value.rasi_array.r7}
                    </td>
                    <td
                      className={`rasi-birth ${value.rasivalue.r6}`}
                      valign="top"
                    >
                      {value.rasi_array.r6}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table className="birth-table" cellSpacing="2" cellPadding="2">
                <tbody>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a12}`}>{AmsamChart.a12}</td>
                    <td className={`rasi-birth ${AmsamSlash.a1}`}>{AmsamChart.a1}</td>
                    <td className={`rasi-birth ${AmsamSlash.a2}`}>{AmsamChart.a2}</td>
                    <td className={`rasi-birth ${AmsamSlash.a3}`}>{AmsamChart.a3}</td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a11}`}>{AmsamChart.a11}</td>
                    <td
                      className="rasi-birth chart-birth"
                      width="220"
                      height="220"
                      colSpan="2"
                      rowSpan="2"
                    >
                      <p className="rasi-chart"> நவாம்சம் </p>
                    </td>
                    <td className={`rasi-birth ${AmsamSlash.a4}`}>{AmsamChart.a4}</td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a10}`} valign="top">{AmsamChart.a10}</td>
                    <td className={`rasi-birth ${AmsamSlash.a5}`} valign="top">{AmsamChart.a5}</td>
                  </tr>
                  <tr>
                    <td className={`rasi-birth ${AmsamSlash.a9}`} valign="top">{AmsamChart.a9}</td>
                    <td className={`rasi-birth ${AmsamSlash.a8}`} valign="top">{AmsamChart.a8}</td>
                    <td className={`rasi-birth ${AmsamSlash.a7}`} valign="top">{AmsamChart.a7}</td>
                    <td className={`rasi-birth ${AmsamSlash.a6}`} valign="top">{AmsamChart.a6}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>

        <div className="table-3">
          <span>நடப்பு தசை / புத்தி கால விவரங்கள்:-</span>
          <table id="table-3">
            <tr>
              <td width="50%">
                <table width="100%" className="thasa-left">
                  <tr>
                    <th width="20%">திசை</th>
                    <th width="20%">புத்தி</th>
                    <th width="20%">தொடக்கம்</th>
                    <th width="20%">முடிவு</th>
                    <th width="20%">
                      வயது <br /> வரு-மா-நா
                    </th>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa1}</td>
                    <td>{Anthiram.antharam_1[0]}</td>
                    <td>{Anthiram.antharam_1_st_date[0]}</td>
                    <td>{Anthiram.antharam_2_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[0]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa2}</td>
                    <td>{Anthiram.antharam_2[0]}</td>
                    <td>{Anthiram.antharam_2_st_date[0]}</td>
                    <td>{Anthiram.antharam_3_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[1]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa3}</td>
                    <td>{Anthiram.antharam_3[0]}</td>
                    <td>{Anthiram.antharam_3_st_date[0]}</td>
                    <td>{Anthiram.antharam_4_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[2]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa4}</td>
                    <td>{Anthiram.antharam_4[0]}</td>
                    <td>{Anthiram.antharam_4_st_date[0]}</td>
                    <td>{Anthiram.antharam_5_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[3]}</td>
                  </tr>

                  <tr>
                    <td>{AnthiramKiragam.thasa5}</td>
                    <td>{Anthiram.antharam_5[0]}</td>
                    <td>{Anthiram.antharam_5_st_date[0]}</td>
                    <td>{Anthiram.antharam_6_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[4]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa6}</td>
                    <td>{Anthiram.antharam_6[0]}</td>
                    <td>{Anthiram.antharam_6_st_date[0]}</td>
                    <td>{Anthiram.antharam_7_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[5]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa7}</td>
                    <td>{Anthiram.antharam_7[0]}</td>
                    <td>{Anthiram.antharam_7_st_date[0]}</td>
                    <td>{Anthiram.antharam_8_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[6]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa8}</td>
                    <td>{Anthiram.antharam_8[0]}</td>
                    <td>{Anthiram.antharam_8_st_date[0]}</td>
                    <td>{Anthiram.antharam_9_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[7]}</td>
                  </tr>
                </table>
              </td>
              <td width="50%">
                <table width="100%" className="thasa-right">
                  <tr>
                    <th width="20%">திசை</th>
                    <th width="20%">புத்தி</th>
                    <th width="20%">தொடக்கம்</th>
                    <th width="20%">முடிவு</th>
                    <th width="20%">
                      வயது <br /> வரு-மா-நா
                    </th>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa9}</td>
                    <td>{Anthiram.antharam_9[0]}</td>
                    <td>{Anthiram.antharam_9_st_date[0]}</td>
                    <td>{Anthiram.antharam_10_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[8]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa10}</td>
                    <td>{Anthiram.antharam_10[0]}</td>
                    <td>{Anthiram.antharam_10_st_date[0]}</td>
                    <td>{Anthiram.antharam_11_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[9]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa11}</td>
                    <td>{Anthiram.antharam_11[0]}</td>
                    <td>{Anthiram.antharam_11_st_date[0]}</td>
                    <td>{Anthiram.antharam_12_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[10]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa12}</td>
                    <td>{Anthiram.antharam_12[0]}</td>
                    <td>{Anthiram.antharam_12_st_date[0]}</td>
                    <td>{Anthiram.antharam_13_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[11]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa13}</td>
                    <td>{Anthiram.antharam_13[0]}</td>
                    <td>{Anthiram.antharam_13_st_date[0]}</td>
                    <td>{Anthiram.antharam_14_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[12]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa14}</td>
                    <td>{Anthiram.antharam_14[0]}</td>
                    <td>{Anthiram.antharam_14_st_date[0]}</td>
                    <td>{Anthiram.antharam_15_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[13]}</td>
                  </tr>
                  <tr>
                  <td>{AnthiramKiragam.thasa15}</td>
                    <td>{Anthiram.antharam_15[0]}</td>
                    <td>{Anthiram.antharam_15_st_date[0]}</td>
                    <td>{Anthiram.antharam_16_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[14]}</td>
                  </tr>
                  <tr>
                    <td>{AnthiramKiragam.thasa16}</td>
                    <td>{Anthiram.antharam_16[0]}</td>
                    <td>{Anthiram.antharam_16_st_date[0]}</td>
                    <td>{Anthiram.antharam_17_st_date[0]}</td>
                    <td>{AnthiramKiragam.thasa_diff_array[15]}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <table width="100%" id="table-6">
          <tbody>
            <tr>
              <td width="70%">
                <table width="100%" className="left">
                  <tbody>
                    <tr>
                      <th width="31%">மகா திசை இருப்பு</th>
                      <td> :{NadapuThasaValues.thasa_value} திசை இருப்பு {NadapuThasaValues.thasa_varudam} வருடம்  {NadapuThasaValues.thasa_matham} மாதம் {NadapuThasaValues.thasa_day} நாட்கள்</td>
                    </tr>
                    <tr>
                      <th>நடப்பு திசை</th>
                      <td>:{AnthiramKiragam.thasa1}</td>
                    </tr>
                    <tr>
                      <th>அதிஷ்ட எண்கள்</th>
                      <td className="numbers">:{Numerology.lucky_number} </td>
                    </tr>
                    <tr>
                      <th>அதிஷ்ட தேதிகள்</th>
                      <td className="numbers">:{Numerology.lucky_date} </td>
                    </tr>
                    <tr>
                      <th>அதிஷ்ட நிறங்கள்</th>
                      <td className="numbers">:{Numerology.lucky_color} </td>
                    </tr>
                    <tr>
                      <th>அதிஷ்ட கற்கள்</th>
                      <td className="numbers">:{Numerology.lucky_stone} </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td width="30%">
                <table
                  className="birth-table-3"
                  cellSpacing="2"
                  cellPadding="2"
                >
                  <tbody>
                    <tr>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b12}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b1}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b2}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b3}</td>
                    </tr>
                    <tr>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b11}</td>
                      <td
                        className="rasi-birth-3 rasi-chart-3"
                        colSpan="2"
                        rowSpan="2"
                      >
                        <p className=""> பாவகம் </p>
                      </td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b4}</td>
                    </tr>
                    <tr>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b10}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b5}</td>
                    </tr>
                    <tr>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b9}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b8}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b7}</td>
                      <td className="rasi-birth-3">{pavagaMaruthalChart.b6}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="table-4-heading">சர்வாஷ்ட வர்கம்</div>
        <table width="100%" id="table-4">
          <thead>
            <tr>
              <th width="7.69%">ராசி</th>
              <th width="7.69%">மேஷம்</th>
              <th width="7.69%">ரிஷபம்</th>
              <th width="7.69%">மிதுனம்</th>
              <th width="7.69%">கடகம்</th>
              <th width="7.69%">சிம்மம்</th>
              <th width="7.69%">கன்னி</th>
              <th width="7.69%">துலாம்</th>
              <th width="7.69%">விருச்சிகம்</th>
              <th width="7.69%">தனுசு</th>
              <th width="7.69%">மகரம்</th>
              <th width="7.69%">கும்பம்</th>
              <th width="7.69%">மீனம்</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>பரல்கள்</td>
              <td>{paralgal.p1}</td>
              <td>{paralgal.p2}</td>
              <td>{paralgal.p3}</td>
              <td>{paralgal.p4}</td>
              <td>{paralgal.p5}</td>
              <td>{paralgal.p6}</td>
              <td>{paralgal.p7}</td>
              <td>{paralgal.p8}</td>
              <td>{paralgal.p9}</td>
              <td>{paralgal.p10}</td>
              <td>{paralgal.p11}</td>
              <td>{paralgal.p12}</td>
            </tr>
          </tbody>
        </table>
        <div className="foot">
          <span>
            இந்த ஜாதகம் www.onepagehoroscope.com இணைய தளத்தில்
            உருவாக்கப்பட்டது...
          </span>
        </div>
      </div>
    </div>
  );
}
