import React from "react";
import {
  OnePagePaidPdfConvertion,
  MarriageMatchingPdfConverTion,
  BabyNamePdf,
} from "../data/pdfProcess";


export default function Table({ TableHeader, TableData }) {
  const HandleGetData = async (data) => {
    if (data.package === "one_page") {
      let response = await OnePagePaidPdfConvertion(data);
    } else if (data.package === "marriage_matching") {
      let Respone = await MarriageMatchingPdfConverTion(data);
    } else if (data.package === "baby_name") {
      // BabyNameApi()
      let Response = await BabyNamePdf(data);
    }
  };

  return (
    <>
      <table className="table table-bordered table-hover">
        <thead className="bg-primary">
          <tr >
            {TableHeader.map((header, index) => (
              <th className="bg-primary text-white fw-bold" key={index}>{header}</th>
            ))}
            <th className="bg-primary text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {TableData.map((body, index) => (
            <tr key={index}>
              {Object.entries(body).map(([key, value], innerIndex) =>
               
                key === "latitude" || key === "longitude" ? null : (
                  <td key={innerIndex}>{value}</td>
                )
              )}
              <td>
                <button
                  className="btn btn-success text-white fw-bold"
                  onClick={() => HandleGetData(body)}
                >
                  resend
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
