import React from 'react'
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import HoroscopeBookForm from '../HoroscopeBook/HoroscopeBookForm';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export default function HoroscopeBook() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Tamil-jathagam-book": "/Tamil-jathagam-book",
      // Add more canonical paths if needed
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };
  
  return (
    <>
    <Menubar/>
    <Helmet>
      <title>Tamil jathagam | தமிழ் ஜாதகம்| Tamil birth horoscope </title>
      <meta
          name="keywords"
            content="tamil jathagam, tamil jathagam book,jathagam book in tamil,online jathagam,tamil jathagam online,online tamil jathagam,horoscope in tamil,tamil birth chart, birth chart in tamil"
          />
          <meta
            name="description"
            content="Get your Tamil jathagam book with birth horoscope, varga chakaram, Asta vargam, panjangam details, etc. book will be printed and will reach you through couriers"
          />
           <meta name="robots" content="index,follow" />
           {canonical()}
           <script type="application/ld+json">
          {`
           "@context": "https://schema.org/", 
           "@type": "Product", 
           "name": "Tamil Jathagam Book",
           "image": "",
           "description": "Get your Tamil jathagam book with birth horoscope, varga chakaram, Asta vargam, panjangam details, etc. book will be printed and will reach you through couriers",
           "brand": {
             "@type": "Brand",
             "name": "One Page Horoscope"         
          `}
        </script>
    </Helmet> 

    <div className=''>
    <HoroscopeBookForm/>
    </div>
   
    <Footer/>
    </>
  );
}
