import React from "react";
import Footer from "../Footer/Footer";
import SideNavbar from "../sideNavBar/sideNav";
import TopNavbar from "../TopNavBar/TopNav";
import { useState } from "react";
import BiodataForm from "../forms/BiodataForm";

export default function CreateBiodata() {
    const [isToggled, setIsToggled] = useState(false);
    const handleToggle = () => {
      setIsToggled(!isToggled);
    };
  return (
    <div>
        <div id="page-top">
        <div id="wrapper">
          <SideNavbar isToggled={isToggled} onToggle={handleToggle} />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <TopNavbar isToggled={isToggled} onToggle={handleToggle} />

              <div className="container-fluid">
                <BiodataForm/>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
    </div>
  )
}
