import React from "react";
// import "../header/Menubar.css";
import Menubar from "../header/Menubar";
import BirthHoroscopeForm from "../Birth-jadhagam-form/BirthHoroscopeForm";
import BirthHoroscopeContent from "../birth-horoscope-content/birth-content";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function BirthHoroscope() {
  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Birth-horoscope": "/Birth-horoscope",
      // Add more canonical paths if needed
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };
  return (
    <div className="">
      <div>
        <Helmet>
          <title>Free Tamil Birth Chart - இலவச தமிழ் பிறப்பு ஜாதகம்</title>
          <meta
            name="description"
            content="Free Tamil Birth Chart to find planetary position at the time of your birth. Get panjang, dasa details, rasi and navamsam. onepage horoscope give in Birth chart."
          />
          <meta
            name="keywords"
            content="tamil birth chart, இலவச பிறப்பு ஜாதகம் ,pirappu jathagam, பிறப்பு ஜாதகம், ஒரு பக்க ஜாதகம், birth chart, birth chart calculator, free birth chart, tamil jathagam, tamil jathagam online, tamil jathagam online free,jathaga kattam"
          />
          <meta name="robots" content="index,follow" />
          {canonical()}
          <script type="application/ld+json">
          {`
           "@context": "https://schema.org/", 
           "@type": "Product", 
           "name": "Birth horoscope",
           "image": "",
           "description": "Free Tamil Birth Chart to find planetary position at the time of your birth. Get panjang, dasa details, rasi and navamsam. onepage horoscope give in Birth chart.",
           "brand": {
             "@type": "Brand",
             "name": "One Page Horoscope"
          `}
        </script>
          
        </Helmet>
        <Menubar />
        <div style={{ marginTop: "150px" }}>
          <BirthHoroscopeForm />
          <BirthHoroscopeContent />
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default BirthHoroscope;