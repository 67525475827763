import React from "react";
import { useEffect, useState } from "react";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import ReactPaginate from "react-js-pagination";
import axios from "axios";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import getepayPortal from "../../tamil/Getepay_pg_react";
import ReactDOMServer from "react-dom/server";
import OnepagePaidTemplate from "../../tamil/onepagePaidTemplate/onepagePaidtemplate";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { Report } from 'notiflix/build/notiflix-report-aio';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

export default function OnepageReport() {
  const [ReportData, setReportData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [Name, setUserName] = useState("");
  const [Email, setUserEmail] = useState("");
  const [MobileNumber, setUserMobileNumber] = useState("");
  const [mobilenumberError, setMobieNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buyerNameError, setBuyerNameError] = useState("");
  const [user_id,setUserId]=useState('');
  const[customerDataId,setcustomerDataId]=useState('');
  const [selectedReport, setSelectedReport] = useState(null);

  const PayNowData = (report) => {
    setSelectedReport(report);
    setUserId(report.user_id_fk);
    setcustomerDataId(report.id);
    const UserInfo={
      'user_id':report.user_id_fk,
      'customerDataId':report.id
    }
    const UserInfoValue = JSON.stringify(UserInfo);
  sessionStorage.setItem('User_Onepage_Ids',UserInfoValue)
  };

  useEffect(() => {
    Loading.dots();
    const Token = localStorage.getItem("authToken");

    axios
      .get("https://www.ctsvsolutions.com/onepage-Api/public/api/myProfile", {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setUserEmail(response.data.email);
        setUserName(response.data.user_name);
        setUserMobileNumber(response.data.mobile_number);
        Loading.remove();
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
        Loading.remove();
      });
  }, []);


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ReportData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setActivePage(1);
  };
  useEffect(() => {
    Loading.dots();
    const Token = localStorage.getItem("authToken");

    fetch("https://www.ctsvsolutions.com/onepage-Api/public/api/OnePage-Data", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setReportData(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        Loading.remove();
      });
  }, []);

  const ViewPaidPdf = (data) => {
    Loading.hourglass("Pdf is generating.....");
    const dateComponents = data.birth_date.split("-");

    const day = dateComponents[0];
    const month = dateComponents[1];
    const year = dateComponents[2];

    const formattedDate = `${year}-${month}-${day}`;

    const formdata = {
      username: data.name,
      gender: data.gender,
      userDateofBirth: formattedDate,
      userBirthtime: data.birth_time,
      userBirthplace: data.birth_place,
      latitude: data.latitude,
      longitude: data.longitude,
    };
    axios
      .post(
        "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
        formdata
      )
      .then((response) => {
        const paidPdf = () => {
          return <OnepagePaidTemplate value={response.data.data} />;
        };

        const printElement = ReactDOMServer.renderToString(paidPdf());

        html2pdf()
          .from(printElement)
          .set({
            image: { type: "jpeg", quality: 1 },
            dpi: 300,
            renderer: "canvas",
            html2canvas: { scale: 2 },
          })
          .save();
        Loading.remove();
        Notify.success("Pdf Downloaded Successfully....!!!");
      });
  };


  //pay and download pdf 
const validateInput = () => {
  let hasError = false;
  // Validate email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!Email) {
    setEmailError("Field is required");
    hasError = true;
  } else if (!emailRegex.test(Email)) {
    setEmailError("Invalid email format");
    hasError = true;
  } else {
    setEmailError("");
  }
  if (!Name) {
    setBuyerNameError("Field is required");
    hasError = true;
  } else {
    setBuyerNameError("");
  }

  if (!MobileNumber || MobileNumber.length !== 10) {
    setMobieNumberError("Field is required and must be 10 digits");
    hasError = true;
  } else {
    setMobieNumberError("");
  }

  return !hasError;
};

const payNow = () => {
  if (validateInput()) {
    const Token = localStorage.getItem("authToken");
    const updateInfo = {
      'email': Email,
      'mobile_number': MobileNumber,
      'customerDataId': selectedReport.id
    };

    axios.post('https://www.ctsvsolutions.com/onepage-Api/public/api/Update-payment-user-info', updateInfo, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
    .then(response => {
      const check = response.data.status;
      if (check === true) {
        const Printdata = selectedReport;
        const dateComponents = Printdata.birth_date.split("-");
        const day = dateComponents[0];
        const month = dateComponents[1];
        const year = dateComponents[2];

        const formattedDate = `${year}-${month}-${day}`;

        // Move formdata outside the scope of the if block
        const formdata = {
          username: Printdata.name,
          gender: Printdata.gender,
          userDateofBirth: formattedDate,
          userBirthtime: Printdata.birth_time,
          userBirthplace: Printdata.birth_place,
          latitude: Printdata.latitude,
          longitude: Printdata.longitude,
        };

        // Perform the second Axios request here
        axios
          .post(
            "https://ctsvsolutions.com/onepage-Api/public/api/Onepage-Horoscope",
            formdata
          )
          .then((response) => {
            if(response.data.status ===true){
              const ApiResponse = response.data.data;
              const ApiResponseString = JSON.stringify(ApiResponse);
              sessionStorage.setItem('userOnepageData', ApiResponseString);
              onePagePaymentGateWay();
            }
        
          })
          .catch((error) => {
            console.error("Error in the second request:", error);
          });
      }
    })
    .catch(error => {
      console.error("Error in the first request:", error);
    });
  }
};

const onePagePaymentGateWay=()=>{
  const data = {
    mid: "993377",
    amount: "99.00",
    merchantTransactionId: "sd12121",
    transactionDate: "Mon Oct 03 13:54:33 IST 2022",
    terminalId: "getepay.merchant131068@icici",
    udf1: MobileNumber,
    udf2: Email,
    udf3: Name,
    udf4: user_id,
    udf5: customerDataId,
    udf6: "",
    udf7: "",
    udf8: "",
    udf9: "",
    udf10: "",
    ru: "https://www.ctsvsolutions.com/onepage-Api/public/onepage-user-payment",
    callbackUrl: "",
    currency: "INR",
    paymentMode: "ALL",
    bankId: "",
    txnType: "single",
    productType: "IPG",
    txnNote: "Onepage-user-dashboard",
    vpa: "getepay.merchant131068@icici",
  }; 

const Config = {
  "mid":"993377",
  "Getepay Terminal Id": "getepay.merchant131068@icici",
  "Getepay Key": "rjW99m2Vo75SBbpyc+K5nfB/eebzWUmkAtx90U5Ku3I=",
  "Getepay IV": "Y7fOrFLKVPosfDFSIFSISQ==",
    "Getepay Url":
      "https://portal.getepay.in:8443/getepayPortal/pg/generateInvoice"
  };
  // console.log(data);
  getepayPortal(data, Config);
}
//after the payment
  //after the payment
  const currentLocation = window.location.href;
  const [baseUrl, queryParamString] = currentLocation.split("?");
  const queryParamsObject = {};

  if (queryParamString) {
    const queryParams = queryParamString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");
      queryParamsObject[key] = value;
    }
    const Authorization = queryParamsObject["Authorization"];
    const Paymemt_Status=queryParamsObject["payment_Status"];

    if(Authorization ==="STruE" && Paymemt_Status ==='CrEdiT')
    {
      Report.success(
        "Payment Success",
        "உங்கள் கட்டணம் வெற்றிகரமாக பெறப்பட்டது ! நீங்கள்  கீழே உள்ள Download ஐ  கிளிக் செய்து உங்கள் PDF ஐ பதிவிறக்கம் செய்து கொள்ளலாம் ...",
        "Download Pdf",
        () => {
          Loading.hourglass("Birth Horoscope is Processing...", {
            backgroundColor: "rgba(0,0,0,0.8)",
          });
    
          const storedUserInfo = sessionStorage.getItem("User_Onepage_Ids");
          const userInfo = JSON.parse(storedUserInfo);
    
          const printElement = ReactDOMServer.renderToString(
            paidPdf()
          );
          html2pdf()
            .from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            })
            .save();
    
          html2pdf().from(printElement)
            .set({
              image: { type: 'jpeg', quality: 1 },
              dpi: 300,
              renderer: 'canvas',
              html2canvas: { scale: 2 },
            }).outputPdf()
            .then((pdfData) => {
              const base64Data = btoa(pdfData);
              savePaidPdf(base64Data, userInfo.user_id, userInfo.customerDataId);
            });
        }
      );
    }
  }

  const savePaidPdf = (pdfData, user_id, customerDataId) => {
    const pdfDataVal = {
      pdfData: pdfData, 
      userId: user_id,
      customerId: customerDataId,
      FileName:'one_page'
    };
    const DataInfo = {
      userId: user_id,
      customerId: customerDataId,
      columnName:"pdf_file"
    };
    axios
      .post(" https://www.ctsvsolutions.com/onepage-Api/public/api/Save-Paid-Pdf", pdfDataVal)
      .then((response) => {
        axios
          .post(
            " https://www.ctsvsolutions.com/onepage-Api/public/api/send-paid-PdfIn-whastapp",
            DataInfo
          )
          .then((response) => {
            axios
              .post(
                " https://www.ctsvsolutions.com/onepage-Api/public/api/Send-pdfIn-Email",
                DataInfo
              )
              .then((response) => {
               
                Loading.remove();
                Confirm.prompt(
                  "Rating ⭐⭐⭐⭐⭐",
                  "How was your experince?",
                  "",
                  "Answer",
                  "Cancel",
                  (clientAnswer) => {
                    const RatingInfo={
                      'user_id':user_id,
                      'customer_data_id':customerDataId,
                      'feedback':clientAnswer                
                   }
                    axios
                    .post(
                      "https://www.ctsvsolutions.com/onepage-Api/public/api/Update-Feed-Back",
                      RatingInfo
                    )
                    .then((response) => {
                      window.location.replace('/user');
                    });

                  },
                  (clientAnswer) => {
                    window.location.replace('/user');
                  },
              
                );
                Notify.success("Sample pdf file sended succussfully");
                
              })
              .catch((error) => {
                console.error("Error sending WhatsApp message:", error);
              });
          })
          .catch((error) => {
            console.error("Error sending WhatsApp file:", error);
          });
      })
      .catch((error) => {
        console.error("Error saving PDF:", error);
      });
  };



  const paidPdf = () => {
    const storedUserInfo = sessionStorage.getItem("userOnepageData");
    const dataInfo = JSON.parse(storedUserInfo);
    return <OnepagePaidTemplate value={dataInfo} />;
  };


  return (
    <div>
      <div>
        <h5 className="text-stat text-primary">Onepage-Horoscope Report</h5>
      </div>
      <div className="card border-0">
        <div className="card-body ">
          <div className="row">
            <div className="col-md-12 m-auto  table-responsive">
              <div className="mb-3">
                <label htmlFor="itemsPerPage">Items per page:</label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={ReportData.length}>All</option>
                </select>
              </div>
              <table className="table text-nowrap table-bordered table-hover">
                <thead>
                  <tr className="">
                    <th scope="col" className="px-4 bg-primary text-white">
                      S.no
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      Name
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      Gender
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      Date of Birth
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      Birth Time
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      Birth place
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      Payment status
                    </th>
                    <th scope="col" className="px-4 bg-primary text-white">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((report, index) => (
                    <tr key={index}>
                      <td>{index + 1 + (activePage - 1) * itemsPerPage}</td>
                      <td>{report.name}</td>
                      <td>{report.gender}</td>
                      <td>{report.birth_date}</td>
                      <td>{report.birth_time}</td>
                      <td>{report.birth_place}</td>
                      <td>{report.status}</td>
                      <td>
                        {report.status === "PAID" ? (
                          // Pass report data to the ViewPaidPdf function on button click
                          <button
                            type="button"
                            onClick={() => ViewPaidPdf(report)}
                            className="btn btn-success"
                          >
                            View
                          </button>
                        ) : (
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop1"
                            className="btn btn-danger"
                            onClick={() => PayNowData(report)}
                          >
                            Pay & Download
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <ReactPaginate
                activePage={activePage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={ReportData.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop1"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="staticBackdropLabel">
                Download Free Pdf
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container py-3 rounded shadow-lg" id="package">
                <form className="mt-5" id="free-pdf-form">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label text-dark fw-bold"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Mail"
                      name="email"
                      id="email1"
                      value={Email}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                      aria-describedby="emailHelp"
                      required
                    />
                    <span id="email_error1" style={{ color: "red" }}>
                      {emailError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name1"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      value={Name}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <span id="name_error1" style={{ color: "red" }}>
                      {buyerNameError}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label text-dark fw-bold"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      name="mobile_number"
                      className="form-control"
                      pattern="[6789][0-9]{9}"
                      placeholder="Enter Your Number"
                      id="mobile_number1"
                      value={MobileNumber}
                      onChange={(e) => setUserMobileNumber(e.target.value)}
                    />
                    <span id="mobile_number_error1" style={{ color: "red" }}>
                      {mobilenumberError}
                    </span>
                  </div>
                  <button
                    type="button"
                    name="free"
                    id="free_pdf"
                    className="text-center but1 mt-3"
                    onClick={payNow}
                  >
                    Print
                    <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //popup
  );
}
