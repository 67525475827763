import React from 'react';
import "../BabyNameTemplate/BabyNameTemplate.css";

export default function BabyNameSample({value}) {
    const personalInfo = value.personal_info;
    const starName = value.starName;
    const CommonName=value.commonName;
    const BabyNameCount=value.name_count;
    // console.log('tamil date',personalInfo.TamilDate);
    const TamilDateofBirth=personalInfo.TamilDate.split(" ");
      const TamilYear=TamilDateofBirth[0];
      const TamilMonth=TamilDateofBirth[2];
      const TamilDay=TamilDateofBirth[4];
    const kilamai=personalInfo.kilamai.split('-');
    const BirthTime=personalInfo.birth_time.split(" ");
    const Gender=personalInfo.gender.split('-');

    
  return (
    <>
     <section className="sheet  border border-secondary mt-1">
        <div className="container-fluid p-2 m-0">
          <div>
            <img
              src={require("../BabyNameSample/images/baby_name_list_front.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="sheet sheet-1 mt-1" >
      <div className="container-fluid py-2">
        <div className="heading my-2">
          <img src="img/baby_name_heading_2.png" alt="" className="img-fluid" />
        </div>
        <div className="card p-1 bg-transparent border-color m-auto my-2">
          <ul style={{ padding: "0", margin: 0, marginLeft: "20px", fontWeight: "bold" }}>
            <li className="ps-3 my-1">
              நிகழும் <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{TamilYear}</span> வருடம், <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{TamilMonth}</span> மாதம், <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{TamilDay}</span> -ஆம் நாள் <span style={{ color: "#e51a4b", fontWeight: "bold" }}>( {personalInfo.birth_date})</span>
              <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{kilamai[1]}</span> கிழமையான, சுப தினத்தில் <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{BirthTime[1]==="AM"? 'காலை' :'மாலை'  } {BirthTime[0]}</span> மணிக்கு
              உயர்திரு <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{personalInfo.father_name}</span> அவர்களுக்கும் திருமதி <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{personalInfo.mother_name}</span> அவர்களுக்கும் பிறந்துள்ள <span style={{ color: "#e51a4b", fontWeight: "bold" }}>{Gender[1] }</span> குழந்தையின் ஜனன ஜாதக குறிப்பு.
            </li>
          </ul>
        </div>
        <div className="card p-1 bg-transparent border-color m-auto">
        <table
              className="table table-borderless fw-bold mb-0"
              id="jadhaga-table"
            >
              <tr >
                <td className='p-2 align-middle'>
                  பாலினம் <span className="float-end bg-transparent">:</span>
                </td >
                <td className='p-2 align-middle'>{personalInfo.gender}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  தந்தை பெயர்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.father_name}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  தாய் பெயர் <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>{personalInfo.mother_name}</td>
              </tr>
              <tr>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த தேதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.birth_date} (உயிர் எண் {personalInfo.final_uyir}{" "}
                  & உடல் எண் {personalInfo.final_udal} )
                </td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  பிறந்த நேரம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>{personalInfo.birth_time}</td>
              </tr>
              <tr>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த இடம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.birth_place}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  பிறந்த நட்சத்திரம் - பிறந்த பாதம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>
                  {personalInfo.natchathiram} -{personalInfo.patham}
                </td>
              </tr>
              <tr>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த ராசி - ராசி அதிபதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.rasi}-{personalInfo.rasi_athipathi}{" "}
                </td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  லக்கினம் - லக்கினாதிபதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>
                  {personalInfo.laknam}-{personalInfo.laknam_athipathi}{" "}
                </td>
              </tr>
              <tr>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  அதிஷ்ட எண்கள்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.lucky_number} </td>
              </tr>
              <tr>
                <td>
                  நட்சத்திர எழுத்துக்கள்{" "}
                  <span className="float-end bg-transparent ">:</span>
                </td>
                <td className='p-2 align-middle'>
                  {personalInfo.natchathira_letter_english},{" "}
                  {personalInfo.natchathira_letter_tamil}
                </td>
              </tr>
              <tr>
                <td className='p-2 align-middle'  style={{ backgroundColor:'#BEE6F4' }}>
                  பிறந்த கிழமை{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.kilamai}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  தமிழ் தேதி <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>{personalInfo.TamilDate}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  பக்ஷம் - திதி{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.patcham}-{personalInfo.thithi}
                </td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  சூரிய உதயம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>{personalInfo.sunrise}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  சூரிய அஸ்தமனம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.sunset}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  சூரிய உதயாதி நாழிகை
                  <span className="float-end bg-transparent ">:</span>
                </td>
                <td className='p-2 align-middle'>{personalInfo.suryaUthahiNaligai}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  கரணம் <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>{personalInfo.karanam}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle'>
                  நித்திய யோகம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td className='p-2 align-middle'>{personalInfo.yogam}</td>
              </tr>
              <tr>
                <td className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  யோகி முனை - யோகி நட்சத்திரம்{" "}
                  <span className="float-end bg-transparent">:</span>
                </td>
                <td  className='p-2 align-middle' style={{ backgroundColor:'#BEE6F4' }}>
                  {personalInfo.yogi}- {personalInfo.YogiStar}
                </td>
              </tr>
            
            </table>
        </div>
      </div>
    </section>

      <section className="sheet  border border-secondary mt-1">
        <div className="container-fluid p-2 m-0">
          <div>
            <img
              src={require("../BabyNameSample/images/baby_name_list_3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>
    </>
  );
}
