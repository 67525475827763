import React, { useEffect, useState } from "react";
import axios from "axios";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import TopNav from "../../tamil/header/Menubar";
import Footer from "../../tamil/footer/Footer";
import { useNavigate } from "react-router-dom";

function Login() {
  const [otpSent, setOtpSent] = useState(false);
  const [UserInput, setUserInput] = useState("");
  const [userOtp, setOtp] = useState("");
  const [Error, setError] = useState("");
  const [OtpError, setOtpError] = useState("");
  const navigate = useNavigate()
useEffect(()=>{

 const Token= localStorage.getItem('authToken');
 if(Token){
  navigate('/user')
 }

})

  const LoginStyle = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "85vh",
      background: "#f8f8f8",
    },
    card: {
      width: "400px",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      background: "#ffffff",
    },
    input: {
      width: "100%",
      padding: "14px",
      margin: "10px 0",
      borderRadius: "6px",
      border: "1px solid #ddd",
      fontSize: "14px",
      boxSizing: "border-box",
      // Customize the input box style
      backgroundColor: "#f2f2f2", // Add your preferred background color
      color: "#333", // Add your preferred text color
      // Add any other styles you'd like to customize
    },
    button: {
      width: "100%",
      padding: "14px",
      borderRadius: "6px",
      border: "none",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "bold",
    },
    error: {
      color: "#e74c3c",
      marginTop: "10px",
      fontSize: "14px",
    },
    title: {
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#333",
    },
    subTitle: {
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "16px",
      color: "#555",
    },
  };

  const handleGetOtp = (e) => {
    e.preventDefault();

    // Assuming UserInput is the input from the user
    const isEmail = /\S+@\S+\.\S+/.test(UserInput);
    const isMobileNumber = /^\d{10}$/.test(UserInput);

    if (isEmail) {  
      Loading.dots();
      // User input is an email
      axios
        .post("https://www.ctsvsolutions.com/onepage-Api/public/api/Email-Otp", {
          email: UserInput,
        })
        .then((response) => {
          Notify.success(`OTP have been sent to ${UserInput} successfully...`);
          Loading.remove();

          setOtpSent(true);
          // setShowOtpInput(true);
        });
    } else if (isMobileNumber) {
      Loading.dots();
      // User input is a 10-digit number
      axios
        .post("https://www.ctsvsolutions.com/onepage-Api/public/api/Send-Normal-OTP", {
          mobile_number: UserInput,
        })
        .then((response) => {
          Notify.success(`OTP have been sent to ${UserInput} successfully...`);
          Loading.remove();

          setOtpSent(true);
          // setShowOtpInput(true);
        });
    } else {
      setError(
        "Invalid input. Please enter a valid email or 10-digit mobile number."
      );
      // Handle invalid input (neither email nor 10-digit number)
      // console.error("Invalid input. Please enter a valid email or 10-digit mobile number.");
    }
  };
  const Back = () => {
    setOtpSent(false);
    // setShowOtpInput(false);
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    const isEmail = /\S+@\S+\.\S+/.test(UserInput);
    const isMobileNumber = /^\d{10}$/.test(UserInput);

    if (isEmail) {
      Loading.dots();
      axios
        .post("https://www.ctsvsolutions.com/onepage-Api/public/api/verify-Email-otp", {
          email: UserInput,
          otp: userOtp,
        })
        .then((response) => {
          Loading.remove();

         
            response.data.status === false
              ? setOtpError("Invalid Otp,try again")
              : setOtpError("");

          if (response.data.status === true) {
            const access_token = response.data.data.access_token;
            const user_id = response.data.data.user_id;
            localStorage.setItem("authToken", access_token);
            localStorage.setItem("LogedUser", user_id);
            window.location.replace("/user");
          }
        });
    } else if (isMobileNumber) {
      Loading.dots();
      axios
        .post("https://www.ctsvsolutions.com/onepage-Api/public/api/verify-Message-OTP", {
          mobile_number: UserInput,
          otp: userOtp,
        })
        .then((response) => {
          Loading.remove();
            response.data.status === false
              ? setOtpError("Invalid Otp,try again")
              : setOtpError("");
          if (response.data.status === true) {
            const access_token = response.data.access_token;
            const user_id = response.data.user_id;
            localStorage.setItem("authToken", access_token);
            localStorage.setItem("LogedUser", user_id);
            window.location.replace("/user");
          }
        });
    } else {
      setError(
        "Invalid input. Please enter a valid email or 10-digit mobile number."
      );
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();

    const isEmail = /\S+@\S+\.\S+/.test(UserInput);
    const isMobileNumber = /^\d{10}$/.test(UserInput);

    if (isEmail) {
      Loading.dots();
      // User input is an email
      axios
        .post("https://www.ctsvsolutions.com/onepage-Api/public/api/Email-Otp", {
          email: UserInput,
        })
        .then((response) => {
          Notify.success(`OTP have been sent to ${UserInput} successfully...`);
          Loading.remove();

          setOtpSent(true);
          // setShowOtpInput(true);
        });
    } else if (isMobileNumber) {
      Loading.dots();
      // User input is a 10-digit number
      axios
        .post("https://www.ctsvsolutions.com/t_api/public/api/Send-Normal-OTP", {
          mobile_number: UserInput,
        })
        .then((response) => {
          Notify.success(`OTP have been sent to ${UserInput} successfully...`);
          Loading.remove();

          setOtpSent(true);
          // setShowOtpInput(true);
        });
    } else {
      setError(
        "Invalid input. Please enter a valid email or 10-digit mobile number."
      );
  };
}

  return (
    <>
      <TopNav />
      <div style={LoginStyle.container} className="mt-5">
        <div style={LoginStyle.card}>
          <div style={LoginStyle.title}>Login</div>
          <form>
            {!otpSent ? (
              <>
                <div style={LoginStyle.subTitle}>
                  Enter your Email or Mobile Number
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={UserInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  style={LoginStyle.input}
                />

                <button
                  style={{
                    ...LoginStyle.button,
                    background: "#3498db",
                    color: "#fff",
                  }}
                  onClick={handleGetOtp}
                >
                  Get OTP
                </button>

                {Error && <div style={LoginStyle.error}>{Error}</div>}
              </>
            ) : (
              <>
                <div style={LoginStyle.subTitle}>
                  OTP has been sent successfully. Please check and enter below.
                </div>

                {OtpError && <div style={LoginStyle.error}>{OtpError}</div>}
                <input
                  type="text"
                  className="form-control"
                  maxLength="4"
                  onChange={(e) => setOtp(e.target.value)}
                  value={userOtp}
                  style={LoginStyle.input}
                />

                <button
                  style={{
                    ...LoginStyle.button,
                    background: "#2ecc71",
                    color: "#fff",
                  }}
                  onClick={handleVerifyOtp}
                >
                  Submit
                </button>
                <div
                  style={{ textAlign: "center", marginTop: "20px" }}
                  className="row"
                >
                  <div className="col-6">
                    <button
                      style={{ ...LoginStyle.button, color: "#333" }}
                      onClick={Back}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      style={{
                        ...LoginStyle.button,
                        background: "#e74c3c",
                        color: "#fff",
                      }}
                      onClick={handleResendOtp}
                    >
                      Resend OTP
                    </button>
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}></div>
              </>
            )}
          </form>
        </div>
      </div>
      <div className="mt-0">
        <Footer />
      </div>
    </>
  );
}

export default Login;
