import React from "react";
import Menubar from "../header/Menubar";
import Footer from "../footer/Footer";
import BabyNameForm from "../Baby-Name-form/BabyNameForm";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
function BabyName() {

  const location = useLocation();

  const canonical = () => {
    const base_url = "https://onepagehoroscope.com";
    const current_path = location.pathname;

    const canonicalPaths = {
      "/Baby-Name-List": "/Baby-Name-List",
      // Add more canonical paths if needed
    };

    return (
      <link
        rel="canonical"
        href={base_url + (canonicalPaths[current_path] || current_path)}
      />
    );
  };
  


  return (
    <>
      <Helmet>
        <title>Numerology baby names - நியூமெராலஜி குழந்தை பெயர்</title>
        <meta
          name="description"
          content="Numerology baby names  time of birth, date of birth, place of birth,  star-matched names, numerology and pyramid numerology in an easy way."
        />
        <meta
          name="keywords"
          content="baby names, tamil baby names,baby boy names,baby girl names hindu baby names, numerology names, numerology baby names, name numerology calculator, name number calculator, indian numerology name calculator, குழந்தை பெயர் "
        />
        <meta name="robots" content="index,follow" />
        {canonical()}
        <script type="application/ld+json">
          {`
             "@context": "https://schema.org/", 
             "@type": "Product", 
             "name": "Numerology Baby Names",
             "image": "",
             "description": "Numerology baby names time of birth, date of birth, place of birth, star-matched names, numerology and pyramid numerology in an easy way.",
             "brand": {
               "@type": "Brand",
               "name": "One Page Horoscope"           
          `}
        </script>
      </Helmet>
      <Menubar />
      <BabyNameForm />
      <BabyNameContent />
      <Footer />
    </>
  );
}



const BabyNameContent=()=>{
  return(
    <>
    <div className="babyname_content container" style={{ lineHeight: "33px" }}>
                  <h2 className="fs-4" style={{ marginTop: "1.25rem", marginBottom: "1.25rem", fontWeight: 550, lineHeight: "1.4em", color: "#b2012f" }}
                  >நியூமெராலஜி குழந்தை பெயர்:</h2>
                  <p className="text-dark">குழந்தை பிறந்தவுடன், எந்தப் பெயர் வைத்தால் அக்குழந்தையின்
                     வருங்காலம் சிறப்பாக, அதிர்ஷ்டமானதாக அமையும் என்று ஆய்வு
                     செய்வதும், சிறப்பான பெயர் அமைய வேண்டும் என்பதும் பெற்றோர்களின்
                     விருப்பமாக இருக்கிறது. 
                  </p>
                  <p className="text-dark">நட்சத்திரம்: சாஸ்திர, துரித, சாது நட்சத்திரங்கள் முறையே புனர்பூசம்,
                     சுவாதி, திருவோணம், அவிட்டம், சதயம், ரோகிணி, உத்திரம், உத்திராடம்,
                     உத்திரட்டாதி, அசுவினி, பூசம், அஸ்தம், மிருகசீரிடம், சித்திரை, அனுஷம்
                     அல்லது ரேவதி சிறந்தவை. 
                  </p>
                  <h2 className="fs-4" style={{ marginTop: "1.25rem", marginBottom: "1.25rem", fontWeight: 550, lineHeight: "1.4em", color: "#b2012f" }}>பிரமிட் எண்கணித முறை:</h2>
                  <p className="text-dark">பிரமிட் எண்கணித முறை என்பது ஒப்பற்ற பலன் தரக்கூடிய ஒரு பழமையான முறையாகும்.
                  </p>
                  <p className="text-dark">வீட்டிட்ற்கு அஸ்திவாரம் போன்றும், மரத்திற்கு ஆணி வேர் போன்றும், கப்பலுக்கு நங்கூரம்
                     போன்றும் செயல்படும். இது பார்வைர் க்குப் புலப்படாமல் பெயருக்குள் மறைந்திருக்கும் ஒரு
                     சூட்சட்ம எண்ணாகும். 
                  </p>
                  <p className="text-dark">அதிர்ஷ்ட எண்ணில் பெயர் அமையும் போது நல்ல பலன் விளையும்.
                     அதே சமயம் பிரமிட் எண்ணும் அதிர்ஷ்ட எண்ணாக இருந்து விட்டாட் ல் மிக உன்னதமான பலன்
                     கிடைக்கும். 
                  </p>
                  <p className="text-dark">இந்த அபூர்வ முறையில் தான் இப்பெயர் பட்டிட்யல் தயாரிக்கப்பட்டுள்ளது.
                     அனைத்துப் பெயர்களும் அதிர்ஷ்ட எண்ணில் இருந்தாலும், அவற்றில் ஒருசில பெயர்களுக்கு
                     மட்டுமே பிரமிட் எண்ணும் அதிர்ஷ்ட எண்ணாக அமைந்திருக்கும். 
                  </p>
                  <p className="text-dark">அப்படிப்பட்ட சிறந்த
                     பெயர்களை அடையாளம் காட்ட * குறியீடு இடப்பட்டிட்ருக்கிறது. * குறியீடு உள்ள
                     பெயர்களைத் தேர்வு செய்து உங்கள் குழந்தையின் எதிர்காலத்தை வளமாக்க எல்லாம் வல்ல
                     இறைவனைப் பிரார்த்திக்கிறோம்.
                  </p>
                </div>
    </>
  );
} 
export default BabyName;